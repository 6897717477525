import { 
    SET_EMAIL_TEMPLATES_LIST,
    SET_ERROR_EMAIL_TEMPLATES,
    SET_LOADING_EMAIL_TEMPLATES,
    SET_SINGLE_EMAIL_TEMPLATE,
    SET_SINGLE_EMAIL_TEMPLATE_ERROR,
    SET_SINGLE_EMAIL_TEMPLATE_LOADING,
    SET_UPDATE_EMAIL_TEMPLATE_ERROR,
    SET_UPDATE_EMAIL_TEMPLATE_LOADING,
    SET_UPDATE_EMAIL_TEMPLATE_SUCCESS,
    SET_CREATE_EMAIL_TEMPLATE_ERROR,
    SET_CREATE_EMAIL_TEMPLATE_LOADING,
    SET_CREATE_EMAIL_TEMPLATE_SUCCESS,
    SET_DELETE_EMAIL_TEMPLATE_ERROR,
    SET_DELETE_EMAIL_TEMPLATE_SUCCESS,
    SET_DELETE_EMAIL_TEMPLATE_LOADING,
    SET_SENDGRID_URL,
    SET_SENDGRID_URL_ERROR,
    SET_SENDGRID_URL_LOADING,
    SET_SENDGRID_URL_SUCCESS,
    SET_EMAIL_TEMPLATE_CURRENT_PAGE,
    SET_EMAIL_TEMPLATE_FIRST_PAGE,
    SET_EMAIL_TEMPLATE_LAST_PAGE,
    SET_EMAIL_TEMPLATE_PREV_PAGE,
    SET_EMAIL_TEMPLATE_NEXT_PAGE,
    SET_TOTAL_EMAIL_TEMPLATES,
    SET_MEILI_EMAIL_TEMPLATES,
    SET_MEILI_TOTAL_EMAIL_TEMPLATES,
    SET_MEILI_TOTAL_PAGES,
    SET_MEILI_EMAIL_TEMPLATES_ERROR,
    SET_MEILI_EMAIL_TEMPLATES_CURRENT_PAGE,
    SET_MEILI_EMAIL_TEMPLATES_FIRST_PAGE,
    SET_MEILI_EMAIL_TEMPLATES_LAST_PAGE,
    SET_MEILI_EMAIL_TEMPLATES_PREV_PAGE,
    SET_MEILI_EMAIL_TEMPLATES_NEXT_PAGE,
    SET_MEIlI_RESET_PAGINATION
} from "./emailTemplate.types";

const INITIAL_STATE = {
    emailTemplateList              : [],
    errorEmailTemplate             : null,
    loadingEmailTemplate           : false,
    singleEmailTemplate            : [],
    singleEmailTemplateError       : null,
    singleEmailTemplateLoading     : false,
    updateEmailTemplateSuccess     : false,
    updateEmailTemplateError       : null,
    updateEmailTemplateLoading     : false,
    createEmailTemplateSuccess     : false,
    createEmailTemplateError       : false,
    createEmailTemplateLoading     : false,
    deleteEmailTemplateError       : null,
    deleteEmailTemplateSuccess     : false,
    deteleEmailTemplateLoading     : false,
    sendgridUrl                    : {},
    sendgridUrlSuccess             : false,
    sendgridUrlError               : null,
    sendgridUrlLoading             : false,
    emailTemplateCurrentPage       : 0,
    emailTemplateFirstPage         : 0,
    emailTemplateLastPage          : 0,
    emailTemplatePrevPage          : 0,
    emailTemplateNextPage          : 0,
    totalEmailTemplates            : 0,
    meiliEmailTemplates            : [],
    meiliTotalEmailTemplates       : 0,
    meiliTotalPages                : 0,
    meiliEmailTemplatesError       : null,
    meiliEmailTemplatesCurrentPage : 0,
    meiliEmailTemplatesFirstPage   : 0,
    meiliEmailTemplatesLastPage    : 0,
    meiliEmailTemplatesPrevPage    : 0,
    meiliEmailTemplatesNextPage    : 0
};

const emailTemplateReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SET_EMAIL_TEMPLATES_LIST:
            return {
                ...state,
                emailTemplateList : action.payload
            };
        case SET_ERROR_EMAIL_TEMPLATES:
            return {
                ...state,
                errorEmailTemplate : action.payload
            }
        case SET_LOADING_EMAIL_TEMPLATES:
            return {
                ...state,
                loadingEmailTemplate : action.payload
            }
        case SET_SINGLE_EMAIL_TEMPLATE:
            return {
                ...state,
                singleEmailTemplate : action.payload
            }
        case SET_SINGLE_EMAIL_TEMPLATE_ERROR:
            return {
                ...state,
                singleEmailTemplateError : action.payload
            }
        case SET_SINGLE_EMAIL_TEMPLATE_LOADING:
            return {
                ...state,
                singleEmailTemplateLoading : action.payload
            }
        case SET_UPDATE_EMAIL_TEMPLATE_ERROR:
            return {
                ...state,
                updateEmailTemplateError : action.payload
            }
        case SET_UPDATE_EMAIL_TEMPLATE_SUCCESS:
            return {
                ...state,
                updateEmailTemplateSuccess : action.payload
            }
        case     SET_UPDATE_EMAIL_TEMPLATE_LOADING:
            return {
                ...state,
                updateEmailTemplateLoading : action.payload
            }
        case     SET_CREATE_EMAIL_TEMPLATE_ERROR:
            return {
                ...state,
                createEmailTemplateError : action.payload
            }
        case     SET_CREATE_EMAIL_TEMPLATE_SUCCESS:
            return {
                ...state,
                createEmailTemplateSuccess : action.payload
            }
        case     SET_CREATE_EMAIL_TEMPLATE_LOADING:
            return {
                ...state,
                createEmailTemplateLoading : action.payload
            }
        case     SET_DELETE_EMAIL_TEMPLATE_ERROR:
            return {
                ...state,
                deleteEmailTemplateError : action.payload
            }
        case     SET_DELETE_EMAIL_TEMPLATE_SUCCESS:
            return {
                ...state,
                deleteEmailTemplateSuccess : action.payload
            }
        case     SET_DELETE_EMAIL_TEMPLATE_LOADING:
            return {
                ...state,
                deteleEmailTemplateLoading : action.payload
            }
        case SET_SENDGRID_URL:
            return {
                ...state,
                sendgridUrl : action.payload
            }
        case SET_SENDGRID_URL_LOADING:
            return {
                ...state,
                sendgridUrlLoading : action.payload
            }
        case SET_SENDGRID_URL_ERROR:
            return {
                ...state,
                sendgridUrlError : action.payload
            }
        case SET_SENDGRID_URL_SUCCESS:
            return {
                ...state,
                sendgridUrlSuccess : action.payload
            }
        case SET_EMAIL_TEMPLATE_CURRENT_PAGE:
            return {
                ...state,
                emailTemplateCurrentPage : action.payload
            }
        case SET_EMAIL_TEMPLATE_FIRST_PAGE:
            return {
                ...state,
                emailTemplateFirstPage : action.payload
            }
        case SET_EMAIL_TEMPLATE_LAST_PAGE:
            return {
                ...state,
                emailTemplateLastPage : action.payload
            }
        case SET_EMAIL_TEMPLATE_PREV_PAGE:
            return {
                ...state,
                emailTemplatePrevPage : action.payload
            }
        case SET_EMAIL_TEMPLATE_NEXT_PAGE:
            return {
                ...state,
                emailTemplateNextPage : action.payload
            }
        case SET_TOTAL_EMAIL_TEMPLATES:
            return {
                ...state,
                totalEmailTemplates : action.payload
            }
        case SET_MEILI_EMAIL_TEMPLATES:
            return {
                ...state,
                meiliEmailTemplates : action.payload
            }
        case SET_MEILI_TOTAL_EMAIL_TEMPLATES:
            return {
                ...state,
                meiliTotalEmailTemplates : action.payload
            }
        case SET_MEILI_TOTAL_PAGES:
            return {
                ...state,
                meiliTotalPages : action.payload
            }
        case SET_MEILI_EMAIL_TEMPLATES_ERROR:
            return {
                ...state,
                meiliEmailTemplatesError : action.payload
            }
        case SET_MEILI_EMAIL_TEMPLATES_CURRENT_PAGE:
            return {
                ...state,
                meiliEmailTemplatesCurrentPage : action.payload
            }
        case SET_MEILI_EMAIL_TEMPLATES_FIRST_PAGE:
            return {
                ...state,
                meiliEmailTemplatesFirstPage : action.payload
            }
        case SET_MEILI_EMAIL_TEMPLATES_LAST_PAGE:
            return {
                ...state,
                meiliEmailTemplatesLastPage : action.payload
            }
        case SET_MEILI_EMAIL_TEMPLATES_PREV_PAGE:
            return {
                ...state,
                meiliEmailTemplatesPrevPage : action.payload
            }
        case SET_MEILI_EMAIL_TEMPLATES_NEXT_PAGE:
            return {
                ...state,
                meiliEmailTemplatesNextPage : action.payload
            }
        case SET_MEIlI_RESET_PAGINATION:
            return {
                ...state,
                meiliTotalEmailTemplates      : 0,
                meiliTotalPages               : 0,
                meiliEmailTemplatesCurrentPage: 0,
                meiliEmailTemplatesFirstPage  : 0,
                meiliEmailTemplatesLastPage   : 0,
                meiliEmailTemplatesPrevPage   : 0
            }
        default:
            return state;
    }
};

export default emailTemplateReducer;
