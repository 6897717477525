import {combineReducers}          from "redux";
import userReducer                from "./User/user.reducer";
import clientsReducer             from "./Clients/clients.reducer";
import productsReducer            from "./Products/products.reducer";
import servicesReducer            from "./Services/services.reducer";
import purchasesReducer           from "./Purchases/purchases.reducer";
import rebillBreakdownReducer     from "./Statistics/RebillBreakdown/rebill-breakdown.reducer";
import websitesReducer            from "./Websites/websites.reducer";
import localesReducer             from "./Locale/locale.reducer";
import marketReducer              from "./Market/market.reducer";
import projectReducer             from "./Project/project.reducer";
import companyReducer             from "./Company/company.reducer";
import paymentPlanSettingsReducer from "./PaymentPlanSettings/payment-plan-setting.reducer";
import paymentGatewayReducer      from "./PaymentGateway/payment-gateway.reducer";
import statsOrderActive           from "./Statistics/StatsOrderActive/stats-order-active.reducer";
import emailTemplateReducer       from "./EmailTemplate/emailTemplate.reducer";
import globalReducer              from "./Global/global.reducer";
import commentsReducer            from "./Comments/comments.reducer";
import websitesConfigReducer      from "./WebsitesConfig/websites-config.reducer";

const rootReducer = combineReducers(
    {
        user               : userReducer,
        clients            : clientsReducer,
        products           : productsReducer,
        services           : servicesReducer,
        purchases          : purchasesReducer,
        rebillBreakdown    : rebillBreakdownReducer,
        websites           : websitesReducer,
        locales            : localesReducer,
        market             : marketReducer,
        project            : projectReducer,
        company            : companyReducer,
        paymentPlanSettings: paymentPlanSettingsReducer,
        paymentGateway     : paymentGatewayReducer,
        order              : statsOrderActive,
        emailTemplates     : emailTemplateReducer,  
        globalState        : globalReducer,
        comments           : commentsReducer,
        websitesConfig     : websitesConfigReducer
    }
);

export default rootReducer;
