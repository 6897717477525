import React           from 'react'
import Navigation      from '../../../components/Navigation/Navigation';
import { Link }        from 'react-router-dom';
import routerConstants from "../../../constants/router-constants";
import { useForm }     from 'react-hook-form';
import { 
    Breadcrumb, 
    Card,
    Row,
    Col,
    Button
}                       from 'react-bootstrap';
import { 
    postCompany,
    getCompanyList
}                       from '../../../redux/Company/company.async-actions';
import { connect }      from 'react-redux';
import { withRouter }   from 'react-router-dom';
import Loader           from '../../../components/Common/Loader';

class CreateCompany extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            sideMenu : true
        }
    }

    _onSideMenu = (active) => {
        this.setState({sideMenu: active})
    }

    formularWebsite = () => {
        return <>
            <Card>
                <Card.Body>
                    <Card.Title>Create Companies</Card.Title>
                    <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                        <div className="row">
                            <div className="col">
                                <label>Address</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Address"
                                    name="address"
                                    {...this.props.register('address',
                                        {
                                            required: {
                                                value  : true,
                                                message: <span style={{color: 'red'}}>This field is required</span>
                                            }
                                        }
                                    )}
                                />
                                {this?.props?.errors?.address?.message}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label>City</label>
                                <input
                                    type="text"
                                    placeholder="City"
                                    className="form-control"
                                    {...this.props.register('city',
                                        {
                                            required: {
                                                value  : true,
                                                message: <span style={{color: 'red'}}>This field is required</span>
                                            }
                                        }
                                    )}
                                />
                                {this?.props?.errors?.city?.message}
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col">
                                <label>Country</label>
                                <input
                                    type="text"
                                    placeholder="Country"
                                    className="form-control"
                                    {...this.props.register('country',
                                        {
                                            required: {
                                                value  : true,
                                                message: <span style={{color: 'red'}}>This field is required</span>
                                            }
                                        }
                                    )}
                                />
                                {this?.props?.errors?.country?.message}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label>Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Name"
                                    {...this.props.register('name',
                                        {
                                            required: {
                                                value  : true,
                                                message: <span style={{color: 'red'}}>This field is required</span>
                                            }
                                        }
                                    )}
                                />
                                {this?.props?.errors?.name?.message}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label>Number</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="number"
                                    {...this.props.register('number',
                                        {
                                            required: {
                                                value  : true,
                                                message: <span style={{color: 'red'}}>This field is required</span>
                                            }
                                        }
                                    )}
                                />
                                {this?.props?.errors?.number?.message}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label>Vat Number</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Vat Number"
                                    {...this.props.register('vatNumber',
                                        {
                                            required: {
                                                value  : true,
                                                message: <span style={{color: 'red'}}>This field is required</span>
                                            }
                                        }
                                    )}
                                />
                                {this?.props?.errors?.vatNumber?.message}
                            </div>
                        </div>

                        <div style={{padding : "10px"}} />

                        <div className="text-center">
                            <Button
                                variant="primary"
                                type="submit">
                                Create Companies
                            </Button>
                        </div>
                    </form>
                </Card.Body>
            </Card>
        </>
    }

    goToRoute(route) {
        this.props.history.push(route);
    }

    onSubmit = (data) => {
        this.props.postCompany(data)

        if (true !== this.props.postSuccess) {
            this.goToRoute(`/list-company`)
        }
    }

    render() {
        let loader = null;

        if (this.props.postLoading) {
            loader = <Loader message="Loading..." />
        }

        return <>
            <div className="page-wrapper">
                <Navigation onclick={this._onSideMenu} />
                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? "" : "hide-sidemenu"}`}>
                    {/* Loader */}
                    {loader}
                    {/* End Loader */}
                    
                    {true === this.props.postSuccess &&
                        <div className="alert alert-success" role="alert">
                            Success
                        </div>
                    }

                    {true === this.props.postError &&
                        <div className="alert alert-danger" role="alert">
                            Fail
                        </div>
                    }

                    {/* Breadcrumb */}
                    <div className="main-content-header">
                        <Breadcrumb>
                            <h1>Create Companies</h1>
                            <Link to={routerConstants.dashboard} className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Link to={routerConstants.listCompany} className="breadcrumb-item">
                                List Companies
                            </Link>
                            <Breadcrumb.Item active>
                                Create Companies
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <Row>
                        <Col lg={12}>
                            {this.formularWebsite()}
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    }
}

const CreateCompanyHookForm = (props) => {
    const {register, handleSubmit, formState : { errors }} = useForm();

    return <CreateCompany 
        {...props}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
    />
}

const mapStateToProps = state => {
    return {
        postLoading: state.company.setPostCompanyLoading,
        postSuccess: state.company.setPostCompanySucced,
        postError  : state.company.setPostCompanyError
    }
}

const mapActionsToProps = {
    postCompany,
    getCompanyList
}

const CreateCompanyConnected = connect(
    mapStateToProps,
    mapActionsToProps
)(CreateCompanyHookForm)

export default withRouter(CreateCompanyConnected);
