import axios from "axios";
import store from "../redux/store";

const http = axios.create(
    {
        baseURL: `/api/`
    }
);

http.interceptors.request.use(
    config => {

        if ("users/token/refresh" === config.url) {
            return config;
        }

        // If the request is a PATCH request, then force use the proper Content Type
        if ("patch" === config.method.toLowerCase()) {
            config.headers["Content-Type"] = "application/merge-patch+json";
        }

        if (-1 !== ["post", "put"].indexOf(config.method.toLowerCase())) {
            config.headers["Content-Type"] = "application/json";
        }

        if (store && typeof store.getState === "function") {
            const state = store.getState();

            if (state.user.access_token !== "" && false !== state.user.authenticated) {
                config.headers.common["Authorization"] = `Bearer ${state.user.access_token}`;
            }
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export default http;
