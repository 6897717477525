import http from "../../services/http";
import {
    setLoadingWebsitesNamesAndIds,
    setWebsitesNamesAndIds, setWebsitesNamesAndIdsLoadingError,
    setLoadingWebsite,
    setListWebsites,
    singleWebsiteLoading,
    setSingleWebsite,
    updateWebsiteLoading,
    createWebsitesLoading,
    websiteFormSuccess,
    websiteFormFail
}           from "./websites.actions";

export const fetchWebsiteDomainsAndIds = (queryParams = {}) => {
    return async dispatch => {
        dispatch(setLoadingWebsitesNamesAndIds(true));
        dispatch(setWebsitesNamesAndIdsLoadingError(null));

        try {
            const response = await http.get(
                `websites/domains-and-ids`,
                {
                    params: queryParams
                }
            );

            if (response?.data?.["hydra:member"]) {
                dispatch(setWebsitesNamesAndIds(response.data["hydra:member"]));
            }
            dispatch(setLoadingWebsitesNamesAndIds(false));
            dispatch(setWebsitesNamesAndIdsLoadingError(null));
        } catch (e) {
            let errorMessage = e?.response?.data["hydra:description"] ?? e.message;

            dispatch(setLoadingWebsitesNamesAndIds(false));
            dispatch(setWebsitesNamesAndIdsLoadingError(errorMessage));
        }
    };
};

export const getWebsites = () => {
    return async dispatch => {
        dispatch(setLoadingWebsite(true));
        try {
            const response = await http.get(`websites`);

            dispatch(setListWebsites(response.data["hydra:member"]));
        } catch (e) {
            console.dir(e);
        } finally {
            dispatch(setLoadingWebsite(false));
        }
    };
};

export const updateWebsite = (websiteId, data) => {
    return async dispatch => {
        dispatch(updateWebsiteLoading(true));
        dispatch(websiteFormSuccess(null));
        dispatch(websiteFormFail(null));

        try {
            await http.patch(
                `websites/${websiteId}`,
                {
                    descriptor        : data.descriptor,
                    domain            : data.domain,
                    phoneNumber       : data.phoneNumber,
                    supportEmail      : data.supportEmail,
                    theme             : data?.theme,
                    gtmId             : data?.gtmId,
                    contactForm       : data?.contactForm,
                    trengoKey         : data?.trengoKey,
                    cookieBannerActive: data?.bannerActive,
                    ...(data.paymentGateway ? {paymentGateway: data.paymentGateway.value} : null)
                }
            );

            dispatch(websiteFormFail(false));
            dispatch(websiteFormSuccess(true));
        } catch (e) {
            let errorMessage = e?.response?.data["hydra:description"] ?? e.message;
            dispatch(websiteFormSuccess(false));
            dispatch(websiteFormFail(errorMessage));
        } finally {
            dispatch(updateWebsiteLoading(false));
            setTimeout(
                () => {
                    dispatch(websiteFormSuccess(null));
                },
                5000
            );
        }
    };
};

export const getSingleWebsite = (website_uuid) => {
    return async dispatch => {
        dispatch(singleWebsiteLoading(true));
        try {
            const response = await http.get(`websites/${website_uuid}`);

            dispatch(setSingleWebsite(response.data));
        } catch (e) {
            console.dir(e);
        } finally {
            dispatch(singleWebsiteLoading(false));
        }
    };
};

export const createWebsites = (
    data,
    companyId,
    marketId,
    projectId,
    localeId,
    paymentGatewayId,
    theme,
    contactForm
) => {
    return async dispatch => {
        dispatch(createWebsitesLoading(true));
        dispatch(websiteFormSuccess(null));
        dispatch(websiteFormFail(null));
        try {
            await http.post(`websites`, {
              descriptor: data.descriptor,
              domain: data.domain,
              phoneNumber: data.phoneNumber,
              supportEmail: data.supportEmail,
              cookieBannerActive: data.bannerActive,
              gtmId: data.gtmId,
              trengoKey: data?.trengoKey,
              locale: localeId,
              project: projectId,
              market: marketId,
              company: companyId,
              contactForm: contactForm,
              ...(paymentGatewayId
                ? { paymentGateway: paymentGatewayId }
                : null),
              theme,
            });

            dispatch(websiteFormSuccess(true));
        } catch (e) {
            dispatch(websiteFormSuccess(false));
            dispatch(websiteFormFail(e.message));
        } finally {
            dispatch(createWebsitesLoading(false));
            setTimeout(
                () => {
                    dispatch(websiteFormSuccess(null));
                },
                5000
            );
        }
    };
};
