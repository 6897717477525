import {
    SET_LOADING_PAYMENT_PLAN_SETTING_LIST,
    SET_PAYMENT_PLAN_SETTING_LIST,
    SET_PAYMENT_PLAN_SETTING_LIST_ERROR
} from "./payment-plan-setting.types";

const INITIAL_STATE = {
    paymentPlanSettingList       : [],
    loadingPaymentPlanSettingList: false,
    paymentPlanSettingListError  : null
};

const paymentPlanSettingReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_PAYMENT_PLAN_SETTING_LIST:
            return {
                ...state,
                paymentPlanSettingList: action.payload
            };
        case SET_LOADING_PAYMENT_PLAN_SETTING_LIST:
            return {
                ...state,
                loadingPaymentPlanSettingList: action.payload
            };
        case SET_PAYMENT_PLAN_SETTING_LIST_ERROR:
            return {
                ...state,
                paymentPlanSettingListError: action.payload
            };

        default:
            return state;
    }
};

export default paymentPlanSettingReducer;
