class ListSorter {
    /**
     * Order a list of objects by a specified property
     * @param list
     * @param property
     * @returns {*}
     */
    static orderListByProperty(list, property) {
        return list.sort((a, b) => {
            if (a[property] < b[property]) {
                return -1;
            }

            if (a[property] > b[property]) {
                return 1;
            }

            return 0;
        });
    }
}

export default ListSorter;
