export const SET_USER_DATA                      = "SET_USER_DATA";
export const CLEAR_USER_DATA                    = "CLEAR_USER_DATA";
export const SET_ACCESS_TOKEN                   = "SET_ACCESS_TOKEN";
export const SET_REFRESH_TOKEN                  = "SET_REFRESH_TOKEN";
export const CLEAR_ACCESS_TOKEN                 = "CLEAR_ACCESS_TOKEN";
export const CLEAR_REFRESH_TOKEN                = "CLEAR_REFRESH_TOKEN";
export const SET_PROCESSING                     = "SET_PROCESSING";
export const SET_AUTHENTICATION_ERROR           = "SET_AUTHENTICATION_ERROR";
export const CLEAR_AUTHENTICATION_ERROR         = "CLEAR_AUTHENTICATION_ERROR";
export const SET_SENDING_PASSWORD_RESET_REQUEST = "SET_SENDING_PASSWORD_RESET_REQUEST";
export const SET_PASSWORD_RESET_ERROR           = "SET_PASSWORD_RESET_ERROR";
export const CLEAR_PASSWORD_RESET_ERROR         = "CLEAR_PASSWORD_RESET_ERROR";
export const SET_PASSWORD_RESET_SUCCESS         = "SET_PASSWORD_RESET_SUCCESS";
export const CLEAR_PASSWORD_RESET_SUCCESS       = "CLEAR_PASSWORD_RESET_SUCCESS";
export const SET_SENDING_NEW_PASSWORD_RESET     = "SET_SENDING_NEW_PASSWORD_RESET";
export const SET_NEW_PASSWORD_ERROR             = "SET_NEW_PASSWORD_ERROR";
export const CLEAR_NEW_PASSWORD_ERROR           = "CLEAR_NEW_PASSWORD_ERROR";
export const SET_NEW_PASSWORD_SUCCESS           = "SET_NEW_PASSWORD_SUCCESS";
export const CLEAR_NEW_PASSWORD_SUCCESS         = "CLEAR_NEW_PASSWORD_SUCCESS";
export const SET_USERS_LIST                     = "SET_USERS_LIST";
export const SET_USERS_LIST_ERROR               = "SET_USERS_LIST_ERROR";
export const SET_SUCCESS_CREATE_USER            = "SET_SUCCESS_CREATE_USER";
export const SET_ERROR_CREATE_USER              = "SET_ERROR_CREATE_USER";
export const SET_LOADING_CREATE_USER            = "SET_LOADING_CREATE_USER";
export const SET_SINGLE_USER                    = "SET_SINGLE_USER";
export const SET_SINGLE_USER_ERROR              = "SET_SINGLE_USER_ERROR";
export const SET_UPDATE_USER_SUCCESS            = "SET_UPDATE_USER_SUCCESS";
export const SET_UPDATE_USER_ERROR              = "SET_UPDATE_USER_ERROR";
export const SET_LOADING_UPDATE_USER            = "SET_LOADING_UPDATE_USER";
export const SET_DELETE_USER_SUCCESS            = "SET_DELETE_USER_SUCCESS";
export const SET_DELETE_USER_ERROR              = "SET_DELETE_USER_ERROR";
export const SET_DELETE_USER_LOADING            = "SET_DELETE_USER_LOADING";
export const SET_MEILI_SEARCH_TOKEN             = "SET_MEILI_SEARCH_TOKEN";
export const CLEAR_MEILI_SEARCH_TOKEN           = "CLEAR_MEILI_SEARCH_TOKEN";
