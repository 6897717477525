import React                from 'react'
import Navigation           from '../../../components/Navigation/Navigation';
import routerConstants      from "../../../constants/router-constants";
import {
    Breadcrumb,
    Row,
    Col,
    Button,
    Table,
    Form,
    ButtonGroup
 }                          from 'react-bootstrap';
import { Link }             from 'react-router-dom';
import { getServicesList }  from '../../../redux/Services/services.async-actions';
import { connect }          from 'react-redux';
import Footer               from '../../Footer/Footer';
import { withRouter }       from 'react-router-dom';
import UUIDs                from '../../../services/UUIDs';
import * as Icon            from "react-feather";
import Loader               from '../../../components/Common/Loader';
import ListSorter           from "../../../Utils/ListSorter";

class ListServices extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            sideMenu : true
        }

        this.form         = React.createRef();
        this.serviceName  = React.createRef();
    }

    _onSideMenu = (active) => {
        this.setState({sideMenu: active});
    };

    goToRoute(route) {
        this.props.history.push(route);
    }

    getService = async (queryParams) => {
        await this.props.getServicesList(queryParams);
    }

    async componentDidMount(queryParams = {}) {
        queryParams = {
            "order[name]": "ASC"
        }

        await this.getService(queryParams);
    }

    mapServices = () => ListSorter.orderListByProperty(
            this?.props?.servicesList, 'name'
        )?.map(
            services => {
                const servicesId = services["@id"]
                const servicesType = services["@type"]
                const { createdAt, description, name, slug, updatedAt } = services;

                return (<tr key={servicesId}>
                    <td>
                        {servicesType}
                    </td>
                    <td>
                        {createdAt}
                    </td>
                    <td>
                        {description}
                    </td>
                    <td>
                        {name}
                    </td>
                    <td>
                        {slug}
                    </td>
                    <td>
                        {updatedAt}
                    </td>
                    <td>
                        <Button
                            variant="outline-primary"
                            as={"button"}
                            onClick={e => this.goToRoute(`/list-services/update/${UUIDs.getEntityUUID(
                                servicesId)}`, e)}
                        >
                            Edit
                        </Button>
                    </td>
                </tr>
            );
        }
    )

    filterPurchases = async () => {
        const serviceName   = this.serviceName.current.value;
        let formData        = {};

        if ("" !== serviceName) {
            formData["name"] = serviceName;
        }

        await this.getService(formData);
    }

    resetFormFilters = async (queryParams = {}) => {
        this.serviceName.current.value  = "";

        queryParams = {
            "order[name]": "ASC"
        }

        await this.getService(queryParams);
    }

    filterForm = () => {
        return <Form
            onReset={this.filterPurchases}
            className="mb-2"
            ref={this.form}
        >
            <Row className="mb-1">
                <Col>
                    <Form.Control
                        as="select"
                        aria-describedby="servicesHelpBlock"
                        ref={this.serviceName}
                    >
                        <option value="">Service</option>
                        {this?.props?.servicesList?.map(
                            service => (<option key={service["@id"]} value={service?.name}>{service?.name}</option>)
                        )}
                    </Form.Control>
                    {null !== this.props.serviceListError && <Form.Text
                        id="servicesHelpBlock"
                        className="text-danger"
                    >
                        {this.props.serviceListError}
                    </Form.Text>}
                </Col>
                <Col xs={"auto"}>
                    <ButtonGroup size="sm" aria-label="Form Action Buttons">
                        <Button
                            variant="primary"
                            onClick={this.filterPurchases}
                        >
                            <Icon.Search
                                className="icon icon-16"
                            />
                        </Button>
                        <Button
                            variant="dark"
                            onClick={this.resetFormFilters}
                        >
                            <Icon.Delete
                                className="icon"
                            />
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </Form>;
    };

    render() {
        let loader = null;

        if (this.props.loadingServicesList) {
            loader = <Loader message="Loading..." />;
        }

        return <>
            <div className="page-wrapper">
                {/* Navigation */}
                <Navigation onClick={this._onSideMenu} />
                {/* End Navigation */}

                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? "" : "hide-sidemenu"}`}>
                    {/* Loader */}
                    {loader}
                    {/* End Loader */}

                    {/* Breadcrumb */}
                    <div className="main-content-header">
                        <Breadcrumb>
                            <h1>List Services</h1>
                            <Link to={routerConstants.dashboard} className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Breadcrumb.Item active>
                               List Services
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    {true === this.props.editSuccess &&
                        <div className="alert alert-success" role="alert">
                            The Service has been updated successfully!
                        </div>
                    }
                    <Row>
                        <Col lg={12}>
                            <div className="card mb-4">
                                <div className="card-body">

                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <h5 className="card-title float-lg-left">Services</h5>
                                        <Button
                                            onClick={e => this.goToRoute(routerConstants.createServices, 2 )}
                                            variant="outline-primary"
                                            className="mb-2"
                                        >
                                            Create Services
                                        </Button>
                                    </div>

                                    {this.filterForm()}

                                    <Table className="m-0" responsive>
                                        <thead>
                                        <tr key="table_headers">
                                            <th>Type</th>
                                            <th width={"290px"}>Created At</th>
                                            <th width={"270px"}>Description</th>
                                            <th width={"265px"}>Name</th>
                                            <th width={"250px"}>Slug</th>
                                            <th width={"170px"}>Updated At</th>
                                            <th width={"100px"}>Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.props.servicesList.length === 0 ?
                                                    <tr key="no_purchases">
                                                        <td colSpan={7} className="text-center">No Purchases Yet</td>
                                                    </tr> :
                                                    this.mapServices()
                                            }
                                        </tbody>
                                   
                                    </Table>

                                </div>
                            </div>
                        </Col>
                    </Row>

                    {/* Footer */}
                    <div className="flex-grow-1" />
                    <Footer />
                    {/* End Footer */}
                </div>
            </div>
        </>
    }
}

const mapStateToProps = state => {
    return {
        servicesList : state.services.servicesList,
        editSuccess  : state.services.setEditServicesSuccess
    }
}

const mapActionsToProps = {
    getServicesList
}

const ListServicesConnected = connect(
    mapStateToProps,
    mapActionsToProps
)(ListServices)

export default withRouter(ListServicesConnected);
