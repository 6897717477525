import React           from "react";
import ReactDOM        from "react-dom";
import {BrowserRouter} from "react-router-dom";
import "../node_modules/bootstrap/scss/bootstrap.scss";
import AppRouter       from "./routes/AppRouter";
import MetaComponents  from "./components/Common/MetaComponents";

// Redux
import {Provider} from "react-redux";
import store      from "./redux/store";

import "./services/RefreshAccessTokens";

ReactDOM.render(
    <Provider store={store}>  
        <MetaComponents />
        <BrowserRouter basename="/">
            <AppRouter />
        </BrowserRouter>
    </Provider>,
    document.querySelector("#root")
);
