import store                             from "../redux/store";
import http                              from "./http";
import JsCookie                                               from "js-cookie";
import {setAccessToken, setMeiliSearchToken, setRefreshToken} from "../redux/User/user.actions";

class RefreshAccessTokens {
    constructor() {
        if (typeof undefined === typeof window) {
            return;
        }

        this.timer = setInterval(
            async () => this.refreshTokens(),
            1000 * 60 * 59
        );
    }

    stop() {
        clearInterval(this.timer);
    }

    async refreshTokens() {
        if (store && typeof store.getState === "function") {
            const state = store.getState();

            // If we have the access and refresh tokens in the Store, we will proceed with refreshing the Access and
            // Refresh tokens using the store data.
            if (
                state.user.access_token !== "" &&
                state.user.refresh_token !== "" &&
                false !== state.user.authenticated
            ) {
                await this.requestTokens(state.user.refresh_token);
            } else if (
                // Else if we have stored the Access and Refresh tokens in the Cookies, we will use those value to
                // refresh the Access and Refresh tokens.
                typeof undefined !== typeof JsCookie.get("at") &&
                typeof undefined !== typeof JsCookie.get("rt")
            ) {
                await this.requestTokens(JsCookie.get("rt"));
            }
        }
    }

    async requestTokens(refreshToken) {
        try {
            // First try to fetch a new JWT Token and a new Refresh Token
            const response = await http
                .post(
                    "users/token/refresh",
                    {
                        "refresh_token": refreshToken
                    }
                );

            // Then dispatch the two following actions to update the Redux Store with the new values.
            store.dispatch(setAccessToken(response.data.token));
            store.dispatch(setRefreshToken(response.data.refresh_token));
            store.dispatch(setMeiliSearchToken(response.data.meili_token));

            // Then, remove the previous Access Token from the Cookies and create a new one with the new value
            JsCookie.remove("at");
            JsCookie.set(
                "at",
                response.data.token,
                {
                    expires: 1 / 24,
                    path   : "/"
                }
            );

            // Then, remove the previous Refresh Token from the Cookies and create a new one with the new value
            JsCookie.remove("rt");
            JsCookie.set(
                "rt",
                response.data.refresh_token,
                {
                    expires: 4 / 24,
                    path   : "/"
                }
            );

            // Then, remove the previous MeiliSearch from the Cookies and create a new one with the new value
            JsCookie.remove("mt");
            JsCookie.set(
                "mt",
                response.data.meili_token,
                {
                    expires: 4 / 24,
                    path   : "/"
                }
            );
        } catch (e) {
            console.log(e);
        }
    }
}

const refreshAccessTokens = new RefreshAccessTokens();
export default refreshAccessTokens;
