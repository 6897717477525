import React                       from "react";
import {withRouter, Link, NavLink} from "react-router-dom";
import * as Icon                   from "react-feather";
import "./Navigation.scss";
import {
    Navbar,
    Nav,
    NavDropdown,
    Image
}                                  from "react-bootstrap";
import routerConstants             from "../../constants/router-constants";

import ColorSwitch from "../Common/ColorSwitch";

// Default dark side menu
import SideMenuDark  from "./SideMenu/SideMenuDark";
// If want to active light sidebar then please uncomment below & comment above component
import SideMenuLight from "./SideMenu/SideMenuLight";

// Logo image path
import Logo      from "../../assets/img/logo.png";
import SmallLogo from "../../assets/img/small-logo.png";

// Profile & user image path
import {connect} from "react-redux";
import {logout}  from "../../redux/User/user.async-actions";

class Navigation extends React.Component {
    state = {
        sideMenu : false,
        term     : "",
        menuColor: false
    };

    constructor(props) {
        super(props);

        this.logout = this.logoutAction.bind(this);
    }

    _toggleClass = () => {
        const currentSideMenu = this.state.sideMenu;
        this.setState({sideMenu: !currentSideMenu});
        this.props.onClick(this.state.sideMenu);
    };

    onSideMenuHandler = (activeColor) => {
        this.setState({menuColor: activeColor});
    };

    async logoutAction() {
        await this.props.logout(this.props.user.me.id);
    }

    render() {
        return (
            <div className="page-wrapper">
                <Navbar fixed="top" className="top-menu">
                    <Link
                        to={routerConstants.dashboard}
                        className={`navbar-brand ${this.state.sideMenu ? "navbar-logo" : ""}`}
                    >
                        {/* Large logo */}
                        <Image src={Logo} alt="Logo" className="large-logo" />
                        {/* Small logo */}
                        <Image src={SmallLogo} alt="Small Logo" className="small-logo" />
                    </Link>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" />

                    {/* Burger menu */}
                    <div
                        className={`burger-menu ${this.state.sideMenu ? "toggle-menu" : ""}`}
                        onClick={this._toggleClass}
                    >
                        <span className="top-bar" />
                        <span className="middle-bar" />
                        <span className="bottom-bar" />
                    </div>
                    {/* End Burger menu */}

                    <Navbar.Collapse id="basic-navbar-nav">

                        <Nav className="ml-auto right-nav">
                            <NavDropdown
                                title="Backoffice Menu"
                                id="basic-nav-dropdown" className="profile-nav-item"
                            >
                                <NavLink to={routerConstants.profile} className="dropdown-item">

                                    <Icon.User
                                        className="icon"
                                    />
                                    Profile
                                </NavLink>
                                <NavLink to={routerConstants.profileSettings} className="dropdown-item">
                                    <Icon.Settings
                                        className="icon"
                                    />
                                    Settings
                                </NavLink>
                                <span onClick={this.logout} className="dropdown-item logout">
                                    <Icon.LogOut
                                        className="icon"
                                    />
                                    Logout
                                </span>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>

                {/* Side Menu File Path: src/components/Navigation/SideMenu/SideMenu.js */}
                {
                    !this.state.menuColor ? <SideMenuDark sideMenu={this.state.sideMenu} /> :
                        <SideMenuLight sideMenu={this.state.sideMenu} />

                }


                {/*  */}

                {/* Side Menu File Path: src/components/Common/ColorSwitch.js */}
                <ColorSwitch onClick={this.onSideMenuHandler} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
}

const NavigationConnected = connect(
    mapStateToProps,
    {
        logout
    }
)(Navigation);

export default withRouter(NavigationConnected);
