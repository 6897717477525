import React           from "react";
import {Button, Modal} from "react-bootstrap";

class ReplaceCustomerEmailModal extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            email       : "",
            invalidEmail: false
        };
    }

    componentDidMount() {
        this.setState(
            {
                email: this.props.email
            }
        );
    }

    onChange(value) {
        this.setState(
            {
                email: value
            }
        );
    }

    onSubmit() {
        const emailValidationRegex = /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|\[[\t -Z^-~]*])/;
        const emailAddress         = this?.state?.email ?? "";

        if (!emailValidationRegex.test(emailAddress)) {
            this.setState({invalidEmail: true});

            setTimeout(
                () => {
                    this.setState({invalidEmail: false});
                },
                6000
            );
            return;
        }

        this.props.onConfirm(emailAddress);
    }

    render() {
        return (
            <div className="modal-wrap">
                <Modal
                    show={this.props.show}
                    onHide={this.handleClose}
                    backdrop="static"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>
                            <strong className="text-danger">Warning!</strong> Modify Email
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        This operation will permanently replace the email address of the
                        customer <strong>{`${this.props.customer?.lastName} ${this.props.customer?.firstName}`}</strong> in
                        the Back Office and Stripe.
                        <br />
                        <br />
                        <div>
                            <label htmlFor="emailAddress">
                                New Address
                            </label>
                            <input
                                type="email"
                                required
                                className={`emailAddress form-control ${this.state.invalidEmail || "" !== this.props.emailError ? "is-invalid" : ""}`}
                                defaultValue={this?.props?.email}
                                data-value={this?.state?.email}
                                onChange={e => this.onChange(e.target.value)}
                                id="emailAddress"
                            />
                            {this.state.invalidEmail &&
                                <span className="invalid-feedback">The email address you entered is invalid.</span>}
                            {"" !== this.props.emailError &&
                                <span className="invalid-feedback">{this.props.emailError}</span>}
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            variant="danger"
                            onClick={this.onSubmit.bind(this)}
                            disabled={this.props.processing}
                        >
                            {this.props.processing ? "Processing ..." : "Confirm"}
                        </Button>
                        <Button
                            variant="success"
                            onClick={this.props.onClose}
                            disabled={this.props.processing}
                        >
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default ReplaceCustomerEmailModal;
