export const SET_PAYMENT_GATEWAY_LIST           = "SET_PAYMENT_GATEWAY_LIST";
export const SET_LOADING_PAYMENT_GATEWAY_LIST   = "SET_LOADING_PAYMENT_GATEWAY_LIST";
export const SET_PAYMENT_GATEWAY_LIST_ERROR     = "SET_PAYMENT_GATEWAY_LIST_ERROR";
export const SET_SINGLE_PAYMENT_GATEWAY         = "SET_SINGLE_PAYMENT_GATEWAY";
export const SET_LOADING_SINGLE_PAYMENT_GATEWAY = "SET_LOADING_SINGLE_PAYMENT_GATEWAY";
export const SET_SINGLE_PAYMENT_GATEWAY_ERROR   = "SET_SINGLE_PAYMENT_GATEWAY_ERROR";
export const SET_UPDATE_PAYMENT_GATEWAY_ERROR   = "SET_UPDATE_PAYMENT_GATEWAY_ERROR";
export const SET_UPDATE_PAYMENT_GATEWAY_SUCCESS = "SET_UPDATE_PAYMENT_GATEWAY_SUCCESS";
export const SET_UPDATE_PAYMENT_GATEWAY_LOADING = "SET_UPDATE_PAYMENT_GATEWAY_LOADING";

