import EditCompanyComponents from "../../../components/Company/EditCompanyComponents"
import React                 from "react"
import { getSingleCompany }  from "../../../redux/Company/company.async-actions"
import { connect }           from "react-redux"
import { withRouter }        from "react-router-dom"
import Loader                from "../../../components/Common/Loader"

class EditCompany extends React.Component{
    constructor(props) {
        super(props)

        this.state = {
            companyData: null,
            sideMenu : true
        }
    }

    _onSideMenu = (active) => {
        this.setState({
                sideMenu: active
        });
    };

    async componentDidMount() {
        await this?.props?.getSingleCompany(this.props.match.params.company_uuid)

        this.setState({
            companyData: await this.props.singleCompany
        })
    };

    render() {
        return this.state.companyData 
            ? <EditCompanyComponents 
                companyData={this.state.companyData}
                _onSideMenu={this._onSideMenu} 
                sideMenu={this.state.sideMenu}
            /> 
            : <Loader message="Loading..." />;
    }
}

const mapStateToProps = state => {
    return {
        singleCompany: state.company.setSingleCompany
    };
}

const mapActionsToProps = {
    getSingleCompany
}

const EditCompanyConnected = connect(
    mapStateToProps,
    mapActionsToProps,
)(EditCompany);

export default withRouter(EditCompanyConnected);
