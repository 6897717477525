import http            from "../../services/http";
import JsCookie        from "js-cookie";
import {Redirect}      from "react-router-dom";
import routerConstants from "../../constants/router-constants";
import {
    clearAccessToken,
    clearAuthenticationError,
    clearNewPasswordResetError,
    clearNewPasswordResetSuccess,
    clearPasswordResetError,
    clearPasswordResetSuccess,
    clearRefreshToken,
    clearUserData,
    setAccessToken,
    setAuthenticationError,
    setNewPasswordResetError,
    setNewPasswordResetSuccess,
    setPasswordResetError,
    setPasswordResetSuccess,
    setProcessing,
    setRefreshToken,
    setSendingNewPasswordReset,
    setSendingPasswordResetRequest,
    setUserData,
    setUsersList,
    setUsersListError,
    setSuccesCreateUser,
    setLoadingCreeateUser,
    setErrorCreeateUser,
    setSingleUser,
    setSingleUserError,
    setUpdateUserError,
    setUpdateUserSuccess,
    setLoadingUpdateUser,
    setDeleteUserError,
    setDeleteUserLoading,
    setDeleteUserSuccess,
    setMeiliSearchToken, clearMeiliSearchToken
} from "./user.actions";

export const login = (username, password) => {
    return async dispatch => {
        dispatch(setProcessing(true));
        dispatch(clearAuthenticationError());

        try {
            const loginTokens = await http
                .post(
                    "users/login",
                    {
                        "username": username,
                        "password": password
                    }
                );

            dispatch(setAccessToken(loginTokens.data.token));
            dispatch(setRefreshToken(loginTokens.data.refresh_token));
            dispatch(setMeiliSearchToken(loginTokens.data.meili_token));

            const userInfo = await http.get("users/me");
            dispatch(setUserData(userInfo.data));
            dispatch(setProcessing(false));
        } catch (e) {
            const errorMessage   = "IT issue, ask the dev team for more details";
            const requestMessage = e?.response?.data?.message;

            dispatch(
                setAuthenticationError(
                    requestMessage || errorMessage
                )
            );
        }
    };
};

export const logout = uuid => {
    return async dispatch => {
        dispatch(setProcessing(true));
        dispatch(clearAuthenticationError());

        try {
            if (typeof uuid != "undefined") {
                await http.delete(`users/${uuid}/logout`);
            }

            dispatch(clearUserData());
            dispatch(clearAccessToken());
            dispatch(clearRefreshToken());
            dispatch(clearMeiliSearchToken());
            dispatch(setAuthenticationError(false));
            dispatch(setProcessing(false));
            JsCookie.remove("rt");
            JsCookie.remove("ud");
            JsCookie.remove("at");
            <Redirect to={routerConstants.login} />;
        } catch (e) {
            dispatch(setAuthenticationError(e.response.data.message));
            dispatch(setProcessing(false));
        }
    };
};

export const password_reset_request = email => {
    return async dispatch => {
        dispatch(setSendingPasswordResetRequest(true));
        dispatch(clearPasswordResetError());
        dispatch(clearPasswordResetSuccess());

        try {
            const resetUrl = `${window.location.origin}/reset-password/%token%/%user_uuid%`;
            const response = await http.post(
                "users/password/reset/request",
                {
                    email,
                    resetUrl
                }
            );

            dispatch(setPasswordResetSuccess(response.data.message));
            dispatch(setSendingPasswordResetRequest(false));

            setTimeout(
                () => {
                    dispatch(clearPasswordResetError());
                    dispatch(clearPasswordResetSuccess());
                },
                6000
            );
        } catch (e) {
            dispatch(setPasswordResetError(e.response.data.message));
            dispatch(setSendingPasswordResetRequest(false));

            setTimeout(
                () => {
                    dispatch(clearPasswordResetError());
                    dispatch(clearPasswordResetSuccess());
                },
                6000
            );
        }
    };
};

export const password_reset = (password, resetToken, user_uuid) => {
    return async dispatch => {
        dispatch(setSendingNewPasswordReset(true));
        dispatch(clearNewPasswordResetError());
        dispatch(clearNewPasswordResetSuccess());

        try {
            await http.post(
                `users/${user_uuid}/password/reset/confirm/${resetToken}`,
                {
                    password
                }
            );

            dispatch(
                setNewPasswordResetSuccess(
                    "You password has been reset. You can now login using the new password."
                )
            );
            dispatch(setSendingNewPasswordReset(false));

            setTimeout(
                () => {
                    dispatch(clearNewPasswordResetError());
                    dispatch(clearNewPasswordResetSuccess());

                    window.location.href = "/";
                },
                6000
            );
        } catch (e) {
            dispatch(setNewPasswordResetError(e.response.data.message));
            dispatch(setSendingNewPasswordReset(false));

            setTimeout(
                () => {
                    dispatch(clearNewPasswordResetError());
                    dispatch(clearNewPasswordResetSuccess());
                },
                6000
            );
        }
    };
};

export const getUsers = () => {
    return async dispatch => {

        try {
            const response = await http.get(
                `users`
            );

            dispatch(setUsersList(response?.data?.["hydra:member"]));
        } catch (e) {
            dispatch(setUsersListError(e.response.data.message));
        }
    };
};

export const createUser = (data) => {
    return async dispatch => {
        dispatch(setLoadingCreeateUser(true));
        try {
            await http.post(
                `users`,
                {
                    "email"    : data.email,
                    "password" : data.password,
                    "firstName": data.firstName,
                    "lastName" : data.lastName,
                    "roles"    : data.roles
                }
            );

            dispatch(setLoadingCreeateUser(false));
            dispatch(setSuccesCreateUser(true));
        } catch (e) {
            dispatch(setErrorCreeateUser(e.message));
            dispatch(setLoadingCreeateUser(false));
            dispatch(setSuccesCreateUser(false));
        } finally {
            setTimeout(
                () => {
                    dispatch(setErrorCreeateUser(null));
                    dispatch(setSuccesCreateUser(false));
                },
                3000
            );
        }
    };
};

export const getSingleUser = (userId) => {
    return async dispatch => {
        try {
            const response = await http.get(
                `users/${userId}`
            );

            dispatch(setSingleUser(response.data));
        } catch (e) {
            dispatch(setSingleUserError(e.message));
        }
    };
};

export const updateUser = (userId, data) => {
    return async dispatch => {
        dispatch(setLoadingUpdateUser(true));

        try {
            await http.patch(
                `users/${userId}`,
                {
                    "email"    : data.email,
                    "password" : data.password,
                    "firstName": data.firstName,
                    "lastName" : data.lastName,
                    "roles"    : data.roles
                }
            );

            dispatch(setLoadingUpdateUser(false));
            dispatch(setUpdateUserSuccess(true));
        } catch (e) {
            dispatch(setUpdateUserError(e.message));
            dispatch(setLoadingUpdateUser(false));
            dispatch(setUpdateUserSuccess(false));
        } finally {
            setTimeout(
                () => {
                    dispatch(setUpdateUserError(null));
                    dispatch(setUpdateUserSuccess(false));
                },
                3000
            );
        }
    };
};

export const deleteUser = (userId) => {
    return async dispatch => {
        dispatch(setDeleteUserLoading(true));

        try {
            await http.delete(
                `users/${userId}`
            );

            dispatch(setDeleteUserLoading(false));
            dispatch(setDeleteUserSuccess(true));
        } catch (e) {
            dispatch(setDeleteUserLoading(false));
            dispatch(setDeleteUserError(e.response.data["hydra:description"]));
        } finally {
            setTimeout(
                () => {
                    dispatch(setDeleteUserSuccess(false));
                    dispatch(setDeleteUserError(null));
                },
                3000
            );
        }
    };
};
