import React                    from "react";
import {
    Modal,
    Button,
    Form 
}                               from "react-bootstrap";
import { setStatusToCompleted } from "../../../redux/Purchases/purchases.async-actions";
import { 
    useDispatch,
    useSelector
}                               from "react-redux";
import {
    setUploadAndMarkAsDeliveredError,
    setUploadAndMarkAsDeliveredSuccess
 }                              from "../../../redux/Purchases/purchases.actions"; 

const SubmitAndDeliveredConfirmationModal = ({
    show = false,
    purchaseId = null,
    handleClose = () => {},
    uploadDetails = null,
    handleCommentsText = () => {},
    commentsText = null,
    submitDocument = () => {},
    getPurchase = () => {}
}) => {
    const dispatch = useDispatch();
    const commentText   = useSelector(state => state.purchases.modalConfirmationComments);
    const uploadSuccess = useSelector(state => state.purchases.purchasesUploadedDocumentSuccess);

    const processConfirmation = async e => {
        if (e.key === "Enter") {
            await submitDocumentAndMarkAsDelivered(e);
        }

        if (e.key === "Escape") {
            handleClose();
        }
    }

    const submitDocumentAndMarkAsDelivered = async e => {
        e.preventDefault();

        try {
            await submitDocument(e, "upload_and_delivered");

            dispatch(
                setStatusToCompleted(
                    purchaseId,
                    null,
                    commentText
                )
            );

            dispatch(setUploadAndMarkAsDeliveredSuccess(true));
        } catch (error) {
            dispatch(setUploadAndMarkAsDeliveredError(error));
        } finally {
            dispatch(setUploadAndMarkAsDeliveredSuccess(false));
            dispatch(setUploadAndMarkAsDeliveredError(null));
            getPurchase();
            handleClose();
        }
    }

    return (
        <div
            className="modal-wrap"
            onKeyDown={(e) => processConfirmation(e)}
        >
            <Modal 
                show={show} 
                onHide={handleClose}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to submit and mark as delivered?</p>
                    {
                        uploadDetails &&
                        <div>
                            <ul>
                                <li>
                                    <strong>File Name</strong>:
                                    {uploadDetails?.name ?? ""}
                                </li>
                                <li>
                                    <strong>File Type</strong>:
                                    {uploadDetails?.type ?? ""}
                                </li>
                                <li>
                                    <strong>Size of the file</strong>:
                                    {uploadDetails?.size ?? ""}
                                </li>
                                <li>
                                    <strong>Last Modified Date</strong>:
                                    {uploadDetails?.lastModifiedDate?.toLocaleDateString() ?? ""}
                                </li>
                            </ul>
                        </div>
                    }
                    <div>
                        <Form.Group>
                            <Form.Label> Add your comments</Form.Label>
                            <textarea
                                className="form-control"
                                name="name"
                                rows="3"
                                onChange={(e) => handleCommentsText(e)}
                            />
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={submitDocumentAndMarkAsDelivered}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default SubmitAndDeliveredConfirmationModal;
