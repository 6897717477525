import React                      from 'react'
import Navigation                 from '../../../components/Navigation/Navigation';
import Loader                     from '../../../components/Common/Loader';
import {
    Breadcrumb,
    Button,
    ButtonGroup,
    Col,
    Dropdown,
    Form,
    FormControl,
    InputGroup,
    Row,
    Table
}                                 from 'react-bootstrap';
import {
    deleteEmailTemplate,
    getEmailTemplatesList,
    getSendgridUrlPath,
    meiliSearchEmailTemplates
}                                 from '../../../redux/EmailTemplate/emailTemplate.async-actions';
import {Link, withRouter}         from 'react-router-dom';
import {connect}                  from 'react-redux';
import UUIDs                      from '../../../services/UUIDs';
import routerConstants            from '../../../constants/router-constants'
import ConfirmDeleteEmailTemplate from '../../ListPurchases/Modals/ConfirmDeleteEmailTemplate';
import GetSendgridUrlModal        from '../../ListPurchases/Modals/GetSendgridUrlModal';
import Paginator                  from "../../../components/Common/Paginator";
import DateUtils                  from "../../../services/DateUtils";
import * as Icon                  from "react-feather";
import JsCookie                   from "js-cookie";
import {resetMeiliPagination}     from "../../../redux/EmailTemplate/emailTemplate.actions";
import store                      from "../../../redux/store";

class ListEmails extends React.Component {
    constructor(props) {
        super(props)

        this.fromDate          = React.createRef();
        this.toDate            = React.createRef();
        this.emailTemplateName = React.createRef();
        this.searchTermField   = React.createRef();
        this.externalId        = React.createRef();

        this.resetFormFilterMethod           = this.resetFormFilter.bind(this);
        this.meiliSearchForm                 = this.meiliSearchForm.bind(this);
        this.emailTemplatesUI                = this.emailTemplatesUI.bind(this);
        this.searchEmailTemplates            = this.searchEmailTemplates.bind(this);
        this.loadSearchFormValuesFromCookies = this.loadSearchFormValuesFromCookies.bind(this);

        this.state = {
            sideMenu                       : true,
            showConfirmDeleteTemplate      : false,
            templateId                     : null,
            processingEmailTemplateDeleted : false,
            templateName                   : "",
            templateExternalId             : "",
            showGetSendgridUrlModal        : false,
            processingLoadUrl              : false,

            searchTerm                     : "",
            currentPage                    : "",

            searchCriteria: {
                searchTerm       : "",
                emailTemplateName: "",
                externalId       : "",
                orderBy          : "name:asc",
                from             : "",
                to               : "",
                currentPage      : 1
            }
        }
    }

    async componentDidMount() {
        this.loadSearchFormValuesFromCookies();

        this
            .setState(
                {
                    searchCriteria: {
                        ...this.state.searchCriteria
                    }
                }
            );

        window.requestAnimationFrame(async () => await this.searchEmailTemplates());
    }

    /**
     * Read the Search Form values from Cookie.
     */
    loadSearchFormValuesFromCookies() {
        const cookie = JsCookie.get("etsfv");
        const defaultSearchCriteria = {
            searchTerm       : "",
            emailTemplateName: "",
            externalId       : "",
            orderBy          : "name:asc",
            from             : "",
            to               : "",
            currentPage      : 1
        };

        if (typeof cookie !== "undefined") {
            const parsedSearchCriteria = JSON.parse(atob(cookie));

            this.setState({
                searchCriteria: {
                    ...defaultSearchCriteria,
                    ...parsedSearchCriteria
                }
            });
        } else {
            this.setState({
                searchCriteria: {
                    ...defaultSearchCriteria
                }
            });
        }
    }

    goToRoute = (route) => {
        this.props.history.push(route);
    }

    getPageToGo = (currentPage) => {
        if (currentPage) {
            this.setState(
                {
                    searchCriteria: {
                        ...this.state.searchCriteria,
                        ...{
                            currentPage: currentPage
                        }
                    }
                }
            );

            window.requestAnimationFrame(async () => await this.searchEmailTemplates());
        }
    }

    resetFormFilter = () => {
        const defaultSearchCriteria = {
            searchTerm       : "",
            emailTemplateName: "",
            externalId       : "",
            orderBy          : "name:asc",
            from             : "",
            to               : "",
            currentPage      : 1
        }

        this
            .setState(
                {
                    searchCriteria: {
                        ...this.state.searchTerm,
                        ...defaultSearchCriteria
                    }
                }
            )

        this.searchTermField.current.value         = "";
        this.fromDate.current.value                = "";
        this.toDate.current.value                  = "";
        this.emailTemplateName.current.value       = "";
        this.externalId.current.value              = "";

        this.resetPagination();

        window.requestAnimationFrame(async () => await this.searchEmailTemplates());
    }

    /**
     * For few interactions it is required to reset the pagination current page to number 1.
     */
    resetPagination = () => {
        this.setState(
            {
                currentPage: 1
            }
        );
    }

    _onSideMenu = (active) => {
        this.setState({sideMenu: active});
    };

    loadSendgridData = async () => {
        this.setState({processingLoadUrl: true})

        await this.props.getSendgridUrlPath(UUIDs.getEntityUUID(this.state.templateId));

        this.setState(
            {
                processingLoadUrl: false
            }
        )
    }

    deleteEmailTemplateFunction = async () => {
        this.setState({processingEmailTemplateDeleted: true})

        await this.props.deleteEmailTemplate(this.state.templateId);

        if (this.props.deleteSuccess) {
           await this.searchEmailTemplates();
        }

        this.setState(
            {
                showConfirmDeleteTemplate     : false,
                processingEmailTemplateDeleted: false
            }
        )
    }

    /**
     * Sensible to the user to show a confirmation dialog before deleting a template.
     *
     * @param templateId
     * @param name
     * @param externalId
     */
    showConfirmDeleteTemplate = (
        templateId,
        name,
        externalId
    ) => {
        this.setState(
            {
                showConfirmDeleteTemplate: true,
                templateId,
                templateName             : name,
                templateExternalId       : externalId
            }
        )
    }

    hideConfirmDeleteTemplate = () => {
        this.setState(
            {
                showConfirmDeleteTemplate: false,
                templateId               : null,
                templateName             : "",
                templateExternalId       : "" 
            }
        )
    }

    showGetSendgridUrlModal = (
        templateId
    ) => {
        this.setState(
            {
                showGetSendgridUrlModal: true,
                templateId
            }
        )
    }

    hideGetSendgridUrl = () => {
        this.setState(
            {
                showGetSendgridUrlModal: false,
                templateId             : null
            }
        )
    }

    /**
     * Perform the Meilisearch search operation using the provided filtered values.
     *
     * @returns {Promise<void>}
     */
    searchEmailTemplates = async () => {
        await new Promise (resolve => setTimeout(resolve, 250));
        const convertDateToUnixTimestamp = (dateString, from = true) => {
            const dateObject = new Date(dateString);
            if (from) {
                dateObject.setHours(0, 0, 0, 0);
            } else {
                dateObject.setHours(23, 59, 59, 99);
            }
            const timestamp = dateObject.getTime() / 1000;
            return Math.floor(timestamp);
        };

        const searchParams = {
            filter : "",
            sort : [
                this.state.searchCriteria.orderBy
            ],
            page : parseInt(this.state.searchCriteria.currentPage),
        };

        if (
            "" !== this.state.searchCriteria.searchTerm
        ) {
            searchParams.q = `${this.state.searchCriteria.searchTerm}`;
        }

        const fromDateString = this.state.searchCriteria.from;
        let fromTimestamp = null;
        if ("" !== fromDateString) {
            fromTimestamp = convertDateToUnixTimestamp(fromDateString);
        }

        const toDateString = this.state.searchCriteria.to;
        let toTimestamp    = null;
        if ("" !== toDateString) {
            toTimestamp = convertDateToUnixTimestamp(toDateString, false);
        }

        switch (true) {
            case null !== fromTimestamp && null === toTimestamp:
                searchParams.filter += ` createdAt >= ${fromTimestamp}`;
                break;
            case null === fromTimestamp && null !== toTimestamp:
                searchParams.filter += `createdAt <= ${toTimestamp}`;
                break;
            case null !== fromTimestamp && null !== toTimestamp:
                searchParams.filter += ` createdAt ${fromTimestamp} TO ${toTimestamp}`;
                break;
        }

        const emailTemplateName = this.state.searchCriteria.emailTemplateName;
        if ("" !== emailTemplateName) {
            if ("" !== searchParams.filter) {
                searchParams.filter += " AND ";
            }
            searchParams.filter += ` name = ${emailTemplateName}`;
        }

        const externalId = this.state.searchCriteria.externalId;
        if ("" !== externalId) {
            if ("" !== searchParams.filter) {
                searchParams.filter += " AND ";
            }
            searchParams.filter += ` externalId = ${externalId}`;
        }

        let cookieValue = btoa(JSON.stringify(this.state.searchCriteria));
        JsCookie.set(
            // etsfv = Email Template Search Form Values
            "etsfv",
            `${cookieValue}`,
            {
                expires: 1 / 24,
                path   : "/"
            }
        );

        setTimeout(
            async () => await this.props.meiliSearchEmailTemplates(searchParams),
            parseInt(process.env.REACT_APP_MEILI_SEARCH_DELAY, 10)
        );
    }

    /**
     * Display eny alerts that may rise in the purchases table.
     *
     * @returns {JSX.Element}
     */
    pageAlerts = () => {
        return <>
            {
                true === this.props.createSuccessEmailTemplate &&
                <div className="alert alert-success" role="alert">
                    The Email Template has been created successfully!
                </div>
            }

            {
                true === this.props.editEmailTemplateSuccess &&
                <div className="alert alert-success" role="alert">
                    The Email Template has been updated successfully!
                </div>
            }

            {
                true === this.props.deleteSuccess &&
                <div className="alert alert-success" role="alert">
                    The Email Template has been deleted successfully!
                </div>
            }

            {
                null !== this.props.deleteError &&
                <div className="alert alert-danger" role="alert">
                    {this.props.deleteError}
                </div>
            }

        </>
    }

    /**
     * Rendering the search form for the purchases.
     *
     * @returns {JSX.Element}
     */
    meiliSearchForm = () => {
        const updateSearchTermState = async searchTerm => {
            this.setState(
                {
                    searchCriteria: {
                        ...this.state.searchCriteria,
                        searchTerm : searchTerm.target.value,
                        currentPage: 1

                    }
                }
            );

            window.requestAnimationFrame(
                async () => {
                    store.dispatch(resetMeiliPagination());
                    await this.searchEmailTemplates();
                }
            );

            return true;
        }

        return <Form className="mb-2">
            {this.pageAlerts()}

            <Row className="mb-1">
                <Col>
                    <InputGroup className="mb-3">
                        <FormControl
                            as={"input"}
                            onKeyUp={e => updateSearchTermState(e)}
                            defaultValue={this.state.searchCriteria.searchTerm}
                            ref={this.searchTermField}
                        />
                    </InputGroup>
                </Col>
            </Row>

            <Row className="mb-1">
                <Col>
                    <Form.Group controlId="filterForm.fromDate">
                        <Form.Control
                            type="date"
                            ref={this.fromDate}
                            onChange={
                                () => {
                                    this.resetPagination();
                                    store.dispatch(resetMeiliPagination());

                                    this.setState(
                                        {
                                            searchCriteria: {
                                                ...this.state.searchCriteria,
                                                from: this.fromDate.current.value
                                            }
                                        }
                                    );

                                    window.requestAnimationFrame(async () => await this.searchEmailTemplates());
                                }
                            }
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Control
                        type="date"
                        ref={this.toDate}
                        onChange={
                            () => {
                                this.resetPagination();
                                store.dispatch(resetMeiliPagination());

                                this.setState(
                                    {
                                        searchCriteria: {
                                            ...this.state.searchCriteria,
                                            to: this.toDate.current.value

                                        }
                                    }
                                );

                                window.requestAnimationFrame(async () => await this.searchEmailTemplates());
                            }
                        }
                    />
                </Col>
                <Col>
                    <Form.Control
                        as="select"
                        ref={this.emailTemplateName}
                        onChange={
                            ()  => {
                                this.resetPagination();
                                store.dispatch(resetMeiliPagination());

                                this.setState(
                                    {
                                        searchCriteria: {
                                            ...this.state.searchCriteria,
                                            emailTemplateName: this.emailTemplateName.current.value
                                        }
                                    }
                                );

                                window.requestAnimationFrame(async () => await this.searchEmailTemplates());
                            }
                        }
                    >
                        <option value="">Email Templates Names</option>
                        {this
                            ?.props
                            ?.emailTemplate
                            ?.meiliEmailTemplates
                            ?.map(emailTemplate => {
                                return <option value={emailTemplate.name}>{emailTemplate.name}</option>
                            })
                        }
                    </Form.Control>
                </Col>
                <Col xs={"auto"}>
                    <ButtonGroup size="sm" aria-label="Form Action Buttons">
                        <Button
                            variant="dark"
                            onClick={this.resetFormFilterMethod}
                        >
                            <Icon.Delete
                                className="icon"
                            />
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </Form>;
    };

    emailTemplatesUI = () => {
        return <>
            {this.meiliSearchForm()}

            <Table className="m-0" responsive>
                <thead>
                <tr key="table_headers">
                    <th width={"270px"}>Name</th>
                    <th width={"250px"}>Description</th>
                    <th width={"170px"}>External Id</th>
                    <th width={"95px"}>Created At</th>
                    <th width={"80px"}>Updated At</th>
                    <th width={"120px"}>Actions</th>
                </tr>
                </thead>
                <tbody>
                    {this.mapMeiliEmailTemplates()}
                </tbody>
            </Table>

            <Paginator
                current={this.state.searchCriteria.currentPage}
                first={this.props.emailTemplate.meiliEmailTemplatesFirstPage}
                last={this.props.emailTemplate.meiliEmailTemplatesLastPage}
                next={this.props.emailTemplate.meiliEmailTemplatesNextPage}
                prev={this.props.emailTemplate.meiliEmailTemplatesPrevPage}
                onClickFirst={e => this.getPageToGo(e)}
            />
        </>
    }

    mapMeiliEmailTemplates = () =>
        this
            ?.props
            ?.emailTemplate
            ?.meiliEmailTemplates
            ?.map(
                emailTemplate => {
                    const emailTemplateId = emailTemplate.objectID;

                    return (
                        <tr key={emailTemplateId}>
                            <td>
                                {emailTemplate.name}
                            </td>
                            <td>
                                {emailTemplate.description}
                            </td>
                            <td>
                                {emailTemplate.externalId}
                            </td>
                            <td>
                                {DateUtils.formatISO8601ToFormat(emailTemplate.createdAt)}
                                &nbsp;<small>({DateUtils.formatISO8601ToHumanReadable(emailTemplate.createdAt)})</small>
                            </td>
                            <td>
                                {DateUtils.formatISO8601ToFormat(emailTemplate.updatedAt)}
                                &nbsp;<small>({DateUtils.formatISO8601ToHumanReadable(emailTemplate.updatedAt)})</small>
                            </td>
                            <td>
                            <Dropdown as={ButtonGroup} size="sm" className="d-inline-block mr-2 mt-2">
                                <Button
                                    onClick={e => this.goToRoute(`/email-template/${
                                        emailTemplateId}`,e)}
                                    variant="outline-primary"
                                    as={"button"}
                                >
                                    Edit
                                </Button>
                                <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />
                                    <Dropdown.Menu  popperConfig={{strategy: "fixed"}}>
                                        <Dropdown.Item
                                            onClick={
                                            () => this
                                                .showConfirmDeleteTemplate(
                                                    emailTemplateId,
                                                    emailTemplate.name,
                                                    emailTemplate.externalId
                                                )
                                            }
                                        >
                                            Delete Email Template
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={
                                            () => this
                                                .showGetSendgridUrlModal(
                                                    emailTemplateId
                                                )
                                            }
                                        >
                                            Get Sendgrid Url
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </td>
                        </tr>
                    )
            }
    )

    render() {
        let loader = null;

        if (this.props.loadingEmailTemplateList || this.props.deleteLoading) {
            loader = <Loader message="Loading..." />
        }

        return<>
            <div className="page-wrapper">
                {/* Navigation */}
                <Navigation onClick={this._onSideMenu} />
                {/* End Navigation */}

                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? "" : "hide-sidemenu"}`}>
                    {/* Loader */}
                    {loader}
                    {/* End Loader */}

                    {/* Breadcrumb */}
                    <div className="main-content-header">
                        <Breadcrumb>
                            <h1>List Email Templates</h1>
                            <Link to={routerConstants.dashboard} className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Breadcrumb.Item active>
                               List Email Templates
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    {/* End ssBreadcrumb */}

                    <ConfirmDeleteEmailTemplate
                        show={this.state.showConfirmDeleteTemplate}
                        processing={this.state.processingEmailTemplateDeleted}
                        onClose={this.hideConfirmDeleteTemplate}
                        onConfirm={this.deleteEmailTemplateFunction}
                        templateId={this.state.templateId}
                        templateExternalId={this.state.templateExternalId}
                        templateName={this.state.templateName}
                    />

                    <GetSendgridUrlModal
                        show={this.state.showGetSendgridUrlModal}
                        processing={this.state.processingLoadUrl}
                        onConfirm={this.loadSendgridData}
                        onClose={this.hideGetSendgridUrl}
                    />

                    <div className="main-content-header">
                        <Row>
                            <Col lg={12}>
                                <div className="card mb-4">
                                    <div className="card-body">
                                        <div className="card-header d-flex justify-content-between align-items-center">
                                            <h5 className="card-title float-lg-left">Emails</h5>
                                            <Button
                                                onClick={e => this.goToRoute(routerConstants.createNewEmailTemplate, 2)}
                                                variant="outline-primary"
                                                className="mb-2"
                                            >
                                                Create Emails
                                            </Button>
                                        </div>
                                        <div

                                        >
                                            {this.emailTemplatesUI()}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    }
}

const mapStateToProps = state => {
    return {
        emailTemplate              : state.emailTemplates,
        emailTemplateListError     : state.emailTemplates.errorEmailTemplate,
        loadingEmailTemplateList   : state.emailTemplates.loadingEmailTemplate,
        editEmailTemplateSuccess   : state.emailTemplates.updateEmailTemplateSuccess,
        createSuccessEmailTemplate : state.emailTemplates.createEmailTemplateSuccess,
        deleteError                : state.emailTemplates.deleteEmailTemplateError,
        deleteSuccess              : state.emailTemplates.deleteEmailTemplateSuccess,
        deleteLoading              : state.emailTemplates.deteleEmailTemplateLoading,
        sendgridUrl                : state.emailTemplates.sendgridUrl,
        totalEmailTemplates        : state.emailTemplates.totalEmailTemplates
    }
}

const mapActionsToProps = {
    getEmailTemplatesList,
    deleteEmailTemplate,
    getSendgridUrlPath,
    meiliSearchEmailTemplates,
}

const ListEmailTemplateConnected = connect(
    mapStateToProps,
    mapActionsToProps
)(ListEmails)

export default withRouter(ListEmailTemplateConnected);
