import { 
    SET_MARKET_LIST,
    SET_LOADING_MARKET,
    SET_MARKET_ERROR
} from "./market.types";

export const setMarketList = market => {
    return {
        type   : SET_MARKET_LIST,
        payload: market
    };
};

export const setLoadingMarket = loading => {
    return {
        type   : SET_LOADING_MARKET,
        payload: loading
    };
};

export const setMarketError = error => {
    return {
        type   : SET_MARKET_ERROR,
        payload: error
    };
};
