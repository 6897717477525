class UUIDs {

    /**
     * This method is responsible to extract the UUID of a given URL.
     *
     * @param {string} entity_id The entity @id property.
     * @returns {string|null} The UUID of the entity or null.
     */
    static getEntityUUID(entity_id = "") {
        const matches = /^\/api\/[^\/]+\/([a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12})/g
            .exec(entity_id);

        return matches[1] ?? null;
    }

    /**
     * This method is responsible for extracting the /api/orders/ part of a given URL.
     *
     * @param {string} path The URL path to be processed.
     * @returns {string|null} The /api/orders/ part of the URL or null if not present.
     */
    static extractApiOrders(path = "") {
        const pattern = /^\/api\/orders\/([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})/;
        const match = path.match(pattern);

        return match ? match[1] : null;
    }


}

export default UUIDs;
