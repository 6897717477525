import React from "react";

class ListDocumentDataSpecific extends React.Component {
    data = [];

    appendData = (label, value) => {
        if ("" !== value.trim()) {
            this.data.push(
                {
                    label,
                    value: value
                }
            );
        }
    };

    getDocumentData = purchase => {
        switch (purchase?.service_slug) {
            case "camerale" :
            case "commercio" :
                this.appendData("Company Name", purchase?.purchase_company_name);
                break;
            case "pra" :
                this.appendData("License Plate Number", purchase?.purchase_license_plate_number);
                break;
            case "crif" :
                this.appendData("Email", purchase?.customer_email);
                break;
            case "catastale" :
            case "protesti" :
                switch (purchase?.purchase_type) {
                    case "Persona":
                        this.appendData("First Name", purchase?.customer_first_name);
                        this.appendData("Last Name", purchase?.customer_last_name);
                        break;
                    default:
                        this.appendData("Company Name", purchase?.purchase_company_name);
                }
                break;
            case  "residenza" :
            case "nascita" :
            case "famiglia" :
            case "matrimonio" :
                this.appendData("First Name", purchase?.customer_first_name);
                this.appendData("Last Name", purchase?.customer_last_name);
                break;
            case "driving_licence" :
                this.appendData("License Number", purchase?.purchase_driving_licence_number);
                this.appendData("Date Of Birth", purchase?.purchase_date_of_birth);
                break;
        }
    };

    render() {
        this.data = [];
        this.getDocumentData(this.props.purchase);

        return <ul>
            {
                this
                    .data
                    .map(
                        entry => <li key={entry.label}><strong>{entry.label}:</strong> {entry.value}</li>
                    )
            }
        </ul>;
    }
}

export default ListDocumentDataSpecific;
