import http from "../../services/http";
import {
    setPaymentPlanSettingListError,
    setPaymentPlanSettingList,
    setLoadingPaymentPlanSettingList
}           from "./payment-plan-setting.actions";

export const getPaymentPlanSettingList = () => {
    return async dispatch => {
        dispatch(setLoadingPaymentPlanSettingList(true));
        dispatch(setPaymentPlanSettingListError(null));

        try {
            const paymentPlanSettings = await http.get(
                `payment-plan-settings`
            );

            dispatch(setPaymentPlanSettingList(paymentPlanSettings.data["hydra:member"]));
        } catch (e) {
            dispatch(setPaymentPlanSettingList([]));
            dispatch(setPaymentPlanSettingListError(e.message));
        } finally {
            dispatch(setLoadingPaymentPlanSettingList(false));
        }
    };
};
