import {
    SET_EMAIL_TEMPLATES_LIST,
    SET_ERROR_EMAIL_TEMPLATES,
    SET_LOADING_EMAIL_TEMPLATES,
    SET_SINGLE_EMAIL_TEMPLATE,
    SET_SINGLE_EMAIL_TEMPLATE_ERROR,
    SET_SINGLE_EMAIL_TEMPLATE_LOADING,
    SET_UPDATE_EMAIL_TEMPLATE_ERROR,
    SET_UPDATE_EMAIL_TEMPLATE_LOADING,
    SET_UPDATE_EMAIL_TEMPLATE_SUCCESS,
    SET_CREATE_EMAIL_TEMPLATE_ERROR,
    SET_CREATE_EMAIL_TEMPLATE_LOADING,
    SET_CREATE_EMAIL_TEMPLATE_SUCCESS,
    SET_DELETE_EMAIL_TEMPLATE_ERROR,
    SET_DELETE_EMAIL_TEMPLATE_SUCCESS,
    SET_DELETE_EMAIL_TEMPLATE_LOADING,
    SET_SENDGRID_URL,
    SET_SENDGRID_URL_LOADING,
    SET_SENDGRID_URL_ERROR,
    SET_SENDGRID_URL_SUCCESS,
    SET_EMAIL_TEMPLATE_CURRENT_PAGE,
    SET_EMAIL_TEMPLATE_FIRST_PAGE,
    SET_EMAIL_TEMPLATE_LAST_PAGE,
    SET_EMAIL_TEMPLATE_PREV_PAGE,
    SET_EMAIL_TEMPLATE_NEXT_PAGE,
    SET_TOTAL_EMAIL_TEMPLATES,
    SET_MEILI_EMAIL_TEMPLATES,
    SET_MEILI_TOTAL_EMAIL_TEMPLATES,
    SET_MEILI_TOTAL_PAGES,
    SET_MEILI_EMAIL_TEMPLATES_ERROR,
    SET_MEILI_EMAIL_TEMPLATES_CURRENT_PAGE,
    SET_MEILI_EMAIL_TEMPLATES_FIRST_PAGE,
    SET_MEILI_EMAIL_TEMPLATES_LAST_PAGE,
    SET_MEILI_EMAIL_TEMPLATES_PREV_PAGE,
    SET_MEILI_EMAIL_TEMPLATES_NEXT_PAGE,
    SET_MEIlI_RESET_PAGINATION
} from "./emailTemplate.types";

export const setEmailTemplates = emailTemplates => {
    return {
        type   : SET_EMAIL_TEMPLATES_LIST,
        payload: emailTemplates
    };
};

export const setEmailTemplatesError = error => {
    return {
        type   : SET_ERROR_EMAIL_TEMPLATES,
        payload: error
    };
};

export const setEmailTemplatesLoading = loading => {
    return {
        type   : SET_LOADING_EMAIL_TEMPLATES,
        payload: loading
    };
};

export const setSingleEmailTemplate = emailTemplate => {
    return {
        type   : SET_SINGLE_EMAIL_TEMPLATE,
        payload: emailTemplate
    }
}

export const setSingleEmailTemplateError = error => {
    return {
        type   : SET_SINGLE_EMAIL_TEMPLATE_ERROR,
        payload: error
    }
}

export const setSingleEmailTemplateLoading = loading => {
    return {
        type   : SET_SINGLE_EMAIL_TEMPLATE_LOADING,
        payload: loading
    }
}

export const setUpdateEmailTemplateSuccess = success => {
    return {
        type   : SET_UPDATE_EMAIL_TEMPLATE_SUCCESS,
        payload: success
    }
}

export const setUpdateEmailTemplateError = error => {
    return {
        type   : SET_UPDATE_EMAIL_TEMPLATE_ERROR,
        payload: error 
    }
}

export const setUpdateEmailTemplateLoading = loading => {
    return {
        type   : SET_UPDATE_EMAIL_TEMPLATE_LOADING,
        payload: loading 
    }
}

export const setCreateEmailTemplateSuccess = success => {
    return {
        type   : SET_CREATE_EMAIL_TEMPLATE_SUCCESS,
        payload: success 
    }
}

export const setCreateEmailTemplateLoading = loading => {
    return {
        type   : SET_CREATE_EMAIL_TEMPLATE_LOADING,
        payload: loading 
    }
}

export const setCreateEmailTemplateError = error => {
    return {
        type   : SET_CREATE_EMAIL_TEMPLATE_ERROR,
        payload: error 
    }
}

export const setDeleteEmailTemplateSuccess = success => {
    return {
        type   : SET_DELETE_EMAIL_TEMPLATE_SUCCESS,
        payload: success
    }
}

export const setDeleteEmailTemplateError = error => {
    return {
        type   : SET_DELETE_EMAIL_TEMPLATE_ERROR,
        payload: error
    }
}

export const setDeleteEmailTemplateLoading = loading => {
    return {
        type   : SET_DELETE_EMAIL_TEMPLATE_LOADING,
        payload: loading
    }
}

export const setSendgridUrl = url => {
    return {
        type   : SET_SENDGRID_URL,
        payload: url
    }
}

export const setSengridUrlError = error => {
    return {
        type   : SET_SENDGRID_URL_ERROR,
        payload: error
    }
}

export const setSendgridUrlLoading = loading => {
    return {
        type   : SET_CREATE_EMAIL_TEMPLATE_LOADING,
        payload: loading
    }
}

export const setSendgridUrlSuccess = success => {
    return {
        type   : SET_CREATE_EMAIL_TEMPLATE_SUCCESS,
        payload: success
    }
}

export const setEmailTemplateCurrentPage = page => {
    return {
        type   : SET_EMAIL_TEMPLATE_CURRENT_PAGE,
        payload: page
    }
}

export const setEmailTemplateFirstPage = page => {
    return {
        type   : SET_EMAIL_TEMPLATE_FIRST_PAGE,
        payload: page
    }
}

export const setEmailTemplateLastPage = page => {
    return {
        type   : SET_EMAIL_TEMPLATE_LAST_PAGE,
        payload: page
    }
}

export const setEmailTemplatePrevPage = page => {
    return {
        type   : SET_EMAIL_TEMPLATE_PREV_PAGE,
        payload: page
    }
}

export const setEmailTemplateNextPage = page => {
    return {
        type   : SET_EMAIL_TEMPLATE_NEXT_PAGE,
        payload: page
    }
}

export const setTotalEmailTemplates = total => {
    return {
        type   : SET_TOTAL_EMAIL_TEMPLATES,
        payload: total
    }
}

export const setMeiliEmailTemplates = emailTemplates => {
    return {
        type   : SET_MEILI_EMAIL_TEMPLATES,
        payload: emailTemplates
    }
}

export const setMeiliTotalEmailTemplates = total => {
    return {
        type   : SET_MEILI_TOTAL_EMAIL_TEMPLATES,
        payload: total
    }
}

export const setMeiliTotalPages = pages => {
    return {
        type   : SET_MEILI_TOTAL_PAGES,
        payload: pages
    }
}

export const setMeiliEmailTemplatesError = error => {
    return {
        type   : SET_MEILI_EMAIL_TEMPLATES_ERROR,
        payload: error
    }
}

export const setMeiliEmailTemplatesCurrentPage = page => {
    return {
        type   : SET_MEILI_EMAIL_TEMPLATES_CURRENT_PAGE,
        payload: page
    }
}

export const setMeiliEmailTemplatesFirstPage = page => {
    return {
        type   : SET_MEILI_EMAIL_TEMPLATES_FIRST_PAGE,
        payload: page
    }
}

export const setMeiliEmailTemplatesLastPage = page => {
    return {
        type   : SET_MEILI_EMAIL_TEMPLATES_LAST_PAGE,
        payload: page
    }
}

export const setMeiliEmailTemplatesPrevPage = page => {
    return {
        type   : SET_MEILI_EMAIL_TEMPLATES_PREV_PAGE,
        payload: page
    }
}

export const setMeiliEmailTemplatesNextPage = page => {
    return {
        type   : SET_MEILI_EMAIL_TEMPLATES_NEXT_PAGE,
        payload: page
    }
}

export const resetMeiliPagination = () => {
    return {
        type: SET_MEIlI_RESET_PAGINATION
    }
}
