import {
    SET_LOADING_PRODUCTS_LIST,
    SET_PRODUCTS_LIST,
    SET_PRODUCTS_LIST_ERROR,
    SET_PRODUCTS_UPDATE_ERROR,
    SET_PRODUCTS_UPDATE_SUCCESS,
    SET_SINGLE_PRODUCT,
    SET_SINGLE_PRODUCT_ERROR,
    SET_CREATE_PRODUCT_ERROR,
    SET_CREATE_PRODUCT_SUCCESS,
    SET_LOADING_PRODUCTS_CREATE,
    SET_LOADING_PRODUCTS_UPDATE, SET_SINGLE_PRODUCT_LOADING
} from "./products.types";

export const setProducts = products => {
    return {
        type   : SET_PRODUCTS_LIST,
        payload: products
    };
};

export const setLoadingProductsList = loading => {
    return {
        type   : SET_LOADING_PRODUCTS_LIST,
        payload: loading
    };
};

export const setProductsListError = error => {
    return {
        type   : SET_PRODUCTS_LIST_ERROR,
        payload: error
    };
};

export const setProductsUpdateError = error => {
    return {
        type   : SET_PRODUCTS_UPDATE_ERROR,
        payload: error
    };
};


export const setProductsUpdateSuccess = success => {
    return {
        type   : SET_PRODUCTS_UPDATE_SUCCESS,
        payload: success
    };
};

export const setSingleProduct = product => {
    return {
        type   : SET_SINGLE_PRODUCT,
        payload: product
    };
};

export const setSingleProductError = error => {
    return {
        type   : SET_SINGLE_PRODUCT_ERROR,
        payload: error
    };
};

export const setSingleProductLoading = loading => {
    return {
        type   : SET_SINGLE_PRODUCT_LOADING,
        payload: loading
    };
};

export const setCreateProductError = error => {
    return {
        type   : SET_CREATE_PRODUCT_ERROR,
        payload: error
    };
};

export const setCreateProductSuccess = success => {
    return {
        type   : SET_CREATE_PRODUCT_SUCCESS,
        payload: success
    };
};

export const setLoadingProductsCreate = loading => {
    return {
        type   : SET_LOADING_PRODUCTS_CREATE,
        payload: loading
    };
};

export const setLoadingProductsUpdate = loading => {
    return {
        type   : SET_LOADING_PRODUCTS_UPDATE,
        payload: loading
    };
};

