export const SET_PRODUCTS_LIST           = "SET_PRODUCTS_LIST";
export const SET_LOADING_PRODUCTS_LIST   = "SET_LOADING_PRODUCTS_LIST";
export const SET_PRODUCTS_LIST_ERROR     = "SET_PRODUCTS_LIST_ERROR";
export const SET_PRODUCTS_UPDATE_ERROR   = "SET_PRODUCTS_UPDATE_ERROR";
export const SET_PRODUCTS_UPDATE_SUCCESS = "SET_PRODUCTS_UPDATE_SUCCESS";
export const SET_SINGLE_PRODUCT          = "SET_SINGLE_PRODUCT";
export const SET_SINGLE_PRODUCT_ERROR    = "SET_SINGLE_PRODUCT_ERROR";
export const SET_SINGLE_PRODUCT_LOADING  = "SET_SINGLE_PRODUCT_LOADING";
export const SET_CREATE_PRODUCT_SUCCESS  = "SET_CREATE_PRODUCT_SUCCESS";
export const SET_CREATE_PRODUCT_ERROR    = "SET_CREATE_PRODUCT_ERROR";
export const SET_LOADING_PRODUCTS_CREATE = "SET_LOADING_PRODUCTS_CREATE";
export const SET_LOADING_PRODUCTS_UPDATE = "SET_LOADING_PRODUCTS_UPDATE";
