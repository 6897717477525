import { 
    SET_LOCALES_LIST,
    SET_ERROR_MESSAGE_LOCALES,
    SET_LOADING_LOCALES
} from "./locale.types";

export const setLocalesList = locales => {
    return {
        type   : SET_LOCALES_LIST,
        payload: locales
    };
};

export const setErrorMessageLocales = locales => {
    return {
        type   :  SET_ERROR_MESSAGE_LOCALES,
        payload: locales
    };
};

export const setLoadingLocales = locales => {
    return {
        type   : SET_LOADING_LOCALES,
        payload: locales
    };
};
