import React            from 'react'
import Navigation       from '../../../components/Navigation/Navigation';
import routerConstants  from "../../../constants/router-constants";
import { Link }         from 'react-router-dom';
import { 
    Breadcrumb,
    Card,
    Button,
    Row,
    Col
}                        from 'react-bootstrap';
import { useForm }       from 'react-hook-form';
import { createProject } from '../../../redux/Project/project.async-actions';
import { connect }       from 'react-redux';
import Loader            from '../../../components/Common/Loader';

class CreateProject extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            sideMenu : true

        };
    }

    formularProject = () => {

        return <>
           <Card>
                <Card.Body>
                    <Card.Title>Create Project</Card.Title>
                    <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                        <div className="row">
                            <div className="col">
                                <label>Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="name"
                                    name="name"
                                    {...this.props.register('name',
                                        {
                                            required: {
                                                value  : true,
                                                message: <span style={{color: 'red'}}>This field is required</span>
                                            }
                                        }
                                    )}
                                />
                                {this?.props?.errors?.name?.message}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label>Type</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Type"
                                    name="type"
                                    {...this.props.register('type',
                                        {
                                            required: {
                                                value  : true,
                                                message: <span style={{color: 'red'}}>This field is required</span>
                                            }
                                        }
                                    )}
                                />
                                {this?.props?.errors?.type?.message}
                            </div>
                        </div>

                        <div style={{padding : "10px"}} />

                        <div className="text-center">
                            <Button
                                variant="primary"
                                type="submit">
                                Create Project
                            </Button>
                        </div>
                    </form>
                </Card.Body>
            </Card>
        </>
    }

    _onSideMenu = (active) => {
        this.setState({sideMenu: active});
    };

    onSubmit = (data) => {
        const name      = data.name;
        const type      = data.type;
        const createdAt = (new Date())
        const updatedAt = (new Date())

        this.props.createProject(name, type, createdAt, updatedAt)
    }

    render() {
        let loader = null;
        const { 
            createLoading,
            createPostSuccess,
            createPostError
        } = this.props;

        if(createLoading) {
            loader = <Loader message="Loading..." />;
        }

        return <>
            <div className="page-wrapper">
                {/* Navigation */}
                <Navigation onClick={this._onSideMenu} />
                {/* End Navigation */}

                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? "" : "hide-sidemenu"}`}>
                    {/* Loader */}
                    {loader}
                    {/* End Loader */}

                    {true === createPostSuccess &&
                        <div class="alert alert-success" role="alert">
                            Success
                        </div>
                    }

                    {null !== createPostError &&
                        <div class="alert alert-danger" role="alert">
                            Fail
                        </div>
                    }

                    <div className="main-content-header">
                        <Breadcrumb>
                            <h1>Create Project</h1>
                            <Link to={routerConstants.dashboard} className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Link to={routerConstants.listProject} className="breadcrumb-item">
                                List Project
                            </Link>
                            <Breadcrumb.Item active>
                               Create Project
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <Row>
                        <Col lg={12}>
                            {
                                this.formularProject()
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    }
}

const CreateProjectConst = (props) => {
    const {register, handleSubmit, formState : {errors}} = useForm();

    return <CreateProject 
        {...props}
        errors={errors}
        register={register}
        handleSubmit={handleSubmit}
    />
}

const mapStateToProps = state => {
    return {
        createPostSuccess : state.project.setCreateSuccess,
        createPostError   : state.project.setCreateError,
        createLoading     : state.project.setCreateLoading
    }
}

const mapActionsToProps = {
    createProject
}

const CreateProjectConnected = connect(
    mapStateToProps,
    mapActionsToProps
)(CreateProjectConst)

export default CreateProjectConnected;
