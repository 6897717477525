import React                           from "react";
import {Link, Redirect}                from "react-router-dom";
import {Row, Col, Form, Button, Alert} from "react-bootstrap";
import routerConstants                 from "../constants/router-constants";
import {connect}                       from "react-redux";
import {password_reset_request}        from "../redux/User/user.async-actions";

class ForgotPassword extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: ""
        };

        this.updateEmail = this.updateEmailCallback.bind(this);
    }

    onForgotHandler = async e => {
        e.preventDefault();

        await this.props.password_reset_request(this.state.email);
        this.setState(
            {
                email: ""
            }
        );
    };

    updateEmailCallback(e) {
        this.setState(
            {
                email: e.target.value
            }
        );
    }

    render() {
        if (this.props.authenticated) {
            return <Redirect to={routerConstants.dashboard} />;
        }

        return <>
            <div className="auth-main-content auth-password-request">
                <div className="d-table">
                    <div className="d-tablecell">
                        <div className="auth-box">
                            <Row>
                                <Col md={12}>
                                    <div className="form-content">
                                        <h1 className="heading">Forgot password</h1>
                                        <Form onSubmit={this.onForgotHandler}>
                                            {
                                                false !== this.props.password_reset_request_error &&
                                                <Alert variant="danger">{this.props.password_reset_request_error}</Alert>
                                            }

                                            {
                                                false !== this.props.password_reset_request_success &&
                                                <Alert variant="success">{this.props.password_reset_request_success}</Alert>
                                            }

                                            <Form.Group>
                                                <Form.Label>Email address</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="email"
                                                    disabled={this.props.sending_password_reset_request}
                                                    value={this.state.email}
                                                    onChange={this.updateEmail}
                                                />
                                            </Form.Group>

                                            <div className="text-center">
                                                <Button
                                                    variant="primary"
                                                    type="submit"
                                                    disabled={this.props.sending_password_reset_request}
                                                >
                                                    Send the Reset Instruction
                                                </Button>

                                                <Link to={routerConstants.login} className="fp-link">Log In</Link>
                                            </div>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        sending_password_reset_request: state.user.sending_password_reset_request,
        password_reset_request_error  : state.user.password_reset_request_error,
        password_reset_request_success: state.user.password_reset_request_success
    };
};

export default connect(
    mapStateToProps,
    {
        password_reset_request
    }
)(ForgotPassword);