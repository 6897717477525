import { 
    SET_MARKET_LIST,
    SET_LOADING_MARKET,
    SET_MARKET_ERROR
} from "./market.types";

const INITIAL_STATE = {
    setMarketList   : [],
    setLoadingMarket: false,
    setMarketError  : null
};

const marketReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_MARKET_LIST:
            return {
                ...state,
                marketList: action.payload
            };
        case SET_LOADING_MARKET:
            return {
                ...state,
                setLoadingMarket: action.payload
            };
        case SET_MARKET_ERROR:
            return {
                ...state,
                setMarketError: action.payload
            };

        default:
            return state;
    }
};

export default marketReducer;
