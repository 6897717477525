import React                                             from "react";
import { Link, withRouter }                              from "react-router-dom";
import {connect}                                         from "react-redux";
import {Breadcrumb, Button, Card, Col, Form, Row, Table} from "react-bootstrap";
import Navigation                                        from "../../components/Navigation/Navigation";
import { getPurchasesDeliveryTimeStats }                 from "../../redux/Statistics/RebillBreakdown/rebill-breakdown.async-actions";
import routerConstants                                   from "../../constants/router-constants";
import * as echarts                                      from "echarts";
import moment                                            from "moment";
import {RangeDatePicker}                                 from "react-google-flight-datepicker";
import Select                                            from "react-select";
import { getOrdersServicesDropDownOptions }              from "../../redux/Services/services.async-actions";
import Loader                                            from "../../components/Common/Loader";

class PurchasesDeliveryTimeStats extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sideMenu  : true,
            startDate : new Date(),
            endDate   : new Date(),
            services  : []
        };

        this.chartOptions    = {
            tooltip: {
                trigger: "item"
            },
            legend : {
                top : "5%",
                left: "center"
            },
            series : [
                {
                    name             : "hours",
                    type             : "pie",
                    radius           : ["47%", "64%"],
                    avoidLabelOverlap: true,
                    itemStyle        : {
                        borderRadius: 10,
                        borderColor : "#FFF",
                        borderWidth : 2
                    },
                    label            : {
                        show    : true,
                        position: "left"
                    },
                    emphasis         : {
                        label: {
                            show      : true,
                            fontSize  : "20",
                            fontWeight: "bold"
                        }
                    },
                    labelLine        : {
                        show: true
                    },
                    data             : []
                }
            ]
        };
        this.from  = React.createRef();
        this.to    = React.createRef();
        this.chart = React.createRef();

        this.from.current = moment(new Date()).format("YYYY-MM-DD");
        this.to.current   = moment(new Date()).format("YYYY-MM-DD");

        this.onDateChange         = this.onDateChange.bind(this);
        this.onServiceValueChange = this.onServiceValueChange.bind(this);

        this.serviceOptions = [];
        this.pieChart       = null;
    }

    _onSideMenu = (active) => this.setState({sideMenu: active});
    
    chartWindowResizeListener = () => this.pieChart.resize();

    mapPurchasesDeliveryTimeStats = () => this?.props?.purchasesDeliveryTimeStats?.delivery_times?.map(
            entry => {
                return <tr key={entry.service_slug} className="text-center">
                <td>{entry.service_slug}</td>
                <th scope="row">{this.secondsToHoursAndMinutes(Number(entry.delivery_time))}</th>
            </tr>;
        }
    );

    componentWillUnmount() {
        window.removeEventListener(
            "resize",
            this.chartWindowResizeListener
        );
        window.removeEventListener(
            "orientationchange",
            this.chartWindowResizeListener
        );
    }

    fetchPurchasesDeliveryTimeStats = async () => {
        let query = {
            services : this.state.services,
            from: this.from.current,
            to  : this.to.current
        };

        await this.props.getPurchasesDeliveryTimeStats(query);

        setTimeout(
            () => {
                window.requestAnimationFrame(
                    () => {
                        this.chartOptions.series[0].data = this.props.purchasesDeliveryTimeStats.delivery_times.map(
                            entry => {
                                entry.name  = entry.service_slug;
                                entry.value = this.secondsToHours(Number(entry.delivery_time));

                                delete entry.service_slug;
                                delete this.secondsToHours(Number(entry.delivery_time));

                                return entry;
                            }
                        );

                        this.pieChart.setOption(this.chartOptions);
                    }
                );
            },
            1000
        );
    }

    secondsToHoursAndMinutes = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        return  `${hours} hours ${minutes} minutes` ;
    };

    secondsToHours = (seconds) => Math.floor(seconds / 3600);

    onDateChange(startDate, endDate) {
        this.setState(
            {
                startDate,
                endDate
            }
        );

        this.from.current = moment(startDate).format("YYYY-MM-DD");
        this.to.current   = moment(endDate).format("YYYY-MM-DD");
    }

    onServiceValueChange(selectedOptions) {
        let values = [];

        selectedOptions.forEach(option => values.push(option.value));
        this.setState(
            {
                services: values
            }
        );
    }

    async componentDidMount() {
        window.requestAnimationFrame(
            () => {
                this.pieChart = echarts.init(this.chart.current);
                this.pieChart.setOption(this.chartOptions);

                window.addEventListener(
                    "resize",
                    this.chartWindowResizeListener
                );
                window.addEventListener(
                    "orientationchange",
                    this.chartWindowResizeListener
                );
            }
        );

        const queryParams = {
            "order[name]": "asc"
        };

        await this.props.getOrdersServicesDropDownOptions(queryParams);
         
        if (null === this.props.ordersServicesListError) {
            this.servicesOptions = this.props.ordersServicesList.map(
                service => {
                    return {
                        value: service.slug,
                        label: service.name
                    };
                }
            );
        }

        this.fetchPurchasesDeliveryTimeStats();
    }

    render() {
        let loader = null;

        if (this.props.purchaseDeliveryLoading) {
            loader = <Loader message="Loading..." />;
        }

        const chartStyle = {
            height : "350px",
            display: "block"
        };

        return <>
            <div className="page-wrapper">
                {/* Navigation */}
                <Navigation onClick={this._onSideMenu} />
                {/* End Navigation */}

                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? "" : "hide-sidemenu"}`}>
                    {/* Loader */}
                    {loader}
                    {/* End Loader */}

                    <div className="main-content-header">

                        {/* Breadcrumb */}
                        <Breadcrumb>
                            <h1>Statistics</h1>
                            <Link to={routerConstants.dashboard} className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Breadcrumb.Item active>
                                Purchases Delivery Time
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        {/* End Breadcrumb */}

                        <Row>
                        <Col sm={12} md={4} lg={3}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <Card.Title>Filters</Card.Title>
                                    <Form>
                                        <Form.Group controlId="dateRange">
                                            <Form.Label>Date Range</Form.Label>
                                            <RangeDatePicker
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                maxDate={new Date()}
                                                onChange={(startDate, endDate) => this.onDateChange(
                                                    startDate,
                                                    endDate
                                                )}
                                                dateFormat="D MMM YY"
                                                monthFormat="MMM YYYY"
                                                startDatePlaceholder="Start Date"
                                                endDatePlaceholder="End Date"
                                                disabled={false}
                                                startWeekDay="monday"
                                                ref={this.dateRange}
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="serviceName">
                                            <Form.Label>Service</Form.Label>
                                            <Select
                                                value={this.props.services}
                                                onChange={this.onServiceValueChange}
                                                options={this.servicesOptions}
                                                isMulti
                                                backspaceRemovesValue={true}
                                                escapeClearsValue={true}
                                                isClearable={true}
                                                menuPlacement={"auto"}
                                                placeholder={"Choose services you desire"}
                                            />
                                            <Form.Text className="text-muted">
                                                The services to use in order to filter the sales.
                                            </Form.Text>
                                            {
                                                this.props.ordersServicesListError !== null &&
                                                <Form.Text className="text-danger">
                                                    Something went wrong and the services cannot be loaded. Please
                                                    contact the development team to ask for more information.
                                                </Form.Text>
                                            }
                                        </Form.Group>
                                    </Form>
                                    <Button
                                        variant="primary"
                                        onClick={this.fetchPurchasesDeliveryTimeStats}
                                    >
                                        Filter
                                    </Button>
                                </Card.Body>
                            </Card>
                            </Col>
                            <Col sm={12} md={8} lg={9}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <Card.Title>Results</Card.Title>
                                    <Table responsive hover className="m-0">
                                        <thead>
                                        <tr className="text-center">
                                            <th>Service</th>
                                            <th className="text-center">Average Time <small>(hours and minutes)</small></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this?.props?.purchasesDeliveryTimeStats?.delivery_time?.length === 0 ?
                                                <tr key="no_purchases">
                                                    <td colSpan={2} className="text-center">No Data</td>
                                                </tr> :
                                                this.mapPurchasesDeliveryTimeStats()
                                        }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                            <Card className="mb-4">
                                <Card.Body>
                                    <Card.Title>Total Puchases Average Times</Card.Title>
                                    <Table responsive hover className="m-0">
                                        <thead>
                                        <tr className="text-center">
                                            <th>Total Purchases Average Time <small>(hours and minutes)</small></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.props.purchasesDeliveryTimeStats.average_delivery_time !== 0 ?
                                                <tr>
                                                    <td colSpan={2} className="text-center">{this.secondsToHoursAndMinutes(this?.props?.purchasesDeliveryTimeStats?.average_delivery_time)}</td>
                                                </tr> :
                                                <tr>
                                                    <td colSpan={2} className="text-center">No Data</td>
                                                </tr>
                                        }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                            <Card className="mb-4">
                                <Card.Body>
                                    <Card.Title>Purchases Delivery Time Graph</Card.Title>
                                    <div
                                        ref={this.chart}
                                        style={chartStyle}
                                    />
                                </Card.Body>
                            </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    }
}

const mapStateToProps = state => {
    return {
        purchasesDeliveryTimeStats: state.rebillBreakdown.purchasesDeliveryTimeStats,
        purchaseDeliveryLoading   : state.rebillBreakdown.loadingPurchasesDeliveryTimeStats,
        ordersServicesList        : state.services.ordersServicesList,
        ordersServicesListError   : state.services.ordersServicesListError,
    };
};

const mapActionsToProps = {
    getPurchasesDeliveryTimeStats,
    getOrdersServicesDropDownOptions
};

const PurchasesDeliveryTimeStatsConnected = connect(
    mapStateToProps,
    mapActionsToProps
)(PurchasesDeliveryTimeStats);

export default withRouter(PurchasesDeliveryTimeStatsConnected);
