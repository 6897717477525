class StringFormater {

	/**
	 * Replaces all letters in a string with asterisks
	 * @param str
	 * @returns {*}
	 */
	static replaceLettersWithAsterisks(str) {
		return str.replace(/[a-zA-Z]/g, '*');
	}
}

export default StringFormater;
