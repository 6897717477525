import React                     from "react";
import {Badge, Nav, NavDropdown} from "react-bootstrap";
import {NavLink}                 from "react-router-dom";
import routerConstants           from "../../../constants/router-constants";
import * as Icon                 from "react-feather";
import Icofont                   from "react-icofont";
import ReactTooltip              from "react-tooltip";
import {connect}                 from "react-redux";
import {
    getTotalOnHoldTransactions,
    getTotalPendingInProgressTransactions
}                                from "../../../redux/Purchases/purchases.async-actions";

class MenuOptions extends React.Component {

    componentDidMount() {
        this.props.getTotalPendingInProgressTransactions();
        this.props.getTotalOnHoldTransactions();

        setInterval(
            () => {
                this.props.getTotalPendingInProgressTransactions();
                this.props.getTotalOnHoldTransactions();
            },
            300000
        );
    }

    render() {
        let pendingInProgressTransactionBadge = undefined;
        let onHoldTransactionBadge            = undefined;
        const roleToSearchFor                 = "ROLE_ADMIN"
        const roleUser = this?.props?.user?.['roles']?.find(
            role => role === roleToSearchFor
        )

        if (this.props.totalPendingInProgressTransactions !== 0) {
            pendingInProgressTransactionBadge = <>
                <Badge
                    variant="primary"
                    pill
                    className="badgeSmall ml-1"
                    data-tip="Pending or In Progress transactions"
                >
                    {this.props.totalPendingInProgressTransactions}
                </Badge>
                <ReactTooltip />
            </>;
        }

        if ( this.props.totalOnHoldTransactions !== 0 ) {
            onHoldTransactionBadge = <>
                <Badge
                    variant="secondary"
                    pill
                    className="badgeSmall ml-1"
                    data-tip="Pending or In Progress transactions"
                >
                    {this.props.totalOnHoldTransactions}
                </Badge>
                <ReactTooltip />
            </>;
        }

        return (
            <Nav>
                <NavLink
                    to={routerConstants.dashboard}
                    className="nav-link"
                >
                    <Icon.Grid
                        className="icon"
                    />
                    <span className="title">Dashboard</span>
                </NavLink>

                <NavLink
                    to={routerConstants.listClients}
                    className="nav-link"
                >
                    <Icon.Users className="icon" />
                    <span className="title">Clients / Prospects</span>
                </NavLink>

                <NavLink
                    to={routerConstants.listPendingInProgressPurchases}
                    className="nav-link"
                >
                    <Icon.DollarSign className="icon" />
                    <span className="title">Clients Purchases</span>
                    {pendingInProgressTransactionBadge}
                    {onHoldTransactionBadge}
                </NavLink>

                {roleUser === "ROLE_ADMIN" && <NavDropdown
                    id="clients-purchases" title={
                    <div className="dropdown-title">
                        <Icon.Sliders className="icon" />
                        <span className="title">
                            Business Management
                            <Icon.ChevronRight
                                className="icon fr"
                            />
                        </span>
                    </div>
                }
                >
                    <NavLink
                        to={routerConstants.listCompany}
                        className="dropdown-item"
                        activeClassName="drpMenu"
                    >
                        <Icon.Home className="icon" />
                        Companies
                    </NavLink>
                    <NavLink
                        to={routerConstants.listProject}
                        className="dropdown-item"
                        activeClassName="drpMenu"
                    >
                        <Icofont icon="list" className="icon" />
                        Projects
                    </NavLink>
                    <NavLink
                        to={routerConstants.listWebsites}
                        className="dropdown-item"
                        activeClassName="drpMenu"
                    >
                        <Icofont icon="web" className="icon" />
                        Websites
                    </NavLink>
                    <NavLink
                        to={routerConstants.listWebsitesConfig}
                        className="dropdown-item"
                        activeClassName="drpMenu"
                    >
                        <Icofont icon="dashboard" className="icon" />
                        Alert Banner
                    </NavLink>
                    <NavLink
                        to={routerConstants.listProductSubscription}
                        className="dropdown-item"
                        activeClassName="drpMenu"
                    >
                        <Icofont icon="prestashop" className="icon" />
                        Product Subscriptions
                    </NavLink>
                    <NavLink
                        to={routerConstants.listServices}
                        className="dropdown-item"
                        activeClassName="drpMenu"
                    >
                        <Icofont icon="hand-drag1" className="icon" />
                        Services
                    </NavLink>
                    <NavLink
                        to={routerConstants.listPaymentGateway}
                        className="dropdown-item"
                        activeClassName="drpMenu"
                    >
                        <Icofont icon="icofont-bank" className="icon" />
                        Payment Gateway
                    </NavLink>
                    <NavLink
                        to="#"
                        className="dropdown-item"
                        activeClassName="drpMenu"
                    >
                        <Icofont icon="file-text" className="icon" />
                        Contents
                    </NavLink>
                    <NavLink
                        to={routerConstants.listEmails}
                        className="dropdown-item"
                        activeClassName="drpMenu"
                    >
                        <Icon.Mail className="icon" />
                        Emails
                    </NavLink>
                </NavDropdown>}
                {roleUser === "ROLE_ADMIN" && <NavLink
                    to="#"
                    className="nav-link"
                >
                    <Icofont icon="bill" className="icon" />
                    <span className="title">Billing Management</span>
                </NavLink>}

                {roleUser === "ROLE_ADMIN" && <NavLink
                    className="nav-link"
                    to={routerConstants.listUsers}
                >
                    <Icofont icon="ui-user-group" className="icon" />
                    <span className="title">User Management</span>
                </NavLink>}

                {roleUser === "ROLE_ADMIN" && <NavDropdown
                    id="clients-purchases" title={
                    <div className="dropdown-title">
                        <Icon.PieChart className="icon" />
                        <span className="title">
                            Statistics
                            <Icon.ChevronRight
                                className="icon fr"
                            />
                        </span>
                    </div>
                }
                >
                    <NavLink
                        to={routerConstants.salesStats}
                        className="dropdown-item"
                        activeClassName="drpMenu"
                    >
                        <Icon.BarChart2 className="icon" />
                        Sales
                    </NavLink>

                    <NavLink
                        to={routerConstants.purchasesStats}
                        className="dropdown-item"
                        activeClassName="drpMenu"
                    >
                        <Icon.BarChart2 className="icon" />
                        Purchases
                    </NavLink>

                    <NavLink
                        to={routerConstants.rebillBreakdown}
                        className="dropdown-item"
                        activeClassName="drpMenu"
                    >
                        <Icon.RefreshCw className="icon" />
                        Rebill Breakdown
                    </NavLink>

                    <NavLink
                        to={routerConstants.statsOrderActive}
                        className="dropdown-item"
                        activeClassName="drpMenu"
                    >
                        <Icon.User className="icon" />
                        Customer Statistics
                    </NavLink>

                    <NavLink
                        to={routerConstants.purchasesDeliveryTimeStats}
                        className="dropdown-item"
                        activeClassName="drpMenu"
                    >
                        <Icon.Clock className="icon" />
                        Purchases Delivery Time Stats
                    </NavLink>
                </NavDropdown>}
            </Nav>
        );
    }
}

const mapStateToProps = state => {
    return {
        totalPendingInProgressTransactions: state.purchases.totalPendingInProgressTransactions,
        totalOnHoldTransactions           : state.purchases.totalOnHoldTransactions,
        user                              : state.user.me
    };
};

const mapActionToProps = {
    getTotalPendingInProgressTransactions,
    getTotalOnHoldTransactions
};

export default connect(mapStateToProps, mapActionToProps)(MenuOptions);
