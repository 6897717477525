import JsCookie from "js-cookie";
import {
    CLEAR_ACCESS_TOKEN,
    CLEAR_MEILI_SEARCH_TOKEN,
    CLEAR_REFRESH_TOKEN,
    CLEAR_USER_DATA,
    SET_ACCESS_TOKEN,
    SET_MEILI_SEARCH_TOKEN,
    SET_REFRESH_TOKEN,
    SET_USER_DATA
}               from "../User/user.types";

export const AlphaCookieMiddleware = store => next => {
    return action => {
        const generalCookieSettings = {
            // Expires in one hour.
            // Read more here:
            // https://github.com/js-cookie/js-cookie/wiki/Frequently-Asked-Questions#expire-cookies-in-less-than-a-day
            expires: 1 / 24,
            path   : "/"
        };

        switch (action.type) {
            case SET_USER_DATA:
                // ud = User Data
                if (typeof undefined === typeof JsCookie.get("ud")) {
                    JsCookie.set(
                        "ud",
                        btoa(JSON.stringify(action.payload)),
                        {
                            ...generalCookieSettings
                        }
                    );
                }
                break;
            case SET_ACCESS_TOKEN:
                // at = Access Token
                if (typeof undefined === typeof JsCookie.get("at")) {
                    JsCookie.set(
                        "at",
                        action.payload,
                        {
                            ...generalCookieSettings
                        }
                    );
                }
                break;
            case SET_REFRESH_TOKEN:
                // rt = Refresh Token
                if (typeof undefined === typeof JsCookie.get("rt")) {
                    JsCookie.set(
                        "rt",
                        action.payload,
                        {
                            ...generalCookieSettings,
                            expires: 4 / 24
                        }
                    );
                }
                break;
            case SET_MEILI_SEARCH_TOKEN:
                // mt = Meilisearch Token
                if (typeof undefined === typeof JsCookie.get("mt")) {
                    JsCookie.set(
                        "mt",
                        action.payload,
                        {
                            ...generalCookieSettings,
                            expires: 4 / 24
                        }
                    );
                }
                break;
            case CLEAR_USER_DATA:
                JsCookie.remove("ud");
                break;
            case CLEAR_ACCESS_TOKEN:
                JsCookie.remove("at");
                break;
            case CLEAR_REFRESH_TOKEN:
                JsCookie.remove("rt");
                break;
            case CLEAR_MEILI_SEARCH_TOKEN:
                JsCookie.remove("mt");
                break;
        }

        next(action);
    };
};
