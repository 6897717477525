import React          from 'react';
import {Modal, Alert, Button} from "react-bootstrap";

class ConfirmBulkActionModal extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="modal-wrap">
                <Modal
                    show={this.props.show}
                    onHide={this.handleClose}
                    backdrop="static"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>
                            <strong className="text-danger">Warning!</strong> Purchase will be {this.props.purchaseStatus === "hold" ? "on hold" : "delivered"}.
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {
                            0 !== this.props.missingFiles &&
                            'delivered' === this.props.currentAction &&
                            <Alert
                                variant="danger"
                                style={{marginBottom: '25px'}}
                            >
                                Some of the purchases you try to mark as delivered seems like they don't have uploaded
                                files. Are you sure you want to proceed with this action?
                            </Alert>
                        }

                        Do you really want to perform this action?
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            variant="danger"
                            onClick={this.props.onConfirm}
                            disabled={this.props.processing}
                        >
                            {this.props.processing ? "Processing ..." : "Confirm"}
                        </Button>
                        <Button
                            variant="success"
                            onClick={this.props.onClose}
                            disabled={this.props.processing}
                        >
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default ConfirmBulkActionModal;
