import React          from "react";
import CopyDatabutton from "./CopyDataButton";

class CrifDataComponent extends React.Component {
    componentDidCatch(error, errorInfo) {
            super.componentDidCatch(error, errorInfo);
    }

    listPurchases() {
        const dataKeys = [
            'registration_type',
            'registrationType',
            'companyName',
            'legalForm',
            'fiscalCodeCompany',
            'vatCompany',
            'fiscal_code',
            'fiscalCode',
            'billingId',
            'lastName',
            'firstName',
            'last_name',
            'first_name',
            'dateOfBirth',
            'sex',
            'birthPlace',
            'birthplace',
            'provinceOfBirth',
            'countryOfBirth',
            'address',
            'addressNumber',
            'address_number',
            'cityOfResidence',
            'postalCode',
            'provinceOfResidence',
            'nation',
            'documentType',
            'documentNumber',
            'validityStartDate',
            'validityEndDate',
            'releaseLocation',
            'issuingProvince',
            'issuingOrganization',
            'companyAddress',
            'companyHouseNumber',
            'companyPostCode',
            'companyMunicipality',
            'companyProvince',
            'document'
        ];

        return dataKeys.map(key => {
            let value = this.props.customerOrder[key] ?? "unknown";
            const readableKey = key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());

            if (value === "unknown") {
                return null;
            }

            if (value === "") {
                value = "unknown";
            }

            return (
                <React.Fragment key={key}>
                    <dt>{readableKey}</dt>
                    <dd>
                        <div className="row gx-5">
                            <div className="col">
                                {value}
                            </div>
                            {value !== 'unknown' ?
                                <div className="col">
                                    <CopyDatabutton data={value} />
                                </div>
                            :null}
                        </div>
                    </dd>
                </React.Fragment>
            );
        })}

    render() {
        return (
            <div>{this.listPurchases()}</div>
        );
    }
}

export default CrifDataComponent;
