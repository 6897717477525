import React              from 'react'
import Navigation         from '../../../components/Navigation/Navigation';
import {
    Breadcrumb,
    Row,
    Col,
    Button,
    Table
}                         from 'react-bootstrap';
import { Link }           from 'react-router-dom';
import routerConstants    from "../../../constants/router-constants";
import { getProjectList } from '../../../redux/Project/project.async-actions';
import { connect }        from 'react-redux';
import Footer             from '../../Footer/Footer';
import UUIDs              from '../../../services/UUIDs';
import Loader             from '../../../components/Common/Loader';
import ListSorter         from "../../../Utils/ListSorter";

class ListProject extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            sideMenu : true
    
        };
    }

    goToRoute(route) {
        this.props.history.push(route);
    }

    componentDidMount() {
       this.props.getProjectList();
    }

    _onSideMenu = (active) => {
        this.setState({sideMenu: active});
    };

    mapProject = () => ListSorter.orderListByProperty(
            this.props.projectList, 'name'
        ).map(
            project => {
                const projectId = project["@id"]

                return (<tr key={projectId}>
                    <td>
                        {project["@type"]}
                    </td>
                    <td>
                        {project.createdAt}
                    </td>
                    <td>
                        {project.name}
                    </td>
                    <td>
                        {project.updatedAt}
                    </td>
                    <td>
                        <Button
                            variant="outline-primary"
                            as={"button"}
                            onClick={e => this.goToRoute(`/list-project/update/${UUIDs.getEntityUUID(
                                projectId)}`, e)}
                        >
                            Edit
                        </Button>
                    </td>
                </tr>
            );
        }
    )

    render() {
        let loader = null;
        const { projectLoading } = this.props;
        
        if (projectLoading) {
            loader = <Loader message= "Loading..." />
        }

        return <>
            <div className="page-wrapper">
                {/* Navigation */}
                <Navigation onClick={this._onSideMenu} />
                {/* End Navigation */}

                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? "" : "hide-sidemenu"}`}>
                    {/* Loader */}
                    {loader}
                    {/* End Loader */}

                    <div className="main-content-header">
                        <Breadcrumb>
                            <h1>List Project</h1>
                            <Link to={routerConstants.dashboard} className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Link to={routerConstants.listProject} className="breadcrumb-item">
                                List Project
                            </Link>
                        </Breadcrumb>
                    </div>

                    <Row>
                        <Col lg={12}>
                            <div className="card mb-4">
                                <div className="card-body">

                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <h5 className="card-title float-lg-left">Project</h5>
                                        <Button
                                        onClick={e => this.goToRoute(routerConstants.createProject, 2 )}
                                            variant="outline-primary"
                                            className="mb-2"
                                        >
                                            Create Project
                                        </Button>
                                    </div>

                                    <Table className="m-0" responsive>
                                        <thead>
                                            <tr key="table_headers">
                                                <th>Type</th>
                                                <th width={"290px"}>Created At</th>
                                                <th width={"270px"}>Name</th>
                                                <th width={"200px"}>Updated At</th>
                                                <th width={"100px"}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.mapProject()}
                                        </tbody>
                                    </Table>

                                </div>
                            </div>
                        </Col>
                    </Row>

                    {/* Footer */}
                    <div className="flex-grow-1" />
                    <Footer />
                    {/* End Footer */}
                </div>
            </div>
        </>
    }
}

const mapStateToProps = state => {
    return {
        projectList    : state.project.setProjectList,
        projectLoading : state.project.setProjectLoading
    }
}

const mapActionsToProps = {
    getProjectList
}

const ListProjectConnected = connect(
    mapStateToProps,
    mapActionsToProps
)(ListProject)

export default ListProjectConnected;
