import React            from 'react'
import Navigation       from '../../components/Navigation/Navigation';
import {connect}        from 'react-redux';
import { 
    getOrderStatistics 
}                       from '../../redux/Statistics/StatsOrderActive/stats-orders-active.async-actions';
import { withRouter }   from 'react-router-dom';
import Loader           from '../../components/Common/Loader';
import {
    Breadcrumb,
    Button,
    Card,
    Col, 
    Form,
    Row,
    Table
}                       from "react-bootstrap";
import { Link }         from 'react-router-dom';
import routerConstants  from '../../constants/router-constants';
import * as echarts     from "echarts";

class StatsOrderActive extends React.Component {
    constructor(props) {
        super(props)

        this.chartOptions           = {
            tooltip: {
                trigger: "item"
            },
            legend : {
                top : "5%",
                left: "center"
            },
            series : [
                {
                    name             : "Order Statistics",
                    type             : "pie",
                    radius           : ["47%", "64%"],
                    avoidLabelOverlap: true,
                    itemStyle        : {
                        borderRadius: 10,
                        borderColor : "#FFF",
                        borderWidth : 2
                    },
                    label            : {
                        show    : true,
                        position: "left"
                    },
                    emphasis         : {
                        label: {
                            show      : true,
                            fontSize  : "20",
                            fontWeight: "bold"
                        }
                    },
                    labelLine        : {
                        show: true
                    },
                    data             : []
                }
            ]
        };

        this.chart    = React.createRef();
        this.pieChart = null;

        this.state = {
            sideMenu  : true,
            totalValue: 0
        }
    }

    chartWindowResizeListener = () => {
        this.pieChart.resize()
    }

    // Toggle side bar menu
    _onSideMenu = (active) => {
        this.setState({sideMenu: active});
    };

    componentDidMount = async () => {
        window.requestAnimationFrame(
            () => {
                this.pieChart = echarts.init(this.chart.current);
                this.pieChart.setOption(this.chartOptions);

                window.addEventListener(
                    "resize",
                    this.chartWindowResizeListener
                );
                window.addEventListener(
                    "orientationchange",
                    this.chartWindowResizeListener
                );
            }
        );

        setTimeout(
            () => {
                window.requestAnimationFrame(
                    () => {
                        this.chartOptions.series[0].data = this?.props?.orderStatistics?.map(
                            entry => {
                                entry.name  = entry.name;
                                entry.value = entry.orderCount;

                                delete entry.orderCount;

                                return entry;
                            }
                        );

                        this.pieChart.setOption(this.chartOptions);
                    }
                );
            },
            500
        );

        await this.props.getOrderStatistics();
        this.totalOrder()
    }

    componentWillUnmount() {
        window.removeEventListener(
            "resize",
            this.chartWindowResizeListener
        );
        window.removeEventListener(
            "orientationchange",
            this.chartWindowResizeListener
        );
    }

    mapOrderStatistics = () => this?.props?.orderStatistics?.map(
        entry => {
            return <tr key={entry.name} className="text-center">
                <td>{entry.name}</td>
                <th scope="row">{entry.orderCount}</th>
            </tr>;
        }
    ); 

    totalOrder = () => {
        let value = 0;

        this?.props?.orderStatistics?.forEach(entry => {
            value += entry.orderCount
        });

        this.setState({
            totalValue: value
        })
    }

    render() {
        let loader = null;

        if (this.props.orderLoader) {
            loader = <Loader message="Loading..." />;
        }

        const chartStyle = {
            height : "350px",
            display: "block"
        };

        return <>
            <div className="page-wrapper">
                {/* Navigation */}
                <Navigation onClick={this._onSideMenu} />
                {/* End Navigation */}

                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? "" : "hide-sidemenu"}`}>
                     {/* Loader */}
                     {loader}
                    {/* End Loader */}

                    {/* Breadcrumb */}
                    <div className="main-content-header">
                        <Breadcrumb>
                            <h1>Statistics</h1>
                            <Link to={routerConstants.dashboard} className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Breadcrumb.Item active>
                                Order Statistics
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        {/* End Breadcrumb */}
                        <Row>
                            <Col>
                                <Card className="mb-4">
                                    <Card.Body>
                                        <Card.Title className="text-center">Results</Card.Title>
                                        <Table responsive hover className="m-0">
                                            <thead>
                                            <tr className="text-center">
                                                <th>Name</th>
                                                <th className="text-center">Total</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this?.props?.orderStatistics?.length === 0 ?
                                                    <tr key="no_purchases">
                                                        <td colSpan={2} className="text-center">No Data</td>
                                                    </tr> :
                                                    this.mapOrderStatistics()
                                            }
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                                <Card className="mb-4">
                                    <Card.Body>
                                        <Card.Title className="text-center">Stats Website Accounts</Card.Title>
                                        <Table responsive hover className="m-0">
                                            <thead>
                                            <tr className="text-center">
                                                <th>Total Website Account</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.totalValue !== 0 ?
                                                    <tr key="RebillBreakdownData">
                                                        <td colSpan={2} className="text-center">{this.state.totalValue}</td>
                                                    </tr> :
                                                    <tr key="RebillBreakdownNoData">
                                                        <td colSpan={2} className="text-center">No Data</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                                <Card className="mb-4">
                                    <Card.Body>
                                        <Card.Title className="text-center">Order Statistics Graph</Card.Title>
                                        <div
                                            ref={this.chart}
                                            style={chartStyle}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    }
}

const mapStateToProps = state => {
    return {
        orderStatistics : state.order.orderStatistics,
        orderLoader     : state.order.orderStatisticsLoader
    };
};

const mapActionsToProps = {
    getOrderStatistics
}

const StatsOrderActiveConnected = connect(
    mapStateToProps,
    mapActionsToProps
)(StatsOrderActive)

export default withRouter(StatsOrderActiveConnected);
