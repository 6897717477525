import React                   from "react";
import Navigation              from "../../../components/Navigation/Navigation";
import {
    Breadcrumb,
    Row,
    Col,
    Button,
    Card,
    FormCheck
}                              from "react-bootstrap";
import routerConstants         from "../../../constants/router-constants";
import {Link}                  from "react-router-dom";
import {connect}               from "react-redux";
import {withRouter}            from "react-router-dom";
import {createWebsites}        from "../../../redux/Websites/websites.async-actions";
import {useForm}               from "react-hook-form";
import Loader                  from "../../../components/Common/Loader";
import Select                  from "react-select";
import {getWebsites}           from "../../../redux/Websites/websites.async-actions";
import {getLocalesList}        from "../../../redux/Locale/locale.async-actions";
import {getMarketList}         from "../../../redux/Market/market.async-actions";
import {getProjectList}        from "../../../redux/Project/project.async-actions";
import {getCompanyList}        from "../../../redux/Company/company.async-actions";
import {getPaymentGatewayList} from "../../../redux/PaymentGateway/payment-gateway.async-actions";
import websiteThemes           from "./website-themes.json";
import contactForm            from "./contact-form-data.json";

class CreateWebsiteComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sideMenu             : true,
            optionsData          : {},
            marketData           : {},
            projectData          : {},
            companyData          : {},
            localesData          : {},
            paymentGatewayData   : {},
            locales              : [],
            project              : "",
            market               : "",
            company              : "",
            paymentGateway       : "",
            theme                : {
                label: websiteThemes?.['theme-1'],
                value: 'theme-1'
            },
            contactForm          : {
                label: contactForm?.['value-1'],
                value: 'value-1'
            },
        };

        this.onSubmit              = this.onSubmit.bind(this);
        this.projectOptions        = [];
        this.marketOptions         = [];
        this.companyOptions        = [];
        this.localesOptions        = [];
        this.paymentGatewayOptions = [];
    }

    onMarketValueChange = (selectedOptions) => {
        let values = selectedOptions.value;

        this.setState(
            {
                market: values
            }
        );
    };

    onProjectValueChange = (selectedOptions) => {
        let values = selectedOptions.value;

        this.setState(
            {
                project: values
            }
        );
    };

    onLocaleValueChange = (selectedOptions) => {
        let values = [];

        selectedOptions.forEach(option => values.push(option.value));
        this.setState(
            {
                locales: values
            }
        );
    };

    onCompanyValueChange = (selectedOptions) => {
        let values = selectedOptions.value;

        this.setState(
            {
                company: values
            }
        );
    };

    onPaymentGatewayValueChange = (selectedOptions) => {
        let values = selectedOptions.value;

        this.setState(
            {
                paymentGateway: values
            }
        );
    };

    onThemeValueChange = selectedOptions => {
        let values = selectedOptions.value;

        this.setState(
            {
                theme: {
                    label: websiteThemes?.[values],
                    value: values
                }
            }
        );
    }

    onContactFormValueChange = selectedOptions => {
        let values = selectedOptions.value;

        this.setState(
            {
                contactForm: {
                    label: contactForm?.[values],
                    value: values
                },
            }
        );
    }

    websiteForm = () => {
        let themes = [];
        let contactFormArray = [];

        for(let theme in websiteThemes) {
            themes.push(
                {
                    label: websiteThemes[theme],
                    value: theme
                }
            );
        }
        themes.sort((a, b) => a.label.localeCompare(b.label))

        for(let form in contactForm) {
            contactFormArray.push(
                {
                    label: contactForm[form],
                    value: form
                }
            );
        }
        contactFormArray.sort((a, b) => a.label.localeCompare(b.label))

        return <>
            <Card>
                <Card.Body>
                    <div className="card-header">
                        <Card.Title>Create Website</Card.Title>
                    </div>
                    {
                        this.props.failMessage &&
                        <div className="alert alert-danger" role="alert">
                            {this.props.failMessage}
                        </div>
                    }
                    <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                        <div className="form-group">
                            <label>Domain</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Domain"
                                name="domain"
                                {...this.props.register(
                                    "domain",
                                    {
                                        required: {
                                            value: true,
                                            message: <span style={{color: "red"}}>This field is required</span>
                                        }
                                    }
                                )}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="company_name">
                                Descriptor
                            </label>
                            <input
                                type="text"
                                id="descriptor"
                                placeholder="Descriptor"
                                className="form-control"
                                {...this.props.register(
                                    "descriptor",
                                    {
                                        required: {
                                            value: true,
                                            message: <span style={{color: "red"}}>This field is required</span>
                                        }
                                    }
                                )}
                            />
                        </div>

                        <div className="form-group">
                            <label>Support Email</label>
                            <input
                                type="email"
                                id="email"
                                className="form-control"
                                placeholder="Support Email"
                                {...this.props.register(
                                    "supportEmail",
                                    {
                                        required: {
                                            value: true,
                                            message: <span style={{color: "red"}}>This field is required</span>
                                        }
                                    }
                                )}
                            />
                            {this?.props?.errors?.email?.message}
                        </div>

                        <div className="form-group">
                            <label>Phone Number</label>
                            <input
                                type="text"
                                id="phone"
                                className="form-control"
                                placeholder="Phone Number"
                                {...this.props.register(
                                    "phoneNumber",
                                    {
                                        required: {
                                            value: true,
                                            message: <span style={{color: "red"}}>This field is required</span>
                                        }
                                    }
                                )}
                            />
                        </div>

                        <div className="form-group">
                            <label>Gtm Id</label>
                            <input
                                type="text"
                                id="gtmId"
                                className="form-control"
                                placeholder="Gtm Id"
                                {...this.props.register(
                                    "gtmId",
                                    {
                                        required: {
                                            value: true,
                                            message: <span style={{color: "red"}}>This field is required</span>
                                        }
                                    }
                                )}
                            />
                        </div>

                        <div className="form-group">
                            <label>Trengo Key</label>
                            <input
                                type="text"
                                id="trengoKey"
                                className="form-control"
                                placeholder="Trengo Key"
                                {...this.props.register(
                                    "trengoKey",
                                    {
                                        required: {
                                            value: true,
                                            message: <span style={{color: "red"}}>This field is required</span>
                                        }
                                    }
                                )}
                            />
                        </div>

                        <div className="row mb-3 ">
                            <div className="col-10 ">
                                <label htmlFor="bannerActive">Active Cookie Banner</label>
                                <FormCheck
                                    type="switch"
                                    id={`bannerActive`}
                                    {...this.props.register(`bannerActive`)}
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label>Contact Form</label>
                            <Select
                                value={this.state.contactForm}
                                options={contactFormArray}
                                onChange={this.onContactFormValueChange}
                                backspaceRemovesValue={true}
                                escapeClearsValue={true}
                                menuPlacement={"auto"}
                                placeholder={"Choose a contact form"}
                            />
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label>Payment Gateway</label>
                                <Select
                                    value={this.props.paymentGatewayData}
                                    options={this.paymentGatewayOptions}
                                    onChange={this.onPaymentGatewayValueChange}
                                    backspaceRemovesValue={true}
                                    escapeClearsValue={true}
                                    menuPlacement={"auto"}
                                    placeholder={"Choose a Payment Gateway"}
                                />
                                {
                                    this.props.companyError !== null &&
                                    <span className="text-danger">
                                        Something went wrong and the services cannot be loaded. Please
                                        contact the development team to ask for more information.
                                    </span>
                                }
                            </div>

                            <div className="form-group col-md-6">
                                <label>Theme</label>
                                <Select
                                    value={this.state.theme}
                                    options={themes}
                                    onChange={this.onThemeValueChange}
                                    backspaceRemovesValue={true}
                                    escapeClearsValue={true}
                                    menuPlacement={"auto"}
                                    placeholder={"Choose Website Theme"}
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label>Market</label>
                                <Select
                                    value={this.props.market}
                                    onChange={this.onMarketValueChange}
                                    options={this.marketOptions}
                                    backspaceRemovesValue={true}
                                    escapeClearsValue={true}
                                    menuPlacement={"auto"}
                                    placeholder={"Choose a product subscription (Default: Completed)"}
                                />
                                {
                                    this.props.marketError !== null &&
                                    <span className="text-danger">
                                        Something went wrong and the services cannot be loaded. Please
                                        contact the development team to ask for more information.
                                    </span>
                                }
                            </div>

                            <div className="form-group col-md-6">
                                <label>Project</label>
                                <Select
                                    value={this.props.project}
                                    options={this.projectOptions}
                                    onChange={this.onProjectValueChange}
                                    backspaceRemovesValue={true}
                                    escapeClearsValue={true}
                                    menuPlacement={"auto"}
                                    placeholder={"Choose a product subscription (Default: Completed)"}
                                />
                                {
                                    this.props.projectError !== null &&
                                    <span className="text-danger">
                                        Something went wrong and the services cannot be loaded. Please
                                        contact the development team to ask for more information.
                                    </span>
                                }
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label>Company</label>
                                <Select
                                    value={this.props.company}
                                    options={this.companyOptions}
                                    onChange={this.onCompanyValueChange}
                                    backspaceRemovesValue={true}
                                    escapeClearsValue={true}
                                    menuPlacement={"auto"}
                                    placeholder={"Choose a product subscription (Default: Completed)"}
                                />
                                {
                                    this.props.companyError !== null &&
                                    <span className="text-danger">
                                        Something went wrong and the services cannot be loaded. Please
                                        contact the development team to ask for more information.
                                    </span>
                                }
                            </div>

                            <div className="form-group col-md-6">
                                <label>Locales</label>
                                <Select
                                    value={this.props.locale}
                                    options={this.localesOptions}
                                    onChange={this.onLocaleValueChange}
                                    backspaceRemovesValue={true}
                                    escapeClearsValue={true}
                                    isClearable={true}
                                    isMulti
                                    menuPlacement={"auto"}
                                    placeholder={"Choose a product subscription (Default: Completed)"}
                                />
                                {
                                    this.props.localesError !== null &&
                                    <span className="text-danger">
                                        Something went wrong and the services cannot be loaded. Please
                                        contact the development team to ask for more information.
                                    </span>
                                }
                            </div>
                        </div>

                        <div className="form-group">
                            <Button
                                variant="primary"
                                type="submit"
                            >
                                Create Website
                            </Button>
                        </div>
                    </form>
                </Card.Body>
            </Card>
        </>;
    };

    async componentDidMount() {
        await this.props.getWebsites();
        await this.props.getLocalesList();
        await this.props.getMarketList();
        await this.props.getProjectList();
        await this.props.getCompanyList();
        await this.props.getPaymentGatewayList();

        if (null === this.props.marketError) {
            this.marketOptions = this.props.marketList.map(
                market => {
                    return {
                        value: market["@id"],
                        label: market.name
                    };
                }
            );
            this.setState(
                {
                    marketData: this.marketOptions
                }
            );
        }

        if (null === this.props.projectError) {
            this.projectOptions = this.props.projectList.map(
                project => {
                    return {
                        value: project["@id"],
                        label: project.name
                    };
                }
            );
            this.setState(
                {
                    projectData: this.projectOptions
                }
            );
        }

        if (null === this.props.companyError) {
            this.companyOptions = this.props.companyList.map(
                company => {
                    return {
                        value: company["@id"],
                        label: company.name
                    };
                }
            );
            this.setState(
                {
                    companyData: this.companyOptions
                }
            );
        }

        if (null === this.props.localesError) {
            this.localesOptions = this.props.localesList.map(
                locale => {
                    return {
                        value: locale["@id"],
                        label: locale.name
                    };
                }
            );
            this.setState(
                {
                    locales: this.localesOptions
                }
            );
        }

        this.paymentGatewayOptions = this.props.paymentGatewayList.map(
            paymentGateway => {
                return {
                    value: paymentGateway["@id"],
                    label: paymentGateway.name
                };
            }
        );

        this.setState(
            {
                paymentGatewayData: this.paymentGatewayOptions
            }
        );
    }

    onSubmit = async (data) => {
        const companyId        = this.state.company;
        const marketId         = this.state.market;
        const projectId        = this.state.project;
        const localeId         = this.state.locales;
        const paymentGatewayId = this.state.paymentGateway;
        const contactForm      = this.state.contactForm.label;

        await this
            .props
            .createWebsites(
                data,
                companyId,
                marketId,
                projectId,
                localeId,
                paymentGatewayId,
                this.state.theme.value,
                contactForm
            );

        if (this.props.successMessage) {
            this.props.history.push(`/list-websites`);
        }
    };

    _onSideMenu = (active) => {
        this.setState({sideMenu: active});
    };

    render() {
        let loader = null;

        if (this.props.loaderCreateWebsite) {
            loader = <Loader message="Loading..." />;
        }

        return <>
            <div className="page-wrapper">
                {/* Navigation */}
                <Navigation onClick={this._onSideMenu} />
                {/* End Navigation */}

                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? "" : "hide-sidemenu"}`}>
                    {loader}

                    <div className="main-content-header">
                        <Breadcrumb>
                            <Link to={routerConstants.dashboard} className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Link to={routerConstants.listWebsites} className="breadcrumb-item">
                                Website List
                            </Link>
                            <Breadcrumb.Item active>
                                Website Create
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <Row>
                        <Col lg={12}>
                            {this.websiteForm()}
                        </Col>
                    </Row>
                </div>
            </div>
        </>;
    }
}

const CreateWebsitesConst = (props) => {
    const {register, handleSubmit, formState: {errors}} = useForm();

    return <CreateWebsiteComponent
        {...props}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
    />;
};

const mapStateToProps = state => {
    return {
        loaderCreateWebsite: state.websites.create_websites_loading,
        websites           : state.websites.set_websites,
        localesList        : state.locales.localesList,
        marketList         : state.market.marketList,
        projectList        : state.project.setProjectList,
        companyList        : state.company.setCompanyList,
        paymentGatewayList : state.paymentGateway.paymentGatewayList,
        marketError        : state.market.setMarketError,
        projectError       : state.project.setProjectError,
        companyError       : state.company.setCompanyError,
        localesError       : state.locales.setErrorMessageLocales,
        paymentGatewayError: state.paymentGateway.setPaymentGatewayError,
        successMessage     : state.websites.website_form_success,
        failMessage        : state.websites.website_form_fail,
    };
};

const mapActionsToProps = {
    createWebsites,
    getWebsites,
    getLocalesList,
    getMarketList,
    getProjectList,
    getCompanyList,
    getPaymentGatewayList
};

const CreateWebsitesConnected = connect(
    mapStateToProps,
    mapActionsToProps
)(CreateWebsitesConst);

export default withRouter(CreateWebsitesConnected);
