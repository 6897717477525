import {
    SET_LOADING_PAYMENT_GATEWAY_LIST,
    SET_PAYMENT_GATEWAY_LIST,
    SET_PAYMENT_GATEWAY_LIST_ERROR,
    SET_LOADING_SINGLE_PAYMENT_GATEWAY,
    SET_SINGLE_PAYMENT_GATEWAY,
    SET_SINGLE_PAYMENT_GATEWAY_ERROR,
    SET_UPDATE_PAYMENT_GATEWAY_ERROR,
    SET_UPDATE_PAYMENT_GATEWAY_LOADING,
    SET_UPDATE_PAYMENT_GATEWAY_SUCCESS
} from "./payment-gateway.types";

const INITIAL_STATE = {
    paymentGatewayList         : [],
    loadingpaymentGatewayList  : false,
    paymentGatewayListError    : null,
    singlePaymentGateway       : [],
    loadingSinglePaymentGateway: false,
    singlePaymentGatewayError  : null,
    updatePaymentGatewayErorr  : null,
    updatePaymentGatewaySuccess: false,
    updatePaymentGatewayLoading: false
};

const paymentGatewayReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_PAYMENT_GATEWAY_LIST:
            return {
                ...state,
                paymentGatewayList: action.payload
            };
        case SET_LOADING_PAYMENT_GATEWAY_LIST:
            return {
                ...state,
                loadingpaymentGatewayList: action.payload
            };
        case SET_PAYMENT_GATEWAY_LIST_ERROR:
            return {
                ...state,
                paymentGatewayListError: action.payload
            };
        case SET_LOADING_SINGLE_PAYMENT_GATEWAY:
            return {
                ...state,
                loadingSinglePaymentGateway: action.payload
            };
        case SET_SINGLE_PAYMENT_GATEWAY:
            return {
                ...state,
                singlePaymentGateway: action.payload
            };
        case SET_SINGLE_PAYMENT_GATEWAY_ERROR:
            return {
                ...state,
                singlePaymentGatewayError: action.payload
            };
        case SET_UPDATE_PAYMENT_GATEWAY_ERROR:
            return {
                ...state,
                updatePaymentGatewayErorr: action.payload
            };
        case SET_UPDATE_PAYMENT_GATEWAY_LOADING:
            return {
                ...state,
                updatePaymentGatewayLoading: action.payload
            };
        case SET_UPDATE_PAYMENT_GATEWAY_SUCCESS:
            return {
                ...state,
                updatePaymentGatewaySuccess: action.payload
            };

        default:
            return state;
    }
};

export default paymentGatewayReducer;
