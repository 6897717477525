import React           from 'react'
import Navigation      from '../../components/Navigation/Navigation';
import { Link }        from 'react-router-dom';
import {
    Breadcrumb,
    Card,
    Row,
    Col,
    Button
}                      from 'react-bootstrap';
import routerConstants from '../../constants/router-constants';
import { useForm }     from 'react-hook-form';
import { connect }     from 'react-redux';
import { withRouter }  from 'react-router-dom';
import Select          from "react-select";
import roles           from '../../constants/roles';
import { createUser }  from '../../redux/User/user.async-actions';
import Loader          from '../../components/Common/Loader';

class CreateUser extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            sideMenu: true,
            roleUser: []
        }
    }

    onUserValueChange = (selectedOptions) => {
        let values = selectedOptions.value;

        this.setState(
            {
                roleUser: values,
            }
        );
    }

    _onSideMenu = (active) => {
        this.setState({sideMenu: active})
    }

    formularUsers = () => {
        const roleUser = this.state.roleUser;

        return <>
            <Card>
                <Card.Body>
                    <Card.Title>Create Users</Card.Title>
                    {this.props.errorUser &&
                        <div className="alert alert-danger" role="alert">
                            {this.props.errorUser}
                        </div>
                    }
                    <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                        <div className="row">
                            <div className="col">
                                <label>Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="email"
                                    name="email"
                                    {...this.props.register('email',
                                        {
                                            required: {
                                                value  : true,
                                                message: <span style={{color: 'red'}}>This field is required</span>
                                            }
                                        }
                                    )}
                                />
                                {this?.props?.errors?.email?.message}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label>Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Password"
                                    {...this.props.register('password',
                                        {
                                            required: {
                                                value  : true,
                                                message: <span style={{color: 'red'}}>This field is required</span>
                                            }
                                        }
                                    )}
                                />
                                {this?.props?.errors?.password?.message}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label>First Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="firstName"
                                    {...this.props.register('firstName',
                                        {
                                            required: {
                                                value  : true,
                                                message: <span style={{color: 'red'}}>This field is required</span>
                                            }
                                        }
                                    )}
                                />
                                {this?.props?.errors?.firstName?.message}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label>Last Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="lastName"
                                    {...this.props.register('lastName',
                                        {
                                            required: {
                                                value  : true,
                                                message: <span style={{color: 'red'}}>This field is required</span>
                                            }
                                        }
                                    )}
                                />
                                {this?.props?.errors?.lastName?.message}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label>Roles</label>
                                <Select
                                    value={roles.filter(function(roles) {
                                        return roles.value === roleUser;
                                    })}
                                    onChange={this.onUserValueChange}
                                    options={roles}
                                    backspaceRemovesValue={true}
                                    escapeClearsValue={true}
                                    menuPlacement={'auto'}
                                    placeholder={'Choose a role'}
                                />
                            </div>
                        </div>

                        <div style={{padding : "10px"}} />

                        <div className="text-center">
                            <Button
                                variant="primary"
                                type="submit">
                                Create a User
                            </Button>
                        </div>
                    </form>
                </Card.Body>
            </Card>
        </>
    }

    onSubmit = async (data) => {

        const finalData = {
            ...data,
            roles: this.state.roleUser
        }

        await this.props.createUser(finalData)

        if (this.props.successUser) {
            this.props.history.push(`/list-users`)
        }
    }

    render() {
        let loader = null;

        if(this.props.loadingCreateUser) {
            loader = <Loader message="Loading..." />
        }

        return <>
            <div className="page-wrapper">
                {/* Navigation */}
                <Navigation onClick={this._onSideMenu} />
                {/* End Navigation */}

                <div  className={`main-content d-flex flex-column ${this.state.sideMenu ? "" : "hide-sidemenu"}`}>
                    {/* Breadcrumb */}

                    {loader}

                    <div className="main-content-header">
                        <Breadcrumb>
                            <h1>Create Users</h1>
                            <Link to={routerConstants.dashboard} className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Link to={routerConstants.listUsers} className="breadcrumb-item">
                                List Users
                            </Link>
                            <Breadcrumb.Item active>
                                Create User
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <Row>
                        <Col lg={12}>
                            {this.formularUsers()}
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    }
}

const CreateUsersHookForm = (props) => {
    const {register, handleSubmit, formState: {errors}} = useForm();

    return <CreateUser 
        {...props}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
    />
}

const mapStateToProps = state => {
    return {
        successUser       : state.user.successCreateUser,
        errorUser         : state.user.errorCreateUser,
        loadingCreateUser : state.user.loadingCreateUser
    }
}

const mapActionsToProps = {
    createUser
}

const CreateUsersConnected = connect(
    mapStateToProps,
    mapActionsToProps
)(CreateUsersHookForm)

export default withRouter(CreateUsersConnected);
