import {
    SET_LOADING_PAYMENT_PLAN_SETTING_LIST,
    SET_PAYMENT_PLAN_SETTING_LIST,
    SET_PAYMENT_PLAN_SETTING_LIST_ERROR
} from "./payment-plan-setting.types";

export const setPaymentPlanSettingList = paymentPlanSettings => {
    return {
        type   : SET_PAYMENT_PLAN_SETTING_LIST,
        payload: paymentPlanSettings
    };
};

export const setLoadingPaymentPlanSettingList = loading => {
    return {
        type   : SET_LOADING_PAYMENT_PLAN_SETTING_LIST,
        payload: loading
    };
};

export const setPaymentPlanSettingListError = error => {
    return {
        type   : SET_PAYMENT_PLAN_SETTING_LIST_ERROR,
        payload: error
    };
};
