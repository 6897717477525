import axios from "axios";
import store from "../redux/store";

const meili = axios.create(
    {
        baseURL: process.env.REACT_APP_MEILI_HOST
    }
);

meili.defaults.prefix = process.env.REACT_APP_MEILI_INDEX_PREFIX;

meili
    .interceptors
    .request
    .use(
        config => {
            config.headers['Content-Type'] = 'application/json';

            if (store && typeof store.getState === "function") {
                const storeState = store.getState();

                if (
                    storeState?.user?.meilisearch_token &&
                    "" !== storeState.user.meilisearch_token
                ) {
                    config.headers.common["Authorization"] = `Bearer ${storeState.user.meilisearch_token}`;
                }
            }

            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );

export default meili;
