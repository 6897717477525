import moment from "moment";

class DateUtils {

    /**
     * Responsible to return a date string with given format from a unix timestamp.
     *
     * @param {string} date The date as unix timestamp
     * @param {string} format The date format to use. Date format is based on moment library.
     * @returns {string} The formatted date.
     */
    static formatUnixTimestampToFormat(date = "0000-00-00T00:00:00+00:00", format = "DD MMM YYYY @ HH:mm:ss") {
        return moment.unix(date).format(format);
    }

    /**
     * Responsible to return a date string with given format from a date string formatted in ISO 8601.
     *
     * @param {string} date The date string as ISO 8601 format. ie: 2021-09-16T16:33:46+03:00
     * @param {string} format The date format to use. Date format is based on moment library.
     * @returns {string} The formatted date.
     */
    static formatISO8601ToFormat(date = "0000-00-00T00:00:00+00:00", format = "DD MMM YYYY @ HH:mm:ss") {
        return moment(date).format(format);
    }

    /**
     * Responsible to return a human readable date from a unix timestamp.
     *
     * @param {string} date The date as unix timestamp
     * @returns {string} The formatted date. ie: 5 minutes ago
     */
    static formatTimestampToHumanReadable(date = "0000-00-00T00:00:00+00:00") {
        return moment.unix(date).fromNow();
    }

    /**
     * Responsible to return a human readable date from a date formatted in ISO 8601.
     *
     * @param {string} date The date string as ISO 8601 format. ie: 2021-09-16T16:33:46+03:00
     * @returns {string} The formatted date. ie: 5 minutes ago
     */
    static formatISO8601ToHumanReadable(date = "0000-00-00T00:00:00+00:00") {
        return moment(date).fromNow();
    }

    /**
     * Responsible to return a date well formatted for use in the Rest API Requests for searching. This method will
     * accept a date string in the form of YYYY-mm-dd and will return a date in the form of YYYY-mm-dd 00:00:00.
     *
     * This way, when we use the From Date fields for searching, we are sure, the start date, will include all the
     * records created in the given date.
     *
     * @param {string} date The date to string to format.
     * @returns {string} The formatted date.
     */
    static formatDateTimeForFromFields(date = "0000-00-00") {
        return moment(date).format("YYYY-MM-DD 00:00:00");
    }

    /**
     * Responsible to return a date well formatted for use in the Rest API Requests for searching. This method will
     * accept a date string in the form of YYYY-mm-dd and will return a date in the form of YYYY-mm-dd 23:59:59.
     *
     * This way, when we use the From Date fields for searching, we are sure, the end date, will include all the
     * records created in the given date.
     *
     * @param {string} date The date to string to format.
     * @returns {string} The formatted date.
     */
    static formatDateTimeForToFields(date = "0000-00-00") {
        return moment(date).format("YYYY-MM-DD 23:59:59");
    }

    /**
     * Responsible to return a date formatted for use in the DatePicker component. This method will accept a date.
     * 
     * @param {string} date The date to string to format.
     * @returns {string} The formatted date.
     */
    static formatDateTimeForDatePicker(date = "0000-00-00") {
        return moment(date).format("YYYY-MM-DD");
    }

    /**
     * Checks if a given date string matches the "DD/MM/YYYY" format and is a valid date.
     *
     * @param {string} dateString The date string to validate.
     * @returns {boolean} True if the date string is valid and matches the format, false otherwise.
     */
    static isDateGetSlash(dateString) {
        // Regular expression to match the date format "DD/MM/YYYY"
        const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
        const match = dateString.match(regex);

        if (!match) {
            return false;
        }

        // Extracting day, month, and year from the matched groups
        const day = parseInt(match[1], 10);
        const month = parseInt(match[2], 10);
        const year = parseInt(match[3], 10);

        // Check the range of day and month
        if (month < 1 || month > 12 || day < 1 || day > 31) {
            return false;
        }

        // Check the number of days in each month
        const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        // Check for leap year
        if (month === 2) {
            const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
            if (isLeapYear) {
                daysInMonth[1] = 29;
            }
        }

        return day <= daysInMonth[month - 1];
    }

    /**
     * Extracts the numbers from a date string in the "DD/MM/YYYY" format without slashes.
     *
     * @param {string} dateString The date string to process.
     * @returns {string} The concatenated numbers from the date string without slashes.
     * @throws {Error} If the date string does not match the "DD/MM/YYYY" format.
     */
    static getNumbersWithoutSlash(dateString) {
        // Regular expression to match the date format "DD/MM/YYYY"
        const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
        const match = dateString.match(regex);

        if (!match) {
            throw new Error("Invalid date format");
        }

        // Extracting day, month, and year from the matched groups
        const day = match[1];
        const month = match[2];
        const year = match[3];

        // Concatenate the extracted values without slashes
        return `${day}${month}${year}`;
    }
}

export default DateUtils;
