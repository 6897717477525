import {Redirect, Route} from "react-router-dom";
import {useSelector}     from "react-redux";
import routerConstants   from "../constants/router-constants";
import JsCookie          from "js-cookie";

function SecureRoute({component: Component, ...props}) {
    const authenticated = useSelector(state => state?.user?.authenticated);
    const accessToken   = useSelector(state => state?.user?.access_token);

    if (typeof accessToken === 'undefined' && authenticated === true) {
        JsCookie.remove("rt");
        JsCookie.remove("ud");
        JsCookie.remove("at");
        return <Redirect to={routerConstants.login} />
    }

    return (
        <Route
            {...props}
            render={
                props => authenticated === true ? <Component {...props} /> : <Redirect to={routerConstants.login} />
            }
        />
    );
}

export default SecureRoute;
