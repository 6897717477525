import { 
    SET_PROJECT_LIST,
    SET_PROJECT_LOADING,
    SET_PROJECT_ERROR,
    SET_SINGLE_PROJECT,
    SET_UPDATE_ERROR,
    SET_UPDATE_SUCCESS,
    SET_UPDATE_LOADING,
    SET_CREATE_ERROR,
    SET_CREATE_SUCCESS,
    SET_CREATE_LOADING
} from "./project.types";

export const setProjectList = project => {
    return {
        type   : SET_PROJECT_LIST,
        payload: project
    };
};

export const setLoadingProject = loading => {
    return {
        type   : SET_PROJECT_LOADING,
        payload: loading
    };
};

export const setProjectError = error => {
    return {
        type   : SET_PROJECT_ERROR,
        payload: error
    };
};

export const setSingleProject = project => {
    return {
        type   : SET_SINGLE_PROJECT,
        payload: project
    };
};

export const setUpdateError = error => {
    return {
        type   : SET_UPDATE_ERROR,
        payload: error
    };
};

export const setUpdateSuccess = success => {
    return {
        type   : SET_UPDATE_SUCCESS,
        payload: success
    };
};

export const setUpdateLoading = loading => {
    return {
        type   : SET_UPDATE_LOADING,
        payload: loading
    };
};

export const setCreateSuccess = success => {
    return {
        type   : SET_CREATE_SUCCESS,
        payload: success
    };
};

export const setCreateError = error => {
    return {
        type   : SET_CREATE_ERROR,
        payload: error
    };
};

export const setCreateLoading = loading => {
    return {
        type   : SET_CREATE_LOADING,
        payload: loading
    };
};
