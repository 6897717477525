export const SET_PROJECT_LIST    = "SET_PROJECT_LIST";
export const SET_PROJECT_LOADING = "SET_PROJECT_LOADING";
export const SET_PROJECT_ERROR   = "SET_PROJECT_ERROR";
export const SET_SINGLE_PROJECT  = "SET_SINGLE_PROJECT";
export const SET_UPDATE_SUCCESS  = "SET_UPDATE_SUCCESS";
export const SET_UPDATE_ERROR    = "SET_UPDATE_ERROR";
export const SET_UPDATE_LOADING  = "SET_UPDATE_LOADING";
export const SET_CREATE_SUCCESS  = "SET_CREATE_SUCCESS";
export const SET_CREATE_ERROR    = "SET_CREATE_ERROR";
export const SET_CREATE_LOADING  = "SET_CREATE_LOADING";
