import React                     from 'react'
import Navigation                from '../../../components/Navigation/Navigation'
import routerConstants           from '../../../constants/router-constants'
import { getPaymentGatewayList } from '../../../redux/PaymentGateway/payment-gateway.async-actions'
import { Link }                  from 'react-router-dom'
import Loader                    from '../../../components/Common/Loader'
import {
    Breadcrumb,
    Button,
    Row,
    Col,
    Table
}                                 from 'react-bootstrap'
import { withRouter }             from 'react-router-dom'
import { connect }                from 'react-redux'
import UUIDs                      from '../../../services/UUIDs'
import ListSorter                 from "../../../Utils/ListSorter";

class ListPaymentGateway extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            sideMenu : true
        }
    }

    goToRoute(route) {
        this.props.history.push(route);
    }

    componentDidMount() {
        this.props.getPaymentGatewayList()
    }

    _onSideMenu = (active) => {
        this.setState({sideMenu: active})
    }

    mapPaymentGateway = () => ListSorter.orderListByProperty(
            this?.props?.paymentGateway, 'name'
        )?.map(
            paymentGateway => {
                const paymentGatewayId = paymentGateway["@id"]

                return (<tr key={paymentGatewayId}>
                    <td>
                        {paymentGateway.name}
                    </td>
                    <td>
                        {paymentGateway.slug}
                    </td>
                    <td>
                        {paymentGateway.createdAt}
                    </td>
                    <td>
                        <Button
                            onClick={e => this.goToRoute(`/payment-gateway/${UUIDs.getEntityUUID(
                                paymentGatewayId)}`,e)}
                            variant="outline-primary"
                            as={"button"}
                        >
                            Edit
                        </Button>
                    </td>
                </tr>
            )
        }
    )

    render() {
        let loader = null;

        if (this.props.paymentGatewayLoading) {
            loader = <Loader message="Loading..." />
        }

        return <>
            <div className="page-wrapper"> 
                <Navigation onClick={this._onSideMenu} />
                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? "" : "hide-sidemenu"}`}>
                    {/* Loader */}
                    {loader}
                    {/* End Loader */}

                    {null !== this.props.paymentGatewayError &&
                        <div className="alert alert-danger" role="alert">
                            {this.props.paymentGatewayError}
                        </div>
                    }

                    {true === this.props.updatePaymentGatewaySuccess &&
                        <div className="alert alert-success" role="alert">
                            The Payment Gateway has been updated successfully!
                        </div>
                    }

                    <div className="main-content-header">
                        <Breadcrumb>
                            <h1>Payment Gateway</h1>
                            <Link to={routerConstants.dashboard} className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Breadcrumb.Item active>
                                Payment Gateway
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>

                    <Row>
                        <Col lg={12}>
                            <div className="card mb-4">
                                <div className="card-body">

                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <h5 className="card-title float-lg-left">Payment Gateway</h5>
                                    </div>

                                    <Table className="m-0" responsive>
                                        <thead>
                                            <tr key="table_headers">
                                                <th width={"20%"}>Paymennt Gateway Name</th>
                                                <th width={"20%"}>Slug</th>
                                                <th width={"20%"}>Created At</th>
                                                <th width={"20%"}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.mapPaymentGateway()}
                                        </tbody>
                                    </Table>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    }
}

const mapStateToProps = state => {
    return {
        paymentGateway              : state.paymentGateway.paymentGatewayList,
        paymentGatewayLoading       : state.paymentGateway.loadingpaymentGatewayList,
        paymentGatewayError         : state.paymentGateway.paymentGatewayListError,
        updatePaymentGatewaySuccess : state.paymentGateway.updatePaymentGatewaySuccess
    };
};

const mapActionsToProps = {
    getPaymentGatewayList
}

const ListPaymentGatewayConnected = connect(
    mapStateToProps,
    mapActionsToProps
)(ListPaymentGateway);

export default withRouter(ListPaymentGatewayConnected);
