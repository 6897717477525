import React                      from 'react'
import { connect }                from 'react-redux';
import { withRouter }             from 'react-router-dom';
import {useForm}                  from "react-hook-form";
import Navigation                 from '../../../components/Navigation/Navigation';
import routerConstants            from "../../../constants/router-constants";
import {
    Breadcrumb,
    Row,
    Col,
    Button,
    Card
}                                 from "react-bootstrap";
import { createNewEmailTemplate } from '../../../redux/EmailTemplate/emailTemplate.async-actions';
import { Link }                   from 'react-router-dom';

class CreateEmailTemplate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sideMenu : true
        }
    }

    _onSideMenu = (active) => {
        this.setState({sideMenu: active})
    }

    EmailTemplateForm = () => {
        return <>
            <Card>
                <Card.Body>
                    <div className="card-header">
                        <Card.Title>
                            Create Email Template
                        </Card.Title>
                    </div>
                    <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                        <div className="form-group col">
                            <label htmlFor="name">
                                    Name
                            </label>
                            <input
                                id="name"
                                type="text"
                                className="form-control"
                                placeholder="Name"
                                name="type"
                                {
                                    ...this
                                        .props
                                        .register(
                                            "name",
                                            {
                                                required: {
                                                    value  : true,
                                                    message: <span style={{color: "red"}}>This field is required</span>
                                                }
                                            }
                                        )
                                }
                            />
                        </div>
                        <div className="form-group col">
                            <label htmlFor="description">
                                    description
                            </label>
                            <input
                                id="description"
                                type="text"
                                className="form-control"
                                placeholder="Description"
                                name="type"
                                {
                                    ...this
                                        .props
                                        .register(
                                            "description",
                                            {
                                                required: {
                                                    value  : true,
                                                    message: <span style={{color: "red"}}>This field is required</span>
                                                }
                                            }
                                        )
                                }
                            />
                        </div>
                        <div className="form-group col">
                            <label htmlFor="externalId">
                                    externalId
                            </label>
                            <input
                                id="externalId"
                                type="text"
                                className="form-control"
                                placeholder="External Id"
                                name="type"
                                {
                                    ...this
                                        .props
                                        .register(
                                            "externalId",
                                            {
                                                required: {
                                                    value  : true,
                                                    message: <span style={{color: "red"}}>This field is required</span>
                                                }
                                            }
                                        )
                                }
                            />
                        </div>

                        <div className="form-group">
                            <Button
                                variant="primary"
                                type="submit"
                            >
                                Create Email Template
                            </Button>
                        </div>
                    </form>
                </Card.Body>
            </Card>
        </>
    }

    onSubmit = async (data) => {
        await this.props.createNewEmailTemplate(data)

        if (this.props.createEmailTemplateSuccess) {
            this.props.history.push(`/list-email`);
        }
    }

    render() {
        let loader = null;

        return <>
            <div className="page-wrapper">
                {/* Navigation */}
                <Navigation onClick={this._onSideMenu} />
                {/* End Navigation */}
                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? "" : "hide-sidemenu"}`}>
                    {loader}

                
                    <div className="main-content-header">
                        <Breadcrumb>
                            <Link to={routerConstants.dashboard} className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Link to={routerConstants.listProductSubscription} className="breadcrumb-item">
                                Product Subscription List
                            </Link>
                            <Breadcrumb.Item active>
                                Create Product Subscription
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <Row>
                        <Col lg={12}>
                            {
                                this.EmailTemplateForm()
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </>   
    }
}

const CreateEmailTemplateHookForm = (props) => {
    const {
        register, 
        handleSubmit, 
        formState: {errors}
    } = useForm();

    return <CreateEmailTemplate 
        {...props}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
    />
}

const mapStateToProps = state => {
    return {
        createEmailTemplateSuccess : state.emailTemplates.createEmailTemplateSuccess,
        createEmailTemplateError   : state.emailTemplates.createEmailTemplateError,
        createEmailTemplateLoading : state.emailTemplates.createEmailTemplateLoading
    }
}

const mapActionsToProps = {
    createNewEmailTemplate
}

const CreateEmailTemplateConnected = connect(
    mapStateToProps,
    mapActionsToProps
)(CreateEmailTemplateHookForm);

export default withRouter(CreateEmailTemplateConnected);
