import {
    SET_CREATE_LOADING_SERVICES,
    SET_CREATE_SERVICES_ERROR,
    SET_CREATE_SERVICES_SUCCESS,
    SET_EDIT_SERVICES_ERROR,
    SET_EDIT_SERVICES_SUCCESS,
    SET_EMAIL_TEMPLATES_LIST,
    SET_EMAIL_TEMPLATES_LIST_ERROR,
    SET_EMAIL_TEMPLATES_LIST_LOADING,
    SET_LOADING_SERVICES_LIST,
    SET_ORDERS_SERVICES_DROP_DOWN,
    SET_ORDERS_SERVICES_DROP_DOWN_ERROR,
    SET_SERVICES_LIST,
    SET_SERVICES_LIST_ERROR,
    SET_SINGLE_SERVICES,
    SET_SINGLE_SERVICES_LOADING
} from "./services.types";

export const setServices = services => {
    return {
        type   : SET_SERVICES_LIST,
        payload: services
    };
};

export const setLoadingServicesList = loading => {
    return {
        type   : SET_LOADING_SERVICES_LIST,
        payload: loading
    };
};

export const setServicesListError = error => {
    return {
        type   : SET_SERVICES_LIST_ERROR,
        payload: error
    };
};

export const setOrdersServicesDropDown = services => {
    return {
        type   : SET_ORDERS_SERVICES_DROP_DOWN,
        payload: services
    };
};


export const setOrdersServicesDropDownError = error => {
    return {
        type   : SET_ORDERS_SERVICES_DROP_DOWN_ERROR,
        payload: error
    };
};

export const setSingleService = services => {
    return {
        type   : SET_SINGLE_SERVICES,
        payload: services
    };
};

export const setEditServicesError = error => {
    return {
        type   : SET_EDIT_SERVICES_ERROR,
        payload: error
    };
};

export const setEditServicesSuccess = success => {
    return {
        type   : SET_EDIT_SERVICES_SUCCESS,
        payload: success
    };
};

export const setCreateServicesError = error => {
    return {
        type   : SET_CREATE_SERVICES_ERROR,
        payload: error
    };
};

export const setCreateServicesSuccess = success => {
    return {
        type   : SET_CREATE_SERVICES_SUCCESS,
        payload: success
    };
};

export const setCreateLoadingServices = loading => {
    return {
        type   : SET_CREATE_LOADING_SERVICES,
        payload: loading
    };
};

export const setSingleServiceLoading = loading => {
    return {
        type   : SET_SINGLE_SERVICES_LOADING,
        payload: loading
    };
};

export const setServiceEmailTemplatesList = emailTemplates => {
    return {
        type   : SET_EMAIL_TEMPLATES_LIST,
        payload: emailTemplates
    };
};

export const setServiceEmailTemplatesListLoading = loading => {
    return {
        type   : SET_EMAIL_TEMPLATES_LIST_LOADING,
        payload: loading
    };
};

export const setServiceEmailTemplatesListError = error => {
    return {
        type   : SET_EMAIL_TEMPLATES_LIST_ERROR,
        payload: error
    };
};
