import {
    SET_CREATE_COMMENT_SUCCESS,
    SET_CREATE_COMMENT_ERROR,
    SET_CREATE_COMMENT_LOADING,
    SET_DELETE_COMMENT_ERROR,
    SET_DELETE_COMMENT_SUCCESS,
    SET_DELETE_COMMENT_LOADING,
    SET_UPDATE_COMMENT_SUCCESS,
    SET_UPDATE_COMMENT_ERROR,
    SET_UPDATE_COMMENT_LOADING
} from './comments.types';

export const setCreateCommentsSuccess = bool => {
    return {
        type   : SET_CREATE_COMMENT_SUCCESS,
        payload: bool
    }
}

export const setCreateCommentsError = error => {
    return {
        type   : SET_CREATE_COMMENT_ERROR,
        payload: error
    }
}


export const setCreateCommentLoading = load => {
    return {
        type   : SET_CREATE_COMMENT_LOADING,
        payload: load
    }
}


export const setDeleteCommentsSuccess = bool => {
    return {
        type   : SET_DELETE_COMMENT_SUCCESS,
        payload: bool
    }
}

export const setDeleteCommentsError = error => {
    return {
        type   : SET_DELETE_COMMENT_ERROR,
        payload: error
    }
}

export const setDeleteCommentLoading = load => {
    return {
        type   : SET_DELETE_COMMENT_LOADING,
        payload: load
    }
}

export const setUpdateCommentsSuccess = bool => {
    return {
        type   : SET_UPDATE_COMMENT_SUCCESS,
        payload: bool
    }
}

export const setUpdateCommentsError = error => {
    return {
        type   : SET_UPDATE_COMMENT_ERROR,
        payload: error
    }
}

export const setUpdateCommentLoading = load => {
    return {
        type   : SET_UPDATE_COMMENT_LOADING,
        payload: load
    }
}
