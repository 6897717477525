import {
    SET_LOCALES_LIST,
    SET_ERROR_MESSAGE_LOCALES,
    SET_LOADING_LOCALES
} from "./locale.types";

const INITIAL_STATE = {
    localesList           : [],
    setErrorMessageLocales: null,
    setLoadingLocales     : false
};

const localesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LOCALES_LIST:
            return {
                ...state,
                localesList: action.payload
            };
        case SET_ERROR_MESSAGE_LOCALES:
            return {
                ...state,
                setErrorMessageLocales: action.payload
            };
        case SET_LOADING_LOCALES:
            return {
                ...state,
                setLoadingLocales: action.payload
            };
            
        default:
            return state;
    }
};

export default localesReducer;
