import { 
    SET_COMPANY_LIST,
    SET_LOADING_COMPANY,
    SET_COMPANY_ERROR,
    SET_SINGLE_COMPANY,
    SET_SINGLE_COMPANY_ERROR,
    SET_ERROR_UPDATE_COMPANY,
    SET_POST_COMPANY_ERROR,
    SET_POST_COMPANY_LOADING,
    SET_POST_COMPANY_SUCCEED,
    SET_UPDATE_LOADING_COMPANY,
    SET_UPDATE_SUCCED_COMPANY
} from "./company.types";

const INITIAL_STATE = {
    setCompanyList         : [],
    setLoadingCompany      : false,
    setCompanyError        : null,
    setSingleCompany       : [],
    setSingleCompanyError  : null,
    setErrorUpdateCompany  : null,
    setPostCompanyError    : null,
    setPostCompanySucced   : false,
    setPostCompanyLoading  : false,
    setUpdateLoadingCompany: false,
    setUpdateSuccedCompany : false
};

const companyReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_COMPANY_LIST:
            return {
                ...state,
                setCompanyList: action.payload
            };
        case SET_LOADING_COMPANY:
            return {
                ...state,
                setLoadingCompany: action.payload
            };
        case SET_COMPANY_ERROR:
            return {
                ...state,
                setCompanyError: action.payload
            };
        case SET_SINGLE_COMPANY:
            return {
                ...state,
                setSingleCompany: action.payload
            };
        case SET_SINGLE_COMPANY_ERROR:
            return {
                ...state,
                setSingleCompanyError: action.payload
            };
        case SET_ERROR_UPDATE_COMPANY:
            return {
                ...state,
                setErrorUpdateCompany: action.payload
            };
        case SET_POST_COMPANY_ERROR:
            return {
                ...state,
                setPostCompanyError: action.payload
            };
        case SET_POST_COMPANY_SUCCEED:
            return {
                ...state,
                setPostCompanySucced: action.payload
            };
        case SET_POST_COMPANY_LOADING:
            return {
                ...state,
                setPostCompanyLoading: action.payload
            };
        case SET_UPDATE_LOADING_COMPANY:
            return {
                ...state,
                setUpdateLoadingCompany: action.payload
            };
        case SET_UPDATE_SUCCED_COMPANY:
            return {
                ...state,
                setUpdateSuccedCompany: action.payload
            };

        default:
            return state;
    }
};

export default companyReducer;
