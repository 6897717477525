import React  from "react";
import {
    Button,
    Modal,
    Form
}             from "react-bootstrap";
import Select from "react-select";

class ConfirmStatusChange extends React.Component {
    state = {
        drivingLicensePoints   : 0,
        proDrivingLicensePoints: 0,
        hasProPoints           : false
    };

    constructor(props, context) {
        super(props, context);

        this.drivingLicensePoints    = React.createRef();
        this.proDrivingLicensePoints = React.createRef();

        this.updateDrivingLicensePointsMethod           = this.updateDrivingLicensePoints.bind(this);
        this.updateProDrivingLicensePointsMethod        = this.updateProDrivingLicensePoints.bind(this);
        this.validateDrivingLicensePointsRangeMethod    = this.validateDrivingLicensePointsRange.bind(this);
        this.validateProDrivingLicensePointsRangeMethod = this.validateProDrivingLicensePointsRange.bind(this);
        this.processConfirmationMethod                  = this.processConfirmation.bind(this);
    }

    componentDidMount() {
        this.setState(
            {
                drivingLicensePoints   : 0,
                proDrivingLicensePoints: 0,
                hasProPoints           : false
            }
        );
    }

    processConfirmation = e => {
        if ("driving_licence" === this.props.nameOfService) {
            this.validateDrivingLicensePointsRangeMethod();
            this.validateProDrivingLicensePointsRangeMethod();
        }

        if (e?.key && e.key === "Enter" && false === this.props.processing) {
            window.requestAnimationFrame(
                () => this.props.onConfirm()
            );
        }

        if (e?.key && e.key === "Escape" && false === this.props.processing) {
            this.props.onClose();
        }
    };

    updateDrivingLicensePoints() {
        this.setState(
            {
                drivingLicensePoints: parseInt(this?.drivingLicensePoints?.current?.value, 10) ?? 0
            }
        );
    }

    updateProDrivingLicensePoints() {
        this.setState(
            {
                drivingLicensePoints: parseInt(this?.drivingLicensePoints?.current?.value, 10) ?? 0
            }
        );
    }

    validateDrivingLicensePointsRange() {
        const pointsText = this?.drivingLicensePoints?.current?.value.replace(/^0+/gm, "");
        let points       = parseInt(pointsText, 10) ?? 0;

        if (isNaN(points)) {
            points = 0;
        }

        if (points < 0) {
            this.drivingLicensePoints.current.value = 0;
        }

        this
            .setState(
                {
                    drivingLicensePoints: points
                }
            );

        this
            .props
            .onUpdateDrivingLicensePoints(
                points,
                this.state.hasProPoints ? this.state.proDrivingLicensePoints : null
            );
    }

    validateProDrivingLicensePointsRange() {
        if (!this.state.hasProPoints) {
            return;
        }

        const pointsText = this?.proDrivingLicensePoints?.current?.value.replace(/^0+/gm, "");
        let points       = parseInt(pointsText, 10) ?? 0;

        if (isNaN(points)) {
            points = 0;
        }

        if (points < 0) {
            this.proDrivingLicensePoints.current.value = 0;
        }

        this
            .setState(
                {
                    proDrivingLicensePoints: points
                }
            );

        this
            .props
            .onUpdateDrivingLicensePoints(
                this.state.drivingLicensePoints,
                points
            );
    }

    render() {
        let modalText = `By doing this operation you will change the status of the purchase related to the 
        customer <strong>%customer%</strong> <em>(%email%)</em> from <strong>%currentStatus%</strong> to 
        <strong>%nextStatus%</strong>`;

        if (this?.props?.requestMissingInfo === true) {
            modalText += ` and the customer will be notified through email.`;
        }

        modalText = modalText
            .replace(
                "%customer%",
                `${this.props.customerLastName} ${this.props.customerFirstName}`.trim()
            )
            .replace(
                "%email%",
                this.props.customerEmail
            )
            .replace(
                "%currentStatus%",
                this.props.currentStatusText
            )
            .replace(
                "%nextStatus%",
                this.props.nextStatusText
            );

        return (
            <div
                className="modal-wrap"
                onKeyDown={this.processConfirmationMethod}
            >
                <Modal
                    show={this.props.show}
                    onHide={this.handleClose}
                    backdrop="static"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>
                            <strong className="text-danger">Warning!</strong> Purchase Status Change
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p
                            style={{lineHeight: "1.2"}}
                            dangerouslySetInnerHTML={{__html: modalText}}
                        />

                        {
                            this.props.nextStatusText === "Not Delivered" ?
                                <Select
                                    value={this.props.emailTemplateValue}
                                    options={this.props.emailTemplateOption}
                                    onChange={this.props.emailTemplateOnChange}
                                    backspaceRemovesValue={true}
                                    escapeClearsValue={true}
                                    isClearable={true}
                                    menuPlacement={"auto"}
                                    placeholder={"Choose a product subscription (Default: Completed)"}
                                />
                                : null
                        }

                        {
                            "driving_licence" === this.props.nameOfService &&
                            <div>
                                <p style={{lineHeight: "1.2"}}>
                                    To accomplish this operation you should provide in addition the driving license
                                    points in the following field.
                                </p>
                                <Form.Group
                                    className="mb-3"
                                    controlId="drivingLicense.Points"
                                >
                                    <Form.Label>
                                        Driving License Points
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        ref={this.drivingLicensePoints}
                                        min={0}
                                        max={30}
                                        onKeyDown={
                                            e => {
                                                if ("Enter" === e.key) {
                                                    e.stopPropagation();
                                                    e.preventDefault();

                                                    window.requestAnimationFrame(
                                                        () => {
                                                            this.validateDrivingLicensePointsRangeMethod();
                                                            this.props.onConfirm();
                                                        }
                                                    );
                                                }
                                            }
                                        }
                                        onChange={this.updateProDrivingLicensePointsMethod}
                                        onBlur={this.validateDrivingLicensePointsRangeMethod}
                                        defaultValue={0}
                                    />
                                </Form.Group>
                                <div className="my-2">
                                    <button
                                        className="btn btn-info"
                                        type="button"
                                        onClick={() => this.setState({hasProPoints: !this.state.hasProPoints})}
                                    >
                                        Pro
                                    </button>
                                </div>
                                {
                                    this.state.hasProPoints &&
                                    <div className="mt-4">
                                        <p style={{lineHeight: "1.2"}}>
                                            Enter the driving license points for a professional driver in the
                                            following field.
                                        </p>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="proDrivingLicense.Points"
                                        >
                                            <Form.Label>
                                                Professional Driving License Points
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                ref={this.proDrivingLicensePoints}
                                                min={0}
                                                max={30}
                                                onKeyDown={
                                                    e => {
                                                        if ("Enter" === e.key) {
                                                            e.stopPropagation();
                                                            e.preventDefault();

                                                            window.requestAnimationFrame(
                                                                () => {
                                                                    this.validateProDrivingLicensePointsRangeMethod();
                                                                    this.props.onConfirm();
                                                                }
                                                            );
                                                        }
                                                    }
                                                }
                                                onChange={this.updateDrivingLicensePointsMethod}
                                                onBlur={this.validateProDrivingLicensePointsRangeMethod}
                                                defaultValue={0}
                                            />
                                        </Form.Group>
                                    </div>
                                }
                            </div>
                        }
                        {
                            <div>
                                Add your comments
                                <form onSubmit={this.props.showCommentsSection}>
                                    <textarea
                                        className="form-control"
                                        name="name"
                                        rows="3"
                                        onChange={this.props.handleCommentsText}
                                        value={this.props.commentsText}
                                    />
                                </form>
                            </div>
                        }
                        {
                            "driving_licence" === this.props.nameOfService &&
                            <p
                                className="mb-0"
                                style={{lineHeight: "1.2"}}
                            >
                                Do you really want to perform this action by assigning <strong>
                                {this.state.drivingLicensePoints}</strong> points {this.state.hasProPoints && <>and <strong>{this.state.proDrivingLicensePoints}</strong> points</>} to
                                the customer
                                driving license ?
                            </p>
                        }
                        {
                            "driving_licence" !== this.props.nameOfService &&
                            <div>
                                Do you really want to perform this action?
                            </div>
                        }
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            variant="danger"
                            onClick={this.props.onConfirm}
                            disabled={this.props.processing}
                        >
                            {this.props.processing ? "Processing ..." : "Confirm"}
                        </Button>
                        <Button
                            variant="success"
                            onClick={this.props.onClose}
                            disabled={this.props.processing}
                        >
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default ConfirmStatusChange;
