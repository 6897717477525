import http from "../../../services/http";
import {
    setOrderStatistics,
    setOrderStatisticsLoader
} from "./stats-order-active.actions"

export const getOrderStatistics = () => {
    return async dispatch => {
        dispatch(setOrderStatisticsLoader(true));

        try {
            const orderStatisticsResponse = await http.get(
                "orders/stats-orders-active"
            );

            dispatch(setOrderStatistics(orderStatisticsResponse.data))
        } catch (e) {
            dispatch(setOrderStatisticsLoader(false));
        } finally {
            dispatch(setOrderStatisticsLoader(false));
        }
    };
};
