import {
    SET_RESET_FILTER_PURCHASES
} from "./global.types";

const INITIAL_STATE = {
    resetFilterPurchases: false
}

const globalReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SET_RESET_FILTER_PURCHASES:
            return {
                ...state,
                resetFilterPurchases: action.payload
            }
        default:
            return state;
    }
};

export default globalReducer;
