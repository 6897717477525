export default [
    { 
        value: ['ROLE_ADMIN'], 
        label: 'Admin' 
    },
    { 
        value: ['ROLE_OPERATOR'], 
        label: 'Operator' 
    },
];
