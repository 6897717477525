import React                         from "react";
import Navigation                    from "../../../components/Navigation/Navigation";
import { Link }                      from "react-router-dom";
import routerConstants               from "../../../constants/router-constants";
import {
    getSinglePaymentGateway,
    putPayementGateway,
}                                    from "../../../redux/PaymentGateway/payment-gateway.async-actions";
import { getWebsites }               from "../../../redux/Websites/websites.async-actions";
import { getPaymentPlanSettingList } from "../../../redux/PaymentPlanSettings/payment-plan-setting.async-actions";
import {
    Breadcrumb,
    Button,
    Row,
    Col,
    Card,
    FormCheck,
}                                     from "react-bootstrap";
import { 
    useFieldArray,
    useForm
}                                     from "react-hook-form";
import { connect }                    from "react-redux";
import Loader                         from "../../../components/Common/Loader";
import Select                         from "react-select";
import { X }                          from "react-feather";

class EditPaymentGateway extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sideMenu: true,
            websites: {},
            paymentGatewaySettings: []
        };

        this.websitesOptions = [];
    }

    onWebsitesValueChange = (websites) => this.setState({ websites });

    _onSideMenu = (active) => {
        this.setState({ sideMenu: active });
    };

    async componentDidMount() {
        await this.props.getWebsites();
        await this.props.getSinglePaymentGateway(
            this.props.match.params.paymentGateway_uuid
        );

        if (null === this.props.errorWebsite) {
            this.websitesOptions = this?.props?.websiteList?.map((website) => {
                return {
                    value: website["@id"],
                    label: website.domain,
                };
            });

            this.setState({
                websites: this.props.paymentGateway.website.map((website) => {
                    return {
                        value: website["@id"],
                        label: website.domain,
                    };
                }),
            });
        }

        if (null === this.props.paymentGatewayError) {
            this.setState({
                paymentGatewaySettings: this.props.paymentGateway.paymentGatewaySettings.map((payment) => {
                    return {
                        name: payment.name,
                        value: payment.value,
                        protected: payment.protected,
                    };
                }),
            });
        }
    }

    PaymentGatewayForm = () => {
    const { name, slug } = this.props.paymentGateway;

    this.props.setValue("name", name);
    this.props.setValue("slug", slug);

    return (
        <>
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <Card>
                    <Card.Body>
                        <Card.Title>Edit Payment Gateway</Card.Title>
                        <div className="row mb-3">
                            <div className="col">
                                <label htmlFor="name">Name</label>
                                <input
                                    id="name"
                                    type="text"
                                    className="form-control"
                                    placeholder="Name"
                                    disabled
                                    name="name"
                                    {...this.props.register("name")}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label htmlFor="slug">Slug</label>
                                <input
                                    id="slug"
                                    type="text"
                                    placeholder="Slug"
                                    className="form-control"
                                    disabled
                                    name="slug"
                                    {...this.props.register("slug")}
                                />
                            </div>
                        </div>

                        <label>Website</label>
                        <Select
                            value={this.state.websites}
                            options={this.websitesOptions}
                            onChange={this.onWebsitesValueChange}
                            backspaceRemovesValue={true}
                            escapeClearsValue={true}
                            isMulti
                            isDisabled={true}
                            menuPlacement={"auto"}
                        />
                        {this.props.errorWebsite !== null && (
                        <span className="text-danger">
                            Something went wrong and the website cannot be loaded. Please
                            contact the development team to ask for more information.
                        </span>
                        )}
                    </Card.Body>
                </Card>

                <div style={{ padding: "10px" }} />

                {this?.state?.paymentGatewaySettings?.map((item, index) => {
                    this.props.setValue(`nameSettings[${index}].name`, item.name)
                    this.props.setValue(`value[${index}].value`, item.value)
                    this.props.setValue(`protected[${index}].protected`, item.protected)

                    return <>
                        <div key={item.value}>
                            <Card>
                                <Card.Body>
                                <div key={index} style={{ padding: "10px" }}>
                                    <div className="container">
                                        <label>Payment Gateway Settings</label>
                                        <X
                                            onClick={() => this.removePaymentGatewaySettings(index)}
                                            className="float-right"
                                        />
                                        <div className="row">
                                            <div className="col-10 ">
                                            <label htmlFor="name">
                                                Name
                                            </label>
                                            <input
                                                id="name"
                                                type="text"
                                                placeholder="Name"
                                                className="form-control"
                                                name={`nameSettings[${index}]`}
                                                key={item.name}
                                                {...this.props.register(`nameSettings[${index}].name`)}
                                            />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-10 ">
                                            <label htmlFor="value">
                                                Value
                                            </label>
                                            <input
                                                id="value"
                                                type="text"
                                                placeholder="Value"
                                                className="form-control"
                                                name={`value[${index}]`}
                                                key={item.value}
                                                {...this.props.register(`value[${index}].value`)}
                                            />
                                            </div>
                                        </div>
                                        <div className="row mb-3 ">
                                            <div className="col-10 ">
                                            <label htmlFor="protected-switch">Protected</label>
                                            <FormCheck
                                                type="switch"
                                                id={`protected-switch-${index}`}
                                                key={item.protected}
                                                {...this.props.register(`protected[${index}].protected`)}
                                            />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </Card.Body>
                            </Card>
                            <div style={{ padding: "10px" }} />
                        </div>
                    </>
                })}
                {this.props.fields.map((item, index) => {
                    return <>
                        <Card key={item.id}>
                            <Card.Body>
                            <div style={{ padding: "10px" }}>
                                <div className="container">
                                    <label>Payment Gateway Settings</label>
                                    <X
                                        onClick={() => this.props.remove()}
                                        className="float-right"
                                    />
                                    <div className="row">
                                        <div className="col-10 ">
                                        <label htmlFor={`newValue[${index}].newName`}>
                                            Name
                                        </label>
                                        <input
                                            id={`newValue[${index}].newName`}
                                            type="text"
                                            placeholder="Name"
                                            className="form-control"
                                            name={`newValue[${index}].newName`}
                                            {...this.props.register(`newValue[${index}].newName`)}
                                        />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-10 ">
                                        <label htmlFor={`newValue[${index}].newValue`}>
                                            Value
                                        </label>
                                        <input
                                            id={`value[${index}].newValue`}
                                            type="text"
                                            placeholder="Value"
                                            className="form-control"
                                            name={`newValue[${index}].newValue`}
                                            {...this.props.register(`newValue[${index}].newValue`)}
                                        />
                                        </div>
                                    </div>
                                    <div className="row mb-3 ">
                                        <div className="col-10 ">
                                        <label htmlFor={`protected-switch-${index}.newProtected`}>
                                            Protected
                                        </label>
                                        <FormCheck
                                            type="switch"
                                            id={`protected-switch-${index}.newProtected`}
                                            {...this.props.register(`newValue[${index}].newProtected`)}
                                        />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </Card.Body>
                        </Card>
                        <div style={{ padding: "10px" }} />
                    </>
                })}
                <div>
                    <Button 
                        variant="secondary" 
                        type=""
                        className="mr-10"
                        size="sm"
                        onClick={() => this.props.append({ newValue: "", newValue: "", newProtected: false})}
                    >
                        Add settings
                    </Button>
                    <Button 
                        variant="primary" 
                        type="submit"
                        size="lg"
                    >
                        Edit Payment Service
                    </Button>
                </div>
            </form>
        </>
        );
    };

    goToRoute(route) {
        this.props.history.push(route);
    }

    prepareArrayObjects = (maxArray ,nameSettingsArray, protectedArray, valueArray) => {
        const arrayMerged = [];

        for (let i = 0; i < maxArray; i++) {
            let mergedObjects = {};

            if (i < nameSettingsArray.length) {
              Object.assign(mergedObjects, nameSettingsArray[i]);
            }
          
            if (i < protectedArray.length) {
              Object.assign(mergedObjects, protectedArray[i]);
            }
          
            if (i < valueArray.length) {
              Object.assign(mergedObjects, valueArray[i]);
            }

            arrayMerged.push(mergedObjects);
        }

        return arrayMerged;
    }

    removePaymentGatewaySettings = (index) => {
        let newPaymentGatewaySettings = [...this.state.paymentGatewaySettings];

        newPaymentGatewaySettings.splice(index, 1);

        this.setState({
            paymentGatewaySettings: newPaymentGatewaySettings
        })
    }

    diffArray = (arr1, arr2) => arr1.filter(
        arrayItem => -1 === arr2.indexOf(arrayItem)
    );

    onSubmit = async (data) => {
        const settingsProps = this.props.paymentGateway.paymentGatewaySettings;
        let finalData;

        const maxLength = Math.max(
                data.nameSettings.length, 
                data.protected.length, 
                data.value.length
            );

        const settings = this.prepareArrayObjects(
                maxLength,
                data.nameSettings,
                data.protected, 
                data.value
            )

        const combinedArray = settings.concat(data.newValue)

        if (
            settingsProps.length <= combinedArray.length 
            && this.state.paymentGatewaySettings.length === combinedArray.length
        ) {
            finalData = combinedArray;
        } else if (settingsProps.length > this.state.paymentGatewaySettings.length) {
            finalData = this.diffArray( this.state.paymentGatewaySettings, combinedArray )
        } else {
            finalData = combinedArray;
        }

        const paymentGatewaySettings = finalData.map((item) => ({
            name: item.name || item.newName,
            value: item.value || item.newValue,
            protected: item.protected || item.newProtected || false,
        }));

        const dataPayload = {
            website: this.state.websites.map((website) => website.value),
            name: data?.name,
            slug: data?.slug,
            paymentGatewaySettings: paymentGatewaySettings
        };

        await this.props.putPayementGateway(
            this.props.match.params.paymentGateway_uuid,
            dataPayload
        );

        if (this.props.updateSuccess) {
            this.goToRoute(`/payment-gateway`);
        }
    };

    render() {
        let loader = null;

        if (
            this.props.updatePaymentGatewayLoading ||
            this.props.paymentGatewayLoading
        ) {
            loader = <Loader message="Loading..." />;
        }

        return <>
            <div className="page-wrapper">
                {/* Navigation */}
                <Navigation onClick={this._onSideMenu} />
                {/* End Navigation */}

                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? "" : "hide-sidemenu"}`}>
                    {/* Loader */}
                    {loader}
                    {/* End Loader */}

                    <div className="main-content-header">
                        <Breadcrumb>
                            <h1>Edit Payment Gateway</h1>
                            <Link to={routerConstants.dashboard} className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Link to={routerConstants.listPaymentGateway} className="breadcrumb-item">
                                Payment Gateway
                            </Link>
                            <Breadcrumb.Item active>
                                Edit Payment Gateway
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>

                    {null !== this.props.updatePaymentGatewayError && (
                        <div className="alert alert-danger" role="alert">
                            {this.props.updatePaymentGatewayError}
                        </div>
                    )}

                    <Row>
                        <Col lg={12}>
                            {this.PaymentGatewayForm()}
                        </Col>
                    </Row>
                </div>
            </div>
        </>;
    }
}

const EditPaymentGatewayPage = (props) => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        control,
        reset
    } = useForm();

    const { 
        fields,
        append,
        remove 
    } = useFieldArray({
        control,
        name: 'newValue',
    });

    return (
        <EditPaymentGateway
            {...props}
            register={register}
            errors={errors}
            handleSubmit={handleSubmit}
            setValue={setValue}
            fields={fields}
            append={append}
            remove={remove}
            reset={reset}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        paymentGateway              : state.paymentGateway.singlePaymentGateway,
        paymentGatewayLoading       : state.paymentGateway.loadingSinglePaymentGateway,
        paymentGatewayError         : state.paymentGateway.singlePaymentGatewayError,
        websiteList                 : state.websites.set_websites,
        errorWebsite                : state.websites.website_form_fail,
        updateSuccess               : state.paymentGateway.updatePaymentGatewaySuccess,
        updatePaymentGatewayError   : state.paymentGateway.updatePaymentGatewayErorr,
        updatePaymentGatewayLoading : state.paymentGateway.updatePaymentGatewayLoading,
    };
};

const mapActionsToProps = {
    getSinglePaymentGateway,
    getPaymentPlanSettingList,
    getWebsites,
    putPayementGateway,
};

const EditPaymentGatewayConnected = connect(
    mapStateToProps,
    mapActionsToProps
)(EditPaymentGatewayPage);

export default EditPaymentGatewayConnected;
