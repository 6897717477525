import React           from 'react'
import Navigation      from '../../components/Navigation/Navigation';
import {
    Breadcrumb,
    Row,
    Col,
    Button,
    Table, 
    Dropdown,
    ButtonGroup
}                      from 'react-bootstrap';
import { 
    Link,
    withRouter
}                           from 'react-router-dom';
import routerConstants    from '../../constants/router-constants';
import { 
    getUsers, 
    deleteUser 
}                        from '../../redux/User/user.async-actions';
import { connect }       from 'react-redux';
import UUIDs             from '../../services/UUIDs';
import Loader            from '../../components/Common/Loader';
import ConfirmDeleteUser from '../ListPurchases/Modals/ConfirmDeleteUser';
import ListSorter        from "../../Utils/ListSorter";

class ListUsers extends React.Component {
    constructor(props) {
        super(props)

        this.state={
            sideMenu                   : true,
            showConfirmDeleteUserModal : false,
            processingUserDeleted      : false,
            userId                     : null,
            userEmail                  : "",
            userLastName               : "",
            userFirstName              : "" 
        }
    }

    async componentDidMount() {
        await this.props.getUsers()
    }

    goToRoute(route) {
        this.props.history.push(route);
    }

    _onSideMenu = (active) => {
        this.setState({sideMenu: active});
    };

    deleteUser = async () => {
        this.setState({processingUserDeleted: true})

        await this.props.deleteUser(this.state.userId);

        if (this.props.successDeleteUser) {
            await this.props.getUsers();
        }

        this.setState(
            {
                processingUserDeleted     : false,
                showConfirmDeleteUserModal: false
            }
        )
    }

    showConfirmDeleteUserModal = (
        userId,
        email,
        firstName,
        lastName
    ) => {
        this.setState(
            {
                showConfirmDeleteUserModal  : true,
                userId,
                userEmail                    : email,
                userFirstName                : firstName,
                userLastName                 : lastName
            }
        )
    }

    hideConfirmDeleteUserModal = () => {
        this.setState(
            {
                showConfirmDeleteUserModal: false,
                userId                    : null,
                userEmail                 : "",
                userFirstName             : "",
                userLastName              : ""
            }
        );
    }

    mapUsers = () => ListSorter.orderListByProperty(
            this.props.users, 'email'
        ).map(
            user => {
                const userId = user?.id;

                return (<tr key={userId}>
                    <td>
                        {user.email}
                    </td>
                    <td>
                        {user.firstName}
                    </td>
                    <td>
                        {user.lastName}
                    </td>
                    <td>
                        {user?.['roles']?.[0]}
                    </td>
                    <td>
                        <Dropdown as={ButtonGroup} size="sm" className="d-inline-block mr-2 mt-2">
                            <Button
                                variant="outline-primary"
                                as={"button"}
                                onClick={
                                    () => this.goToRoute(
                                        `/update-user/${userId}`
                                    )
                                }
                            >
                                Edit
                            </Button>
                            <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />
                                <Dropdown.Menu  popperConfig={{strategy: "fixed"}}>
                                    <Dropdown.Item
                                        onClick={
                                            () => this.showConfirmDeleteUserModal(
                                                userId,
                                                user.email,
                                                user.firstName,
                                                user.lastName
                                            )
                                        }
                                    >
                                        Delete User
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                        </Dropdown>
                    </td>
                </tr>
            );
        }
    )

    render() {
        let loader = null;

        if (this.props.deleteUserLoading) {
            loader = <Loader message="Loading..." />
        }

        return <>
            <div className="page-wrapper">
                {/* Navigation */}
                 <Navigation onClick={this._onSideMenu} />
                {/* End Navigation */}

                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? "" : "hide-sidemenu"}`}>

                    {loader}

                    <div className="main-content-header">
                        <Breadcrumb>
                            <h1>List Users</h1>
                            <Link to={routerConstants.dashboard} className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Breadcrumb.Item active>
                                List Users
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>

                    <ConfirmDeleteUser
                        show={this.state.showConfirmDeleteUserModal}
                        processing={this.state.processingUserDeleted}
                        onClose={this.hideConfirmDeleteUserModal}
                        onConfirm={this.deleteUser}
                        userId={this.state.userId}
                        userEmail={this.state.userEmail}
                        userLastName={this.state.userLastName}
                        userFirstName={this.state.userLastName}

                    />

                    {true === this.props.successUserCreate &&
                        <div className="alert alert-success" role="alert">
                            The User has been created successfully!
                        </div>
                    }
                    {true === this.props.successUserUpdated &&
                        <div className="alert alert-success" role="alert">
                            The User has been updated successfully!
                        </div>
                    }
                    {true === this.props.successDeleteUser &&
                        <div className="alert alert-success" role="alert">
                            The user has been deleted
                        </div>
                    }
                    {null !== this.props.errorDeleteUser &&
                        <div className="alert alert-danger" role="alert">
                            {this.props.errorDeleteUser}
                        </div>
                    }
                    <Row>
                        <Col lg={12}>
                            <div className="card mb-4">
                                <div className="card-body">

                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <h5 className="card-title float-lg-left">Users</h5>
                                        <Button
                                            onClick={e => this.goToRoute(routerConstants.createUser, 2 )}
                                            variant="outline-primary" 
                                            className="mb-2"
                                        >
                                            Create User
                                        </Button>
                                    </div>

                                    <Table className="m-0" responsive>
                                        <thead>
                                            <tr key="table_headers">
                                                <th width={"20%"}>Email</th>
                                                <th width={"20%"}>First Name</th>
                                                <th width={"20%"}>Last Name</th>
                                                <th width={"20%"}>Role</th>
                                                <th width={"20%"}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.mapUsers()}
                                        </tbody>
                                    </Table>

                                </div>
                            </div>
                        </Col>
                    </Row>

                </div>
            </div>
        </>
    }
}

const mapStateToProps = state => {
    return {
        users              : state.user.usersList,
        successUserCreate  : state.user.successCreateUser,
        successUserUpdated : state.user.updateUserSuccess,
        successDeleteUser  : state.user.deleteUserSuccess,
        errorDeleteUser    : state.user.deleteUserError,
        deleteUserLoading  : state.user.deleteUserLoading
    }
}

const mapActionsToProps = {
    getUsers,
    deleteUser
}

const ListUsersConnected = connect(
    mapStateToProps,
    mapActionsToProps
)(ListUsers);

export default withRouter(ListUsersConnected);
