import {
    SET_CREATE_LOADING_SERVICES,
    SET_CREATE_SERVICES_ERROR,
    SET_CREATE_SERVICES_SUCCESS,
    SET_EDIT_SERVICES_ERROR,
    SET_EDIT_SERVICES_SUCCESS,
    SET_EMAIL_TEMPLATES_LIST, SET_EMAIL_TEMPLATES_LIST_ERROR, SET_EMAIL_TEMPLATES_LIST_LOADING,
    SET_LOADING_SERVICES_LIST,
    SET_ORDERS_SERVICES_DROP_DOWN,
    SET_ORDERS_SERVICES_DROP_DOWN_ERROR,
    SET_SERVICES_LIST,
    SET_SERVICES_LIST_ERROR,
    SET_SINGLE_SERVICES,
    SET_SINGLE_SERVICES_LOADING
} from "./services.types";

const INITIAL_STATE = {
    servicesList                : [],
    loadingServicesList         : false,
    serviceListError            : null,
    ordersServicesList          : [],
    ordersServicesListError     : null,
    setSingleServices           : [],
    setEditServicesError        : null,
    setEditServicesSuccess      : false,
    setSingleServicesLoading    : false,
    setCreateServicesError      : null,
    setCreateServicesSuccess    : false,
    setCreateLoadingServices    : false,
    serviceEmailTemplates       : [],
    serviceEmailTemplatesLoading: false,
    serviceEmailTemplatesError  : null
};

const servicesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_SERVICES_LIST:
            return {
                ...state,
                servicesList: action.payload
            };
        case SET_LOADING_SERVICES_LIST:
            return {
                ...state,
                loadingServicesList: action.payload
            };
        case SET_SERVICES_LIST_ERROR:
            return {
                ...state,
                serviceListError: action.payload
            };
        case SET_ORDERS_SERVICES_DROP_DOWN:
            return {
                ...state,
                ordersServicesList: action.payload
            };
        case SET_ORDERS_SERVICES_DROP_DOWN_ERROR:
            return {
                ...state,
                ordersServicesListError: action.payload
            };
        case SET_SINGLE_SERVICES:
            return {
                ...state,
                setSingleServices: action.payload
            };
        case SET_EDIT_SERVICES_ERROR:
            return {
                ...state,
                setEditServicesError: action.payload
            };
        case SET_EDIT_SERVICES_SUCCESS:
            return {
                ...state,
                setEditServicesSuccess: action.payload
            };
        case SET_CREATE_SERVICES_ERROR:
            return {
                ...state,
                setCreateServicesError: action.payload
            };
        case SET_CREATE_SERVICES_SUCCESS:
            return {
                ...state,
                setCreateServicesSuccess: action.payload
            };
        case SET_CREATE_LOADING_SERVICES:
            return {
                ...state,
                setCreateLoadingServices: action.payload
            };
        case SET_SINGLE_SERVICES_LOADING:
            return {
                ...state,
                setSingleServicesLoading: action.payload
            };
        case SET_EMAIL_TEMPLATES_LIST:
            return {
                ...state,
                serviceEmailTemplates: action.payload
            };
        case SET_EMAIL_TEMPLATES_LIST_LOADING:
            return {
                ...state,
                serviceEmailTemplatesLoading: action.payload
            };
        case SET_EMAIL_TEMPLATES_LIST_ERROR:
            return {
                ...state,
                serviceEmailTemplatesError: action.payload
            };

        default:
            return state;
    }
};

export default servicesReducer;
