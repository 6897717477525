import { 
    SET_COMPANY_LIST,
    SET_LOADING_COMPANY,
    SET_COMPANY_ERROR,
    SET_SINGLE_COMPANY,
    SET_SINGLE_COMPANY_ERROR,
    SET_ERROR_UPDATE_COMPANY,
    SET_POST_COMPANY_LOADING,
    SET_POST_COMPANY_ERROR,
    SET_POST_COMPANY_SUCCEED,
    SET_UPDATE_LOADING_COMPANY,
    SET_UPDATE_SUCCED_COMPANY
} from "./company.types";

export const setCompanyList = company => {
    return {
        type   : SET_COMPANY_LIST,
        payload: company
    };
};

export const setLoadingCompany = loading => {
    return {
        type   : SET_LOADING_COMPANY,
        payload: loading
    };
};

export const setCompanyError = loading => {
    return {
        type   : SET_COMPANY_ERROR,
        payload: loading
    };
};

export const setSingleCompany = company => {
    return {
        type   : SET_SINGLE_COMPANY,
        payload: company
    };
};

export const setSingleCompanyError = error => {
    return {
        type   : SET_SINGLE_COMPANY_ERROR,
        payload: error
    };
};

export const setErrorUpdateCompany = error => {
    return {
        type   : SET_ERROR_UPDATE_COMPANY,
        payload: error
    };
};

export const setPostCompanySucceed = succeed => {
    return {
        type   : SET_POST_COMPANY_SUCCEED,
        payload: succeed
    };
};

export const setPostCompanyError = error => {
    return {
        type   : SET_POST_COMPANY_ERROR,
        payload: error
    };
};

export const setPostCompanyLoading = loading => {
    return {
        type   : SET_POST_COMPANY_LOADING,
        payload: loading
    };
};

export const setUpdateLoadingCompany = loading => {
    return {
        type   : SET_UPDATE_LOADING_COMPANY,
        payload: loading
    };
};

export const setUpdateSuccedCompany = succed => {
    return {
        type   : SET_UPDATE_SUCCED_COMPANY,
        payload: succed
    };
};
