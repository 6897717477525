import React                       from "react";
import Navigation                  from "../../../components/Navigation/Navigation";
import {Link}                      from "react-router-dom";
import routerConstants             from "../../../constants/router-constants";
import {
    Breadcrumb, 
    Row, 
    Col, 
    Form, 
    Button,
    Card
}                                  from "react-bootstrap";
import {
    updateProductSubscription,
    getSingleProductSubscription
}                                  from "../../../redux/Products/products.async-actions";
import {useForm}                   from "react-hook-form";
import {connect}                   from "react-redux";
import Loader                      from "../../../components/Common/Loader";
import {getPaymentPlanSettingList} from "../../../redux/PaymentPlanSettings/payment-plan-setting.async-actions";
import Select                      from "react-select";

class EditProductSubscriptionComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sideMenu          : true,
            paymentPlanSetting: {},
            paymentPlanError  : null
        };

        this.paymentPlanSettingsList = [];
    }

    ProductSubscriptionForm = () => {
        const {name, trialPeriodPrice, trialPeriodDays, trialMaxPurchasesAllowed, subscriptionMaxPurchasesAllowed} = this.props.singleProduct;

        this.props.setValue('name', name);
        this.props.setValue('trialPeriodPrice', trialPeriodPrice * 100);
        this.props.setValue('trialPeriodDays', trialPeriodDays);
        this.props.setValue('trialMaxPurchasesAllowed', trialMaxPurchasesAllowed);
        this.props.setValue('subscriptionMaxPurchasesAllowed', subscriptionMaxPurchasesAllowed);

        return <>
            <Card>
                <Card.Body>
                    <div className="card-header">
                        <Card.Title>Edit Product Subscription</Card.Title>
                    </div>

                    <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="name">
                                    Name
                                </label>
                                <input
                                    id="name"
                                    type="text"
                                    className="form-control"
                                    placeholder="Name"
                                    value={this.state.name}
                                    {
                                        ...this
                                            .props
                                            .register(
                                                "name",
                                                {
                                                    required: {
                                                        value  : true,
                                                        message: <span style={{color: "red"}}>This field is required</span>
                                                    }
                                                }
                                            )
                                    }
                                />
                                <Form.Text className="text-muted">
                                    A name that you will recognize across the system.
                                </Form.Text>
                                {
                                    null !== this?.props?.errors?.type?.message &&
                                    <span className="text-danger">
                                        {this?.props?.errors?.type?.message}
                                    </span>
                                }
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="paymentPlan">
                                    Payment Plan
                                </label>
                                <Select
                                    id="paymentPlan"
                                    value={this.state.paymentPlanSetting}
                                    onChange={this.onPaymentPlanSettingValueChange}
                                    options={this.paymentPlanSettingsList}
                                    backspaceRemovesValue={true}
                                    escapeClearsValue={true}
                                    isMulti
                                    menuPlacement={"auto"}
                                />
                                <Form.Text className="text-muted">
                                    Choose the payment plan you need for this product subscription.
                                </Form.Text>
                                {
                                    this.props.paymentPlanSettingsError !== null &&
                                    <span className="text-danger">
                                        Something went wrong and cannot load the Payment Plan Settings. Please
                                        contact the development team to ask for more information.
                                    </span>
                                }
                                {
                                    null !== this.state.paymentPlanError &&
                                    <span className="text-danger">
                                        {this.state.paymentPlanError}
                                    </span>
                                }
                            </div>
                        </div>


                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="trialPeriodPrice">
                                    Trial Period Price
                                </label>
                                <input
                                    id="trialPeriodPrice"
                                    type="text"
                                    className="form-control"
                                    placeholder="Trial Period Price"
                                    {
                                        ...this
                                            .props
                                            .register(
                                                "trialPeriodPrice",
                                                {
                                                    required: {
                                                        value  : true,
                                                        message: <span style={{color: "red"}}>This field is required</span>
                                                    },
                                                    min     : {
                                                        value  : 0,
                                                        message:
                                                            <span style={{color: "red"}}>Value should be greater than 0</span>
                                                    }
                                                }
                                            )
                                    }
                                />
                                <Form.Text className="text-muted">
                                    this is the amount the customer will be charged for the trial period. (ex: 390 for 3,90)
                                </Form.Text>
                                {
                                    null !== this?.props?.errors?.trialPeriodPrice?.message &&
                                    <span className="text-danger">
                                        {this?.props?.errors?.trialPeriodPrice?.message}
                                    </span>
                                }
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="trialPeriodDays">
                                    Trial Period Days
                                </label>
                                <input
                                    id="trialPeriodDays"
                                    type="text"
                                    className="form-control"
                                    placeholder="Trial Period Days"
                                    {
                                        ...this
                                            .props
                                            .register(
                                                "trialPeriodDays",
                                                {
                                                    required: {
                                                        value  : true,
                                                        message: <span style={{color: "red"}}>This field is required</span>
                                                    },
                                                    min     : {
                                                        value  : 0,
                                                        message:
                                                            <span style={{color: "red"}}>Value should be greater than 0</span>
                                                    }
                                                }
                                            )
                                    }
                                />
                                <Form.Text className="text-muted">
                                    Enter the amount of trial days
                                </Form.Text>
                                {
                                    null !== this?.props?.errors?.trialPeriodDays?.message &&
                                    <span className="text-danger">
                                        {this?.props?.errors?.trialPeriodDays?.message}
                                    </span>
                                }
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="trialMaxPurchasesAllowed">
                                    Trial Max Purchases Allowed
                                </label>
                                <input
                                    id="trialMaxPurchasesAllowed"
                                    type="text"
                                    className="form-control"
                                    placeholder="Trial Max Purchases Allowed"
                                    {
                                        ...this
                                            .props
                                            .register(
                                                "trialMaxPurchasesAllowed",
                                                {
                                                    required: {
                                                        value  : true,
                                                        message: <span style={{color: "red"}}>This field is required</span>
                                                    },
                                                    min     : {
                                                        value  : 0,
                                                        message:
                                                            <span style={{color: "red"}}>Value should be greater than 0</span>
                                                    }
                                                }
                                            )
                                    }
                                />
                                <Form.Text className="text-muted">
                                    Enter the amount of Trial Max Purchases Allowed
                                </Form.Text>
                                {
                                    null !== this?.props?.errors?.trialMaxPurchasesAllowed?.message &&
                                    <span className="text-danger">
                                        {this?.props?.errors?.trialMaxPurchasesAllowed?.message}
                                    </span>
                                }
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="subscriptionMaxPurchasesAllowed">
                                    Subscription Max Purchases Allowed
                                </label>
                                <input
                                    id="subscriptionMaxPurchasesAllowed"
                                    type="text"
                                    className="form-control"
                                    placeholder="Subscription Max Purchases Allowed"
                                    {
                                        ...this
                                            .props
                                            .register(
                                                "subscriptionMaxPurchasesAllowed",
                                                {
                                                    required: {
                                                        value  : true,
                                                        message: <span style={{color: "red"}}>This field is required</span>
                                                    },
                                                    min     : {
                                                        value  : 0,
                                                        message:
                                                            <span style={{color: "red"}}>Value should be greater than 0</span>
                                                    }
                                                }
                                            )
                                    }
                                />
                                <Form.Text className="text-muted">
                                    Enter the amount of Subscription Max Purchases Allowed
                                </Form.Text>
                                {
                                    null !== this?.props?.errors?.subscriptionMaxPurchasesAllowed?.message &&
                                    <span className="text-danger">
                                        {this?.props?.errors?.subscriptionMaxPurchasesAllowed?.message}
                                    </span>
                                }
                            </div>
                        </div>

                        <div className="form-group">
                            <Button
                                variant="primary"
                                type="submit"
                            >
                                Update Product Subscription
                            </Button>
                        </div>
                    </form>
                </Card.Body>
            </Card>
        </>
    };

    onSubmit = data => {
        let paymentPlanArryIds = [];

        this.state.paymentPlanSetting.map(
            paymentPlanSetting => {
                return paymentPlanArryIds.push(paymentPlanSetting.value);
            }
        );

        const product_uuid = this.props.match.params.product_uuid;
        data.paymentPlan   = paymentPlanArryIds;
        data.trialPeriodDays = parseInt(data.trialPeriodDays);
        data.trialPeriodPrice = parseInt(data.trialPeriodPrice);
        data.trialMaxPurchasesAllowed = parseInt(data.trialMaxPurchasesAllowed);
        data.subscriptionMaxPurchasesAllowed = parseInt(data.subscriptionMaxPurchasesAllowed);
 
        this.props.updateProductSubscription(product_uuid, data);
    };

    _onSideMenu = (active) => {
        this.setState({sideMenu: active});
    };

    async componentDidMount() {
        await this.props.getPaymentPlanSettingList();
        await this.props.getSingleProductSubscription(this.props.match.params.product_uuid);

        if (null === this.props.paymentPlanSettingsError) {
            this.paymentPlanSettingsList = this.props.paymentPlanSettingsList.map(
                planSettings => {
                    return {
                        value: planSettings["@id"],
                        label: planSettings.name
                    };
                }
            );

            const paymentPlanSettingValue = this.props.singleProduct.paymentPlan.map(
                paymentPlan => {
                    return {
                        value: paymentPlan["@id"],
                        label: paymentPlan.name
                    };
                }
            );

            this.setState(
                {
                    paymentPlanSetting: paymentPlanSettingValue
                }
            );
        }
    }

    onPaymentPlanSettingValueChange = paymentPlanSetting => this.setState({paymentPlanSetting});

    render() {
        const {
            updateError,
            updateSuccess,
            singleProduct,
            updateLoading,
            paymentPlanSettingsLoading
        } = this.props;
        let loader = null;

        if (singleProduct === null || updateLoading || paymentPlanSettingsLoading) {
            loader = <Loader message="Loading..." />;
        }

        return <>
            <div className="page-wrapper">
                {/* Navigation */}
                <Navigation onClick={this._onSideMenu} />
                {/* End Navigation */}

                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? "" : "hide-sidemenu"}`}>
                    {loader}

                    <div className="main-content-header">
                        <Breadcrumb>
                            <Link to={routerConstants.dashboard} className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Link to={routerConstants.listProductSubscription} className="breadcrumb-item">
                                Product Subscription List
                            </Link>
                            <Breadcrumb.Item active>
                                Edit Product Subscription
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <Row>
                        <Col lg={12}>
                            {true === updateSuccess &&
                                <div className="alert alert-success" role="alert">
                                    The Product Subscription successfully updated.
                                </div>
                            }

                            {null !== updateError &&
                                <div className="alert alert-danger" role="alert">
                                    Something went wrong. Please try again or contact the developers for more information.
                                </div>
                            }
                            {this.ProductSubscriptionForm()}
                        </Col>
                    </Row>
                </div>
            </div>
        </>;
    }
}

const EditProductSubscriptionPage = props => {
    const {register, handleSubmit, setValue, formState: {errors}} = useForm();

    return <EditProductSubscriptionComponent
        {...props}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        setValue={setValue}
    />;
};

const mapStateToProps = state => {
    return {
        singleProduct             : state.products.setSingleProduct,
        singleProductLoading      : state.products.setSingleProductLoading,
        updateError               : state.products.setProductsUpdatedError,
        updateSuccess             : state.products.setProductsUpdatedSuccess,
        updateLoading             : state.products.setLoadingProductsUpdate,
        paymentPlanSettingsList   : state.paymentPlanSettings.paymentPlanSettingList,
        paymentPlanSettingsLoading: state.paymentPlanSettings.loadingPaymentPlanSettingList,
        paymentPlanSettingsError  : state.paymentPlanSettings.paymentPlanSettingListError
    };
};

const mapActionsToProps = {
    getSingleProductSubscription,
    updateProductSubscription,
    getPaymentPlanSettingList
};

const EditProductSubscriptionConnected = connect(
    mapStateToProps,
    mapActionsToProps
)(EditProductSubscriptionPage);

export default EditProductSubscriptionConnected;
