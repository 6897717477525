import http              from "../../services/http";
import meiliSearchClient from "../../services/MeiliSearchClient";

import {
    setDeleteUploadDocumentError,
    setDeleteUploadDocumentSuccess,
    setLoadingPurchasesList,
    setLoadingSinglePurchase,
    setMeiliPurchases,
    setMeiliPurchasesCurrentPage,
    setMeiliPurchasesError,
    setMeiliPurchasesFirstPage,
    setMeiliPurchasesLastPage,
    setMeiliPurchasesNextPage,
    setMeiliPurchasesPreviousPage,
    setMeiliTotalPages,
    setMeiliTotalPurchases,
    setPurchaseCommentsData,
    setPurchaseCommentsError,
    setPurchaseCommentsLoading,
    setPurchasesListError,
    setPurchasesUploadedDocumentError,
    setPurchasesUploadedDocumentLoader,
    setPurchasesUploadedDocumentSuccess,
    setSinglePurchase,
    setSinglePurchaseError,
    setTotalOnHoldTransactions,
    setTotalPendingInProgressTransactions,
    setTotalAskInformationTransactions
} from "./purchases.actions";

export const getSinglePurchase = (client_uuid) => {
    return async dispatch => {
        dispatch(setLoadingSinglePurchase(true));
        dispatch(setSinglePurchaseError(null));

        try {
            const clientData = await http.get(
                `purchases/${client_uuid}`,
                {
                    params: {}
                }
            );

            dispatch(setSinglePurchase(clientData.data));
        } catch (e) {
            dispatch(setSinglePurchase(null));
            dispatch(setSinglePurchaseError(e.message));
        } finally {
            dispatch(setLoadingSinglePurchase(false));
        }
    };
};

export const getSinglePurchaseComments = (purchaseUuid, queryParams) => {
    return async dispatch => {
        dispatch(setPurchaseCommentsLoading(true));
        dispatch(setPurchaseCommentsError(null));

        try {
            const purchaseComments = await http.get(
                `purchases/${purchaseUuid}/comments`,
                {
                    params: queryParams
                }
            );

            dispatch(setPurchaseCommentsData(purchaseComments.data["hydra:member"]));
        } catch (e) {
            dispatch(setPurchaseCommentsData(null));
            dispatch(setPurchaseCommentsError(e.message));
        } finally {
            dispatch(setPurchaseCommentsLoading(false));
        }
    };
};

export const setStatusToCancelled = (purchaseUuid, emailTemplateData, comments_text) => {
    return async dispatch => {
        dispatch(setLoadingPurchasesList(true));
        dispatch(setPurchasesListError(null));

        try {
            await http.put(
                `purchases/${purchaseUuid}/change-status`,
                {
                    ...(emailTemplateData.value !== "" ?
                            {
                                "emailTemplateId": emailTemplateData.value
                            } : null
                    ),
                    "status": "CANCELLED",
                    ...(comments_text !== "" ?
                            {
                                "comments_text": comments_text
                            } : null
                    )
                }
            );
        } catch (e) {
            let errorMessage = e?.response?.data["hydra:description"] ?? e.message;
            dispatch(setPurchasesListError(errorMessage));
        } finally {
            dispatch(setLoadingPurchasesList(false));
        }
    };
};

export const setStatusToCompleted = (purchaseUuid, selectedFieldsIOds, comments_text) => {
    return async dispatch => {
        dispatch(setLoadingPurchasesList(true));
        dispatch(setPurchasesListError(null));

        try {
            await http.put(
                `purchases/${purchaseUuid}/change-status`,
                {
                    "status": "COMPLETED",
                    ...(typeof selectedFieldsIOds !== "undefined" ?
                            {
                                "purchaseIds": selectedFieldsIOds
                            } : null
                    ),
                    ...(comments_text !== "" ?
                            {
                                "comments_text": comments_text
                            } : null
                    )
                }
            );
        } catch (e) {
            let errorMessage = e?.response?.data["hydra:description"] ?? e.message;
            dispatch(setPurchasesListError(errorMessage));
        } finally {
            dispatch(setLoadingPurchasesList(false));
        }
    };
};

export const setStatusToOnHold = (purchaseUuid, selectedFieldsIOds, comments_text) => {
    return async dispatch => {
        dispatch(setLoadingPurchasesList(true));
        dispatch(setPurchasesListError(null));

        try {
            await http.put(
                `purchases/${purchaseUuid}/change-status`,
                {
                    ...(typeof selectedFieldsIOds !== "undefined" ?
                            {
                                "purchaseIds": selectedFieldsIOds
                            } : null
                    ),
                    "status": "ON_HOLD",
                    ...(comments_text !== "" ?
                            {
                                "comments_text": comments_text
                            } : null
                    )
                }
            );
        } catch (e) {
            let errorMessage = e?.response?.data["hydra:description"] ?? e.message;
            dispatch(setPurchasesListError(errorMessage));
        } finally {
            dispatch(setLoadingPurchasesList(false));
        }
    };
};

export const setPurchaseDrivingLicensePoints = (purchaseUuid, drivingLicensePoints, proDrivingLicensePoints = null) => {
    return async dispatch => {
        dispatch(setLoadingPurchasesList(true));
        dispatch(setPurchasesListError(null));
        try {
            let requestBody = {
                "drivingLicensePoints": drivingLicensePoints
            };

            if (null !== proDrivingLicensePoints) {
                requestBody.proDrivingLicensePoints = proDrivingLicensePoints;
            }

            await http.put(`purchases/${purchaseUuid}/update-drivint-license-points`, requestBody);
        } catch (e) {
            let errorMessage = e?.response?.data["hydra:description"] ?? e.message;
            dispatch(setPurchasesListError(errorMessage));
        } finally {
            dispatch(setLoadingPurchasesList(false));
        }
    };
};

export const requestMissingInformation = (purchaseUuid, selectedFieldsIOds, comments_text) => {
    return async dispatch => {
        dispatch(setLoadingPurchasesList(true));
        dispatch(setPurchasesListError(null));

        try {
            await http.put(
                `purchases/${purchaseUuid}/change-status?ask=missing-info`,
                {
                    ...(typeof selectedFieldsIOds !== "undefined" ?
                            {
                                "purchaseIds": selectedFieldsIOds
                            } : null
                    ),
                    "status": "ASK_INFORMATION",
                    ...(comments_text !== "" ?
                            {
                                "comments_text": comments_text
                            } : null
                    )
                }
            );
        } catch (e) {
            let errorMessage = e?.response?.data["hydra:description"] ?? e.message;
            dispatch(setPurchasesListError(errorMessage));
        } finally {
            dispatch(setLoadingPurchasesList(false));
        }
    };
};

export const getTotalPendingInProgressTransactions = () => {
    return async dispatch => {
        const response = await meiliSearchClient.post(
            `/indexes/${meiliSearchClient.defaults.prefix}purchases/search`,
            {
                filter: "status IN [IN_PROGRESS, PENDING, CANCELLED_ORDER] AND service NOT IN [CLASSICA, 'CLASSICA" +
                    " +'] AND is_prospect = false",
                page  : 1,
                sort  : [
                    "purchase_created:desc"
                ]
            },
            {
                "headers": {}
            }
        );

        dispatch(setTotalPendingInProgressTransactions(response?.data?.totalHits ?? 0));
    };
};

export const getTotalOnHoldTransactions = () => {
    return async dispatch => {
        const response = await meiliSearchClient.post(
            `/indexes/${meiliSearchClient.defaults.prefix}purchases/search`,
            {
                filter: "status IN [ON_HOLD] AND service NOT IN [CLASSICA, 'CLASSICA +'] AND is_prospect = false",
                page  : 1,
                sort  : [
                    "purchase_created:asc"
                ]
            },
            {
                "headers": {}
            }
        );

        dispatch(setTotalOnHoldTransactions(response?.data?.totalHits ?? 0));
    };
};

export const getTotalAskInformationTransactions = () => {
    return async dispatch => {
        const response = await meiliSearchClient.post(
            `/indexes/${meiliSearchClient.defaults.prefix}purchases/search`,
            {
                filter: "status IN [ASK_INFORMATION] AND service NOT IN [CLASSICA, 'CLASSICA +'] AND is_prospect = false",
                page  : 1,
                sort  : [
                    "purchase_created:asc"
                ]
            },
            {
                "headers": {}
            }
        );

        dispatch(setTotalAskInformationTransactions(response?.data?.totalHits ?? 0));
    }
}

export const postUploadDocument = (purchaseUuid, formData) => {
    return async dispatch => {
        dispatch(setPurchasesUploadedDocumentLoader(true));
        dispatch(setPurchasesUploadedDocumentError(null));
        dispatch(setPurchasesUploadedDocumentSuccess(false));
        dispatch(setSinglePurchase(null));
        try {
            await http.post(
                `purchases/${purchaseUuid}/upload-document`,
                formData
            );
            dispatch(setPurchasesUploadedDocumentSuccess(true));
        } catch (e) {
            dispatch(setPurchasesUploadedDocumentLoader(false));
            dispatch(setPurchasesUploadedDocumentSuccess(false));
            dispatch(setPurchasesUploadedDocumentError(e.message));
        } finally {
            dispatch(setPurchasesUploadedDocumentLoader(false));

            setTimeout(() => {
                dispatch(setPurchasesUploadedDocumentSuccess(false));
            }, 3000);
        }
    };
};

export const deleteUploadedDocument = (purchaseUuid) => {
    return async dispatch => {
        dispatch(setDeleteUploadDocumentError(null));
        dispatch(setDeleteUploadDocumentSuccess(false));
        try {
            await http.put(
                `purchases/${purchaseUuid}`,
                {}
            );
            dispatch(setDeleteUploadDocumentSuccess(true));
        } catch (e) {
            dispatch(setDeleteUploadDocumentError(e.message));
            dispatch(setDeleteUploadDocumentSuccess(false));
        } finally {
            setTimeout(() => {
                dispatch(setDeleteUploadDocumentSuccess(false));
                dispatch(setDeleteUploadDocumentError(null));
            }, 3000);
        }
    };
};

export const meiliSearch = options => {
    return async dispatch => {
        dispatch(setMeiliPurchasesError(null));
        dispatch(setMeiliPurchases([]));
        dispatch(setMeiliTotalPurchases(0));
        dispatch(setMeiliTotalPages(1));
        dispatch(setMeiliPurchasesCurrentPage(1));
        dispatch(setMeiliPurchasesFirstPage(1));
        dispatch(setMeiliPurchasesLastPage(1));
        dispatch(setMeiliPurchasesNextPage(1));
        dispatch(setMeiliPurchasesPreviousPage(1));

        try {
            const defaultOptions = {
                sort       : [
                    "purchase_created:asc"
                ],
                hitsPerPage: 20
            };
            const response       = await meiliSearchClient.post(
                `/indexes/${meiliSearchClient.defaults.prefix}purchases/search`,
                {
                    ...defaultOptions,
                    ...options
                },
                {
                    "headers": {}
                }
            );

            dispatch(setMeiliPurchases(response?.data?.hits ?? []));
            dispatch(setMeiliTotalPurchases(response?.data?.totalHits ?? 0));
            dispatch(setMeiliTotalPages(response?.data?.totalPages ?? 0));
            dispatch(setMeiliPurchasesCurrentPage(response?.data?.page ?? 0));
            dispatch(setMeiliPurchasesFirstPage(1));
            dispatch(setMeiliPurchasesLastPage(response?.data?.totalPages ?? 0));
            dispatch(setMeiliPurchasesNextPage(response.data.totalPages > response.data.page ? response?.data?.page + 1 : response?.data?.totalPages));
            dispatch(setMeiliPurchasesPreviousPage(1 < response.data.page ? response?.data?.page - 1 : 1));

        } catch (e) {
            console.log(e);
        }
    };
};
