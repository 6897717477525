import http from "../../services/http";
import {
    setPaymentGatewayListError,
    setPaymentGatewayList,
    setLoadingPaymentGatewayList,
    setLoadingSinglePaymentGateway,
    setSinglePaymentGateway,
    setSinglePaymentGatewayError,
    setUpdatePaymentGatewayError,
    setUpdatePaymentGatewayLoading,
    setUpdatePaymentGatewaySuccess
}           from "./payment-gateway.actions";

export const getPaymentGatewayList = (queryParams = {}) => {
    return async dispatch => {
        dispatch(setLoadingPaymentGatewayList(true));
        dispatch(setPaymentGatewayListError(null));

        try {
            const PaymentGateways = await http.get(
                `payment-gateways`,
                {
                    params: queryParams
                }
            );

            dispatch(setPaymentGatewayList(PaymentGateways?.data?.["hydra:member"]));
        } catch (e) {
            dispatch(setPaymentGatewayList([]));
            dispatch(setPaymentGatewayListError(e.message));
        } finally {
            dispatch(setLoadingPaymentGatewayList(false));
        }
    };
};

export const getSinglePaymentGateway = (paymentGatewayId) => {
    return async dispatch => {
        dispatch(setLoadingSinglePaymentGateway(true))
        dispatch(setSinglePaymentGatewayError(null))

        try {
            const response = await http.get(
                `payment-gateways/${paymentGatewayId}`
            )
            
            dispatch(setSinglePaymentGateway(response?.data))
        } catch (e) {
            dispatch(setSinglePaymentGateway([]))
            dispatch(setSinglePaymentGatewayError(e.message))
        } finally {
            dispatch(setLoadingSinglePaymentGateway(false))
        }
    }
}

export const putPayementGateway = (paymentGatewayId, data) => {
    return async dispatch => {
        dispatch(setUpdatePaymentGatewayLoading(true))
        dispatch(setUpdatePaymentGatewayError(null))

        try {
            await http.put(`payment-gateways/${paymentGatewayId}`,
                    {
                        website                : data.website,
                        name                   : data.name,
                        slug                   : data.slug,
                        paymentGatewaySettings : data.paymentGatewaySettings
                    }
            )
            
            dispatch(setUpdatePaymentGatewayLoading(false))
            dispatch(setUpdatePaymentGatewaySuccess(true))
        } catch (e) {
            dispatch(setUpdatePaymentGatewayLoading(false))
            dispatch(setUpdatePaymentGatewayError(e.message))
        } finally {
            dispatch(setUpdatePaymentGatewayLoading(false))
        }
    }
}
