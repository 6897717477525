import {connect}                                         from "react-redux";
import {Link, withRouter}                                from "react-router-dom";
import React                                             from "react";
import Navigation                                        from "../../components/Navigation/Navigation";
import {Breadcrumb, Button, Card, Col, Form, Row, Table} from "react-bootstrap";
import routerConstants                                   from "../../constants/router-constants";
import Loader                                            from "../../components/Common/Loader";
import {RangeDatePicker}                                 from "react-google-flight-datepicker";
import "react-google-flight-datepicker/dist/main.css";
import moment                                            from "moment";
import {
    getRebillBreakdownData
}                                                        from "../../redux/Statistics/RebillBreakdown/rebill-breakdown.async-actions";
import {
    getPaymentGatewayList
}                      from '../../redux/PaymentGateway/payment-gateway.async-actions';
import * as echarts                                      from "echarts";
import Select                                            from "react-select";

class RebillBreakdown extends React.Component {
    state = {
        sideMenu       : true,
        startDate      : new Date(),
        endDate        : new Date(),
        statuses       : [],
        paymentGateway : []
    };

    constructor(props) {
        super(props);

        this.amount       = React.createRef();
        this.priceCompare = React.createRef();
        this.from         = React.createRef();
        this.to           = React.createRef();
        this.chart        = React.createRef();

        this.from.current = moment(new Date()).format("YYYY-MM-DD");
        this.to.current   = moment(new Date()).format("YYYY-MM-DD");

        this.chartOptions           = {
            tooltip: {
                trigger: "item"
            },
            legend : {
                top : "5%",
                left: "center"
            },
            series : [
                {
                    name             : "Rebill Breakdown",
                    type             : "pie",
                    radius           : ["47%", "64%"],
                    avoidLabelOverlap: true,
                    itemStyle        : {
                        borderRadius: 10,
                        borderColor : "#FFF",
                        borderWidth : 2
                    },
                    label            : {
                        show    : true,
                        position: "left"
                    },
                    emphasis         : {
                        label: {
                            show      : true,
                            fontSize  : "20",
                            fontWeight: "bold"
                        }
                    },
                    labelLine        : {
                        show: true
                    },
                    data             : []
                }
            ]
        };

        this.paymentStatusesOptions = [
            {
                value: "COMPLETED",
                label: "Completed"
            },
            {
                value: "PENDING",
                label: "Pending"
            },
            {
                value: "FAILED",
                label: "Failed"
            },
            {
                value: "REFUNDED",
                label: "Refunded"
            }
        ];

        this.paymentGatewayOptions = [];

        this.onDateChange                   = this.onDateChange.bind(this);
        this.fetchRebillBreakdownData       = this.fetchRebillBreakdownData.bind(this);
        this.chartWindowResizeListener      = this.chartWindowResizeListener.bind(this);
        this.handlePaymentStatusValueChange = this.handlePaymentStatusValueChange.bind(this);
        this.handlePaymentGatewayValueChange = this.handlePaymentGatewayValueChange.bind(this);

        this.pieChart = null;
    }

    // Toggle side bar menu
    _onSideMenu = (active) => {
        this.setState({sideMenu: active});
    };

    handlePaymentStatusValueChange(selectedOptions) {
        let values = [];

        selectedOptions.forEach(option => values.push(option.value));
        this.setState(
            {
                statuses: values
            }
        );
    }

    handlePaymentGatewayValueChange(selectedOptions) {
        let values = [];

        selectedOptions.forEach(option => values.push(option.value));
        this.setState(
            {
                paymentGateway: values
            }
        );
    }

    chartWindowResizeListener() {
        this.pieChart.resize();
    }

    async componentDidMount() {

        window.requestAnimationFrame(
            () => {
                this.pieChart = echarts.init(this.chart.current);
                this.pieChart.setOption(this.chartOptions);

                window.addEventListener(
                    "resize",
                    this.chartWindowResizeListener
                );
                window.addEventListener(
                    "orientationchange",
                    this.chartWindowResizeListener
                );
            }
        );

        const queryParams = {
            "order[name]" : "ASC",
        };

        await this.props.getPaymentGatewayList(queryParams);

        if (null === this.props.paymentGatewayError) {
            this.paymentGatewayOptions = this?.props?.paymentGatewayList?.map(
                paymentGateway => {
                    return {
                        value: paymentGateway["@id"].replace('/api/payment-gateways/', ''),
                        label: paymentGateway.name
                    };
                }
            );
        }

        this.fetchRebillBreakdownData();
    }

    componentWillUnmount() {
        window.removeEventListener(
            "resize",
            this.chartWindowResizeListener
        );
        window.removeEventListener(
            "orientationchange",
            this.chartWindowResizeListener
        );
    }

    onDateChange(startDate, endDate) {
        this.setState(
            {
                startDate,
                endDate
            }
        );

        this.from.current = moment(startDate).format("YYYY-MM-DD");
        this.to.current   = moment(endDate).format("YYYY-MM-DD");
    }

    async fetchRebillBreakdownData() {
        let query = {
            from           : this.from.current,
            to             : this.to.current,
            status         : this.state.statuses,
            paymentGateway : this.state.paymentGateway
        };

        if ("" !== this.amount.current.value) {
            query.amount       = Math.round(this.amount.current.value * 100);
            query.priceCompare = this.priceCompare.current.value;
        }

        await this.props.getRebillBreakdownData(query);

        setTimeout(
            () => {
                window.requestAnimationFrame(
                    () => {
                        this.chartOptions.series[0].data = this.props.breakdown.services.map(
                            entry => {
                                entry.name  = `R${entry.step}`;
                                entry.value = entry.total;

                                delete entry.step;
                                delete entry.total;

                                return entry;
                            }
                        );

                        this.pieChart.setOption(this.chartOptions);
                    }
                );
            },
            500
        );
    }

    mapRebillBreakdown = () => this?.props?.breakdown?.services?.map(
        entry => {
            return <tr key={entry.step} className="text-center">
                <td>R{entry.step}</td>
                <th scope="row">{entry.total}</th>
            </tr>;
        }
    );  

    render() {
        let loader = null;

        if (this.props.loadingRebillBreakdown) {
            loader = <Loader message="Loading..." />;
        }

        const chartStyle = {
            height : "350px",
            display: "block"
        };

        return <>
            <div className="page-wrapper">
                {/* Navigation */}
                <Navigation onClick={this._onSideMenu} />
                {/* End Navigation */}

                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? "" : "hide-sidemenu"}`}>
                    {/* Loader */}
                    {loader}
                    {/* End Loader */}

                    {/* Breadcrumb */}
                    <div className="main-content-header">
                        <Breadcrumb>
                            <h1>Statistics</h1>
                            <Link to={routerConstants.dashboard} className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Breadcrumb.Item active>
                                Rebill Breakdown
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    {/* End Breadcrumb */}

                    <Row>
                        <Col sm={12} md={4} lg={3}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <Card.Title>Filters</Card.Title>
                                    <Form>
                                        <Form.Group controlId="amount">
                                            <Form.Label>Amount</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="ie: 35"
                                                min={0}
                                                ref={this.amount}
                                            />
                                            <Form.Text className="text-muted">
                                                The amount number to filter the breakdown results.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="priceCompare">
                                            <Form.Label>Amount Comparison Operator</Form.Label>
                                            <Form.Control
                                                as="select"
                                                ref={this.priceCompare}
                                            >
                                                <option value="eq">Equal (=)</option>
                                                <option value="gt">Greater Than (&gt;)</option>
                                                <option value="gte">Greater Than or Equal (&gt;=)</option>
                                                <option value="lt">Lower Than (&lt;)</option>
                                                <option value="lte">Lower Than or Equal (&lt;=)</option>
                                            </Form.Control>
                                            <Form.Text className="text-muted">
                                                The operator to be used to filter the rebill breakdown based on the
                                                provided amount.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="dateRange">
                                            <Form.Label>Date Range</Form.Label>
                                            <RangeDatePicker
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                maxDate={new Date()}
                                                onChange={(startDate, endDate) => this.onDateChange(
                                                    startDate,
                                                    endDate
                                                )}
                                                dateFormat="D MMM YY"
                                                monthFormat="MMM YYYY"
                                                startDatePlaceholder="Start Date"
                                                endDatePlaceholder="End Date"
                                                disabled={false}
                                                startWeekDay="monday"
                                                ref={this.dateRange}
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="paymentStatus">
                                            <Form.Label>Payment Status</Form.Label>
                                            <Select
                                                value={this.props.statuses}
                                                onChange={this.handlePaymentStatusValueChange}
                                                options={this.paymentStatusesOptions}
                                                isMulti
                                                backspaceRemovesValue={true}
                                                escapeClearsValue={true}
                                                isClearable={true}
                                                menuPlacement={'auto'}
                                                placeholder={'Choose a payment status (Default: Completed)'}
                                            />
                                            <Form.Text className="text-muted">
                                                The payment statuses to be used in order to calculate the rebill breakdown.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="paymentGateway">
                                            <Form.Label>Payment Gateway(s)</Form.Label>
                                            <Select
                                                value={this.props.paymentGateway}
                                                onChange={this.handlePaymentGatewayValueChange}
                                                options={this.paymentGatewayOptions}
                                                isMulti
                                                backspaceRemovesValue={true}
                                                escapeClearsValue={true}
                                                isClearable={true}
                                                menuPlacement={'auto'}
                                                placeholder={'Choose a Payment Gateway (Default: Nothing)'}
                                            />
                                            <Form.Text className="text-muted">
                                                The payment gateway to be used in order to calculate the rebill breakdown.
                                            </Form.Text>
                                        </Form.Group>

                                    </Form>
                                    <Button
                                        variant="primary"
                                        onClick={this.fetchRebillBreakdownData}
                                    >
                                        Filter
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} md={8} lg={9}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <Card.Title>Results</Card.Title>
                                    <Table responsive hover className="m-0">
                                        <thead>
                                        <tr className="text-center">
                                            <th>Step</th>
                                            <th className="text-center">Total</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this?.props?.breakdown?.services?.length === 0 ?
                                                <tr key="no_purchases">
                                                    <td colSpan={2} className="text-center">No Data</td>
                                                </tr> :
                                                this.mapRebillBreakdown()
                                        }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                            <Card className="mb-4">
                                <Card.Body>
                                    <Card.Title>Stats Rebill Breakdown</Card.Title>
                                    <Table responsive hover className="m-0">
                                        <thead>
                                        <tr className="text-center">
                                            <th>Total Rebill Breakdown</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this?.props?.breakdown?.totalRebill !== 0 ?
                                                <tr key="RebillBreakdownData">
                                                    <td colSpan={2} className="text-center">{this.props.breakdown.totalRebill}</td>
                                                </tr> :
                                                <tr key="RebillBreakdownNoData">
                                                    <td colSpan={2} className="text-center">No Data</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                            <Card className="mb-4">
                                <Card.Body>
                                    <Card.Title>Rebill Breakdown Graph</Card.Title>
                                    <div
                                        ref={this.chart}
                                        style={chartStyle}
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        breakdown             : state.rebillBreakdown.breakdown,
        loadingRebillBreakdown: state.rebillBreakdown.loadingRebillBreakdown,
        paymentGatewayList    : state.paymentGateway.paymentGatewayList,
        paymentGatewayError   : state.paymentGateway.paymentGatewayListError
    };
};

const mapActionsToProps = {
    getRebillBreakdownData,
    getPaymentGatewayList
};

const RebillBreakdownConnected = connect(mapStateToProps, mapActionsToProps)(RebillBreakdown);
export default withRouter(RebillBreakdownConnected);
