import {
    LOADING_WEBSITES_DOMAINS_AND_IDS,
    SET_WEBSITES_DOMAINS_AND_IDS,
    SET_WEBSITES_DOMAINS_AND_IDS_ERROR,
    LOADING_UPDATE,
    SINGLE_WEBSITE_LOADING,
    SET_SINGLE_WEBSITE,
    SET_WEBSITES,
    SET_LOADING_WEBSITES,
    CREATE_WEBSITES_LOADING,
    WEBSITE_FORM_SUCCESS,
    WEBSITE_FORM_FAIL
} from "./websites.types";

export const setLoadingWebsitesNamesAndIds = loading => {
    return {
        type   : LOADING_WEBSITES_DOMAINS_AND_IDS,
        payload: loading
    };
};

export const setWebsitesNamesAndIds = loading => {
    return {
        type   : SET_WEBSITES_DOMAINS_AND_IDS,
        payload: loading
    };
};

export const setWebsitesNamesAndIdsLoadingError = loading => {
    return {
        type   : SET_WEBSITES_DOMAINS_AND_IDS_ERROR,
        payload: loading
    };
};

export const setListWebsites = data => {
    return {
        type   : SET_WEBSITES,
        payload: data
    }
}

export const setLoadingWebsite = loading => {
    return {
        type   : SET_LOADING_WEBSITES,
        payload: loading
    }
}

export const updateWebsiteLoading = loading => {
    return {
        type   : LOADING_UPDATE,
        payload: loading
    }
}

export const singleWebsiteLoading = loading => {
    return {
        type   : SINGLE_WEBSITE_LOADING,
        payload: loading
    }
}

export const setSingleWebsite = data => {
    return {
        type   : SET_SINGLE_WEBSITE,
        payload: data
    }
}

export const createWebsitesLoading = loading => {
    return {
        type   : CREATE_WEBSITES_LOADING,
        payload: loading
    }
}

export const websiteFormSuccess = success => {
    return {
        type   : WEBSITE_FORM_SUCCESS,
        payload: success
    }
}

export const websiteFormFail = error => {
    return {
        type   : WEBSITE_FORM_FAIL,
        payload: error
    }
}
