import React from "react";

class Footer extends React.Component {
    render() {
        return (
            <footer className="footer mt-2">
                <p>
                    All Rights Reserved @ 2021 Gallium
                </p>
            </footer>
        );
    }
}

export default Footer;
