import React                               from "react";
import {Modal, Alert}                      from "react-bootstrap";
import {connect}                           from "react-redux";
import {
    postUploadDocument,
    deleteUploadedDocument
}                                          from "../../../redux/Purchases/purchases.async-actions";
import {withRouter}                        from "react-router-dom";
import * as Icon                           from "react-feather";
import {
    setShowUploadDocumentModal,
    setIsFilePicked,
    setShowSubmitAndDeliveredConfirmation,
    setConfirmationComments
}                                          from "../../../redux/Purchases/purchases.actions";
import store                               from "../../../redux/store";
import ListDocumentDataSpecific            from "../../../components/Common/ListDocumentDataSpecific";
import SubmitAndDeliveredConfirmationModal from "./SubmitAndDeliveredConfirmationModal";

class UploadDocumentModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile               : null,
            displayCheckPdfErrorMessage: false
        };

        this.processConfirmationMethod = this.processConfirmation.bind(this);
    }

    onChange = event => {
        const file = event.target.files[0];
        this.setState(
            {
                selectedFile: file
            }
        );

        store.dispatch(setIsFilePicked(true));
    };

    loadState = async () => {
        await this.props.getPurchase();
    };

    handleSubmission = async (e, options) => {
        e.preventDefault();
        let {selectedFile} = this.state;

        let formData = new FormData();
        formData.append("files", selectedFile);
        store.dispatch(setIsFilePicked(false));

        if (selectedFile?.type === "application/pdf") {
            await this.props.postUploadDocument(
                this.props.purchaseId,
                formData
            );
            store.dispatch(setShowUploadDocumentModal(false));
            await this.loadState();
        } else {
            this.setState(
                {
                    displayCheckPdfErrorMessage: true
                }
            );
        }

        if (this.state.displayCheckPdfErrorMessage) {
            setTimeout(
                () => {
                    this.setState(
                        {
                            displayCheckPdfErrorMessage: false
                        }
                    );
                },
                3000
            );
        }

        if (options === "upload_modal") {
            await this.props.getPurchase();
        }
    };

    deleteDocument = async e => {
        e.preventDefault();

        store.dispatch(setIsFilePicked(false));

        if (
            this?.props?.purchase?.attachment_path
            || this?.props?.purchase?.sendgrid_attachement_path
        ) {
            await this.props.deleteUploadedDocument(this.props.purchaseId);
            store.dispatch(setShowUploadDocumentModal(false));
            await this.loadState();
        }

        await this.props.getPurchase();
    };

    processConfirmation = async e => {
        e.persist();

        if (e?.key && e.key === "Enter") {
            await this.handleSubmission(e, "upload_modal");
        }

        if (e?.key && e.key === "Escape") {
            this.props.onClose();
        }
    };

    componentDidMount() {
        this
            .setState(
                {
                    displayCheckPdfErrorMessage: false
                }
            );
    }

    openSubmitAndDelievredConfirmation = e => {
        e.preventDefault();
        store
            .dispatch(
                setShowSubmitAndDeliveredConfirmation(true)
            );
    }

    closeOpenSubmitAndDelievredConfirmation = () => {
        store
            .dispatch(
                setShowSubmitAndDeliveredConfirmation(false)
            );
    }

    /**
     * Save in the redux store the confirmation comment.
     *
     * @param e
     */
    handleCommentsText = e => {
        store
            .dispatch(
                setConfirmationComments(e.target.value)
            );
    }

    render() {
        return (
            <div
                className="modal-wrap"
                onKeyDown={this.processConfirmationMethod}
            >
                <Modal
                    show={this.props.show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    centered
                >
                    <Modal.Header
                        className="modal-header-custom"
                    >
                        <Modal.Title>
                            Upload Document
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {
                            this.props.deleteDocumentError !== null ?
                                <Alert key={"danger"} variant={"danger"}>
                                    {this.props.deleteDocumentError}
                                </Alert> :
                                null
                        }

                        <SubmitAndDeliveredConfirmationModal
                            show={this.props.displaySubmitAndDeliveredModal}
                            purchaseId={this.props.purchaseId}
                            handleClose={this.closeOpenSubmitAndDelievredConfirmation}
                            uploadDetails={this?.state?.selectedFile}
                            handleCommentsText={this.handleCommentsText}
                            commentsText={this.props.commentsText}
                            submitDocument={this.handleSubmission}
                            getPurchase={this.props.getPurchase}
                        />

                        <div className="text-center">
                            {this.props.isFilePicked && <div>
                                Upload the purchase document for
                                <strong>{this.props.customerLastName} {this.props.customerFirstName}</strong>
                            </div>
                            }
                            <br />
                            <div>
                                <label
                                    htmlFor="file-upload"
                                    className="custom-file-upload"
                                >
                                    <Icon.Download
                                        className="icon"
                                    />
                                    File Upload
                                </label>
                                <input
                                    accept="application/pdf"
                                    id="file-upload"
                                    type="file"
                                    name="file"
                                    onChange={this.onChange}
                                />
                                <div>
                                    <div>
                                        <ListDocumentDataSpecific purchase={this?.props?.purchase} />
                                    </div>
                                    {
                                        this.props.isFilePicked &&
                                        <div>
                                            <ul>
                                                <li>
                                                    <strong>File Name</strong>:
                                                    {this?.state?.selectedFile?.name ?? ""}
                                                </li>
                                                <li>
                                                    <strong>File Type</strong>:
                                                    {this?.state?.selectedFile?.type ?? ""}
                                                </li>
                                                <li>
                                                    <strong>Size of the file</strong>:
                                                    {this?.state?.selectedFile?.size ?? ""}
                                                </li>
                                                <li>
                                                    <strong>Last Modified Date</strong>:
                                                    {this?.state?.selectedFile?.lastModifiedDate?.toLocaleDateString() ?? ""}
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                    {
                                        !this.props.isFilePicked &&
                                        <p>Select a file to show details</p>
                                    }
                                </div>
                                {
                                   (this?.props?.purchase?.attachment_path ||
                                    this?.props?.purchase?.sendgrid_attachement_path) &&
                                    <div className="col p-2">
                                        <button
                                            className="button-custom-close"
                                            onClick={(e) => this.deleteDocument(e)}
                                        >
                                            Delete Document
                                        </button>
                                    </div>
                                }
                                <div className="col p-2">
                                    <button
                                        className="button-custom"
                                        onClick={(e) => this.handleSubmission(e, "upload_modal")}
                                    >
                                        Submit
                                    </button>
                                </div>
                                {
                                    this.props.isFilePicked &&
                                    <div className="col p-2">
                                        <button
                                            className="button-custom"
                                            onClick={(e) => this.openSubmitAndDelievredConfirmation(e)}
                                        >
                                            Submit & Delivered
                                        </button>
                                    </div>
                                }
                                {
                                    this?.state?.displayCheckPdfErrorMessage &&
                                    <Alert key={"danger"} variant={"danger"}>Only pdf file is required</Alert>
                                }
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <button
                            className="button-custom-close"
                            onClick={this.props.onClose}
                        >
                            Close
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        uploadDocumentModalState      : state.purchases.showUploadDocumentModal,
        isFilePicked                  : state.purchases.isFilePicked,
        deleteDocumentError           : state.purchases.deleteDocumentError,
        displaySubmitAndDeliveredModal: state.purchases.showSubmitAndDeliveredConfirmation,
        commentsText                  : state.purchases.modalConfirmationComments
    };
};

const mapActionsToProps = {
    postUploadDocument,
    deleteUploadedDocument
};

const UploadDocumentModalConnected = connect(
    mapStateToProps,
    mapActionsToProps
)(UploadDocumentModal);

export default withRouter(UploadDocumentModalConnected);
