import {
	SET_RESET_FILTER_PURCHASES
} from "./global.types";

export const setResetFilterPurchases = value => {
    return {
        type   : SET_RESET_FILTER_PURCHASES,
        payload: value
    }
}
