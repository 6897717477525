import {
    CLEAR_ACCESS_TOKEN,
    CLEAR_AUTHENTICATION_ERROR,
    CLEAR_PASSWORD_RESET_ERROR,
    CLEAR_PASSWORD_RESET_SUCCESS,
    CLEAR_REFRESH_TOKEN,
    CLEAR_USER_DATA,
    SET_ACCESS_TOKEN,
    SET_AUTHENTICATION_ERROR,
    SET_NEW_PASSWORD_ERROR,
    SET_NEW_PASSWORD_SUCCESS,
    SET_PASSWORD_RESET_ERROR,
    SET_PASSWORD_RESET_SUCCESS,
    SET_PROCESSING,
    SET_REFRESH_TOKEN,
    SET_SENDING_NEW_PASSWORD_RESET,
    SET_SENDING_PASSWORD_RESET_REQUEST,
    SET_USER_DATA,
    SET_USERS_LIST,
    SET_USERS_LIST_ERROR,
    SET_SUCCESS_CREATE_USER,
    SET_ERROR_CREATE_USER,
    SET_LOADING_CREATE_USER,
    SET_SINGLE_USER,
    SET_SINGLE_USER_ERROR,
    SET_UPDATE_USER_ERROR,
    SET_UPDATE_USER_SUCCESS,
    SET_LOADING_UPDATE_USER,
    SET_DELETE_USER_ERROR,
    SET_DELETE_USER_LOADING,
    SET_DELETE_USER_SUCCESS,
    SET_MEILI_SEARCH_TOKEN,
    CLEAR_MEILI_SEARCH_TOKEN
} from "./user.types";

export const setUserData = user_data => {
    return {
        type   : SET_USER_DATA,
        payload: user_data
    };
};

export const clearUserData = () => {
    return {
        type: CLEAR_USER_DATA
    };
};

export const setAccessToken = access_token => {
    return {
        type   : SET_ACCESS_TOKEN,
        payload: access_token
    };
};

export const clearAccessToken = () => {
    return {
        type: CLEAR_ACCESS_TOKEN
    };
};

export const setRefreshToken = access_token => {
    return {
        type   : SET_REFRESH_TOKEN,
        payload: access_token
    };
};

export const clearRefreshToken = () => {
    return {
        type: CLEAR_REFRESH_TOKEN
    };
};

export const setMeiliSearchToken = meili_search_token => {
    return {
        type   : SET_MEILI_SEARCH_TOKEN,
        payload: meili_search_token
    };
};

export const clearMeiliSearchToken = () => {
    return {
        type: CLEAR_MEILI_SEARCH_TOKEN
    };
};

export const setProcessing = processing => {
    return {
        type   : SET_PROCESSING,
        payload: processing
    };
};

export const setAuthenticationError = error => {
    return {
        type   : SET_AUTHENTICATION_ERROR,
        payload: error
    };
};

export const clearAuthenticationError = () => {
    return {
        type: CLEAR_AUTHENTICATION_ERROR
    };
};

export const setSendingPasswordResetRequest = sending => {
    return {
        type   : SET_SENDING_PASSWORD_RESET_REQUEST,
        payload: sending
    };
};

export const setPasswordResetError = error => {
    return {
        type   : SET_PASSWORD_RESET_ERROR,
        payload: error
    };
};

export const clearPasswordResetError = () => {
    return {
        type   : CLEAR_PASSWORD_RESET_ERROR,
        payload: false
    };
};

export const setPasswordResetSuccess = success => {
    return {
        type   : SET_PASSWORD_RESET_SUCCESS,
        payload: success
    };
};

export const clearPasswordResetSuccess = () => {
    return {
        type   : CLEAR_PASSWORD_RESET_SUCCESS,
        payload: false
    };
};

export const setSendingNewPasswordReset = sending => {
    return {
        type   : SET_SENDING_NEW_PASSWORD_RESET,
        payload: sending
    };
};

export const setNewPasswordResetError = error => {
    return {
        type   : SET_NEW_PASSWORD_ERROR,
        payload: error
    };
};

export const clearNewPasswordResetError = () => {
    return {
        type   : SET_NEW_PASSWORD_ERROR,
        payload: false
    };
};

export const setNewPasswordResetSuccess = success => {
    return {
        type   : SET_NEW_PASSWORD_SUCCESS,
        payload: success
    };
};

export const clearNewPasswordResetSuccess = () => {
    return {
        type   : SET_NEW_PASSWORD_SUCCESS,
        payload: false
    };
};

export const setUsersList = users => {
    return {
        type   : SET_USERS_LIST,
        payload: users
    };
};

export const setUsersListError = error => {
    return {
        type   : SET_USERS_LIST_ERROR,
        payload: error
    };
};

export const setSuccesCreateUser = success => {
    return {
        type   : SET_SUCCESS_CREATE_USER,
        payload: success
    };
};

export const setErrorCreeateUser = error => {
    return {
        type   : SET_ERROR_CREATE_USER,
        payload: error
    };
};

export const setLoadingCreeateUser = load => {
    return {
        type   : SET_LOADING_CREATE_USER,
        payload: load
    };
};

export const setSingleUser = user => {
    return {
        type   : SET_SINGLE_USER,
        payload: user
    };
};


export const setSingleUserError = user => {
    return {
        type   : SET_SINGLE_USER_ERROR,
        payload: user
    };
};

export const setUpdateUserError = error => {
    return {
        type   : SET_UPDATE_USER_ERROR,
        payload: error
    };
};

export const setUpdateUserSuccess = success => {
    return {
        type   : SET_UPDATE_USER_SUCCESS,
        payload: success
    };
};

export const setLoadingUpdateUser = load => {
    return {
        type   : SET_LOADING_UPDATE_USER,
        payload: load
    };
};

export const setDeleteUserSuccess = success => {
    return {
        type   : SET_DELETE_USER_SUCCESS,
        payload: success
    };
};

export const setDeleteUserError = error => {
    return {
        type   : SET_DELETE_USER_ERROR,
        payload: error
    };
};

export const setDeleteUserLoading = load => {
    return {
        type   : SET_DELETE_USER_LOADING,
        payload: load
    };
};
