import React            from "react";
import {Button ,Modal } from "react-bootstrap";
import CopyDatabutton   from "../../../components/Common/CopyDataButton";
import { connect } from "react-redux";

class GetSendgridUrlModal extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="modal-wrap">
                <Modal
                    show={this.props.show}
                    onHide={this.handleClose}
                    backdrop="static"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>
                            Generate Sendgrid Url
                        </Modal.Title>
                    </Modal.Header>
                    <form>
                        <Modal.Body>
                            <h4>Sendgrid Url Path</h4>&nbsp;
                            <div>
                                <a>{this.props.sendgridUrl['url']}</a>
                            </div>
                            {
                                typeof this.props.sendgridUrl['url'] !== 'undefined' 
                                ? <CopyDatabutton data={this.props.sendgridUrl["url"]}/> 
                                : null
                            }
                        </Modal.Body>

                        <Modal.Footer>
                            {Object.keys( this?.props?.sendgridUrl).length === 0 
                                ?   <Button
                                        variant="danger"
                                        onClick={this.props.onConfirm}
                                        disabled={this.props.processing}
                                    >
                                        {this.props.processing ? "Processing ..." : "Load"}
                                    </Button> 
                                : null
                            }
                            <Button
                                variant="success"
                                onClick={this.props.onClose}
                                disabled={this.props.processing}
                            >
                                Cancel
                            </Button>
                        </Modal.Footer>
         
                    </form>
                </Modal>
            </div>
        )
    }
}

const mapActionsToProps = {

}

const mapStateToProps = state => {
    return {
        sendgridUrl : state.emailTemplates.sendgridUrl
    }
}

const GetSendgridUrlModalConnected = connect(
    mapStateToProps,
    mapActionsToProps
)(GetSendgridUrlModal)

export default GetSendgridUrlModalConnected;
