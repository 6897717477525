import React                      from 'react'
import Navigation                 from '../../../components/Navigation/Navigation'
import { getCompanyList }         from '../../../redux/Company/company.async-actions'
import { withRouter }             from 'react-router-dom'
import { connect }                from 'react-redux'
import Loader                     from '../../../components/Common/Loader'
import { Link }                   from 'react-router-dom'
import { 
    Breadcrumb,
    Button,
    Row,
    Col,
    Table
 }                                from 'react-bootstrap'
import routerConstants            from "../../../constants/router-constants";
import UUIDs                      from '../../../services/UUIDs'
import ListSorter                 from "../../../Utils/ListSorter";

class ListCompany extends React.Component {
    constructor(props) {
        super (props)

        this.state = {
            sideMenu : true
        }
    }

    goToRoute(route) {
        this.props.history.push(route);
    }

    componentDidMount() {
        this.props.getCompanyList()

        if (this.props.successCreate) {
            this.props.getCompanyList()
        }

        if(this.props.successEdit){
            this.props.getCompanyList()
        }
    }

    _onSideMenu = (active) => {
        this.setState({sideMenu: active})
    }

    mapCompany = () => ListSorter.orderListByProperty(
        this.props.company, 'name'
        ).map(
            company => {
                const companyId = company["@id"]

                return (<tr key={companyId}>
                    <td>
                        {company.address}
                    </td>
                    <td>
                        {company.city}
                    </td>
                    <td>
                        {company.country}
                    </td>
                    <td>
                        {company.name}
                    </td>
                    <td>
                        {company.number}
                    </td>
                    <td>
                        {company.vatNumber}
                    </td>
                    <td>
                        <Button
                            onClick={e => this.goToRoute(`/list-company/update/${UUIDs.getEntityUUID(
                                companyId)}`,e)}
                            variant="outline-primary"
                            as={"button"}
                        >
                            Edit
                        </Button>
                    </td>
                </tr>
            );
        }
    )

    render() {
        let loader = null;

        if (this.props.loadingCompany) {
            loader = <Loader message="Loading..." />
        }

        if (this.props.company === null) {
            loader = <Loader message="Loading..." />
        }

        return <>
            <div className="page-wrapper">
                <Navigation onClick={this._onSideMenu} />
                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? "" : "hide-sidemenu"}`}>
                    {/* Loader */}
                    {loader}
                    {/* End Loader */}

                    {true === this.props.successEdit &&
                        <div className="alert alert-success" role="alert">
                            Success
                        </div>
                    }

                    <div className="main-content-header">
                        <Breadcrumb>
                            <h1>Companies</h1>
                            <Link to={routerConstants.dashboard} className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Breadcrumb.Item active>
                                Companies
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>

                    <Row>
                        <Col lg={12}>
                            <div className="card mb-4">
                                <div className="card-body">

                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <h5 className="card-title float-lg-left">Companies</h5>
                                        <Button
                                            onClick={e => this.goToRoute(routerConstants.createCompany, 2 )}
                                            variant="outline-primary" 
                                            className="mb-2"
                                        >
                                            Create Companies
                                        </Button>
                                    </div>

                                    <Table className="m-0" responsive>
                                        <thead>
                                            <tr key="table_headers">
                                                <th width={"20%"}>Address</th>
                                                <th width={"20%"}>City</th>
                                                <th width={"20%"}>Country</th>
                                                <th width={"20%"}>Company Name</th>
                                                <th width={"20%"}>Number</th>
                                                <th width={"20%"}>Vat Number</th>
                                                <th width={"20%"}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.mapCompany()}
                                        </tbody>
                                    </Table>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    }
}

const mapStateToProps = state => {
    return {
       company       : state.company.setCompanyList,
       loadingCompany: state.company.setLoadingCompany,
       successEdit   : state.company.setUpdateSuccedCompany,
       errorEdit     : state.company.setErrorUpdateCompany,
       successCreate : state.company.setPostCompanySucced,
    };
};

const mapActionsToProps = {
    getCompanyList
}

const ListCompanyConnected = connect(
    mapStateToProps,
    mapActionsToProps
)(ListCompany);

export default withRouter(ListCompanyConnected);
