import {
    SET_CREATE_COMMENT_ERROR,
    SET_CREATE_COMMENT_SUCCESS,
    SET_CREATE_COMMENT_LOADING,
    SET_DELETE_COMMENT_ERROR,
    SET_DELETE_COMMENT_SUCCESS,
    SET_DELETE_COMMENT_LOADING,
    SET_UPDATE_COMMENT_ERROR,
    SET_UPDATE_COMMENT_SUCCESS,
    SET_UPDATE_COMMENT_LOADING
} from "./comments.types";

const INITIAL_STATE = {
    createCommentsSuccess : false,
    createCommentsError   : null,
    createCommentsLoading : false,
    deleteCommentsSuccess : false,
    deleteCommentsError   : null,
    deleteCommentsLoading : false,
    updateCommentsSuccess : false,
    updateCommentsError   : null,
    updateCommentsLoading : false

};

const commentsReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SET_CREATE_COMMENT_SUCCESS:
            return {
                ...state,
                createCommentsSuccess: action.payload
            };
        case SET_CREATE_COMMENT_ERROR:
            return {
                ...state,
                createCommentsError: action.payload
            };
        case SET_CREATE_COMMENT_LOADING:
            return {
                ...state,
                createCommentsLoading: action.payload
            };
        case SET_DELETE_COMMENT_SUCCESS:
            return {
                ...state,
                deleteCommentsSuccess: action.payload
            };
        case SET_DELETE_COMMENT_ERROR:
            return {
                ...state,
                deleteCommentsError: action.payload
            };
        case SET_DELETE_COMMENT_LOADING:
            return {
                ...state,
                deleteCommentsLoading: action.payload
            };
        case SET_UPDATE_COMMENT_SUCCESS:
            return {
                ...state,
                updateCommentsSuccess: action.payload
            };
        case SET_UPDATE_COMMENT_ERROR:
            return {
                ...state,
                updateCommentsError: action.payload
            };
        case SET_UPDATE_COMMENT_LOADING:
            return {
                ...state,
                updateCommentsLoading: action.payload
            };
        default:
            return state;
    }
};

export default commentsReducer;
