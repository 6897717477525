import {
    SET_LOADING_PAYMENT_GATEWAY_LIST,
    SET_PAYMENT_GATEWAY_LIST,
    SET_PAYMENT_GATEWAY_LIST_ERROR,
    SET_LOADING_SINGLE_PAYMENT_GATEWAY,
    SET_SINGLE_PAYMENT_GATEWAY,
    SET_SINGLE_PAYMENT_GATEWAY_ERROR,
    SET_UPDATE_PAYMENT_GATEWAY_ERROR,
    SET_UPDATE_PAYMENT_GATEWAY_LOADING,
    SET_UPDATE_PAYMENT_GATEWAY_SUCCESS
} from "./payment-gateway.types";

export const setPaymentGatewayList = PaymentGateways => {
    return {
        type   : SET_PAYMENT_GATEWAY_LIST,
        payload: PaymentGateways
    };
};

export const setLoadingPaymentGatewayList = loading => {
    return {
        type   : SET_LOADING_PAYMENT_GATEWAY_LIST,
        payload: loading
    };
};

export const setPaymentGatewayListError = error => {
    return {
        type   : SET_PAYMENT_GATEWAY_LIST_ERROR,
        payload: error
    };
};

export const setSinglePaymentGateway = SinglePaymentGateway => {
    return {
        type   : SET_SINGLE_PAYMENT_GATEWAY,
        payload: SinglePaymentGateway
    };
};

export const setLoadingSinglePaymentGateway = loading => {
    return {
        type   : SET_LOADING_SINGLE_PAYMENT_GATEWAY,
        payload: loading
    };
};

export const setSinglePaymentGatewayError = error => {
    return {
        type   : SET_SINGLE_PAYMENT_GATEWAY_ERROR,
        payload: error
    };
};

export const setUpdatePaymentGatewayError = error => {
    return {
        type   : SET_UPDATE_PAYMENT_GATEWAY_ERROR,
        payload: error
    };
};

export const setUpdatePaymentGatewaySuccess = success => {
    return {
        type   : SET_UPDATE_PAYMENT_GATEWAY_SUCCESS,
        payload: success
    };
};

export const setUpdatePaymentGatewayLoading = loading => {
    return {
        type   : SET_UPDATE_PAYMENT_GATEWAY_LOADING,
        payload: loading
    };
};
