import http              from "../../services/http";
import meiliSearchClient from "../../services/MeiliSearchClient";

import {
    setEmailTemplates,
    setEmailTemplatesError,
    setEmailTemplatesLoading,
    setSingleEmailTemplate,
    setSingleEmailTemplateError,
    setSingleEmailTemplateLoading,
    setUpdateEmailTemplateError,
    setUpdateEmailTemplateLoading,
    setUpdateEmailTemplateSuccess,
    setCreateEmailTemplateError,
    setCreateEmailTemplateLoading,
    setCreateEmailTemplateSuccess,
    setDeleteEmailTemplateError,
    setDeleteEmailTemplateSuccess,
    setDeleteEmailTemplateLoading,
    setSendgridUrl,
    setSendgridUrlLoading,
    setSendgridUrlSuccess,
    setSengridUrlError,
    setEmailTemplateFirstPage,
    setEmailTemplateLastPage,
    setEmailTemplateNextPage,
    setEmailTemplatePrevPage,
    setEmailTemplateCurrentPage,
    setTotalEmailTemplates,
    setMeiliEmailTemplates,
    setMeiliTotalEmailTemplates,
    setMeiliTotalPages,
    setMeiliEmailTemplatesError,
    setMeiliEmailTemplatesCurrentPage,
    setMeiliEmailTemplatesFirstPage,
    setMeiliEmailTemplatesLastPage,
    setMeiliEmailTemplatesPrevPage,
    setMeiliEmailTemplatesNextPage,
    resetMeiliPagination
} from "./emailTemplate.actions";

export const getEmailTemplatesList = queryParams => {
    return async dispatch => {
        dispatch(setEmailTemplatesLoading(true))

        try {
            const response = await http.get(
                `email-templates`,
                {
                    params: queryParams
                }
            );

            const data = response.data;
            const hydraview = data?.["hydra:view"];
            dispatch(setEmailTemplateCurrentPage(hydraview["@id"] ?? null));
            dispatch(setEmailTemplateFirstPage(hydraview["hydra:first"] ?? null));
            dispatch(setEmailTemplateLastPage(hydraview["hydra:last"] ?? null));
            dispatch(setEmailTemplateNextPage(hydraview["hydra:next"] ?? null));
            dispatch(setEmailTemplatePrevPage(hydraview["hydra:previous"] ?? null));

            dispatch(setEmailTemplates(data["hydra:member"]))
            dispatch(setTotalEmailTemplates(data["hydra:member"].length))
        } catch(e) {
            dispatch(setEmailTemplates([]))
            dispatch(setTotalEmailTemplates(0))
            dispatch(setEmailTemplatesError(e.message))
        } finally {
            dispatch(setEmailTemplatesLoading(false))
        }
    }
}

export const getSingleEmailTemplate = (emailTemplateId) => {
    return async dispatch => {
        dispatch(setSingleEmailTemplateLoading(true));

        try {
            const response = await http.get(`email-templates/${emailTemplateId}`);
            const data = response.data;

            dispatch(setSingleEmailTemplate(data));
        } catch (e) {
            dispatch(setSingleEmailTemplateError(e.message))
        } finally {
            dispatch(setSingleEmailTemplateLoading(false))
        }
    }
}

export const createNewEmailTemplate = (data) => {
    return async dispatch => {
        dispatch(setCreateEmailTemplateLoading(true));

        try {
            await http.post(`email-templates`,
                {
                    "name"        : data.name,
                    "description" : data.description,
                    "externalId"  : data.externalId
                }
            )

            dispatch(setCreateEmailTemplateSuccess(true))
        } catch (e) {
            dispatch(setCreateEmailTemplateError(e.message))
            dispatch(setCreateEmailTemplateLoading(false))
        } finally {
            dispatch(setCreateEmailTemplateLoading(false))

            setTimeout(() => {
                dispatch(setCreateEmailTemplateSuccess(false))
            }, 3000)
        }
    }
}

export const editEmailTemplate = (emailTemplateId, data) => {
    return async dispatch => {
        dispatch(setUpdateEmailTemplateLoading(true))
        try {
            await http.patch(
                `email-templates/${emailTemplateId}`, 
                {
                    "name"        : data.name,
                    "description" : data.description,
                    "externalId"  : data.externalId
                }   
            )

            dispatch(setUpdateEmailTemplateSuccess(true))
        } catch (e) {
            dispatch(setUpdateEmailTemplateError(e.message))
        } finally {
            setTimeout(() => {
                dispatch(setUpdateEmailTemplateSuccess(false))
                dispatch(setUpdateEmailTemplateError(null))
            }, 3000)
        }
    }
}

export const deleteEmailTemplate = (emailTemplateId) => {
    return async dispatch => {
        dispatch(setDeleteEmailTemplateLoading(true))

        try {
            await http.delete(
                `email-templates/${emailTemplateId}`
            )

            dispatch(setDeleteEmailTemplateLoading(false))
            dispatch(setDeleteEmailTemplateSuccess(true))
        } catch (e) {
            dispatch(setDeleteEmailTemplateLoading(false))
            dispatch(setCreateEmailTemplateError(e.message))
        } finally {
            setTimeout(
                () => {
                    dispatch(setDeleteEmailTemplateSuccess(false))
                    dispatch(setDeleteEmailTemplateError(null))
                }, 
                3000
            );
        }
    }
}

export const getSendgridUrlPath = (emailTemplateId) => {
    return async dispatch => {
        dispatch(setSendgridUrlLoading(true));

        try {
            const response =  await http.get(
                `email-templates/${emailTemplateId}/version-id`
            )

            dispatch(setSendgridUrl(response.data));
            dispatch(setSendgridUrlSuccess(true))
        } catch(e) {
            dispatch(setSengridUrlError(e.message))
            dispatch(setSendgridUrlLoading(false))
        } finally {
            setTimeout(
                () => {
                    dispatch(setSendgridUrlLoading(false))
                    dispatch(setSendgridUrlSuccess(false))
                },
                3000
            )
        }
    }
}

export const meiliSearchEmailTemplates = (searchQuery) => {
    return async dispatch => {
        dispatch(resetMeiliPagination());
        dispatch(setMeiliEmailTemplatesError(null));
        dispatch(setMeiliEmailTemplates([]));
        dispatch(setMeiliTotalPages(0));
        dispatch(setMeiliTotalEmailTemplates(0));
        dispatch(setMeiliEmailTemplatesCurrentPage(1));
        dispatch(setMeiliEmailTemplatesFirstPage(1));
        dispatch(setMeiliEmailTemplatesLastPage(1));
        dispatch(setMeiliEmailTemplatesNextPage(1));
        dispatch(setMeiliEmailTemplatesPrevPage(1));

        try {
            const defaultOptions = {
                sort : [
                    "createdAt:asc"
                ]
            };
            const response = await meiliSearchClient.post(
                `/indexes/${meiliSearchClient.defaults.prefix}email_templates/search`,
                {
                    ...defaultOptions,
                    ...searchQuery
                },
                {
                    "headers": {}
                }
            );

            dispatch(setMeiliEmailTemplates(response?.data?.hits ?? []));
            dispatch(setMeiliTotalEmailTemplates(response?.data?.totalHits ?? 0));
            dispatch(setMeiliTotalPages(response?.data?.totalPages ?? 0));
            dispatch(setMeiliEmailTemplatesCurrentPage(response?.data?.page ?? 1));
            dispatch(setMeiliEmailTemplatesFirstPage(1));
            dispatch(setMeiliEmailTemplatesLastPage(response?.data?.totalPages ?? 1));
            dispatch(setMeiliEmailTemplatesNextPage(response.data.totalPages > response.data.page ? response?.data?.page + 1 : response?.data?.totalPages));
            dispatch(setMeiliEmailTemplatesPrevPage(1 < response.data.page ? response?.data?.page - 1 : 1));

        } catch (e) {
            console.log(e);
        }
    }
}
