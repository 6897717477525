import {
    setWebsitesConfig,
    setLoadingWebsitesConfig,
    setWebsitesConfigError,
    setSingleWebsiteConfig,
    setLoadingSingleWebsiteConfig,
    setSingleWebsiteConfigError,
    setEditWebsiteConfigError,
    setEditWebsiteConfigSuccess,
    setEditWebsiteConfigLoading
}           from "./websites-config.actions";
import http from "../../services/http";

export const getWebsitesConfig = () => {
    return async dispatch => {
        dispatch(setLoadingWebsitesConfig(true));
        dispatch(setWebsitesConfigError(null));

        try {
            const response = await http.get(`website-configs`);

            if (response?.data?.["hydra:member"]) {
                dispatch(setWebsitesConfig(response.data["hydra:member"]));
            }
        } catch (e) {
            let errorMessage = e?.response?.data["hydra:description"] ?? e.message;

            dispatch(setWebsitesConfigError(errorMessage));
        } finally {
            dispatch(setLoadingWebsitesConfig(false));
        }
    };
}

export const getSingleWebsiteConfig = (id) => {
    return async dispatch => {
        dispatch(setLoadingSingleWebsiteConfig(true));
        dispatch(setSingleWebsiteConfigError(null));

        try {
            const response = await http.get(`website-configs/${id}`);

            if (response?.data) {
                dispatch(setSingleWebsiteConfig(response.data));
            }
        } catch (e) {
            let errorMessage = e?.response?.data["hydra:description"] ?? e.message;

            dispatch(setSingleWebsiteConfigError(errorMessage));
        } finally {
            dispatch(setLoadingSingleWebsiteConfig(false));
        }
    };
}

export const editWebsiteConfig = (id, data) => {
    return async dispatch => {
        dispatch(setEditWebsiteConfigLoading(true));
        dispatch(setEditWebsiteConfigError(null));
        dispatch(setEditWebsiteConfigSuccess(null));

        try {
            await http.put(`website-configs/${id}`,
                {
                    enabledConfig: data.enabledConfig,
                    dataConfig : data.dataConfig,
                    website : data.websites,
                    category: data.category
                }
            );

            dispatch(setEditWebsiteConfigError(null));
            dispatch(setEditWebsiteConfigSuccess(true));
        } catch (e) {
            let errorMessage = e?.response?.data["hydra:description"] ?? e.message;

            dispatch(setEditWebsiteConfigError(errorMessage));
        } finally {
            setTimeout(() => {
                dispatch(setEditWebsiteConfigError(null));
                dispatch(setEditWebsiteConfigSuccess(null));
            }, 3000);
            dispatch(setEditWebsiteConfigLoading(false));
        }
    }
}
