import React from "react";
import Navigation from "../../components/Navigation/Navigation";
import {
  Alert,
  Badge,
  Breadcrumb,
  Button,
  ButtonGroup,
  Col,
  Dropdown,
  Row,
  Table,
} from "react-bootstrap";
import Footer from "../Footer/Footer";
import Loader from "../../components/Common/Loader";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  getOrderPayments,
  getSingleClient,
  getSingleOrderComments,
  refundPayments,
  setNewEmailAddress,
  unsubscribeClient,
  sendBackWelcomeEmail,
} from "../../redux/Clients/clients.async-actions";
import routerConstants from "../../constants/router-constants";
import { setSingleClient } from "../../redux/Clients/clients.actions";
import DateUtils from "../../services/DateUtils";
import UnsubscribeModal from "./Modals/UnsubscribeModal";
import RefundPaymentsModal from "./Modals/RefundPaymentsModal";
import ReplaceCustomerEmailModal from "./Modals/ReplaceCustomerEmailModal";
import UUIDs from "../../services/UUIDs";
import { Edit3, Trash } from "react-feather";
import { useFieldArray, useForm } from "react-hook-form";
import {
  deleteComment,
  postComment,
} from "../../redux/Comments/comments.async-actions";
import ConfirmDeletComments from "../ListPurchases/Modals/ConfirmDeleteCommentsModal";
import http from "../../services/http";
import ConfirmSendEmail from "../ListPurchases/Modals/ConfirmSendEmail";

class ListSingleClient extends React.Component {
  state = {
    sideMenu: true,
    showUnsubscribeModal: false,
    processingUnsubscription: false,
    selectedPayments: [],
    processingRefunding: false,
    showRefundPaymentsModal: false,
    processingRefundPayments: false,
    refundedPayments: [],
    notRefundedPayments: [],

    showReplaceEmailModal: false,
    processingReplaceEmailModal: false,
    replaceEmailError: "",

    isAdminUser: false,

    commentsElement: [],
    newCommentLimiter: false,
    commentAdded: true,
    commentsId: "",
    commentsIndex: 0,
    showCommentsModal: false,
    processingCommentsDelete: false,

    processingAutoConnectLink: false,
    autoConnectLinkError: null,

    showSendEmailModal: false,
  };

  constructor(props) {
    super(props);

    this.showUnsubscribeModalCallback = this.showUnsubscribeModal.bind(this);
    this.hideUnsubscribeModalCallback = this.hideUnsubscribeModal.bind(this);
    this.unsubscribeCustomerCallback = this.unsubscribeCustomer.bind(this);
    this.hideReplaceEmailModalCallback = this.hideReplaceEmailModal.bind(this);
    this.showReplaceEmailModalCallback = this.showReplaceEmailModal.bind(this);
    this.replaceCustomerEmailCallback = this.replaceCustomerEmail.bind(this);
    this.toggleSelectedPayments = this.toggleSelectedPayments.bind(this);
    this.showRefundPaymentModal = this.showRefundPaymentModal.bind(this);
    this.hideRefundPaymentModal = this.hideRefundPaymentModal.bind(this);
    this.refundSelectedPayments = this.refundSelectedPayments.bind(this);
  }

  // Loading icon false after DOM loaded
  async componentDidMount() {
    await this.props.getSingleClient(this.props.match.params.client_uuid);
    await this.props.getOrderPayments(this.props.match.params.client_uuid);
    await this.getSingleOrderComments();

    const roleUser = this?.props?.user?.["roles"]?.find(
      (role) => role === "ROLE_ADMIN"
    );

    this.setState({ isAdminUser: "ROLE_ADMIN" === roleUser });

    if (null === this.props.commentsError) {
      this.setState({
        commentsElement: this?.props?.orderComments?.map((comments) => {
          return {
            id: comments.id,
            commentText: comments.commentText,
            createdAt: comments.createdAt,
            userEmail: comments.userEmail,
          };
        }),
      });
    }
  }

  async getSingleOrderComments(queryParams = {}) {
    queryParams = {
      "order[createdAt]": "desc",
    };

    await this.props.getSingleOrderComments(
      this.props.match.params.client_uuid,
      queryParams
    );
  }

  goToRoute(route) {
    this.props.history.push(route);
  }

  componentWillUnmount() {
    this.props.setSingleClient(null);
  }

  // Toggle side bar menu
  _onSideMenu = (active) => {
    this.setState({ sideMenu: active });
  };

  toggleSelectedPayments = (payment) => {
    const paymentIndex = this.state.selectedPayments.indexOf(payment);

    if (-1 === paymentIndex) {
      const newState = [...this.state.selectedPayments, payment];

      this.setState({
        selectedPayments: newState,
      });
    } else {
      const newState = [
        ...this.state.selectedPayments.slice(0, paymentIndex),
        ...this.state.selectedPayments.slice(paymentIndex + 1),
      ];

      this.setState({
        selectedPayments: newState,
      });
    }
  };

  clientFullName() {
    if (this.props.singleClient) {
      const client = this.props.singleClient;
      return `${client.customer.lastName} ${client.customer.firstName}`;
    }

    return "loading...";
  }

  getCustomerInfo() {
    const processedKeys = {};
    const results = [];

    if (this.props.singleClient?.customer) {
      const client = this.props.singleClient;
      const customerData = client.customer?.customerData ?? {};

      processedKeys["First Name"] = client.customer?.firstName ?? "-";
      processedKeys["Last Name"] = client.customer?.lastName ?? "-";
      processedKeys["Phone Number"] = client.customer?.phoneNumber ?? "-";

      for (let key in customerData) {
        let keyParts = key
          .split("_")
          .map((key) => key.charAt(0).toUpperCase() + key.slice(1))
          .map((key) => {
            return key
              .split(/(?=[A-Z])/)
              .map((key) => key.charAt(0).toUpperCase() + key.slice(1))
              .join(" ");
          })
          .join(" ");

        processedKeys[keyParts] = customerData[key];
      }

      for (let title in processedKeys) {
        results.push({
          title: title,
          value: processedKeys[title],
        });
      }
    }

    return results;
  }

  showUnsubscribeModal() {
    this.setState({ showUnsubscribeModal: true });
  }

  hideUnsubscribeModal() {
    this.setState({ showUnsubscribeModal: false });
  }

  showRefundPaymentModal() {
    this.setState({ showRefundPaymentsModal: true });
  }

  hideRefundPaymentModal() {
    this.setState({ showRefundPaymentsModal: false });
  }

  showReplaceEmailModal() {
    this.setState({ showReplaceEmailModal: true });
  }

  hideReplaceEmailModal() {
    this.setState({ showReplaceEmailModal: false });
  }

  async replaceCustomerEmail(email) {
    this.setState({
      processingReplaceEmailModal: true,
      replaceEmailError: "",
    });

    let customerID = this?.props?.singleClient?.customer["@id"];
    customerID = UUIDs.getEntityUUID(customerID);

    const response = await this.props.setNewEmailAddress(customerID, email);

    this.setState({
      processingReplaceEmailModal: false,
    });

    if ("" !== response) {
      this.setState({
        replaceEmailError: response,
      });

      setTimeout(() => {
        this.setState({
          replaceEmailError: "",
        });
      }, 6000);
    } else {
      this.hideReplaceEmailModal();
    }
  }

  sendWelcomeEmail = async () => {
    let customerID = this?.props?.singleClient?.customer["@id"];
    customerID = UUIDs.getEntityUUID(customerID);

    await this.props.sendBackWelcomeEmail(customerID);

    if (this.props.sendWelcomeEmailSuccess) {
      this.setState({
        showSendEmailModal: false,
      });
    }
  };

  async unsubscribeCustomer() {
    this.setState({ processingUnsubscription: true });

    let customerID = this?.props?.singleClient?.customer["@id"];
    customerID = UUIDs.getEntityUUID(customerID);

    await this.props.unsubscribeClient(customerID);
    await this.props.getSingleClient(this.props.match.params.client_uuid);

    this.setState({ processingUnsubscription: false });
    this.setState({ showUnsubscribeModal: false });
  }

  async refundSelectedPayments() {
    this.setState({ processingRefunding: true });

    try {
      const response = await this.props.refundPayments(
        this.state.selectedPayments
      );

      this.setState({
        refundedPayments: response?.refunded ?? [],
        notRefundedPayments: response?.not_refunded ?? [],
      });

      this.props.getOrderPayments(this.props.match.params.client_uuid);
    } catch (e) {
      console.log(e.message);
    } finally {
      setTimeout(() => {
        this.setState({
          notRefundedPayments: [],
        });
      }, 10000);
    }

    this.setState({ processingRefunding: false });
    this.setState({ showRefundPaymentsModal: false });
  }

  paymentHasError(payment) {
    if (0 === this.state.notRefundedPayments.length) {
      return "";
    }

    for (const errorIdx in this.state.notRefundedPayments) {
      let error = this.state.notRefundedPayments[errorIdx];

      if (error.payment === payment) {
        return <div className={"text-danger small"}>{error.error}</div>;
      }
    }

    return "";
  }

  renderPurchases() {
    const purchases = this?.props?.singleClient?.purchases.sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    );

    if (!purchases || purchases.length === 0) {
      return (
        <tr>
          <td colSpan={4} className={"text-center"}>
            No Purchases Found
          </td>
        </tr>
      );
    }

    return purchases.map((purchase) => {
      const purchaseId = UUIDs.getEntityUUID(purchase["@id"]);
      const purchaseLink = `/list-purchases/purchase/${purchaseId}`;
      let statusBadgeVariant;
      let statusBadgeText;
      let typeBadgeVariant;
      let typeBadgeText;

      switch (purchase.status) {
        case "IN_PROGRESS":
          statusBadgeVariant = "primary";
          statusBadgeText = "In Progress";
          break;
        case "PENDING":
          statusBadgeVariant = "dark";
          statusBadgeText = "Pending";
          break;
        case "COMPLETED":
          statusBadgeVariant = "success";
          statusBadgeText = "Completed";
          break;
        case "CANCELLED":
          statusBadgeVariant = "warning";
          statusBadgeText = "Cancelled";
          break;
        case "CANCELLED_ORDER":
          statusBadgeVariant = "danger";
          statusBadgeText = "Cancelled Subscription";
          break;
        case "ON_HOLD":
          statusBadgeVariant = "info";
          statusBadgeText = "On Hold";
          break;
        default:
          statusBadgeVariant = "primary";
          statusBadgeText = "In Progress";
      }

      switch (purchase.type) {
        case "SALES":
          typeBadgeVariant = "success";
          typeBadgeText = "Sale";
          break;
        case "CONNECTED_AREA":
          typeBadgeVariant = "primary";
          typeBadgeText = "Subscription";
          break;
        default:
          typeBadgeVariant = "primary";
          typeBadgeText = "Sale";
      }

      return (
        <tr key={purchase["@id"]}>
          <td>
            <Link to={purchaseLink}>
              {DateUtils.formatISO8601ToFormat(purchase.createdAt)}
              &nbsp;
              <small>
                ({DateUtils.formatISO8601ToHumanReadable(purchase.createdAt)})
              </small>
            </Link>
          </td>
          <td>{purchase.service.name}</td>
          <td>
            <Badge pill variant={typeBadgeVariant} className="mt-2 mr-2">
              {typeBadgeText}
            </Badge>
          </td>
          <td>
            <Badge pill variant={statusBadgeVariant} className="mt-2 mr-2">
              {statusBadgeText}
            </Badge>
          </td>
          <td>{purchase?.comments?.length}</td>
        </tr>
      );
    });
  }

  renderPayments(stripeCustomerCardIsNotNull) {
    const payments = this?.props?.orderPayments;
    const paymentGatewaySlug =
      this?.props?.singleClient?.paymentGateway?.slug ?? "";

    if (!payments || payments.length === 0) {
      return (
        <tr>
          <td colSpan={5} className={"text-center"}>
            No Payments Found
          </td>
        </tr>
      );
    }

    return payments?.map((payment) => {
      let statusBadgeVariant;
      let statusBadgeText;
      let typeBadgeVariant;
      let typeBadgeText;

      switch (payment.status) {
        case "PENDING":
          statusBadgeVariant = "dark";
          statusBadgeText = "Pending";
          break;
        case "COMPLETED":
          statusBadgeVariant = "success";
          statusBadgeText = "Completed";
          break;
        case "FAILED":
          statusBadgeVariant = "warning";
          statusBadgeText = "Failed";
          break;
        case "REFUNDED":
          statusBadgeVariant = "danger";
          statusBadgeText = "Refunded";
          break;
        default:
          statusBadgeVariant = "success";
          statusBadgeText = "Completed";
      }

      switch (payment.type) {
        case "REBILL":
          typeBadgeVariant = "success";
          typeBadgeText = "Rebill";
          break;
        case "SALE":
          typeBadgeVariant = "warning";
          typeBadgeText = "Sale";
          break;
        default:
          typeBadgeVariant = "success";
          typeBadgeText = "Rebill";
      }

      let oppwa_transaction_url = "https://test.prtso.com/bip/detail.prc?id=";

      if ("bo.gallium-media.co.uk" === window.location.host) {
        oppwa_transaction_url = "https://prtso.com/bip/detail.prc?id=";
      }

      return (
        <tr key={payment.createdAt}>
          <td>
            {"Stripe" !== paymentGatewaySlug && (
              <input
                type="checkbox"
                value={payment.id}
                disabled={
                  this.state.processingRefunding ||
                  "NO" !== payment.refundProcessing
                }
                onClick={() => this.toggleSelectedPayments(payment.id)}
              />
            )}
            {null !== payment.externalChargeId &&
              "Oppwa" !== paymentGatewaySlug && (
                <input
                  type="checkbox"
                  value={payment.id}
                  disabled={
                    this.state.processingRefunding ||
                    "NO" !== payment.refundProcessing
                  }
                  onClick={() => this.toggleSelectedPayments(payment.id)}
                />
              )}
          </td>
          <td>
            {DateUtils.formatISO8601ToFormat(payment.createdAt)}
            &nbsp;
            <small>
              ({DateUtils.formatISO8601ToHumanReadable(payment.createdAt)})
            </small>
            {!stripeCustomerCardIsNotNull && (
              <a
                href={`${oppwa_transaction_url}${payment.externalPaymentId}`}
                target="_blank"
                rel="noopener noreferrer meaning"
                style={{ marginLeft: "15px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                  style={{ width: 16, height: "auto" }}
                >
                  <g data-name="99 link-external-01">
                    <path d="M23.8 27H8.2A3.2 3.2 0 0 1 5 23.8V8.2A3.2 3.2 0 0 1 8.2 5h4.5v2H8.2A1.2 1.2 0 0 0 7 8.2v15.6A1.2 1.2 0 0 0 8.2 25h15.6a1.2 1.2 0 0 0 1.2-1.2v-4.3h2v4.3a3.2 3.2 0 0 1-3.2 3.2ZM27 14.9h-2V7h-7.9V5H25a2 2 0 0 1 2 2Z" />
                    <path d="m15.3 15.3 9.3-9.4 1.5 1.4-9.4 9.4z" />
                  </g>
                </svg>
              </a>
            )}
          </td>
          <td>
            {null !== payment.refundedAt
              ? DateUtils.formatISO8601ToFormat(payment.refundedAt)
              : "-"}
            &nbsp;
            <small>
              (
              {null !== payment.refundedAt
                ? DateUtils.formatISO8601ToHumanReadable(payment.createdAt)
                : "-"}
              )
            </small>
          </td>
          <td style={{ textAlign: "center" }}>
            <Badge pill variant={typeBadgeVariant} className="mt-2 mr-2">
              {typeBadgeText}
            </Badge>
          </td>
          <td style={{ textAlign: "center" }}>
            <Badge pill variant={statusBadgeVariant} className="mt-2 mr-2">
              {statusBadgeText}
            </Badge>
          </td>
          <td style={{ textAlign: "right" }}>
            {(payment.amount / 100).toFixed(2)} &euro;
          </td>
          <td>{this.paymentHasError(payment.id)}</td>
        </tr>
      );
    });
  }

  showConfirmDeleteCommentsModal = (commentsId, userName, commentsIndex) => {
    this.setState({
      showCommentsModal: true,
      commentsId,
      userName,
      commentsIndex,
    });
  };

  hideConfirmDeleteCommentsModal = () => {
    this.setState({
      showCommentsModal: false,
      commentsId: null,
      userName: "",
    });
  };

  showSendWelcomeEmailModal = () => {
    this.setState({ showSendEmailModal: true });
  };

  hideSendWelcomeEmailModal = () => {
    this.setState({ showSendEmailModal: false });
  };

  addCommentField = () => {
    this.props.append({ newComment: "" });
    this.setState({ newCommentLimiter: true });
  };

  removeCommentField = (index) => {
    this.props.remove(index);
    this.setState({ newCommentLimiter: false });
  };

  deleteComment = () => {
    let newComments = [...this.state.commentsElement];

    newComments.splice(this.state.commentsIndex, 1);

    this.setState({
      commentsElement: newComments,
    });

    this.props.deleteComment(this.state.commentsId);

    this.setState({
      processingCommentsDelete: false,
      showCommentsModal: false,
    });
  };

  onSubmit = async (data) => {
    if (data.newValue.newComment === "") {
      return;
    }

    let newValue = {};

    if (!data.newValue[0]) {
      newValue = { ...data.newValue, userNewEmail: this.props.user.email };
    } else {
      newValue = { ...data.newValue[0], userNewEmail: this.props.user.email };
    }

    await this.props.postComment(
      this.props.match.params.client_uuid,
      newValue,
      "order"
    );

    if (this.props.commentsSuccess === true) {
      this.setState({ commentsElement: [] });

      await this.getSingleOrderComments(this.props.match.params.client_uuid);

      this.setState({
        commentAdded: false,
        commentsElement: this?.props?.orderComments?.map((comment) => {
          return {
            id: comment.id,
            commentText: comment.commentText,
            createdAt: comment.createdAt,
            userEmail: comment.userEmail,
          };
        }),
      });
    }
  };

  async fetchCustomerJWT(copyLink = false) {
    const customerID =
      this?.props?.singleClient?.customer?.["@id"]?.replace(
        "/api/customers/",
        ""
      ) ?? "";
    const websiteDomain = `${this?.props?.singleClient?.website?.domain}/`;
    let link = null;

    try {
      this.setState({
        processingAutoConnectLink: true,
        autoConnectLinkError: null,
      });
      const response = await http.get(`customers/${customerID}/jwt`);
      link = `${websiteDomain}?t=${btoa(response?.data?.token ?? "")}`;
    } catch (e) {
      this.setState({
        autoConnectLinkError: e.message,
      });
    } finally {
      this.setState({
        processingAutoConnectLink: false,
      });
    }

    if (null === link) {
      return;
    }

    if (false === copyLink) {
      window.open(link, "_blank").focus();
    }

    if (copyLink) {
      if ("clipboard" in navigator) {
        try {
          const allowClipboardAccess = await navigator.permissions.query({
            name: "clipboard-write",
          });

          if (
            allowClipboardAccess.state === "granted" ||
            allowClipboardAccess.state === "prompt"
          ) {
            await navigator.clipboard.writeText(link);
          }
        } catch (e) {
          this.setState({
            autoConnectLinkError: e.message,
          });
        }
      } else {
        const textArea = document.createElement("textarea");
        textArea.value = link;
        textArea.style.position = "fixed";
        textArea.style.left = "-99999999px";

        document.body.appendChild(textArea);

        textArea.select();

        try {
          document.execCommand("copy");
        } catch (e) {
          this.setState({
            autoConnectLinkError: e.message,
          });
        }

        document.body.removeChild(textArea);
      }
    }
  }

  render() {
    let loader = null;

    if (
      this.props.loadingSingleClient ||
      this.props.commentsLoading ||
      this.props.deleteLoading ||
      this.props.createCommentsLoading
    ) {
      loader = <Loader message="Loading..." />;
    }

    return (
      <>
        <div className="page-wrapper">
          {/* Navigation */}
          <Navigation onClick={this._onSideMenu} />
          {/* End Navigation */}

          <div
            className={`main-content d-flex flex-column ${
              this.state.sideMenu ? "" : "hide-sidemenu"
            }`}
          >
            {/* Loader */}
            {loader}
            {/* End Loader */}
            {/* Breadcrumb */}
            <div className="main-content-header">
              <Breadcrumb>
                <h1>Clients</h1>
                <Link
                  to={routerConstants.dashboard}
                  className="breadcrumb-item"
                >
                  Dashboard
                </Link>
                <Link
                  to={routerConstants.listClients}
                  className="breadcrumb-item"
                >
                  {this?.props?.singleClient?.prospectStatus ?? {}
                    ? "Clients"
                    : "Clients"}
                </Link>
                <Breadcrumb.Item active>
                  {this.clientFullName()}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            {/* End Breadcrumb */}
            {this.props.commentsSuccess === true ? (
              <Alert key={"successComment"} variant={"success"}>
                Comment added successfully
              </Alert>
            ) : null}
            {this.props.commentsError !== null ? (
              <Alert key={"errorComment"} variant={"danger"}>
                {this.props.commentsError}
              </Alert>
            ) : null}
            {this.props.deleteSuccess === true ? (
              <Alert key={"successDelete"} variant={"success"}>
                {" "}
                Comment deleted successfully
              </Alert>
            ) : null}
            {this.props.deleteError !== null ? (
              <Alert key={"errorDelete"} variant={"danger"}>
                {this.props.deleteError}
              </Alert>
            ) : null}
            {this.props.singleClientError !== null ? (
              <Alert key={"errorDelete"} variant={"danger"}>
                {this.props.singleClientError}
              </Alert>
            ) : null}
            {this.props.commentsError !== null ? (
              <Alert key={"errorComment"} variant={"danger"}>
                {this.props.commentsError}
              </Alert>
            ) : null}
            {this.state.autoConnectLinkError !== null ? (
              <Alert key={"errorComment"} variant={"danger"}>
                {this.state.autoConnectLinkError}
              </Alert>
            ) : null}
            {this.props.sendWelcomeEmailSuccess === true ? (
              <Alert key={"successEmail"} variant={"success"}>
                Email sent successfully
              </Alert>
            ) : null}
            {this.props.sendWelcomeEmailError !== null ? (
              <Alert key={"errorEmail"} variant={"danger"}>
                {this.props.sendWelcomeEmailError}
              </Alert>
            ) : null}
            <UnsubscribeModal
              show={this.state.showUnsubscribeModal}
              processing={this.state.processingUnsubscription}
              onClose={this.hideUnsubscribeModalCallback}
              onConfirm={this.unsubscribeCustomerCallback}
              customer={this?.props?.singleClient?.customer}
            />
            <RefundPaymentsModal
              show={this.state.showRefundPaymentsModal}
              processing={this.state.processingRefundPayments}
              onClose={this.hideRefundPaymentModal}
              onConfirm={this.refundSelectedPayments}
              paymentIds={this.state.selectedPayments}
            />
            <ReplaceCustomerEmailModal
              show={this.state.showReplaceEmailModal}
              processing={this.state.processingReplaceEmailModal}
              onClose={this.hideReplaceEmailModalCallback}
              onConfirm={this.replaceCustomerEmailCallback}
              customer={this?.props?.singleClient?.customer}
              email={this?.props?.singleClient?.customer?.email}
              emailError={this.state.replaceEmailError}
            />
            <ConfirmDeletComments
              show={this.state.showCommentsModal}
              onConfirm={this.deleteComment}
              onClose={this.hideConfirmDeleteCommentsModal}
              processing={this.state.processingCommentsDelete}
              commentsId={this.state.commentsId}
              userName={this.state.userName}
            />
            <ConfirmSendEmail
              show={this.state.showSendEmailModal}
              onConfirm={this.sendWelcomeEmail}
              onClose={this.hideSendWelcomeEmailModal}
              processing={this.props.sendWelcomeEmailLoading}
              customer={this?.props?.singleClient?.customer}
            />
            <Row>
              <Col lg={6} sm={12}>
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="card-header d-flex justify-content-between align-items-baseline">
                      <h5 className="card-title w-auto float-left">
                        Client Detail
                      </h5>
                      <div
                        className="d-flex flex-row justify-content-end flex-fill"
                        style={{ gap: "0.5rem" }}
                      >
                        {null !==
                          this?.props?.singleClient?.customer
                            ?.externalCustomerId && (
                          <Button
                            variant={"outline-info"}
                            size="xs"
                            className="rounded-pill"
                            href={`https://dashboard.stripe.com/test/customers/${this?.props?.singleClient?.customer?.externalCustomerId}`}
                            target={"_blank"}
                            rel={"noreferrer noopener"}
                          >
                            Stripe Customer Profile
                          </Button>
                        )}
                        <Button
                          variant={
                            this?.props?.singleClient?.status
                              ? "outline-danger"
                              : "outline-secondary"
                          }
                          size="xs"
                          className="rounded-pill"
                          onClick={this.showUnsubscribeModalCallback}
                          disabled={
                            this.state.processingUnsubscription ||
                            !this?.props?.singleClient?.status
                          }
                        >
                          {this.state.processingUnsubscription
                            ? "Processing ..."
                            : "Unsubscribe"}
                        </Button>
                        <Button
                          variant={"outline-warning"}
                          size="xs"
                          className="rounded-pill"
                          as={"button"}
                          onClick={(e) =>
                            this.goToRoute(
                              `/reset-password/${UUIDs.getEntityUUID(
                                this.props.singleClient.customer["@id"]
                              )}`,
                              e
                            )
                          }
                        >
                          Reset Client Password
                        </Button>
                        <Button
                          variant={"outline-primary"}
                          size="xs"
                          className="rounded-pill"
                          as={"button"}
                          onClick={this.showSendWelcomeEmailModal}
                        >
                          Send Back Welcome Email
                        </Button>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant={"outline-primary"}
                            id="connectLinkDD"
                            className="rounded-pill"
                          >
                            Connect Link
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={async () =>
                                await this.fetchCustomerJWT(true)
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                style={{
                                  display: "inline-block",
                                  width: "0.9rem",
                                  marginRight: "5px",
                                }}
                              >
                                <path
                                  fill="#2962FF"
                                  d="M468.5 101.6 372 5.1H159.6v77.2H43.7v424.8h309v-77.2h115.8V101.6zM372 32.4l69.2 69.2H372V32.4zM63 487.8V101.6h173.8v96.6h96.5v289.6H63zm193.1-378.2 69.2 69.3h-69.2v-69.3zm96.5 301V178.9l-96.5-96.6H179V24.4h173.7V121h96.6v289.7h-96.6z"
                                />
                              </svg>
                              {this.state.processingAutoConnectLink
                                ? "Processing ..."
                                : "Copy Link"}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>

                    <dl className="customer-property-list">
                      <dt>Email Address</dt>
                      <dd className="edit_email">
                        {this?.props?.singleClient?.customer?.email}
                        {this.state.isAdminUser && (
                          <Edit3 onClick={this.showReplaceEmailModalCallback} />
                        )}
                      </dd>
                      <dt>Website</dt>
                      <dd>
                        <a
                          href={`//${this?.props?.singleClient?.website?.domain}`}
                          target="_blank"
                          rel="nofollow noreferrer"
                        >
                          {this?.props?.singleClient?.website?.domain}
                        </a>
                      </dd>
                      <dt>Order ID</dt>
                      <dd>{this?.props?.singleClient?.order_number}</dd>
                      <dt>Order Date</dt>
                      <dd>
                        {DateUtils.formatISO8601ToFormat(
                          this?.props?.singleClient?.createdAt
                        )}
                        &nbsp;
                        <small>
                          (
                          {DateUtils.formatISO8601ToHumanReadable(
                            this?.props?.singleClient?.createdAt
                          )}
                          )
                        </small>
                      </dd>
                      <dt>Subscription Started Date</dt>
                      <dd>
                        {DateUtils.formatISO8601ToFormat(
                          this?.props?.singleClient?.subscriptionStart
                        )}
                        &nbsp;
                        <small>
                          (
                          {DateUtils.formatISO8601ToHumanReadable(
                            this?.props?.singleClient?.subscriptionStart
                          )}
                          )
                        </small>
                      </dd>
                      <dt>Product Subscription</dt>
                      <dd>
                        {this?.props?.singleClient?.productSubscription?.name ??
                          "-"}
                      </dd>
                      {this?.props?.singleClient?.subscriptionEnd !== null ? (
                        <>
                          <dt>Subscription End</dt>
                          <dd>
                            {DateUtils.formatISO8601ToFormat(
                              this?.props?.singleClient?.subscriptionEnd
                            )}
                            &nbsp;
                            <small>
                              (
                              {DateUtils.formatISO8601ToHumanReadable(
                                this?.props?.singleClient?.subscriptionEnd
                              )}
                              )
                            </small>
                          </dd>
                        </>
                      ) : null}
                      <dt>Order Status</dt>
                      <dd style={{ display: "block" }}>
                        <Badge
                          pill
                          variant={
                            this?.props?.singleClient?.status
                              ? "success"
                              : "danger"
                          }
                          className="mt-2 mr-2"
                        >
                          {this?.props?.singleClient?.status
                            ? "Active"
                            : "Inactive"}
                        </Badge>
                        {null !== this?.props?.singleClient?.actionName && (
                          <div style={{ marginTop: "5px", fontWeight: "500" }}>
                            {this?.props?.singleClient?.actionName}
                          </div>
                        )}
                      </dd>
                      <dt>Payment Gateway</dt>
                      <dd>
                        {this?.props?.singleClient?.paymentGateway?.slug ?? "-"}
                        <br />
                        {this?.props?.singleClient?.paymentGateway?.name ?? "-"}
                      </dd>
                      <dt>Customer IP</dt>
                      <dd>{this?.props?.singleClient?.customer?.ip}</dd>
                      <dt>User Agent</dt>
                      <dd>{this?.props?.singleClient?.customer?.userAgent}</dd>
                    </dl>
                  </div>
                </div>
              </Col>
              <Col lg={6} sm={12}>
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="card-header d-flex">
                      <h5 className="card-title w-50 float-left">
                        Order Details
                      </h5>
                    </div>

                    <dl className="customer-property-list text-uppercase">
                      {this.getCustomerInfo().map((item) => {
                        return [
                          <dt key={item.title + "-1"}>{item.title}</dt>,
                          <dd key={item.title + "-2"}>{item.value}</dd>,
                        ];
                      })}
                    </dl>
                  </div>
                </div>
              </Col>
            </Row>
            {this.props.refundError !== null ? (
              <Alert key={"errorDelete"} variant={"danger"}>
                {this.props.refundError}
              </Alert>
            ) : null}
            <Row>
              <Col lg={12}>
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="card-header d-flex justify-content-between align-items-baseline">
                      <h5 className="card-title w-50 float-left">Payments</h5>
                      <div>
                        <Button
                          variant={
                            0 < this.state.selectedPayments.length
                              ? "outline-danger"
                              : "outline-secondary"
                          }
                          size="xs"
                          className="rounded-pill"
                          onClick={this.showRefundPaymentModal}
                          disabled={
                            this.state.processingRefunding ||
                            0 === this.state.selectedPayments.length
                          }
                        >
                          {this.state.processingRefunding
                            ? "Processing ..."
                            : "Refund"}
                        </Button>
                      </div>
                    </div>

                    <Table className="m-0" responsive>
                      <thead>
                        <tr key="table_headers">
                          <th>&nbsp;</th>
                          <th>Completed At</th>
                          <th>Refunded At</th>
                          <th width={"20%"} style={{ textAlign: "center" }}>
                            Type
                          </th>
                          <th width={"20%"} style={{ textAlign: "center" }}>
                            Status
                          </th>
                          <th width={"20%"} style={{ textAlign: "right" }}>
                            Amount
                          </th>
                          <th>Error Message</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.renderPayments(
                          null !==
                            this?.props?.singleClient?.customer
                              ?.externalCustomerId
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="card-header d-flex">
                      <h5 className="card-title w-50 float-left">Purchases</h5>
                    </div>

                    <Table className="m-0" responsive>
                      <thead>
                        <tr key="table_headers">
                          <th width={"25%"}>Requested</th>
                          <th>Document Type</th>
                          <th width={"25%"}>Purchase Type</th>
                          <th width={"25%"}>Status</th>
                          <th width={"25%"}>Comments</th>
                        </tr>
                      </thead>
                      <tbody>{this.renderPurchases()}</tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="card-header d-flex justify-content-between align-items-baseline">
                      <h5 className="card-title w-50 float-left">Comments</h5>
                      <div>
                        {this.state.newCommentLimiter === false &&
                        this.state.commentsElement.length !== 0 ? (
                          <Button
                            variant="danger"
                            size="sm"
                            className="rounded-pill"
                            onClick={() => this.addCommentField()}
                          >
                            New Comment
                          </Button>
                        ) : null}
                      </div>
                    </div>
                    <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                      <Table className="m-0" responsive>
                        <thead>
                          <tr key="comments_section">
                            <th>
                              <Badge variant="secondary">Comments</Badge>
                            </th>
                            <th width={"220px"}>Date</th>
                            <th width={"170px"}>
                              <Badge variant="secondary">Name</Badge>
                            </th>
                            <th width={"95px"}>
                              <Badge variant="secondary">Actions</Badge>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this?.state?.commentsElement?.length === 0 ? (
                            <tr>
                              <td>
                                <textarea
                                  id="commentText"
                                  type="text"
                                  rows="4"
                                  cols="50"
                                  className="form-control"
                                  name={`comment`}
                                  {...this.props.register(
                                    `newValue.newComment`
                                  )}
                                />
                              </td>
                              <td>
                                {DateUtils.formatISO8601ToFormat(new Date())}
                              </td>
                              <td>{this.props.user.email}</td>
                            </tr>
                          ) : null}
                          {this?.state?.commentsElement?.map(
                            (comment, index) => {
                              this.props.setValue(
                                `comment[${index}].text`,
                                comment.commentText
                              );

                              return (
                                <tr key={comment.id}>
                                  <td>
                                    <textarea
                                      id="commentText"
                                      type="text"
                                      rows="4"
                                      cols="50"
                                      className="form-control"
                                      name={`comment[${index}]`}
                                      key={comment.id}
                                      readOnly
                                      {...this.props.register(
                                        `comment[${index}].text`
                                      )}
                                    />
                                  </td>
                                  <td>
                                    {DateUtils.formatISO8601ToFormat(
                                      comment["createdAt"]
                                    )}
                                  </td>
                                  <td>{comment.userEmail}</td>
                                  <td>
                                    <div>
                                      <Dropdown
                                        as={ButtonGroup}
                                        size="sm"
                                        className="d-inline-block mr-2 mt-2"
                                      >
                                        <Button
                                          variant="outline-primary"
                                          as={"button"}
                                        >
                                          Action
                                        </Button>
                                        <Dropdown.Toggle
                                          split
                                          variant="primary"
                                          id="dropdown-split-basic"
                                        />
                                        <Dropdown.Menu
                                          popperConfig={{ strategy: "fixed" }}
                                        >
                                          <Dropdown.Item
                                            onClick={() =>
                                              this.showConfirmDeleteCommentsModal(
                                                comment.id,
                                                comment.userEmail,
                                                index
                                              )
                                            }
                                          >
                                            Delete
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          {this.props.fields.map((item, index) => {
                            return this.state.commentAdded ? (
                              <tr key={item.id}>
                                <td>
                                  <textarea
                                    rows="4"
                                    cols="50"
                                    className="form-control"
                                    name={`newValue[${index}].newComment`}
                                    {...this.props.register(
                                      `newValue[${index}].newComment`
                                    )}
                                  />
                                </td>
                                <td>
                                  {DateUtils.formatISO8601ToFormat(new Date())}
                                </td>
                                <td>{this.props.user.email}</td>
                                <td>
                                  <div>
                                    <Trash
                                      onClick={() => this.removeCommentField()}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ) : null;
                          })}
                        </tbody>
                      </Table>
                      <Button
                        variant="primary"
                        onClick={this.props.handleSubmit(this.onSubmit)}
                        className="mr-10"
                        size="sm"
                      >
                        Submit Comment
                      </Button>
                    </form>
                  </div>
                </div>
              </Col>
            </Row>
            {/* Footer */}
            <div className="flex-grow-1" />
            <Footer />
            {/* End Footer */}
          </div>
        </div>
      </>
    );
  }
}

const ListSingleClientPage = (props) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "newValue",
  });

  return (
    <ListSingleClient
      {...props}
      register={register}
      handleSubmit={handleSubmit}
      setValue={setValue}
      errors={errors}
      control={control}
      reset={reset}
      fields={fields}
      append={append}
      remove={remove}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    singleClient: state.clients.singleClient,
    singleClientError: state.clients.singleClientError,
    refundError: state.clients.refundError,
    loadingSingleClient: state.clients.loadingSingleClient,
    orderPayments: state.clients.orderPayments,
    user: state.user.me,
    orderComments: state.clients.clientCommentData,
    commentsError: state.clients.clientCommentError,
    commentsLoading: state.clients.clientCommentLoading,
    commentsSuccess: state.comments.createCommentsSuccess,
    deleteSuccess: state.comments.deleteCommentsSuccess,
    deleteError: state.comments.deleteCommentsError,
    createCommentsLoading: state.comments.createCommentsLoading,
    deleteLoading: state.comments.deleteCommentsLoading,
    sendWelcomeEmailSuccess: state.clients.sendWelcomeEmailSuccess,
    sendWelcomeEmailError: state.clients.sendWelcomeEmailError,
    sendWelcomeEmailLoading: state.clients.sendWelcomeEmailLoding,
  };
};

const ListSingleClientConnected = connect(mapStateToProps, {
  getSingleClient,
  setSingleClient,
  unsubscribeClient,
  getOrderPayments,
  refundPayments,
  setNewEmailAddress,
  getSingleOrderComments,
  postComment,
  deleteComment,
  sendBackWelcomeEmail,
})(ListSingleClientPage);

export default withRouter(ListSingleClientConnected);
