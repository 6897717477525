import React                           from "react";
import {Link, Redirect}                from "react-router-dom";
import {Row, Col, Form, Button, Alert} from "react-bootstrap";
import routerConstants                 from "../constants/router-constants";
import {connect}                       from "react-redux";
import {password_reset}                from "../redux/User/user.async-actions";

class ResetPassword extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            password         : "",
            password_repeat  : "",
            password_not_same: false,
            strong_password  : true
        };

        this.setPassword        = this.setPasswordCallback.bind(this);
        this.setPasswordRepeat  = this.setPasswordRepeatCallback.bind(this);
        this.passwordValidation = this.passwordValidationCallback.bind(this);
        this.passwordStrength   = this.passwordStrengthCallback.bind(this);
    }

    onForgotHandler = async e => {
        e.preventDefault();

        const password = this.state.password;

        this.setState(
            {
                password         : "",
                password_repeat  : "",
                password_not_same: false,
                strong_password  : true
            }
        );

        await this.props.password_reset(
            password,
            this.props.match.params.token,
            this.props.match.params.user_uuid
        );
    };

    setPasswordCallback(e) {
        this.setState(
            {
                password: e.target.value
            }
        );
    }

    setPasswordRepeatCallback(e) {
        this.setState(
            {
                password_repeat: e.target.value
            }
        );
    }

    passwordStrengthCallback() {
        this.setState(
            {
                strong_password: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(this.state.password)
            }
        );
    }

    passwordValidationCallback() {
        if ("" === this.state.password_repeat) {
            return;
        }

        this.setState(
            {
                password_not_same: this.state.password !== this.state.password_repeat
            }
        );
    }

    render() {
        if (this.props.authenticated) {
            return <Redirect to={routerConstants.dashboard} />;
        }

        return (
            <div className="auth-main-content auth-password-reset">
                <div className="d-table">
                    <div className="d-tablecell">
                        <div className="auth-box">
                            <Row>
                                <Col md={12}>
                                    <div className="form-content">
                                        <h1 className="heading">Reset password</h1>

                                        {
                                            true === this.state.password_not_same &&
                                            <Alert variant="danger">
                                                Your password doesn't match.
                                            </Alert>
                                        }

                                        {
                                            false !== this.props.new_password_reset_error &&
                                            <Alert variant="danger">
                                                {this.props.new_password_reset_error}
                                            </Alert>
                                        }

                                        {
                                            false !== this.props.new_password_reset_success &&
                                            <Alert variant="success">
                                                {this.props.new_password_reset_success}
                                            </Alert>
                                        }

                                        {
                                            false === this.state.strong_password &&
                                            <Alert variant="danger">
                                                Your password is not strong enough. Please enter at least 8 characters,
                                                and the password should contain at least one lowercase letter, one
                                                uppercase letter, one digit and one special character
                                            </Alert>
                                        }

                                        <Form onSubmit={this.onForgotHandler}>
                                            <Form.Group>
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    minLength="8"
                                                    disabled={this.props.sending_new_password_reset}
                                                    value={this.state.password}
                                                    onChange={this.setPassword}
                                                    onBlur={this.passwordStrength}
                                                />
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Label>Password Repeat</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    minLength="8"
                                                    disabled={this.props.sending_new_password_reset}
                                                    value={this.state.password_repeat}
                                                    onChange={this.setPasswordRepeat}
                                                    onKeyUp={this.passwordValidation}
                                                />
                                            </Form.Group>

                                            <div className="text-center">
                                                <Button
                                                    variant="primary"
                                                    type="submit"
                                                    disabled={this.props.sending_new_password_reset}
                                                >
                                                    Set New Password
                                                </Button>

                                                <Link to={routerConstants.login} className="fp-link">Log In</Link>
                                            </div>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        sending_new_password_reset: state.user.sending_new_password_reset,
        new_password_reset_error  : state.user.new_password_reset_error,
        new_password_reset_success: state.user.new_password_reset_success
    };
};

export default connect(
    mapStateToProps,
    {
        password_reset
    }
)(ResetPassword);