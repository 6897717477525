import http from "../../services/http";
import { 
    setCreateCommentsError,
    setCreateCommentsSuccess,
    setCreateCommentLoading,
    setDeleteCommentsError,
    setDeleteCommentsSuccess,
    setDeleteCommentLoading,
    setUpdateCommentsError,
    setUpdateCommentsSuccess,
    setUpdateCommentLoading
}           from "./comments.actions";

export const postComment = (postId, comment, target) => {
    return async dispatch => {
        dispatch(setCreateCommentsSuccess(false));
        dispatch(setCreateCommentsError(null));
        dispatch(setCreateCommentLoading(true));

        try {
            await http.post(
                `comments`,
                {   
                    userEmail  : comment.userNewEmail,
                    commentText: comment.newComment,
                    ...(target === 'order' ? {"orders": `api/orders/${postId}`} : {"purchase": `api/purchases/${postId}`})
                }
            );
            dispatch(setCreateCommentLoading(false));
            dispatch(setCreateCommentsSuccess(true));
        } catch (e) {
            dispatch(setCreateCommentsError(e.message));
            dispatch(setCreateCommentLoading(false));
        } finally {
            setTimeout(() => {
                dispatch(setCreateCommentsSuccess(false));
                dispatch(setCreateCommentsError(null));
            }, 3000);
        }
    }
};

export const deleteComment = (commentId) => {
    return async dispatch => {
        dispatch(setDeleteCommentsSuccess(false));
        dispatch(setDeleteCommentsError(null));
        dispatch(setDeleteCommentLoading(true));

        try {
            await http.delete(
                `comments/${commentId}`
            );
            dispatch(setDeleteCommentsSuccess(true));
            dispatch(setDeleteCommentLoading(false));
        } catch (e) {
            dispatch(setDeleteCommentsError(e.message));
        } finally {
            setTimeout(() => {
                dispatch(setDeleteCommentsSuccess(false));
                dispatch(setDeleteCommentsError(null));
            }, 3000);
        }
    }
}

export const updateComment = (commentId, commentText, userMail) => {
    return async dispatch => {
        dispatch(setUpdateCommentsSuccess(false));
        dispatch(setUpdateCommentsError(null));
        dispatch(setUpdateCommentLoading(true));
        try {
            await http.put(
                `comments/${commentId}`,
                {   
                    userEmail  : userMail,
                    commentText: commentText
                }
            );
            dispatch(setUpdateCommentLoading(false));
            dispatch(setUpdateCommentsSuccess(true));
        } catch (e) {
            dispatch(setUpdateCommentsError(e.message));
            dispatch(setUpdateCommentLoading(false));
        } finally {
            setTimeout(() => {
                dispatch(setUpdateCommentsSuccess(false));
                dispatch(setUpdateCommentsError(null));
            }, 3000);
        }
    }
}
