import {
    CLEAR_ACCESS_TOKEN,
    CLEAR_AUTHENTICATION_ERROR,
    CLEAR_NEW_PASSWORD_ERROR,
    CLEAR_NEW_PASSWORD_SUCCESS,
    CLEAR_PASSWORD_RESET_ERROR,
    CLEAR_PASSWORD_RESET_SUCCESS,
    CLEAR_REFRESH_TOKEN,
    CLEAR_USER_DATA,
    SET_ACCESS_TOKEN,
    SET_AUTHENTICATION_ERROR,
    SET_NEW_PASSWORD_ERROR,
    SET_NEW_PASSWORD_SUCCESS,
    SET_PASSWORD_RESET_ERROR,
    SET_PASSWORD_RESET_SUCCESS,
    SET_PROCESSING,
    SET_REFRESH_TOKEN,
    SET_SENDING_NEW_PASSWORD_RESET,
    SET_SENDING_PASSWORD_RESET_REQUEST,
    SET_USER_DATA,
    SET_USERS_LIST,
    SET_USERS_LIST_ERROR,
    SET_SUCCESS_CREATE_USER,
    SET_ERROR_CREATE_USER,
    SET_LOADING_CREATE_USER,
    SET_SINGLE_USER,
    SET_SINGLE_USER_ERROR,
    SET_UPDATE_USER_ERROR,
    SET_UPDATE_USER_SUCCESS,
    SET_LOADING_UPDATE_USER,
    SET_DELETE_USER_ERROR,
    SET_DELETE_USER_SUCCESS,
    SET_DELETE_USER_LOADING,
    SET_MEILI_SEARCH_TOKEN,
    CLEAR_MEILI_SEARCH_TOKEN
}               from "./user.types";
import JsCookie from "js-cookie";

const INITIAL_STATE = {
    me                            : typeof undefined !== typeof JsCookie.get("ud") ? JSON.parse(atob(JsCookie.get("ud"))) : {},
    access_token                  : typeof undefined !== typeof JsCookie.get("at") ? JsCookie.get("at") : "",
    refresh_token                 : typeof undefined !== typeof JsCookie.get("rt") ? JsCookie.get("rt") : "",
    meilisearch_token             : typeof undefined !== typeof JsCookie.get("mt") ? JsCookie.get("mt") : "",
    authenticated                 : typeof undefined !== typeof JsCookie.get("at"),
    processing                    : false,
    authentication_error          : false,
    sending_password_reset_request: false,
    password_reset_request_error  : false,
    password_reset_request_success: false,
    sending_new_password_reset    : false,
    new_password_reset_error      : false,
    new_password_reset_success    : false,
    usersList                     : [],
    usersListError                : null,
    successCreateUser             : false,
    errorCreateUser               : null,
    loadingCreateUser             : false,
    singleUser                    : [],
    singleUserError               : null,
    updateUserSuccess             : false,
    updateUserError               : null,
    loadingUpdateUser             : false,
    deleteUserSuccess             : false,
    deleteUserError               : null,
    deleteUserLoading             : false
};

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_USER_DATA:
            return {
                ...state,
                me: action.payload
            };
        case CLEAR_USER_DATA:
            return {
                ...state,
                me: {}
            };
        case SET_ACCESS_TOKEN:
            return {
                ...state,
                access_token : action.payload,
                authenticated: true
            };
        case CLEAR_ACCESS_TOKEN:
            return {
                ...state,
                access_token : "",
                authenticated: false
            };
        case SET_REFRESH_TOKEN:
            return {
                ...state,
                refresh_token: action.payload,
                authenticated: true
            };
        case SET_MEILI_SEARCH_TOKEN:
            return {
                ...state,
                meilisearch_token: action.payload
            };
        case CLEAR_MEILI_SEARCH_TOKEN:
            return {
                ...state,
                meilisearch_token: ""
            };
        case CLEAR_REFRESH_TOKEN:
            return {
                ...state,
                refresh_token: "",
                authenticated: false
            };
        case SET_PROCESSING:
            return {
                ...state,
                processing: action.payload
            };
        case SET_AUTHENTICATION_ERROR:
            return {
                ...state,
                authentication_error: action.payload
            };
        case CLEAR_AUTHENTICATION_ERROR:
            return {
                ...state,
                authentication_error: false
            };
        case SET_SENDING_PASSWORD_RESET_REQUEST:
            return {
                ...state,
                sending_password_reset_request: action.payload
            };
        case SET_PASSWORD_RESET_ERROR:
            return {
                ...state,
                password_reset_request_error: action.payload
            };
        case CLEAR_PASSWORD_RESET_ERROR:
            return {
                ...state,
                password_reset_request_error: action.payload
            };
        case SET_PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                password_reset_request_success: action.payload
            };
        case CLEAR_PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                password_reset_request_success: action.payload
            };

        case SET_SENDING_NEW_PASSWORD_RESET:
            return {
                ...state,
                sending_new_password_reset: action.payload
            };
        case SET_NEW_PASSWORD_ERROR:
            return {
                ...state,
                new_password_reset_error: action.payload
            };
        case CLEAR_NEW_PASSWORD_ERROR:
            return {
                ...state,
                new_password_reset_error: false
            };
        case SET_NEW_PASSWORD_SUCCESS:
            return {
                ...state,
                new_password_reset_success: action.payload
            };
        case CLEAR_NEW_PASSWORD_SUCCESS:
            return {
                ...state,
                new_password_reset_success: false
            };
        case SET_USERS_LIST:
            return {
                ...state,
                usersList: action.payload
            };
        case SET_USERS_LIST_ERROR:
            return {
                ...state,
                usersListError: action.payload
            };
        case SET_SUCCESS_CREATE_USER:
            return {
                ...state,
                successCreateUser: action.payload
            };
        case SET_ERROR_CREATE_USER:
            return {
                ...state,
                errorCreateUser: action.payload
            };
        case SET_LOADING_CREATE_USER:
            return {
                ...state,
                loadingCreateUser: action.payload
            };
        case SET_SINGLE_USER:
            return {
                ...state,
                singleUser: action.payload
            };
        case SET_SINGLE_USER_ERROR:
            return {
                ...state,
                singleUserError: action.payload
            };
        case SET_UPDATE_USER_ERROR:
            return {
                ...state,
                updateUserError: action.payload
            };
        case SET_UPDATE_USER_SUCCESS:
            return {
                ...state,
                updateUserSuccess: action.payload
            };
        case SET_LOADING_UPDATE_USER:
            return {
                ...state,
                loadingUpdateUser: action.payload
            };
        case SET_DELETE_USER_ERROR:
            return {
                ...state,
                deleteUserError: action.payload
            };
        case SET_DELETE_USER_SUCCESS:
            return {
                ...state,
                deleteUserSuccess: action.payload
            };
        case SET_DELETE_USER_LOADING:
            return {
                ...state,
                deleteUserLoading: action.payload
            };
        default:
            return state;
    }
};

export default userReducer;
