import http from "../../services/http";
import { 
    setMarketList, 
    setLoadingMarket,
    setMarketError
}           from "./market.actions";

export const getMarketList = () => {
    return async dispatch => {
        dispatch(setLoadingMarket(true));
        dispatch(setMarketError(null))

        try {
            const response = await http.get(`markets`)
            const data = response.data;

            dispatch(setMarketList(data["hydra:member"]))
        } catch(e) {
            dispatch(setMarketError(e.message));
        } finally {
            dispatch(setLoadingMarket(false));
        }
    }
}
