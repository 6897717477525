import {
    LOADING_WEBSITES_DOMAINS_AND_IDS,
    SET_WEBSITES_DOMAINS_AND_IDS, SET_WEBSITES_DOMAINS_AND_IDS_ERROR,
    SET_WEBSITES,
    SET_LOADING_WEBSITES,
    LOADING_UPDATE,
    SINGLE_WEBSITE_LOADING,
    SET_SINGLE_WEBSITE,
    CREATE_WEBSITES_LOADING,
    WEBSITE_FORM_SUCCESS,
    WEBSITE_FORM_FAIL
} from "./websites.types";

const INITIAL_STATE = {
    domains_and_ids        : [],
    loading_domains_and_ids: false,
    domains_and_ids_error  : null,
    set_websites           : [],
    set_loading_websites   : false,
    loading_update         : false,
    single_website_loading : false,
    set_single_website     : [],
    create_websites_loading: false,
    website_form_success   : null,
    website_form_fail      : null,

};

const websitesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOADING_WEBSITES_DOMAINS_AND_IDS:
            return {
                ...state,
                loading_domains_and_ids: action.payload
            };
        case SET_WEBSITES_DOMAINS_AND_IDS:
            return {
                ...state,
                domains_and_ids: action.payload
            };
        case SET_WEBSITES_DOMAINS_AND_IDS_ERROR:
            return {
                ...state,
                domains_and_ids_error: action.payload
            };
        case SET_WEBSITES:
            return {
                ...state,
                set_websites: action.payload
            }
        case SET_LOADING_WEBSITES:
            return {
                ...state,
                set_loading_websites: action.payload
            }
        case LOADING_UPDATE:
            return {
                ...state,
                loading_update: action.payload
            }
        case SINGLE_WEBSITE_LOADING:
            return {
                ...state,
                single_website_loading: action.payload
            }
        case SET_SINGLE_WEBSITE:
            return {
                ...state,
                set_single_website: action.payload
            }
        case CREATE_WEBSITES_LOADING:
            return {
                ...state,
                create_websites_loading: action.payload
            }
        case WEBSITE_FORM_SUCCESS:
            return {
                ...state,
                website_form_success: action.payload
            }
        case WEBSITE_FORM_FAIL:
            return {
                ...state,
                website_form_fail: action.payload
            }
        default:
            return state;
    }
};

export default websitesReducer;
