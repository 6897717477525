import React from 'react';
import { MetaTags } from 'react-meta-tags';
import { connect } from 'react-redux';

class MetaComponents extends React.Component {
    render() {
        return <>
            <MetaTags>
                <title>({this.props.totalPendingInProgressTransactions}) - BO</title>
            </MetaTags>
        </>
    }
}

const mapStateToProps = state => {
    return {
        totalPendingInProgressTransactions: state.purchases.totalPendingInProgressTransactions,
    }
}

export default connect(mapStateToProps)(MetaComponents);
