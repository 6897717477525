import React, {
	useEffect,
	useState
}								 from 'react';
import {
	useSelector,
	useDispatch
} 							     from "react-redux";
import {setResetFilterPurchases} from "../../redux/Global/global.actions"
import '../../assets/css/custom/components/mulstiSelect.scss';

const MultiSelect = ({
		 servicesRef,
		 serviceData,
		 onChangeSelect,
		 tabIndex
	 }) => {

	const dispatch = useDispatch();
	const resetFilter = useSelector(state => state.globalState.resetFilterPurchases);
	const [isOpen, setIsOpen] = useState(false);
	const [selectedOptions, setSelectedOptions] = useState({
		pending: [],
		askInformation: [],
		hold: [],
		delivered: []
	});

	useEffect(() => {
		if (servicesRef && servicesRef.current) {
			servicesRef.current.selectedOptions = selectedOptions[tabIndex] || [];
		}
	}, [selectedOptions, servicesRef, tabIndex]);

	useEffect(() => {
		if (resetFilter) {
			setSelectedOptions({
				pending: [],
				askInformation: [],
				hold: [],
				delivered: []
			});
			dispatch(setResetFilterPurchases(false));
		}
	}, [resetFilter]);

	const toggleOptions = () => {
		setIsOpen(!isOpen);
	};

	const removeSelectedOption = (option) => {
		setSelectedOptions(prevOptions => ({
			...prevOptions,
			[tabIndex]: prevOptions[tabIndex].filter(selectedOption => selectedOption !== option)
		}));

		onChangeSelect(selectedOptions[tabIndex].filter(selectedOption => selectedOption !== option));
	};

	const handleCheckboxChange = (event) => {
		const value = event.target.value;

		setSelectedOptions(prevOptions => {
			const updatedOptions = prevOptions[tabIndex].includes(value)
				? prevOptions[tabIndex].filter(option => option !== value)
				: [...prevOptions[tabIndex], value];
			onChangeSelect(updatedOptions);

			return {
				...prevOptions,
				[tabIndex]: updatedOptions
			};
		});
	};

	const alphabeticallyData = serviceData.sort((a, b) => a.slug.localeCompare(b.slug));

	return (
		<div className="select-container">
			<div className="select-box" onClick={toggleOptions}>
				<div className="selected-items">
					{selectedOptions[tabIndex].length === 0 ? (
						<span className="placeholder">Service</span>
					) : (
						selectedOptions[tabIndex].map((option, index) => (
							<div key={index} className="selected-item">
								{option}
								<span className="remove-item" onClick={() => removeSelectedOption(option)}>X</span>
							</div>
						))
					)}
				</div>
			</div>
			{isOpen && (
				<div className="options-container">
					{alphabeticallyData.map((option, index) => {
						if (-1 !== ["classica", "classica_plus"].indexOf(option.slug)) {
							return null;
						}

						return <div key={index} className="option">
								<input
									ref={servicesRef}
									type="checkbox"
									id={`option-${index}`}
									value={option.slug}
									checked={selectedOptions[tabIndex].includes(option.slug)}
									onChange={handleCheckboxChange}
								/>
							<label htmlFor={`option-${index}`}>{option.slug}</label>
						</div>
					})}
				</div>
			)}
		</div>
	);
};

export default MultiSelect;
