import React        from "react";
import {Pagination} from "react-bootstrap";

const Paginator = props => {
    const howMany     = 2;
    const currentPage = props.current;
    const firstPage   = props.first;
    const lastPage    = props.last;
    const prevPage    = props.prev;
    const nextPage    = props.next;

    const getPageNumber = page => {
        const result     = /page\=(?<page>\d+)/.exec(page);
        const pageNumber = result?.groups?.page ?? page;

        return pageNumber ? parseInt(pageNumber, 10) : null;
    };

    const currentPageNumber = getPageNumber(currentPage);
    const firstPageNumber   = getPageNumber(firstPage);
    const lastPageNumber    = getPageNumber(lastPage);
    const prevPageNumber    = getPageNumber(prevPage);
    const nextPageNumber    = getPageNumber(nextPage);

    if (lastPageNumber <= 1) {
        return "";
    }

    const handlePageChange = event => {
        let target = event.target;

        if ("SPAN" === target.tagName) {
            target = target.parentElement;
        }

        if (typeof undefined !== typeof target.dataset.page) {
            props.onClickFirst(target.dataset.page);
        }
    };

    const printIntermediatePages = () => {
        const pages = [];

        for (let pageIndex = currentPageNumber - howMany; pageIndex <= currentPageNumber + howMany; pageIndex++) {
            if (pageIndex <= 0 || pageIndex > lastPageNumber) {
                continue;
            }
            if (pageIndex === currentPageNumber) {
                pages.push(
                    (<Pagination.Item key={pageIndex} data-page={pageIndex} disabled>{pageIndex}</Pagination.Item>)
                );
            } else {
                pages.push(
                    (<Pagination.Item
                        key={pageIndex}
                        data-page={pageIndex}
                        onClick={handlePageChange}
                    >{pageIndex}</Pagination.Item>)
                );
            }
        }

        return pages;
    };

    return <div className="text-center">
        <Pagination className="d-inline-flex border-radius-fl-item mt-4 mb-0">
            <Pagination.First data-page={firstPageNumber} onClick={handlePageChange} />
            <Pagination.Prev data-page={prevPageNumber} onClick={handlePageChange} />
            {currentPageNumber > howMany + 1 ? <Pagination.Ellipsis disabled /> : undefined}
            {printIntermediatePages().map(page => page)}
            {currentPageNumber < lastPageNumber - howMany ? <Pagination.Ellipsis disabled /> : undefined}
            <Pagination.Next data-page={nextPageNumber} onClick={handlePageChange} />
            <Pagination.Last data-page={lastPageNumber} onClick={handlePageChange} />
        </Pagination>
        <br />
        <small className="text-muted">(Page {currentPageNumber} of {lastPageNumber})</small>
    </div>;
};

Paginator.defaultProps = {
    first  : "",
    last   : "",
    prev   : "",
    next   : "",
    current: ""
};

export default Paginator;
