import React           from "react";
import Navigation      from "../../../components/Navigation/Navigation";
import {
    Breadcrumb,
    Button,
    Col,
    Row,
    Table
}                      from "react-bootstrap";
import Footer          from "../../Footer/Footer";
import {connect}       from "react-redux";
import {
    Link,
    withRouter
}                      from "react-router-dom";
import routerConstants from "../../../constants/router-constants";
import Loader          from "../../../components/Common/Loader";
import UUIDs           from "../../../services/UUIDs";
import {getWebsites}   from "../../../redux/Websites/websites.async-actions";
import websiteThemes   from "./website-themes.json";
import ListSorter      from "../../../Utils/ListSorter";

class ListWebsite extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sideMenu: true
        };
    }

    _onSideMenu = (active) => {
        this.setState({sideMenu: active});
    };

    goToRoute(route) {
        this.props.history.push(route);
    }

    // Loading icon false after DOM loaded
    componentDidMount() {
        this.props.getWebsites();
    }

    mapWebsites = () => ListSorter.orderListByProperty(
            this.props.websites, 'domain'
        ).map(
            websites => {
                const websiteId = websites["@id"];

                return (<tr key={websites.id}>
                    <td>
                        {websites.domain}
                    </td>
                    <td>
                        {websites.company.name}
                    </td>
                    <td>
                        {websites.market.name}
                    </td>
                    <td>
                        {websites.locale[0].name}
                    </td>
                    <td>
                        {websites.descriptor}
                    </td>
                    <td>
                        {websiteThemes?.[websites.theme] ?? "-"}
                    </td>
                    <td>
                        {websites.updatedAt}
                    </td>
                    <td>
                        <Button
                            variant="outline-primary"
                            as={"button"}
                            onClick={e => this.goToRoute(`/list-websites/update/${UUIDs.getEntityUUID(
                                websiteId)}`, e)}
                        >
                            Edit
                        </Button>
                    </td>
                </tr>
            );
        }
    );

    render() {
        let loader = null;

        if (this.props.loadingWebsite) {
            loader = <Loader message="Loading..." />;
        }

        return <>
            <div className="page-wrapper">
                {/* Navigation */}
                <Navigation onClick={this._onSideMenu} />
                {/* End Navigation */}

                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? "" : "hide-sidemenu"}`}>

                    {/* Loader */}
                    {loader}
                    {/* End Loader */}

                    {/* Breadcrumb */}
                    <div className="main-content-header">
                        <Breadcrumb>
                            <Link to={routerConstants.dashboard} className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Breadcrumb.Item active>
                                Website List
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    {/* End Breadcrumb */}

                    {true === this.props.successMessage &&
                        <div className="alert alert-success" role="alert">
                            Success
                        </div>
                    }

                    {true === this.props.failMessage &&
                        <div className="alert alert-danger" role="alert">
                            Fail
                        </div>
                    }

                    <Row>
                        <Col lg={12}>
                            <div className="card mb-4">
                                <div className="card-body">

                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <h5 className="card-title float-lg-left">Websites</h5>
                                        <Button
                                            onClick={e => this.goToRoute(routerConstants.createWebsites, 2)}
                                            variant="outline-primary"
                                            className="mb-2"
                                        >
                                            Create Website
                                        </Button>
                                    </div>

                                    <Table className="m-0" responsive>
                                        <thead>
                                        <tr key="table_headers">
                                            <th>Domain</th>
                                            <th width={"290px"}>Company Name</th>
                                            <th width={"270px"}>Market</th>
                                            <th width={"265px"}>Locales</th>
                                            <th width={"250px"}>Descriptor</th>
                                            <th width={"170px"}>Theme</th>
                                            <th width={"170px"}>Updated At</th>
                                            <th width={"100px"}>Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.mapWebsites()}
                                        </tbody>
                                    </Table>

                                </div>
                            </div>
                        </Col>
                    </Row>

                    {/* Footer */}
                    <div className="flex-grow-1" />
                    <Footer />
                    {/* End Footer */}
                </div>
            </div>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        websites      : state.websites.set_websites,
        loadingWebsite: state.websites.set_loading_websites,
        successMessage: state.websites.website_form_success,
        failMessage   : state.website_form_fail
    };
};

const mapActionsToProps = {
    getWebsites
};

const ListWebsiteConnected = connect(
    mapStateToProps,
    mapActionsToProps
)(ListWebsite);

export default withRouter(ListWebsiteConnected);
