import http from "../../services/http";
import {
    setLoadingProductsList,
    setProducts,
    setProductsListError,
    setProductsUpdateError,
    setProductsUpdateSuccess,
    setSingleProduct,
    setSingleProductError,
    setCreateProductError,
    setCreateProductSuccess,
    setLoadingProductsCreate,
    setLoadingProductsUpdate, setSingleProductLoading
}           from "./products.actions";

export const getProductsList = () => {
    return async dispatch => {
        dispatch(setLoadingProductsList(true));
        dispatch(setProductsListError(null));

        try {
            const products = await http.get(
                `product-subscriptions`
            );

            dispatch(setProducts(products.data["hydra:member"]));
        } catch (e) {
            dispatch(setProducts([]));
            dispatch(setProductsListError(e.message));
        } finally {
            dispatch(setLoadingProductsList(false));
        }
    };
};

export const getSingleProductSubscription = (product_uuid) => {
    return async dispatch => {
        dispatch(setSingleProductError(null));
        dispatch(setSingleProduct([]));
        dispatch(setSingleProductLoading(true));

        try {
            const response = await http.get(`product-subscriptions/${product_uuid}`);

            dispatch(setSingleProduct(response.data));
        } catch (e) {
            dispatch(setSingleProductError(e.message));
        } finally {
            dispatch(setSingleProductLoading(false));
        }
    };
};

export const updateProductSubscription = (product_uuid, data) => {
    return async dispatch => {
        dispatch(setProductsUpdateSuccess(false));
        dispatch(setProductsUpdateError(null));
        dispatch(setLoadingProductsUpdate(true));
        try {
            const response = await http.patch(
                `product-subscriptions/${product_uuid}`,
                data
            );
            dispatch(setLoadingProductsUpdate(false));
            dispatch(setProductsUpdateSuccess(true));
            dispatch(setSingleProduct(response.data));

            setTimeout(
                () => dispatch(setProductsUpdateSuccess(null)),
                5000
            );
        } catch (e) {
            dispatch(setProductsUpdateError(e.message));
            dispatch(setLoadingProductsUpdate(false));

            setTimeout(
                () => dispatch(setLoadingProductsUpdate(null)),
                5000
            );
        }
    };
};

export const createProductSubscription = data => {
    return async dispatch => {
        dispatch(setCreateProductError(null));
        dispatch(setCreateProductSuccess(false));
        dispatch(setLoadingProductsCreate(true));

        try {
            await http.post(
                `product-subscriptions`,
                data
            );
            dispatch(setCreateProductSuccess(true));
            dispatch(setLoadingProductsCreate(false));
        } catch (e) {
            dispatch(setCreateProductError(e.message));
            dispatch(setLoadingProductsCreate(false));
        } finally {
            setTimeout(
                () => {
                    dispatch(setCreateProductSuccess(false));
                },
                5000
            );
        }
    };
};
