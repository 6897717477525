import http from "../../services/http";
import {
    setProjectList,
    setLoadingProject,
    setProjectError,
    setSingleProject,
    setUpdateError,
    setUpdateSuccess,
    setCreateError,
    setCreateSuccess,
    setCreateLoading,
    setUpdateLoading
}           from "./project.actions";

export const getProjectList = () => {
    return async dispatch => {
        dispatch(setLoadingProject(true))
        dispatch(setProjectError(null))

        try {
            const response = await http.get(`projects`)
            const data = response.data;

            dispatch(setProjectList(data["hydra:member"]))
        } catch(e) {
            dispatch(setProjectError(e.message))
        } finally {
            dispatch(setLoadingProject(false))
        }

    }
}

export const getSingleProject = ( projectId ) => {
    return async dispatch => {
        try {
            const response = await http.get(`projects/${projectId}`)
            const data = response.data;

            dispatch(setSingleProject(data))
        } catch(e) {
            console.dir(e)
        }
    }
}

export const updateProject = ( projectId, name, newDate ) => {
    return async dispatch => {
        dispatch(setUpdateError(null))
        dispatch(setUpdateSuccess(false))
        dispatch(setUpdateLoading(true))
        try {
            await http.patch(`projects/${projectId}`,
                {
                    name      : name,
                    updatedAt : newDate
                }
            )
            dispatch(setUpdateLoading(false))
            dispatch(setUpdateSuccess(true))
        } catch(e) {
            dispatch(setUpdateError(e.message))
        }
    }
}

export const createProject = ( name, type, createdAt, updatedAt) => {
    return async dispatch => {
        dispatch(setCreateError(null))
        dispatch(setCreateSuccess(false))
        dispatch(setCreateLoading(true))
        try {
            await http.post(`projects`,
                {
                    name      : name,
                    type      : type,
                    createdAt : createdAt,
                    updatedAt : updatedAt
                }
            )
            dispatch(setCreateLoading(false))
            dispatch(setCreateSuccess(true))
        } catch (e) {
            dispatch(setCreateError(e.message))
        }
    }
}
