export const LOADING_WEBSITES_DOMAINS_AND_IDS   = "LOADING_WEBSITES_DOMAINS_AND_IDS";
export const SET_WEBSITES_DOMAINS_AND_IDS       = "SET_WEBSITES_DOMAINS_AND_IDS";
export const SET_WEBSITES_DOMAINS_AND_IDS_ERROR = "SET_WEBSITES_DOMAINS_AND_IDS_ERROR";
export const LOADING_UPDATE                     = "LOADING_UPDATE";
export const SINGLE_WEBSITE_LOADING             = "SINGLE_WEBSITE_LOADING";
export const SET_SINGLE_WEBSITE                 = "SET_SINGLE_WEBSITE";
export const SET_WEBSITES                       = "SET_WEBSITES";
export const SET_LOADING_WEBSITES               = "SET_LOADING_WEBSITES";
export const CREATE_WEBSITES_LOADING            = "CREATE_WEBSITES_LOADING";
export const WEBSITE_FORM_SUCCESS               = "WEBSITE_FORM_SUCCESS";
export const WEBSITE_FORM_FAIL                  = "WEBSITE_FORM_FAIL"
