import {
    SET_WEBSITES_CONFIG,
    SET_LOADING_WEBSITES_CONFIG,
    SET_WEBSITES_CONFIG_ERROR,
    SET_SINGLE_WEBSITE_CONFIG_ERROR,
    SET_LOADING_SINGLE_WEBSITE_CONFIG,
    SET_SINGLE_WEBSITE_CONFIG,
    SET_EDIT_WEBSITE_CONFIG_ERROR,
    SET_EDIT_WEBSITE_CONFIG_SUCCESS,
    SET_EDIT_WEBSITE_CONFIG_LOADING
} from "./websites-config.types";

const INITIAL_STATE = {
    websitesConfig            : [],
    loadingWebsitesConfig     : false,
    websitesConfigError       : null,
    singleWebsiteConfig       : {},
    loadingSingleWebsiteConfig: false,
    singleWebsiteConfigError  : null,
    editWebsiteConfigError    : null,
    editWebsiteConfigSuccess  : null,
    editWebsiteConfigLoading  : false
}

const websitesConfigReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_WEBSITES_CONFIG:
            return {
                ...state,
                websitesConfig: action.payload
            };
        case SET_LOADING_WEBSITES_CONFIG:
            return {
                ...state,
                loadingWebsitesConfig: action.payload
            };
        case SET_WEBSITES_CONFIG_ERROR:
            return {
                ...state,
                websitesConfigError: action.payload
            };
        case SET_SINGLE_WEBSITE_CONFIG_ERROR:
            return {
                ...state,
                singleWebsiteConfigError: action.payload
            };
        case SET_LOADING_SINGLE_WEBSITE_CONFIG:
            return {
                ...state,
                loadingSingleWebsiteConfig: action.payload
            };
        case SET_SINGLE_WEBSITE_CONFIG:
            return {
                ...state,
                singleWebsiteConfig: action.payload
            };
        case SET_EDIT_WEBSITE_CONFIG_ERROR:
            return {
                ...state,
                editWebsiteConfigError: action.payload
            };
        case SET_EDIT_WEBSITE_CONFIG_SUCCESS:
            return {
                ...state,
                editWebsiteConfigSuccess: action.payload
            };
        case SET_EDIT_WEBSITE_CONFIG_LOADING:
            return {
                ...state,
                editWebsiteConfigLoading: action.payload
            };
        default:
            return state;
    }
}

export default websitesConfigReducer;
