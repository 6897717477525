import http from "../../services/http";
import {
    setCreateLoadingServices,
    setCreateServicesError,
    setCreateServicesSuccess,
    setEditServicesError,
    setEditServicesSuccess,
    setLoadingServicesList,
    setOrdersServicesDropDown,
    setOrdersServicesDropDownError,
    setServiceEmailTemplatesList, setServiceEmailTemplatesListError,
    setServiceEmailTemplatesListLoading,
    setServices,
    setServicesListError,
    setSingleService,
    setSingleServiceLoading
}           from "./services.actions";

export const getServicesList = queryParams => {
    return async dispatch => {
        dispatch(setLoadingServicesList(true));
        dispatch(setServicesListError(null));

        try {
            const response = await http.get(
                "services",
                {
                    params: queryParams
                }
            );

            dispatch(setServices(response.data["hydra:member"]));
        } catch (e) {
            dispatch(setServices([]));
            dispatch(setServicesListError(e.message));
        } finally {
            dispatch(setLoadingServicesList(false));
        }
    };
};

export const getOrdersServicesDropDownOptions = (queryParams = {}) => {
    return async dispatch => {
        dispatch(setOrdersServicesDropDownError(null));

        try {
            const services = await http.get(
                `services?pagination=false`,
                {
                    params: queryParams
                }
            );

            let data       = services.data["hydra:member"];
            let resArr     = [];

            data.filter(
                item => {
                    const i = resArr.findIndex(entry => entry.slug === item.slug);

                    if (i === -1) {
                        resArr.push(item);
                    }

                    return null;
                }
            );

            dispatch(setOrdersServicesDropDown(data));
        } catch (e) {
            dispatch(setOrdersServicesDropDown([]));
            dispatch(setOrdersServicesDropDownError(e.message));
        }
    };
};

export const getSingleService = (servicesUUID) => {
    return async dispatch => {
        dispatch(setSingleServiceLoading(true));
        try {
            const response = await http.get(`services/${servicesUUID}`);

            dispatch(setSingleService(response.data));
            dispatch(setSingleServiceLoading(false));
        } catch (e) {
            console.dir(e);
        }
    };
};

export const updateServices = data => {
    const {
              slug,
              description,
              name,
              discountedPrice,
              productsId,
              websitesId,
              servicesId,
              welcomeEmail
          } = data;

    return async dispatch => {
        dispatch(setCreateServicesError(null));
        dispatch(setEditServicesSuccess(false));

        try {
            const response = await http.patch(
                `services/${servicesId}`,
                {
                    description        : description,
                    name               : name,
                    slug               : slug,
                    discountedPrice    : discountedPrice,
                    productSubscription: [
                        productsId
                    ],
                    website            : websitesId,
                    welcomeEmail       : welcomeEmail
                }
            );

            dispatch(setEditServicesSuccess(true));
            dispatch(setSingleService(response.data));

            setTimeout(
                () => dispatch(setEditServicesSuccess(null)),
                5000
            );

        } catch (e) {
            dispatch(setEditServicesError(e.message));
            dispatch(setEditServicesSuccess(false));
        } finally {
            setTimeout(
                () => dispatch(setEditServicesError(null)
            ), 5000);
            dispatch(setSingleServiceLoading(false));
        }
    };
};

export const postServices = data => {
    const {
              slug,
              description,
              name,
              websitesId,
              productsId,
              welcomeEmail
          } = data;

    return async dispatch => {
        dispatch(setEditServicesError(null));
        dispatch(setCreateServicesSuccess(false));
        dispatch(setCreateLoadingServices(true));
        try {
            const body = {
                description        : description,
                name               : name,
                slug               : slug,
                productSubscription: [
                    productsId
                ],
                website            : websitesId,
                welcomeEmail       : welcomeEmail

            };

            await http.post(`services`, body);
            dispatch(setCreateLoadingServices(false));
            dispatch(setCreateServicesSuccess(true));
        } catch (e) {
            dispatch(setCreateLoadingServices(false));
            dispatch(setEditServicesError(e.message));
        }
    };
};

export const getAllEmailTemplatesList = () => {
    return async dispatch => {
        dispatch(setSingleServiceLoading(true));
        dispatch(setServiceEmailTemplatesListLoading(true));

        try {
            const response = await http.get(`email-templates?pagination=false`);
            const data     = response?.data?.["hydra:member"] ?? [];

            dispatch(setServiceEmailTemplatesList(data));
        } catch (e) {
            dispatch(setServiceEmailTemplatesListError(e.message));
        } finally {
            dispatch(setSingleServiceLoading(false));
            dispatch(setServiceEmailTemplatesListLoading(false));
        }
    };
};
