import {
    SET_DELETE_UPLOADED_DOCUMENT_ERROR,
    SET_DELETE_UPLOADED_DOCUMENT_SUCCESS,
    SET_IS_TEXT_COPIED,
    SET_IS_FILE_PICKED,
    SET_LOADING_PURCHASES,
    SET_LOADING_SINGLE_PURCHASE,
    SET_MEILI_PURCHASES,
    SET_MEILI_PURCHASES_CURRENT_PAGE,
    SET_MEILI_PURCHASES_ERROR,
    SET_MEILI_PURCHASES_FIRST_PAGE,
    SET_MEILI_PURCHASES_LAST_PAGE,
    SET_MEILI_PURCHASES_NEXT_PAGE,
    SET_MEILI_PURCHASES_PREV_PAGE,
    SET_MEIlI_RESET_PAGINATION,
    SET_MEILI_TOTAL_PAGES,
    SET_MEILI_TOTAL_PURCHASES,
    SET_MODAL_CONFIRMATION_COMMENTS,
    SET_PURCHASE_COMMENTS_DATA,
    SET_PURCHASE_COMMENTS_ERROR,
    SET_PURCHASE_COMMENTS_LOADING,
    SET_PURCHASES_CURRENT_PAGE,
    SET_PURCHASES_FIRST_PAGE,
    SET_PURCHASES_LAST_PAGE,
    SET_PURCHASES_LIST,
    SET_PURCHASES_LIST_ERROR,
    SET_PURCHASES_NEXT_PAGE,
    SET_PURCHASES_PREV_PAGE,
    SET_PURCHASES_UPLOADED_DOCUMENT_ERROR,
    SET_PURCHASES_UPLOADED_DOCUMENT_LOADER,
    SET_PURCHASES_UPLOADED_DOCUMENT_SUCCESS,
    SET_SHOW_UPLOAD_DOCUMENT_MODAL,
    SET_SINGLE_PURCHASE,
    SET_SINGLE_PURCHASE_ERROR,
    SET_TOTAL_ON_HOLD_TRANSACTIONS,
    SET_TOTAL_PENDING_IN_PROGRESS_TRANSACTIONS,
    SET_TOTAL_ASK_INFORMATION_TRANSACTIONS,
    SET_TOTAL_PURCHASES,
    SET_SHOW_SUBMIT_AND_DELIVERED_CONFIRMATION,
    SET_UPLOAD_AND_MARK_AS_DELIVERED_SUCCESS,
    SET_UPLOAD_AND_MARK_AS_DELIVERED_ERROR
} from "./purchases.types";

const INITIAL_STATE = {
    purchasesList                     : [],
    totalPurchases                    : 0,
    purchasesListError                : null,
    purchasesCurrentPage              : "",
    purchasesFirstPage                : "",
    purchasesLastPage                 : "",
    purchasesPrevPage                 : "",
    purchasesNextPage                 : "",
    loadingPurchasesList              : false,
    loadingSinglePurchase             : false,
    singlePurchase                    : null,
    singlePurchaseError               : null,
    totalPendingInProgressTransactions: 0,
    totalOnHoldTransactions           : 0,
    totalAskInformationTransactions   : 0,
    purchasesUploadedDocumentLoader   : false,
    purchasesUploadedDocumentError    : null,
    purchasesUploadedDocumentSuccess  : false,
    showUploadDocumentModal           : false,
    deleteDocumentError               : null,
    deleteDocumentSuccess             : false,
    isTextCopied                      : false,
    isFilePicked                      : false,
    modalConfirmationComments         : "",
    purchaseCommentsData              : null,
    purchaseCommentsLoading           : false,
    purchaseCommentsError             : null,
    meiliPurchases                    : [],
    meiliTotalPurchases               : 0,
    meiliTotalPages                   : 0,
    meiliPurchasesError               : null,
    meiliPurchasesCurrentPage         : 0,
    meiliPurchasesFirstPage           : 0,
    meiliPurchasesLastPage            : 0,
    meiliPurchasesPrevPage            : 0,
    meiliPurchasesNextPage            : 0,
    showSubmitAndDeliveredConfirmation: false,
    uploadAndMarkAsDeliveredSuccess   : false,
    uploadAndMarkAsDeliveredError     : null
};

const purchasesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_PURCHASES_LIST:
            return {
                ...state,
                purchasesList: action.payload
            };
        case SET_TOTAL_PURCHASES:
            return {
                ...state,
                totalPurchases: action.payload
            };
        case SET_PURCHASES_LIST_ERROR:
            return {
                ...state,
                purchasesListError: action.payload
            };
        case SET_LOADING_PURCHASES:
            return {
                ...state,
                loadingPurchasesList: action.payload
            };
        case SET_LOADING_SINGLE_PURCHASE:
            return {
                ...state,
                loadingSinglePurchase: action.payload
            };
        case SET_SINGLE_PURCHASE:
            return {
                ...state,
                singlePurchase: action.payload
            };
        case SET_SINGLE_PURCHASE_ERROR:
            return {
                ...state,
                singlePurchaseError: action.payload
            };
        case SET_PURCHASES_CURRENT_PAGE:
            return {
                ...state,
                purchasesCurrentPage: action.payload
            };
        case SET_PURCHASES_FIRST_PAGE:
            return {
                ...state,
                purchasesFirstPage: action.payload
            };
        case SET_PURCHASES_LAST_PAGE:
            return {
                ...state,
                purchasesLastPage: action.payload
            };
        case SET_PURCHASES_PREV_PAGE:
            return {
                ...state,
                purchasesPrevPage: action.payload
            };
        case SET_PURCHASES_NEXT_PAGE:
            return {
                ...state,
                purchasesNextPage: action.payload
            };
        case SET_TOTAL_PENDING_IN_PROGRESS_TRANSACTIONS:
            return {
                ...state,
                totalPendingInProgressTransactions: action.payload
            };
        case SET_TOTAL_ON_HOLD_TRANSACTIONS:
            return {
                ...state,
                totalOnHoldTransactions: action.payload
            };
        case SET_TOTAL_ASK_INFORMATION_TRANSACTIONS:
            return {
                ...state,
                totalAskInformationTransactions: action.payload
            };
        case SET_PURCHASES_UPLOADED_DOCUMENT_LOADER:
            return {
                ...state,
                purchasesUploadedDocumentLoader: action.payload
            };
        case SET_PURCHASES_UPLOADED_DOCUMENT_ERROR:
            return {
                ...state,
                purchasesUploadedDocumentError: action.payload
            };
        case SET_PURCHASES_UPLOADED_DOCUMENT_SUCCESS:
            return {
                ...state,
                purchasesUploadedDocumentSuccess: action.payload
            };
        case SET_SHOW_UPLOAD_DOCUMENT_MODAL:
            return {
                ...state,
                showUploadDocumentModal: action.payload
            };
        case     SET_DELETE_UPLOADED_DOCUMENT_ERROR:
            return {
                ...state,
                deleteDocumentError: action.payload
            };
        case     SET_DELETE_UPLOADED_DOCUMENT_SUCCESS:
            return {
                ...state,
                deleteDocumentSuccess: action.payload
            };
        case     SET_IS_FILE_PICKED:
            return {
                ...state,
                isFilePicked: action.payload
            };
        case     SET_MODAL_CONFIRMATION_COMMENTS:
            return {
                ...state,
                modalConfirmationComments: action.payload
            };
        case    SET_PURCHASE_COMMENTS_DATA:
            return {
                ...state,
                purchaseCommentsData: action.payload
            };
        case    SET_PURCHASE_COMMENTS_LOADING:
            return {
                ...state,
                purchaseCommentsLoading: action.payload
            };
        case    SET_PURCHASE_COMMENTS_ERROR:
            return {
                ...state,
                purchaseCommentsError: action.payload
            };
        case SET_MEILI_PURCHASES:
            return {
                ...state,
                meiliPurchases: action.payload
            };
        case SET_MEILI_TOTAL_PURCHASES:
            return {
                ...state,
                meiliTotalPurchases: action.payload
            };
        case SET_MEILI_PURCHASES_ERROR:
            return {
                ...state,
                meiliPurchasesError: action.payload
            };
        case SET_MEILI_PURCHASES_CURRENT_PAGE:
            return {
                ...state,
                meiliPurchasesCurrentPage: action.payload
            };
        case SET_MEILI_PURCHASES_NEXT_PAGE:
            return {
                ...state,
                meiliPurchasesNextPage: action.payload
            };
        case SET_MEILI_PURCHASES_LAST_PAGE:
            return {
                ...state,
                meiliPurchasesLastPage: action.payload
            };
        case SET_MEILI_PURCHASES_FIRST_PAGE:
            return {
                ...state,
                meiliPurchasesFirstPage: action.payload
            };
        case SET_MEILI_PURCHASES_PREV_PAGE:
            return {
                ...state,
                meiliPurchasesPrevPage: action.payload
            };
        case SET_MEILI_TOTAL_PAGES:
            return {
                ...state,
                meiliTotalPages: action.payload
            };
        case     SET_IS_TEXT_COPIED:
            return {
                ...state,
                isTextCopied: action.payload
            };
        case SET_MEIlI_RESET_PAGINATION:
            return {
                ...state,
                meiliTotalPurchases      : 0,
                meiliTotalPages          : 0,
                meiliPurchasesCurrentPage: 0,
                meiliPurchasesFirstPage  : 0,
                meiliPurchasesLastPage   : 0,
                meiliPurchasesPrevPage   : 0,
                meiliPurchasesNextPage   : 0
            };
        case SET_SHOW_SUBMIT_AND_DELIVERED_CONFIRMATION:
            return {
                ...state,
                showSubmitAndDeliveredConfirmation: action.payload
            };
        case SET_UPLOAD_AND_MARK_AS_DELIVERED_SUCCESS:
            return {
                ...state,
                uploadAndMarkAsDeliveredSuccess: action.payload
            };
        case SET_UPLOAD_AND_MARK_AS_DELIVERED_ERROR:
            return {
                ...state,
                uploadAndMarkAsDeliveredError: action.payload
            };
        default:
            return state;
    }
};

export default purchasesReducer;
