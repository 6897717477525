import React           from "react";
import {Button, Modal} from "react-bootstrap";

class UnsubscribeModal extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        return (
            <div className="modal-wrap">
                <Modal
                    show={this.props.show}
                    onHide={this.handleClose}
                    backdrop="static"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>
                            <strong className="text-danger">Warning!</strong> Unsubscribe Customer
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        This operation will permanently unsubscribe the customer <strong>{`${this.props.customer?.lastName} ${this.props.customer?.firstName}`}</strong>.
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            variant="danger"
                            onClick={this.props.onConfirm}
                            disabled={this.props.processing}
                        >
                            {this.props.processing ? 'Processing ...' : 'Confirm' }
                        </Button>
                        <Button
                            variant="success"
                            onClick={this.props.onClose}
                            disabled={this.props.processing}
                        >
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default UnsubscribeModal;
