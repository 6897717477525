import React             from "react";
import { Button, Modal } from "react-bootstrap";

class ConfirmSendEmail extends React.Component {
    constructor(props, context) {
        super(props, context);

    }

    render() {
        const {email, firstName, LastName} = this?.props?.customer ?? {};

        return (
            <div className="modal-wrap">
                <Modal
                    show={this.props.show}
                    onHide={this.handleClose}
                    backdrop="static"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>
                        <strong className="text-danger">Warning!</strong> Are you sure you want to send an email ?
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        By doing this operation you will send an email to&nbsp;
                        <strong>
                        {LastName} {firstName}&nbsp;
                        </strong>{" "}
                        <em>({email})</em>.
                        <br />
                        <br />
                        Do you really want to perform this action?
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            variant="danger"
                            onClick={this.props.onConfirm}
                            disabled={this.props.processing}
                        >
                            {this.props.processing ? "Processing ..." : "Confirm"}
                        </Button>
                        <Button
                            variant="success"
                            onClick={this.props.onClose}
                            disabled={this.props.processing}
                        >
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default ConfirmSendEmail;
