import React         from "react";
import {Navbar} from "react-bootstrap";
import MenuOptions   from "./MenuOptions";
import "./SideMenu.scss";

class SideMenuDark extends React.Component {
    render() {
        return (
            <div className={`sidemenu-area ${this.props.sideMenu ? "sidemenu-toggle" : ""}`}>
                <Navbar className={`sidemenu ${this.props.sideMenu ? "hide-nav-title" : ""}`}>
                    <Navbar.Collapse>
                        <MenuOptions />
                    </Navbar.Collapse>
                </Navbar>
            </div>
        );
    }
}

export default SideMenuDark;