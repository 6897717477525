import React                           from "react";
import {Link, Redirect}                from "react-router-dom";
import {Row, Col, Form, Button, Alert} from "react-bootstrap";
import routerConstants                 from "../constants/router-constants";
import {connect}                       from "react-redux";
// Redux
import {login}                         from "../redux/User/user.async-actions";

class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: ""
        };

        this.updateUsername = this.updateUsernameCallback.bind(this);
        this.updatePassword = this.updatePasswordCallback.bind(this);
    }

    onLoginHandler = async e => {
        e.preventDefault();

        await this.props.login(this.state.username, this.state.password);
    };

    updateUsernameCallback(e) {
        this.setState(
            {
                username: e.target.value
            }
        );
    }

    updatePasswordCallback(e) {
        this.setState(
            {
                password: e.target.value
            }
        );
    }

    render() {
        if ( this.props.authenticated ) {
            return <Redirect to={routerConstants.dashboard} />
        }

        return <>
            <div className="auth-main-content auth-bg-image">
                <div className="d-table">
                    <div className="d-tablecell">
                        <div className="auth-box">
                            <Row>
                                <Col md={12}>
                                    <div className="form-content">
                                        <h1 className="heading">Log In</h1>

                                        {
                                            false !== this.props.authentication_error &&
                                            <Alert variant="danger">{this.props.authentication_error}</Alert>
                                        }

                                        <Form onSubmit={this.onLoginHandler}>
                                            <Form.Group>
                                                <Form.Label>Email address</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="email"
                                                    value={this.state.username}
                                                    onChange={e => this.updateUsername(e)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter a valid email address
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="password"
                                                    minLength="8"
                                                    value={this.state.password}
                                                    onChange={e => this.updatePassword(e)}
                                                />
                                            </Form.Group>

                                            <div className="text-center">
                                                <Button
                                                    variant="primary"
                                                    type="submit"
                                                >
                                                    Log In
                                                </Button>

                                                <Link
                                                    to={routerConstants.forgotPassword}
                                                    className="fp-link"
                                                >
                                                    Forgot Password?
                                                </Link>
                                            </div>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        authenticated       : state.user.authenticated,
        processing          : state.user.processing,
        authentication_error: state.user.authentication_error
    };
};

export default connect(
    mapStateToProps,
    {
        login
    }
)(Login);
