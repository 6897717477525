import React                from 'react'
import Navigation           from '../../../components/Navigation/Navigation';
import Loader               from '../../../components/Common/Loader';
import routerConstants      from '../../../constants/router-constants'
import { connect }          from 'react-redux';
import { withRouter }       from 'react-router-dom';
import {
    getSingleEmailTemplate,
    editEmailTemplate
}                           from '../../../redux/EmailTemplate/emailTemplate.async-actions';
import { useForm }          from "react-hook-form";
import {
    Breadcrumb,
    Row,
    Col,
    Button,
    Card
}                            from "react-bootstrap";
import { Link }              from 'react-router-dom';

class EditEmailTemplate extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            sideMenu : true
        }
    }

    _onSideMenu = (active) => {
        this.setState({sideMenu: active})
    }

    componentDidMount() {
        this.props.getSingleEmailTemplate(this.props.match.params.emailTemplate_uuid)
    }

    goToRoute(route) {
        this.props.history.push(route);
    }

    EmailTemplateForm = () => {
        const {name, description, externalId} = this.props.singleEmailTemplate;

        this.props.setValue("name", name);
        this.props.setValue("description", description);
        this.props.setValue("externalId", externalId)

        return <>
            <Card>
                <Card.Body>
                    <div className="card-header">
                        <Card.Title>Edit Email Template</Card.Title>
                    </div>

                    <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                        <div className="row mb-3">
                            <div className="col">
                                <label
                                    htmlFor="name"
                                >
                                    name
                                </label>
                                <input
                                    id="name"
                                    type="text"
                                    className="form-control"
                                    placeholder="Name"
                                    name="name"
                                    {...this.props.register('name')}
                                />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col">
                                <label
                                    htmlFor="description"
                                >
                                    Description
                                </label>
                                <input
                                    id="description"
                                    type="text"
                                    className="form-control"
                                    placeholder="Description"
                                    name="description"
                                    {...this.props.register('description')}
                                />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col">
                                <label
                                    htmlFor="externalId"
                                >
                                    External Id
                                </label>
                                <input
                                    id="externalId"
                                    type="text"
                                    className="form-control"
                                    placeholder="External Id"
                                    name="externalId"
                                    {...this.props.register('externalId')}
                                />
                            </div>
                        </div>

                        <div style={{padding: "10px"}} />

                        <div className="text-center">
                            <Button
                                variant="primary"
                                type="submit"
                            >
                                Update Services
                            </Button>
                        </div>
                    </form>
                </Card.Body>
            </Card>
        </>
    }

    onSubmit = async (data) => {
        await this.props.editEmailTemplate(this.props.match.params.emailTemplate_uuid, data)

        if(this.props.editEmailTemplateSuccess) {
            this.goToRoute(`/list-email`)
        }
    }

    render() {
        let loader = null;

        if (this.props.singleEmailTemplateLoading || this.props.editEmailTemplateLoading) {
            loader = <Loader messagee="loading..." />;
        }

        return <>
            <div className="page-wrapper">
                <Navigation onClick={this._onSideMenu} />
                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? "" : "hide-sidemenu"}`}>
                    {/* Loader */}
                    {loader}
                    {/* End Loader */}

                    {/* Breadcrumb */}
                    <div className="main-content-header">
                        <Breadcrumb>
                            <h1>Email Templates</h1>
                            <Link to={routerConstants.dashboard} className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Breadcrumb.Item active>
                                Email Templates
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    {/* End Breadcrumb */}

                    {null !== this.props.editEmailTemplateError &&
                        <div className="alert alert-success" role="alert">
                            {this.props.editEmailTemplateError}
                        </div>
                    }

                    <Row>
                        <Col lg={12}>
                            {
                                this.EmailTemplateForm()
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    }
}

const EditEmailTemplateHookForm = props => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors}
    } = useForm();

    return <EditEmailTemplate
        {...props}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        setValue={setValue}
    />
}

const mapStateToProps = state => {
    return {
        singleEmailTemplate        : state.emailTemplates.singleEmailTemplate,
        singleEmailTemplateError   : state.emailTemplates.singleEmailTemplateError,
        singleEmailTemplateLoading : state.emailTemplates.singleEmailTemplateLoading,
        editEmailTemplateSuccess   : state.emailTemplates.updateEmailTemplateSuccess,
        editEmailTemplateError     : state.emailTemplates.updateEmailTemplateError,
        editEmailTemplateLoading   : state.emailTemplates.updatesEmailTemplateLoading
    }
}

const mapActionsToProps = {
    getSingleEmailTemplate,
    editEmailTemplate
}

const EditEmailTemplateConnected = connect(
    mapStateToProps,
    mapActionsToProps
)(EditEmailTemplateHookForm);

export default withRouter(EditEmailTemplateConnected);
