import React           from 'react'
import Navigation      from '../../../components/Navigation/Navigation';
import routerConstants from "../../../constants/router-constants";
import { 
    Breadcrumb,
    Card,
    Button,
    Row,
    Col
}                      from 'react-bootstrap';
import { Link }        from 'react-router-dom';
import { useForm }     from 'react-hook-form';
import { 
    getSingleProject, 
    updateProject 
}                      from '../../../redux/Project/project.async-actions';
import { connect }     from 'react-redux';
import Loader          from '../../../components/Common/Loader';

class EditProject extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            sideMenu : true
        };
    }

    formularProject = () => {
        const { name } = this.props.singleProject

        return <>
           <Card>
                <Card.Body>
                    <Card.Title>Edit Project</Card.Title>
                    <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                        <div className="row">
                            <div className="col">
                                <label>Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={name}
                                    name="name"
                                    {...this.props.register('name',
                                        {
                                            required: {
                                                value  : true,
                                                message: <span style={{color: 'red'}}>This field is required</span>
                                            }
                                        }
                                    )}
                                />
                                {this?.props?.errors?.name?.message}

                                <div style={{padding : "10px"}} />

                                <div className="text-center">
                                    <Button
                                        variant="primary"
                                        type="submit">
                                        Edit Websites
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Card.Body>
           </Card>
        </>
    }

    componentDidMount() {
        this.props.getSingleProject(this.props.match.params.project_uuid)
    }

    _onSideMenu = (active) => {
        this.setState({sideMenu: active});
    };

    onSubmit = (data) => {
        const project_uuid = this.props.match.params.project_uuid;
        const name         = data.name;
        const newDate      = (new Date())

        this.props.updateProject(project_uuid, name, newDate)

    }

    render() {
        let loader = null;
        const {
            updateSuccess,
            updateError,
            updateLoading
        } = this.props;
        
        if (updateLoading) {
            loader = <Loader message="Loading..." />
        }

        return <>
            <div className="page-wrapper">
                {/* Navigation */}
                <Navigation onClick={this._onSideMenu} />
                {/* End Navigation */}

                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? "" : "hide-sidemenu"}`}>
                    {/* Loader */}
                    {loader}
                    {/* End Loader */}

                    {true === updateSuccess &&
                        <div class="alert alert-success" role="alert">
                            Success
                        </div>
                    }

                    {null !== updateError &&
                        <div class="alert alert-danger" role="alert">
                            Fail
                        </div>
                    }
                    <div className="main-content-header">
                        <Breadcrumb>
                            <h1>Edit Project</h1>
                            <Link to={routerConstants.dashboard} className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Link to={routerConstants.listProject} className="breadcrumb-item">
                                List Project
                            </Link>
                            <Breadcrumb.Item active>
                               Edit Project
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <Row>
                        <Col lg={12}>
                            {
                                this.formularProject()
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    }
}

const EditProjectConst = (props) => {
    const {register, handleSubmit, formState : {errors}} = useForm();

    return <EditProject
        {...props}
        errors={errors}
        register={register}
        handleSubmit={handleSubmit}
    />
}

const mapStateToProps = state => {
    return {
        singleProject : state.project.setSingleProject,
        updateSuccess : state.project.setUpdateSuccess,
        updateError   : state.project.setUpdateError,
        updateLoading : state.project.setUpdateLoading
    }
}

const mapActionsToProps = {
    getSingleProject,
    updateProject
}

const EditProjectConnected = connect(
    mapStateToProps,
    mapActionsToProps
)(EditProjectConst)

export default EditProjectConnected;
