import {connect}                                         from "react-redux";
import {Link, withRouter}                                from "react-router-dom";
import React                                             from "react";
import Navigation                                        from "../../components/Navigation/Navigation";
import {Breadcrumb, Button, Card, Col, Form, Row, Table} from "react-bootstrap";
import routerConstants                                   from "../../constants/router-constants";
import Loader                                            from "../../components/Common/Loader";
import {RangeDatePicker}                                 from "react-google-flight-datepicker";
import "react-google-flight-datepicker/dist/main.css";
import moment                                            from "moment";
import {
    getSalesStatistics
}                                                        from "../../redux/Statistics/RebillBreakdown/rebill-breakdown.async-actions";
import * as echarts                                      from "echarts";
import Select                                            from "react-select";
import {getOrdersServicesDropDownOptions}                from "../../redux/Services/services.async-actions";
import {fetchWebsiteDomainsAndIds}                       from "../../redux/Websites/websites.async-actions";

class SalesStatistics extends React.Component {
    state = {
        sideMenu     : true,
        startDate    : new Date(),
        endDate      : new Date(),
        services     : [],
        websiteId    : ""
    };

    constructor(props) {
        super(props);

        this.from  = React.createRef();
        this.to    = React.createRef();
        this.chart = React.createRef();

        this.from.current = moment(new Date()).format("YYYY-MM-DD");
        this.to.current   = moment(new Date()).format("YYYY-MM-DD");

        this.chartOptions    = {
            tooltip: {
                trigger: "item"
            },
            legend : {
                top : "5%",
                left: "center"
            },
            series : [
                {
                    name             : "Sales",
                    type             : "pie",
                    radius           : ["47%", "64%"],
                    avoidLabelOverlap: true,
                    itemStyle        : {
                        borderRadius: 10,
                        borderColor : "#FFF",
                        borderWidth : 2
                    },
                    label            : {
                        show    : true,
                        position: "left"
                    },
                    emphasis         : {
                        label: {
                            show      : true,
                            fontSize  : "20",
                            fontWeight: "bold"
                        }
                    },
                    labelLine        : {
                        show: true
                    },
                    data             : []
                }
            ]
        };
        this.servicesOptions     = [];
        this.websitesOptions     = [];

        this.onDateChange              = this.onDateChange.bind(this);
        this.fetchSalesStats           = this.fetchSalesStats.bind(this);
        this.chartWindowResizeListener = this.chartWindowResizeListener.bind(this);
        this.onServiceValueChange      = this.onServiceValueChange.bind(this);
        this.onWebsiteValueChange      = this.onWebsiteValueChange.bind(this);

        this.pieChart = null;
    }

    // Toggle side bar menu
    _onSideMenu = (active) => {
        this.setState(
            {
                sideMenu: active
            }
        );
    };

    chartWindowResizeListener() {
        this.pieChart.resize();
    }

    async componentDidMount() {
        window.requestAnimationFrame(
            () => {
                this.pieChart = echarts.init(this.chart.current);
                this.pieChart.setOption(this.chartOptions);

                window.addEventListener(
                    "resize",
                    this.chartWindowResizeListener
                );
                window.addEventListener(
                    "orientationchange",
                    this.chartWindowResizeListener
                );
            }
        );

        const queryParamsServices = {
            "order[name]": "ASC"
        };

        const queryParamsWebsite = {
            "order[domain]": "ASC"
        };

        await this.props.getOrdersServicesDropDownOptions(queryParamsServices);
        await this.props.fetchWebsiteDomainsAndIds(queryParamsWebsite);

        if (null === this.props.ordersServicesListError) {
            this.servicesOptions = this.props.ordersServicesList.map(
                service => {
                    return {
                        value: service.slug,
                        label: service.name
                    };
                }
            );
        }

        if (null === this.props.domainsAndIdsError) {
            this.websitesOptions = this.props.websitesDomainsAndIds.map(
                website => {
                    return {
                        value: website.id,
                        label: website.domain
                    };
                }
            );
        }

        this.fetchSalesStats();
    }

    componentWillUnmount() {
        window.removeEventListener(
            "resize",
            this.chartWindowResizeListener
        );
        window.removeEventListener(
            "orientationchange",
            this.chartWindowResizeListener
        );
    }

    onDateChange(startDate, endDate) {
        this.setState(
            {
                startDate,
                endDate
            }
        );

        this.from.current = moment(startDate).format("YYYY-MM-DD");
        this.to.current   = moment(endDate).format("YYYY-MM-DD");
    }

    onServiceValueChange(selectedOptions) {
        let values = [];

        selectedOptions.forEach(option => values.push(option.value));
        this.setState(
            {
                services: values
            }
        );
    }

    onWebsiteValueChange(selectedWebsite) {
        if ( null === selectedWebsite ) {
            this.setState(
                {
                    websiteId: ''
                }
            );
        } else {
            this.setState(
                {
                    websiteId: selectedWebsite.value
                }
            );
        }
    }

    fetchSalesStats() {
        let query = {
            from         : this.from.current,
            to           : this.to.current,
            services     : this.state.services,
        };

        if ( '' !== this.state.websiteId ) {
            query.website = this.state.websiteId;
        }

        this.props.getSalesStatistics(query);

        setTimeout(
            () => {
                window.requestAnimationFrame(
                    () => {
                        this.chartOptions.series[0].data = this.props.salesStats.services.map(
                            entry => {
                                entry.name  = entry.service;
                                entry.value = entry.total;

                                delete entry.service;
                                delete entry.total;

                                return entry;
                            }
                        );

                        this.pieChart.setOption(this.chartOptions);
                    }
                );
            },
            1000
        );
    }

    mapSalesStats = () => this?.props?.salesStats?.services?.map(
        entry => {
            return <tr key={entry.service} className="text-center">
                <td>{entry.service}</td>
                <th scope="row">{entry.total}</th>
            </tr>;
        }
    );

    render() {
        let loader = null;

        if (this.props.loadingSalesStats || this.props.loadingDomainsAndIds) {
            loader = <Loader message="Loading..." />;
        }

        const chartStyle = {
            height : "350px",
            display: "block"
        };

        return <>
            <div className="page-wrapper">
                {/* Navigation */}
                <Navigation onClick={this._onSideMenu} />
                {/* End Navigation */}

                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? "" : "hide-sidemenu"}`}>
                    {/* Loader */}
                    {loader}
                    {/* End Loader */}

                    {/* Breadcrumb */}
                    <div className="main-content-header">
                        <Breadcrumb>
                            <h1>Statistics</h1>
                            <Link to={routerConstants.dashboard} className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Breadcrumb.Item active>
                                Sales
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    {/* End Breadcrumb */}

                    <Row>
                        <Col sm={12} md={4} lg={3}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <Card.Title>Filters</Card.Title>
                                    <Form>
                                        <Form.Group controlId="dateRange">
                                            <Form.Label>Date Range</Form.Label>
                                            <RangeDatePicker
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                maxDate={new Date()}
                                                onChange={(startDate, endDate) => this.onDateChange(
                                                    startDate,
                                                    endDate
                                                )}
                                                dateFormat="D MMM YY"
                                                monthFormat="MMM YYYY"
                                                startDatePlaceholder="Start Date"
                                                endDatePlaceholder="End Date"
                                                disabled={false}
                                                startWeekDay="monday"
                                                ref={this.dateRange}
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="serviceName">
                                            <Form.Label>Service</Form.Label>
                                            <Select
                                                value={this.props.services}
                                                onChange={this.onServiceValueChange}
                                                options={this.servicesOptions}
                                                isMulti
                                                backspaceRemovesValue={true}
                                                escapeClearsValue={true}
                                                isClearable={true}
                                                menuPlacement={"auto"}
                                                placeholder={"Choose services you desire"}
                                            />
                                            <Form.Text className="text-muted">
                                                The services to use in order to filter the sales.
                                            </Form.Text>
                                            {
                                                this.props.ordersServicesListError !== null &&
                                                <Form.Text className="text-danger">
                                                    Something went wrong and the services cannot be loaded. Please
                                                    contact the development team to ask for more information.
                                                </Form.Text>
                                            }
                                        </Form.Group>

                                        <Form.Group controlId="websiteDomain">
                                            <Form.Label>Website</Form.Label>
                                            <Select
                                                value={this.props.websiteId}
                                                onChange={this.onWebsiteValueChange}
                                                options={this.websitesOptions}
                                                backspaceRemovesValue={true}
                                                escapeClearsValue={true}
                                                isClearable={true}
                                                menuPlacement={"auto"}
                                                placeholder={"Choose a website"}
                                            />
                                            <Form.Text className="text-muted">
                                                The website to use in order to filter the sales.
                                            </Form.Text>
                                            {
                                                this.props.domainsAndIdsError !== null &&
                                                <Form.Text className="text-danger">
                                                    Something went wrong and the websites cannot be loaded. Please
                                                    contact the development team to ask for more information.
                                                </Form.Text>
                                            }
                                        </Form.Group>
                                    </Form>
                                    <Button
                                        variant="primary"
                                        onClick={this.fetchSalesStats}
                                    >
                                        Filter
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} md={8} lg={9}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <Card.Title>Results</Card.Title>
                                    <Table responsive hover className="m-0">
                                        <thead>
                                        <tr className="text-center">
                                            <th>Service</th>
                                            <th className="text-center">Total</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this?.props?.salesStats?.services?.length === 0 ?
                                                <tr key="no_purchases">
                                                    <td colSpan={2} className="text-center">No Data</td>
                                                </tr> :
                                                this.mapSalesStats()
                                        }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                            <Card className="mb-4">
                                <Card.Body>
                                    <Card.Title>Stats Sales</Card.Title>
                                    <Table responsive hover className="m-0">
                                        <thead>
                                        <tr className="text-center">
                                            <th>Total sales</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.props.salesStats.totalSales !== 0 ?
                                                <tr>
                                                  <td colSpan={2} className="text-center">{this?.props?.salesStats?.totalSales}</td>
                                                </tr> : 
                                                <tr>
                                                    <td colSpan={2} className="text-center">No Data</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                            <Card className="mb-4">
                                <Card.Body>
                                    <Card.Title>Sales Graph</Card.Title>
                                    <div
                                        ref={this.chart}
                                        style={chartStyle}
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        salesStats             : state.rebillBreakdown.salesStats,
        loadingSalesStats      : state.rebillBreakdown.loadingSalesStats,
        ordersServicesList     : state.services.ordersServicesList,
        ordersServicesListError: state.services.ordersServicesListError,
        websitesDomainsAndIds  : state.websites.domains_and_ids,
        loadingDomainsAndIds   : state.websites.loading_domains_and_ids,
        domainsAndIdsError     : state.websites.domains_and_ids_error
    };
};

const mapActionsToProps = {
    getSalesStatistics,
    getOrdersServicesDropDownOptions,
    fetchWebsiteDomainsAndIds
};

const SalesStatisticsConnected = connect(mapStateToProps, mapActionsToProps)(SalesStatistics);
export default withRouter(SalesStatisticsConnected);
