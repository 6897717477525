import React          from 'react'
import Navigation     from '../../components/Navigation/Navigation';
import { 
    Breadcrumb, 
    Row, 
    Col, 
    Form, 
    Button 
}                      from "react-bootstrap";
import routerConstants from "../../constants/router-constants";
import { Link }        from 'react-router-dom';
import { updateSingleCompany } from '../../redux/Company/company.async-actions';
import { connect }     from 'react-redux';
import { withRouter }  from 'react-router-dom';
import { useForm }     from 'react-hook-form';
import Loader          from '../../components/Common/Loader';

class EditCompanyComponents extends React.Component {
    onsubmit = async (data) => {
        await this.props.updateSingleCompany(this.props.match.params.company_uuid, data)

        if(this.props.successEdit) {
            this.props.history.push(`/list-company`);
        }
    }

    formularCompany = () => {
        const { companyData } = this.props

        return <div className="profile-settings-form mb-3">
            <Form onSubmit={this.props.handleSubmit(this.onsubmit)}>
                <Form.Group>
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Address"
                        defaultValue={companyData.address}
                        {...this.props.register("address")}
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="City"
                        defaultValue={companyData.city}
                        {...this.props.register("city")}
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="country"
                        defaultValue={companyData.country}
                        {...this.props.register("country")}
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Name"
                        defaultValue={companyData.name}
                        {...this.props.register("name")}
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Number</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Number"
                        defaultValue={companyData.number}
                        {...this.props.register("number")}
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Vat Number</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Vat Number"
                        defaultValue={companyData.vatNumber}
                        {...this.props.register("vatNumber")}
                    />
                </Form.Group>

                <div className="text-center">
                    <Button
                        variant="primary"
                        type="submit"
                    >
                        Update Companies
                    </Button>
                </div>
            </Form>
        </div>
    }

    render() {
        let loader = null;

        if (this.props.updateLoading) {
            loader = <Loader message="Loading..." />
        }

        return <>
            <div className="page-wrapper">
                <Navigation onClick={this.props._onSideMenu} />
                <div className={`main-content d-flex flex-column ${this.props.sideMenu ? "" : "hide-sidemenu"}`}>
                    {/* Loader */}
                    {loader}
                    {/* End Loader */}

                    <div className="main-content-header">
                        <Breadcrumb>
                            <h1>Edit Companies</h1>
                            <Link to={routerConstants.dashboard} className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Link to={routerConstants.listCompany} className="breadcrumb-item">
                                List Companies
                            </Link>
                            <Breadcrumb.Item active>
                               Edit Companies
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>

                    {null !== this.props.errorEdit &&
                        <div className="alert alert-danger" role="alert">
                            Fail
                        </div>
                    }

                    <Row>
                        <Col lg={12}>
                            {
                                this.formularCompany()
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    }
}

const EditCompanyHookForm = (props) => {
    const {
        register,
        handleSubmit,
    } = useForm();

    return <EditCompanyComponents 
        {...props}
        register={register}
        handleSubmit={handleSubmit}
    />
}

const mapStateToProps = state => {
    return {
        updateLoading : state.company.setUpdateLoadingCompany,
        successEdit   : state.company.setUpdateSuccedCompany,
        errorEdit     : state.company.setErrorUpdateCompany
    };
}

const mapActionsToProps = {
    updateSingleCompany
}

const EditCompanyComponentsConnected = connect(
    mapStateToProps,
    mapActionsToProps
)(EditCompanyHookForm)

export default withRouter(EditCompanyComponentsConnected);
