import React                 from 'react'
import Navigation            from '../../components/Navigation/Navigation';
import routerConstants       from "../../constants/router-constants";
import { Link }              from 'react-router-dom';
import { 
    Breadcrumb,
    Row,
    Col,
    Button,
    Form
}                            from 'react-bootstrap';
import { getRandomPassword } from '../../redux/Clients/clients.async-actions';
import { connect }           from 'react-redux';
import { useForm }           from 'react-hook-form';
import  CopyToClipBoard      from 'react-copy-to-clipboard';

class SingleClientGetResetPassword extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            sideMenu   : true,
            value      : '',
            copied     : false
        };
    }

    handleChange(event) {
        this.setState({value: event.target.value});
    } 

    // Toggle side bar menu
    _onSideMenu = active => {
        this.setState({sideMenu: active});
    };

    resetPassword = () => {
        this.props.getRandomPassword(this.props.match.params.client_uuid);
    }

    render() {
        return (
            <div className="page-wrapper">
                <Navigation onClick={this._onSideMenu} />
                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? "" : "hide-sidemenu"}`}>
                    <div className="main-content-header">
                        <Breadcrumb>
                            <h1>Reset Password</h1>
                            <Link to={routerConstants.dashboard} className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Breadcrumb.Item active>
                                Reset Password
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <Row>
                        <Col lg={12}>
                            <div className="profile-settings-form mb-3">
                                <Form onSubmit={this.props.handleSubmit(this.resetPassword)}>
                                    <Form.Group>
                                        <Form.Label>Get a new password</Form.Label>
                                        <Form.Control
                                            name="Reset Password"
                                            type="text"
                                            className="form-control"
                                            value={this.props.setPassword.password}
                                            onChange={this.handleChange}
                                            readOnly
                                        />
                                    </Form.Group>
                                    <div className="p-2 row text-center ">
                                        <div className="col ">
                                            <Button
                                                variant="primary"
                                                type="submit"
                                            >
                                                Get a temporary password
                                            </Button>
                                        </div> 
                                    </div>
                                    <div className="row text-center">
                                        <div className="col">
                                            <CopyToClipBoard text={this.props.setPassword.password} onCopy={() => this.setState({copied: true})}>
                                                <Button variant="primary">
                                                    Copy
                                                </Button>
                                            </CopyToClipBoard>
                                            {this.state.copied ? <span style={{color : 'red'}}> Copied !</span> : null}
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

const SingleClientGetResetPasswordHookForm = (props) => {
    const {handleSubmit} = useForm();
    
    return <SingleClientGetResetPassword 
        {...props}
        handleSubmit={handleSubmit}
    />
}

const mapStateToProps = state => {
    return {
        setPassword : state.clients.setRandomPassword,
    }
}

const mapActionsToProps = {
    getRandomPassword,
}

const SingleClientGetResetPasswordConnected = connect(
    mapStateToProps,
    mapActionsToProps
)(SingleClientGetResetPasswordHookForm)

export default SingleClientGetResetPasswordConnected;
