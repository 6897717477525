import React                                    from "react";
import Navigation                               from "../../../components/Navigation/Navigation";
import {Link, withRouter}                       from "react-router-dom";
import routerConstants                          from "../../../constants/router-constants";
import {Breadcrumb, Button, Card, Col, Row}     from "react-bootstrap";
import {useForm}                                from "react-hook-form";
import Select                                   from "react-select";
import {getProductsList}                        from "../../../redux/Products/products.async-actions";
import {getWebsites}                            from "../../../redux/Websites/websites.async-actions";
import {connect}                                from "react-redux";
import {getAllEmailTemplatesList, postServices} from "../../../redux/Services/services.async-actions";
import Loader                                   from "../../../components/Common/Loader";

class CreateServices extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sideMenu          : true,
            websitesObj       : {},
            productsObj       : {},
            websites          : {},
            products          : {},
            emailTemplates    : {},
            emailTemplatesList: []
        };

        this.websitesOptions = [];
        this.productsOptions = [];
    }

    goToRoute(route) {
        this.props.history.push(route);
    }

    async componentDidMount() {
        await this.props.getProductsList();
        await this.props.getWebsites();
        await this.props.getAllEmailTemplatesList();

        if (null === this.props.errorWebsite) {
            this.websitesOptions = this.props.websiteList.map(
                website => {
                    return {
                        value: website["@id"],
                        label: website.domain
                    };
                }
            );
            this.setState(
                {
                    websitesObj: this.websitesOptions
                }
            );
        }

        if (null === this.props.errorProduct) {
            this.productsOptions = this.props.productList.map(
                product => {
                    return {
                        value: product["@id"],
                        label: product.name
                    };
                }
            );
            this.setState(
                {
                    productsObj: this.productsOptions
                }
            );
        }

        this
            .setState(
                {
                    emailTemplatesList: [
                        ...this
                            .props
                            .serviceEmailTemplates
                            .map(
                                template => {
                                    return {
                                        value: template["@id"],
                                        label: template.name
                                    };
                                }
                            )
                    ]
                }
            );
    }

    onWebsitesValueChange      = selection => this.setState({websites: selection.map(option => option.value)});
    onProductsValueChange      = selection => this.setState({products: selection?.value ?? ""});
    onEmailTemplateValueChange = emailTemplates => this.setState({emailTemplates});

    formularServices = () => {
        return <>
            <Card>
                <Card.Body>
                    <Card.Title>Create Services</Card.Title>
                    <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                        <div className="row mb-3">
                            <div className="col">
                                <label
                                    htmlFor="companyName"
                                >
                                    Name
                                </label>
                                <input
                                    id="companyName"
                                    type="text"
                                    className="form-control"
                                    placeholder="Name"
                                    name="name"
                                    {...this.props.register(
                                        "name",
                                        {
                                            required: {
                                                value  : true,
                                                message: <span style={{color: "red"}}>This field is required</span>
                                            }
                                        }
                                    )}
                                />
                                {this?.props?.errors?.name?.message}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label
                                    htmlFor="description"
                                >
                                    Description
                                </label>
                                <input
                                    id="description"
                                    type="text"
                                    className="form-control"
                                    placeholder="description"
                                    name="description"
                                    {...this.props.register("description")}
                                />
                                {this?.props?.errors?.description?.message}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label
                                    htmlFor="slug"
                                >
                                    Slug
                                </label>
                                <input
                                    id="slug"
                                    type="text"
                                    className="form-control"
                                    placeholder="Slug"
                                    name="slug"
                                    {...this.props.register(
                                        "slug",
                                        {
                                            required: {
                                                value  : true,
                                                message: <span style={{color: "red"}}>This field is required</span>
                                            }
                                        }
                                    )}
                                />
                                {this?.props?.errors?.slug?.message}
                            </div>
                        </div>

                        <label>Product Subscription</label>
                        <Select
                            value={this.props.productSubscription}
                            onChange={this.onProductsValueChange}
                            options={this.productsOptions}
                            backspaceRemovesValue={true}
                            escapeClearsValue={true}
                            menuPlacement={"auto"}
                            placeholder="Product Subscription"
                        />
                        {
                            this.props.errorProduct !== null &&
                            <span className="text-danger">
                                Something went wrong and the product subscription cannot be loaded. Please
                                contact the development team to ask for more information.
                            </span>
                        }

                        <label>Website</label>
                        <Select
                            value={this.props.website}
                            options={this.websitesOptions}
                            onChange={this.onWebsitesValueChange}
                            backspaceRemovesValue={true}
                            escapeClearsValue={true}
                            isMulti
                            menuPlacement={"auto"}
                            placeholder="Website"
                        />
                        {
                            this.props.errorWebsite !== null &&
                            <span className="text-danger">
                                Something went wrong and the website cannot be loaded. Please
                                contact the development team to ask for more information.
                            </span>
                        }

                        <label>Welcome Email</label>
                        <Select
                            options={this.state.emailTemplatesList}
                            onChange={this.onEmailTemplateValueChange}
                            menuPlacement={"auto"}
                            isSearchable
                            isClearable
                        />
                        {
                            null !== this.props.serviceEmailTemplatesError &&
                            <span className="text-danger">
                                Something went wrong and the email templates cannot be loaded. Please
                                contact the development team to ask for more information.
                            </span>
                        }

                        <div style={{padding: "10px"}} />

                        <div className="text-center">
                            <Button
                                variant="primary"
                                type="submit"
                            >
                                Create Service
                            </Button>
                        </div>
                    </form>
                </Card.Body>
            </Card>
        </>;
    };

    onSubmit = (data) => {
        const superData = {
            slug        : data.slug,
            name        : data.name,
            description : data.description,
            productsId  : this.state.products,
            websitesId  : this.state.websites,
            welcomeEmail: this?.state?.emailTemplates?.value ?? null
        };

        this.props.postServices(superData);

        if (this.props.loadingServices) {
            this.goToRoute(`/list-services`);
        }
    };

    _onSideMenu = (active) => {
        this.setState({sideMenu: active});
    };

    render() {
        let loader = null;

        if (
            this.props.loadingServices ||
            this.props.serviceEmailTemplatesLoading
        ) {
            return <Loader message="Loading..." />;
        }

        return <>
            <div className="page-wrapper">
                {/* Navigation */}
                <Navigation onClick={this._onSideMenu} />
                {/* End Navigation */}

                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? "" : "hide-sidemenu"}`}>
                    {loader}

                    {true === this.props.createSuccessServices &&
                        <div className="alert alert-success" role="alert">
                            Success
                        </div>
                    }

                    {null !== this.props.createErrorServices &&
                        <div className="alert alert-danger" role="alert">
                            Fail
                        </div>
                    }

                    <div className="main-content-header">
                        <Breadcrumb>
                            <h1>Create Services</h1>
                            <Link to={routerConstants.dashboard} className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Link to={routerConstants.listServices} className="breadcrumb-item">
                                List Services
                            </Link>
                            <Breadcrumb.Item active>
                                Create Services
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <Row>
                        <Col lg={12}>
                            {
                                this.formularServices()
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </>;
    }
}

const CreateServicesHookForm = (props) => {
    const {register, handleSubmit, formState: {errors}} = useForm();

    return <CreateServices
        {...props}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
    />;
};

const mapStateToProps = state => {
    return {
        productList                 : state.products.productsList,
        websiteList                 : state.websites.set_websites,
        errorWebsite                : state.websites.website_form_fail,
        errorProduct                : state.products.productListError,
        createErrorServices         : state.services.setCreateServicesError,
        createSuccessServices       : state.services.setCreateServicesSuccess,
        loadingServices             : state.services.setCreateLoadingServices,
        serviceEmailTemplates       : state.services.serviceEmailTemplates,
        serviceEmailTemplatesLoading: state.services.serviceEmailTemplatesLoading,
        serviceEmailTemplatesError  : state.services.serviceEmailTemplatesError
    };
};

const mapActionsToProps = {
    getProductsList,
    getWebsites,
    postServices,
    getAllEmailTemplatesList
};

const CreateServicesConnected = connect(
    mapStateToProps,
    mapActionsToProps
)(CreateServicesHookForm);

export default withRouter(CreateServicesConnected);
