import React                                from "react";
import Navigation                           from "../../components/Navigation/Navigation";
import {
    Alert,
    Badge,
    Breadcrumb,
    Button,
    ButtonGroup,
    Col,
    Dropdown,
    DropdownButton,
    Form,
    FormControl,
    InputGroup,
    Row,
    Tab,
    Table,
    Tabs
}                                           from "react-bootstrap";
import Footer                               from "../../pages/Footer/Footer";
import Loader                               from "../../components/Common/Loader";
import {connect}                            from "react-redux";
import {generatePath, Link, withRouter}     from "react-router-dom";
import {getClientsList, meiliSearchClients} from "../../redux/Clients/clients.async-actions";
import {getProductsList}                    from "../../redux/Products/products.async-actions";
import DateUtils                            from "../../services/DateUtils";
import routerConstants                      from "../../constants/router-constants";
import * as Icon                            from "react-feather";
import Paginator                            from "../../components/Common/Paginator";
import {getOrdersServicesDropDownOptions}   from "../../redux/Services/services.async-actions";
import {getPaymentGatewayList}              from "../../redux/PaymentGateway/payment-gateway.async-actions";
import JsCookie                             from "js-cookie";
import {resetMeiliClientsPagination}        from "../../redux/Clients/clients.actions";
import store                                from "../../redux/store";
import ListSorter                           from "../../Utils/ListSorter";

class ListClients extends React.Component {

    state = {
        sideMenu      : true,
        activeTab     : "clients",
        searchCriteria: {
            clients  : {
                searchField     : "any_field",
                searchFieldLabel: "",
                searchTerm      : "",
                fromDate        : "",
                toDate          : "",
                paymentGateway  : "",
                service         : "",
                currentPage     : 1
            },
            prospects: {
                searchField     : "any_field",
                searchFieldLabel: "",
                searchTerm      : "",
                orderBy         : "first_purchase_created:desc",
                fromDate        : "",
                toDate          : "",
                paymentGateway  : "",
                service         : "",
                currentPage     : 1
            }
        }
    };

    constructor(props) {
        super(props);

        this.searchField            = React.createRef();
        this.searchTerm             = React.createRef();
        this.fromDate               = React.createRef();
        this.toDate                 = React.createRef();
        this.paymentGateway         = React.createRef();
        this.service                = React.createRef();
        this.resetFormFiltersMethod = this.resetFormFilters.bind(this);
        this.getPageToGoMethod      = this.getPageToGo.bind(this);

        this.searchFields = [
            {
                key  : "any_field",
                value: "Any Field"
            },
            {
                key  : "customer_first_name",
                value: "First Name"
            },
            {
                key  : "customer_last_name",
                value: "Last Name"
            },
            {
                key  : "custom_email",
                value: "Email"
            },
            {
                key  : "customer_data.license_plate",
                value: "License Plate Number"
            },
            {
                key  : "customer_data.driving_license_number",
                value: "Driving License Number"
            },
            {
                key  : "customer_data.company_name",
                value: "Company Name"
            },
            {
                key  : "customer_data.vat_number",
                value: "VAT Number"
            },
            {
                key  : "customer_data.fiscal_code",
                value: "Fiscal Code"
            },
            {
                key  : "order_number",
                value: "Order Number"
            },
            {
                key  : "comments",
                value: "Comment Text"
            }
        ];
    }

    // Loading icon false after DOM loaded
    async componentDidMount() {
        this.fakeTabStyling();

        await this.props.getOrdersServicesDropDownOptions();
        await this.props.getPaymentGatewayList();

        this.loadSearchFormValuesFromCookies();

        window.requestAnimationFrame(() => this.setSearchFieldLabel());
        window.requestAnimationFrame(async () => await this.searchOrders());
    }

    setSearchFieldLabel() {
        let currentState = {
            ...this.state.searchCriteria
        };
        [
            "clients",
            "prospects"
        ].forEach(
            purchaseState => {
                const searchFieldLabel = this
                    .searchFields
                    .filter(
                        field => {
                            return field.key === this.state.searchCriteria[purchaseState].searchField;
                        }
                    )[0].value;
                currentState           = {
                    ...currentState,
                    [purchaseState]: {
                        ...currentState[purchaseState],
                        searchFieldLabel
                    }
                };
            }
        );
        this
            .setState(
                {
                    searchCriteria: {
                        ...currentState
                    }
                }
            );
    }

    fakeTabStyling = () => {
        let style = document.querySelector("style#fakeTab");

        if (null === style) {
            const rules     = [
                "border-bottom: none",
                "border-left: none",
                "border-right: none",
                "padding: 0"
            ];
            style           = document.createElement("style");
            style.id        = "fakeTab";
            style.innerText = `.fakeTab + .tab-content {${rules.join(";")}`;
            document.head.appendChild(style);
        }
    };

    /**
     * Read the Search Form values from Cookie.
     */
    loadSearchFormValuesFromCookies() {
        const cookie = JsCookie.get("csfv");

        if (typeof undefined !== typeof cookie) {
            const [searchCriteria, tab] = cookie.split("=+=+=+=").map(part => atob(part));

            [
                "clients",
                "prospects"
            ].forEach(
                clientType => {
                    if (searchCriteria?.[clientType]?.selectedFieldsIds) {
                        searchCriteria[clientType].selectedFieldsIds = [];
                    }
                }
            );

            const newState = {
                searchCriteria: {
                    ...this.state.searchCriteria,
                    ...JSON.parse(searchCriteria)
                },
                activeTab     : tab
            };

            this.setState(newState);

            window.requestAnimationFrame(
                () => {
                    const tabElement = document.querySelector(`#${tab}Tab`);
                    tabElement && tabElement.click();
                }
            );
        }
    }

    // Toggle side bar menu
    _onSideMenu = (active) => {
        this.setState({sideMenu: active});
    };

    getPageToGo = currentPage => {
        const tab = this.state.activeTab;

        if (currentPage) {
            this
                .setState(
                    {
                        searchCriteria: {
                            ...this.state.searchCriteria,
                            [tab]: {
                                ...this.state.searchCriteria[tab],
                                ...{
                                    currentPage: currentPage
                                }
                            }
                        }
                    }
                );

            window.requestAnimationFrame(async () => await this.searchOrders());
        }
    };

    /**
     * Responsible to list the table rows containing the clients information.
     * @returns {HTMLTableRowElement[]}
     */
    mapClients = () => {
        const tab       = this.state.activeTab;
        const dateField = "prospects" === tab ? "order_created_at" : "order_created_at";

        return this
            .props
            .clients
            .map(
                client => {
                    return <tr key={client.id}>
                        <td>
                            <Link
                                to={
                                    generatePath(
                                        routerConstants.listSingleClient,
                                        {
                                            client_uuid: client.id
                                        }
                                    )
                                }
                            >
                                {client.custom_email}
                                <br />
                                <small>{client.customer_last_name} {client.customer_first_name}</small>
                            </Link>
                        </td>
                        <td>
                            {DateUtils.formatUnixTimestampToFormat(client[dateField])}
                            &nbsp;
                            <small>({DateUtils.formatTimestampToHumanReadable(client[dateField])})</small>
                        </td>
                        <td>
                            {client.service_name}
                        </td>
                        <td>
                            {client.website_domain}
                        </td>
                        <td>
                            {client.payment_gateway}
                        </td>
                        <td>
                            <Badge
                                pill
                                variant={client.order_status ? "success" : "danger"}
                                className="mt-2 mr-2"
                            >
                                {client.order_status ? "Active" : "Inactive"}
                            </Badge>
                        </td>
                    </tr>;
                }
            );
    };

    resetFormFilters = async e => {
        e.stopPropagation();
        e.preventDefault();

        const tab                   = this.state.activeTab;
        const defaultSearchCriteria = {
            clients  : {
                searchField     : "any_field",
                searchFieldLabel: "",
                searchTerm      : "",
                fromDate        : "",
                toDate          : "",
                paymentGateway  : "",
                service         : "",
                currentPage     : 1
            },
            prospects: {
                searchField     : "any_field",
                searchFieldLabel: "",
                searchTerm      : "",
                fromDate        : "",
                orderBy         : "first_purchase_created:desc",
                toDate          : "",
                paymentGateway  : "",
                service         : "",
                currentPage     : 1
            }
        };
        const newState              = {
            searchCriteria: {
                ...this.state.searchCriteria,
                [tab]: {
                    ...this.state.searchCriteria[tab],
                    ...defaultSearchCriteria[tab]
                }
            }
        };
        await this.setState(newState);

        this.searchField.current.value    = "any_field";
        this.searchTerm.current.value     = "";
        this.fromDate.current.value       = "";
        this.toDate.current.value         = "";
        this.paymentGateway.current.value = "";
        this.service.current.value        = "";

        this.resetPagination();

        window.requestAnimationFrame(
            () => {
                JsCookie.remove(
                    "csfv",
                    {
                        path: "/"
                    }
                );
            }
        );
        window.requestAnimationFrame(
            () => {
                let cookieValue = btoa(JSON.stringify(newState));
                let tabValue    = btoa(tab);
                JsCookie.set(
                    // csfv = Client Search Form Values
                    "csfv",
                    `${cookieValue}=+=+=+=${tabValue}`,
                    {
                        expires: 1 / 24,
                        path   : "/"
                    }
                );
            }
        );
        window.requestAnimationFrame(() => this.setSearchFieldLabel());
        window.requestAnimationFrame(async () => await this.searchOrders());
    };

    /**
     * For few interactions it is required to reset the pagination current page to number 1.
     */
    resetPagination() {
        const tab = this.state.activeTab;

        this
            .setState(
                {
                    searchCriteria: {
                        ...this.state.searchCriteria,
                        [tab]: {
                            ...this.state.searchCriteria[tab],
                            ...{
                                ...this.state.searchCriteria[tab],
                                ...{
                                    currentPage: 1
                                }
                            }
                        }
                    }
                }
            );
    }

    changeClientStatus = activeTab => {
        if (-1 !== ["clients", "prospects"].indexOf(activeTab)) {
            this.setState({activeTab});
            this.searchField.current.value    = this.state.searchCriteria[activeTab].searchField;
            this.searchTerm.current.value     = this.state.searchCriteria[activeTab].searchTerm;
            this.fromDate.current.value       = this.state.searchCriteria[activeTab].fromDate;
            this.toDate.current.value         = this.state.searchCriteria[activeTab].toDate;
            this.paymentGateway.current.value = this.state.searchCriteria[activeTab].paymentGateway;
            this.service.current.value        = this.state.searchCriteria[activeTab].service;
        }

        window.requestAnimationFrame(() => this.searchOrders());
    };

    filterForm = () => {
        const tab = this.state.activeTab;

        const updateSearchFieldState = async key => {
            this
                .setState(
                    {
                        searchCriteria: {
                            ...this.state.searchCriteria,
                            [tab]: {
                                ...this.state.searchCriteria[tab],
                                ...{
                                    searchField: key,
                                    searchTerm : "",
                                    currentPage: 1
                                }
                            }
                        }
                    }
                );
            this.searchTerm.current.value = "";
            this.searchTerm.current.focus();

            window.requestAnimationFrame(() => this.setSearchFieldLabel());
            window.requestAnimationFrame(
                async () => {
                    store.dispatch(resetMeiliClientsPagination());
                    await this.searchOrders();
                }
            );
        };
        const updateSearchTermState  = async searchTerm => {
            this.setState(
                {
                    searchCriteria: {
                        ...this.state.searchCriteria,
                        [tab]: {
                            ...this.state.searchCriteria[tab],
                            ...{
                                searchTerm : searchTerm.target.value,
                                currentPage: 1
                            }
                        }
                    }
                }
            );

            window.requestAnimationFrame(
                async () => {
                    store.dispatch(resetMeiliClientsPagination());
                    await this.searchOrders();
                }
            );
            return true;
        };

        return <Form
            onReset={this.filterClientsMethod}
            className="mb-2"
        >
            <Row className="mb-1">
                <Col>
                    <InputGroup className="mb-3">
                        <DropdownButton
                            as={InputGroup.Prepend}
                            variant="outline-primary"
                            title={this.state.searchCriteria[tab].searchFieldLabel}
                            id="input-group-dropdown-1"
                            ref={this.searchField}
                        >
                            {
                                this
                                    .searchFields
                                    .map(
                                        field => {
                                            return <Dropdown.Item
                                                eventKey={field.key}
                                                key={`field_${field.key}`}
                                                onSelect={key => updateSearchFieldState(key)}
                                            >
                                                {field.value}
                                            </Dropdown.Item>;
                                        }
                                    )
                            }
                        </DropdownButton>
                        <FormControl
                            as={"input"}
                            onKeyUp={e => updateSearchTermState(e)}
                            defaultValue={this.state.searchCriteria[tab].searchTerm}
                            ref={this.searchTerm}
                        />
                    </InputGroup>
                </Col>
            </Row>

            <Row className="mb-1">
                <Col>
                    <Form.Group controlId="filterForm.fromDate">
                        <Form.Control
                            type="date"
                            ref={this.fromDate}
                            onChange={
                                () => {
                                    this.resetPagination();
                                    store.dispatch(resetMeiliClientsPagination());

                                    this.setState(
                                        {
                                            searchCriteria: {
                                                ...this.state.searchCriteria,
                                                [tab]: {
                                                    ...this.state.searchCriteria[tab],
                                                    ...{
                                                        fromDate: this.fromDate.current.value
                                                    }
                                                }
                                            }
                                        }
                                    );

                                    window.requestAnimationFrame(async () => await this.searchOrders());
                                }
                            }
                        />
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Control
                        type="date"
                        ref={this.toDate}
                        onChange={
                            () => {
                                this.resetPagination();
                                store.dispatch(resetMeiliClientsPagination());

                                this.setState(
                                    {
                                        searchCriteria: {
                                            ...this.state.searchCriteria,
                                            [tab]: {
                                                ...this.state.searchCriteria[tab],
                                                ...{
                                                    toDate: this.toDate.current.value
                                                }
                                            }
                                        }
                                    }
                                );

                                window.requestAnimationFrame(async () => await this.searchOrders());
                            }
                        }
                    />
                </Col>

                <Col>
                    <Form.Control
                        as="select"
                        aria-describedby="servicesHelpBlock"
                        ref={this.service}
                        onChange={
                            () => {
                                this.resetPagination();
                                store.dispatch(resetMeiliClientsPagination());

                                this.setState(
                                    {
                                        searchCriteria: {
                                            ...this.state.searchCriteria,
                                            [tab]: {
                                                ...this.state.searchCriteria[tab],
                                                ...{
                                                    service: this.service.current.value
                                                }
                                            }
                                        }
                                    }
                                );

                                window.requestAnimationFrame(async () => await this.searchOrders());
                            }
                        }
                    >
                        <option value="">Service</option>
                        {
                            ListSorter.orderListByProperty(
                                this.props.ordersServicesList, 'slug'
                            ).map(
                                service => <option
                                    key={service["@id"]}
                                    value={service?.slug}
                                >
                                    {service?.name}
                                </option>
                            )
                        }
                    </Form.Control>
                    {
                        null !== this.props.ordersServicesListError &&
                        <Form.Text
                            id="servicesHelpBlock"
                            className="text-danger"
                        >
                            {this.props.ordersServicesListError}
                        </Form.Text>
                    }
                </Col>

                <Col>
                    <Form.Control
                        as="select"
                        aria-describedby="productsHelpBlock"
                        ref={this.paymentGateway}
                        onChange={
                            () => {
                                this.resetPagination();
                                store.dispatch(resetMeiliClientsPagination());

                                this.setState(
                                    {
                                        searchCriteria: {
                                            ...this.state.searchCriteria,
                                            [tab]: {
                                                ...this.state.searchCriteria[tab],
                                                ...{
                                                    paymentGateway: this.paymentGateway.current.value
                                                }
                                            }
                                        }
                                    }
                                );

                                window.requestAnimationFrame(async () => await this.searchOrders());
                            }
                        }
                    >
                        <option value="">Payment Gateway</option>
                        {
                            ListSorter.orderListByProperty(
                                this.props.paymentGateway, 'name'
                            ).map(
                                gateway => <option
                                    key={gateway["@id"]}
                                    value={gateway.name}
                                >
                                    {gateway.name}
                                </option>
                            )
                        }
                    </Form.Control>
                    {
                        null !== this.props.clientsError &&
                        <Form.Text
                            id="productsHelpBlock"
                            className="text-danger"
                        >
                            {this.props.clientsError}
                        </Form.Text>
                    }
                </Col>

                <Col xs={"auto"}>
                    <ButtonGroup size="sm" aria-label="Form Action Buttons">
                        <Button
                            variant="dark"
                            onClick={this.resetFormFiltersMethod}
                        >
                            <Icon.Delete
                                className="icon"
                            />
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </Form>;
    };

    searchOrders = async () => {
        await new Promise(resolve => setTimeout(resolve, 250)); // Sleep for 250ms before continue
        const convertDateToUnixTimestamp = (dateString, from = true) => {
            const dateObject = new Date(dateString);
            if (from) {
                dateObject.setHours(0, 0, 0, 0);
            } else {
                dateObject.setHours(23, 59, 59, 99);
            }
            const timestamp = dateObject.getTime() / 1000;
            return Math.floor(timestamp);
        };
        const tab                        = this.state.activeTab;
        const dateField                  = "prospects" === tab ? "order_created_at" : "subscription_date";
        const searchParams               = {
            filter: `is_prospect = ${"prospects" === tab}`,
            sort  : [
                `${dateField}:desc`
            ],
            page  : parseInt(this.state.searchCriteria[tab].currentPage, 10)
        };

        if (
            "" !== this.state.searchCriteria[tab].searchField &&
            "" !== this.state.searchCriteria[tab].searchTerm
        ) {
            searchParams.q = `${this.state.searchCriteria[tab].searchTerm}`;
            if ("any_field" !== this.state.searchCriteria[tab].searchField) {
                searchParams.attributesToSearchOn = [
                    this.state.searchCriteria[tab].searchField
                ];
            }
        }

        const fromDateString = this.state.searchCriteria[tab].fromDate;
        let fromTimestamp    = null;
        if ("" !== fromDateString) {
            fromTimestamp = convertDateToUnixTimestamp(fromDateString);

            if (isNaN(fromTimestamp)) {
                fromTimestamp = null;
            }
        }

        const toDateString = this.state.searchCriteria[tab].toDate;
        let toTimestamp    = null;
        if ("" !== toDateString) {
            toTimestamp = convertDateToUnixTimestamp(toDateString, false);

            if (isNaN(toTimestamp)) {
                toTimestamp = null;
            }
        }

        switch (true) {
            case null !== fromTimestamp && null === toTimestamp:
                searchParams.filter += ` AND ${dateField} >= ${fromTimestamp}`;
                break;
            case null === fromTimestamp && null !== toTimestamp:
                searchParams.filter += ` AND ${dateField} <= ${toTimestamp}`;
                break;
            case null !== fromTimestamp && null !== toTimestamp:
                searchParams.filter += ` AND ${dateField} ${fromTimestamp} TO ${toTimestamp}`;
                break;
        }

        const service = this.state.searchCriteria[tab].service;
        if ("" !== service) {
            searchParams.filter += ` AND service_slug = ${service}`;
        }

        const paymentGateway = this.state.searchCriteria[tab].paymentGateway;
        if ("" !== paymentGateway) {
            searchParams.filter += ` AND payment_gateway = "${paymentGateway}"`;
        }

        if (tab === "prospects") {
            searchParams.sort[0] = "first_purchase_created:desc";
        }

        let cookieValue = btoa(JSON.stringify(this.state.searchCriteria));
        let tabValue    = btoa(tab);
        JsCookie.set(
            // csfv = Client Search Form Values
            "csfv",
            `${cookieValue}=+=+=+=${tabValue}`,
            {
                expires: 1 / 24,
                path   : "/"
            }
        );

        setTimeout(
            async () => await this.props.meiliSearchClients(searchParams),
            parseInt(process.env.REACT_APP_MEILI_SEARCH_DELAY, 10)
        );
        window.scrollTo(0, 0);
    };

    render() {
        const tab       = this.state.activeTab;
        const dateField = "prospects" === tab ? "Created At" : "Subscription Date";
        let loader      = null;

        if (this.props.loadingClientsList || this.props.loadingProductsList) {
            loader = <Loader message="Loading..." />;
        }

        if (!this.props.clients) {
            return <></>;
        }

        return <div className="page-wrapper">
            {/* Navigation */}
            <Navigation onClick={this._onSideMenu} />
            {/* End Navigation */}

            <div className={`main-content d-flex flex-column ${this.state.sideMenu ? "" : "hide-sidemenu"}`}>
                {/* Loader */}
                {loader}
                {/* End Loader */}

                {/* Breadcrumb */}
                <div className="main-content-header">
                    <Breadcrumb>
                        <h1>Customer Clients</h1>
                        <Link to={routerConstants.dashboard} className="breadcrumb-item">
                            Dashboard
                        </Link>
                        <Breadcrumb.Item active>
                            Clients
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                {/* End Breadcrumb */}

                <Row>
                    <Col lg={12}>
                        <div className="card mb-4">
                            <div className="card-body">
                                <div className="card-header d-flex">
                                    <h5 className="card-title w-50 float-left">Clients</h5>
                                </div>

                                {
                                    this.props.clientsError !== null
                                        ?
                                        <Alert variant="danger" className="rounded">
                                            {this.props.clientsError}
                                        </Alert> :
                                        ""
                                }

                                <div className="tabs-style-three">
                                    <Tabs
                                        className="fakeTab"
                                        defaultActiveKey={this.state.activeTab}
                                        activeKey={this.state.activeTab}
                                        onSelect={activeKey => this.changeClientStatus(activeKey)}
                                    >
                                        <Tab
                                            eventKey="clients"
                                            id="clientsTab"
                                            title="Clients"
                                        />
                                        <Tab
                                            eventKey="prospects"
                                            id="prospectsTab"
                                            title="Prospects"
                                        />
                                    </Tabs>
                                </div>
                                <div
                                    className="tabContainer"
                                    style={{padding: "20px", border: "1px solid #dee2e6", borderTop: "none"}}
                                >
                                    {this.filterForm()}

                                    <Table className="m-0" responsive>
                                        <thead>
                                        <tr key="table_headers">
                                            <th width={"25%"}>Email Address</th>
                                            <th width={"25%"}>{dateField}</th>
                                            <th width={"20%"}>Service</th>
                                            <th width={"20%"}>Website Domain</th>
                                            <th width={"20%"}>Payment Gateway</th>
                                            <th>Status</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.props.clients.length === 0 ?
                                                <tr key="no_clients">
                                                    <td colSpan={5} className="text-center">No Clients Yet</td>
                                                </tr> :
                                                this.mapClients()
                                        }
                                        </tbody>
                                    </Table>

                                    <Paginator
                                        current={this.state.searchCriteria[tab].currentPage}
                                        first={this.props.clientsFirstPage}
                                        last={this.props.clientsLastPage}
                                        next={this.props.clientsNextPage}
                                        prev={this.props.clientsPrevPage}
                                        onClickFirst={e => this.getPageToGoMethod(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                {/* Footer */}
                <div className="flex-grow-1" />
                <Footer />
                {/* End Footer */}
            </div>
        </div>;
    }
}

const mapStateToProps = state => {
    return {
        clients                : state.clients.meiliClients,
        clientsError           : state.clients.meiliClientsError,
        clientsFirstPage       : state.clients.meiliClientsFirstPage,
        clientsLastPage        : state.clients.meiliClientsLastPage,
        clientsNextPage        : state.clients.meiliClientsNextPage,
        clientsPrevPage        : state.clients.meiliClientsPrevPage,
        loadingClientsList     : state.clients.loadingClientsList,
        clientsListError       : state.clients.clientsListError,
        products               : state.products.productsList,
        productsListError      : state.products.productListError,
        loadingProductsList    : state.products.loadingProductsList,
        ordersServicesList     : state.services.ordersServicesList,
        ordersServicesListError: state.services.ordersServicesListError,
        paymentGateway         : state.paymentGateway.paymentGatewayList,
        searchBarValue         : state.globalState.filterSearchBarValueClientPage
    };
};

const mapActionToProps = {
    getClientsList,
    getProductsList,
    getOrdersServicesDropDownOptions,
    getPaymentGatewayList,
    meiliSearchClients,
    resetMeiliClientsPagination
};

const ListClientsConnected = connect(mapStateToProps, mapActionToProps)(ListClients);

export default withRouter(ListClientsConnected);
