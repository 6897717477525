import React           from "react"
import { useSelector } from "react-redux";
import {
    setIsTextCopied
}                      from "../../redux/Purchases/purchases.actions";
import store           from "../../redux/store";
import DateUtils       from "../../services/DateUtils";
import StringFormater  from "../../services/StringFormater";

const CopyDatabutton = (copyValue) => {
    const isCopied = useSelector(state => state.purchases.isTextCopied)
    let data;

    const copyData = async(e) => {

        if (
            DateUtils.isDateGetSlash(copyValue.data)
            && copyValue.label === "Date Of Birth"
            || copyValue.label === "Date_of_birth"
        ) {
            data = DateUtils.getNumbersWithoutSlash(copyValue.data)
        }

        if (
            copyValue.label === "Driving Licence Number"
            || copyValue.label === "Driving_licence_number"
        ) {
            data = StringFormater.replaceLettersWithAsterisks(copyValue.data)
        }

        if (
            copyValue.label !== "Driving Licence Number"
            && copyValue.label !== "Driving_licence_number"
            && !DateUtils.isDateGetSlash(copyValue.data)
        ) {
            data = copyValue.data;
        }

        try {
            const allowedToCopy = await navigator
                .permissions
                .query(
                    {
                        name: "clipboard-write"
                    }
                );

            if ("denied" === allowedToCopy.state) {
                alert("You should allow clipboard access to use this functionality");
                store.dispatch(setIsTextCopied(false))
                return;
            }

            if ("granted" === allowedToCopy.state || "prompt" === allowedToCopy.state) {
                try {
                    await navigator.clipboard.writeText(data);
                    store.dispatch(setIsTextCopied(true))
                } catch (e) {
                    alert("Value wasn't copied to clipboard.");
                    store.dispatch(setIsTextCopied(false))
                }
            }
        } catch (e) {
            alert("You should allow clipboard access to use this functionality");
            store.dispatch(setIsTextCopied(false))
        }

        if (!isCopied) {
            setTimeout(() => {
                store.dispatch(setIsTextCopied(false))
            }, 3000)
        }
    }

    return <>
        <img
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMSAyMSI+CiAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4KICAgIDxwYXRoIGQ9Im0xMC41IDE0LjUtMy0zIDMtM20tMyAzaDExIi8+CiAgICA8cGF0aCBkPSJNMTYuNSA5LjV2LTRhMSAxIDAgMCAwLS44OC0xSDEzLjVtLTYgMGgtMmExIDEgMCAwIDAtMSAuODhWMTYuNWExIDEgMCAwIDAgMSAxaDEwYTEgMSAwIDAgMCAxLS44OFYxNCIvPgogICAgPHBhdGggZD0iTTguNSAzLjVoNGExIDEgMCAxIDEgMCAyaC00YTEgMSAwIDEgMSAwLTJ6Ii8+CiAgPC9nPgo8L3N2Zz4K"
            style={{cursor: "copy"}}
            onClick={e => copyData(e)}
            width="20"
            height="20"
            title="Copy to clipboard"
        />
    </>
}

export default CopyDatabutton
