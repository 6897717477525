import React                   from "react";
import Navigation              from "../../../components/Navigation/Navigation";
import {
    Breadcrumb,
    Row,
    Col,
    Form,
    Button,
    Card,
    FormCheck
}                              from "react-bootstrap";
import routerConstants         from "../../../constants/router-constants";
import {Link}                  from "react-router-dom";
import {
    updateWebsite,
    getSingleWebsite
}                              from "../../../redux/Websites/websites.async-actions";
import {getPaymentGatewayList} from "../../../redux/PaymentGateway/payment-gateway.async-actions";
import {connect}               from "react-redux";
import {withRouter}            from "react-router-dom";
import {useForm}               from "react-hook-form";
import Loader                  from "../../../components/Common/Loader";
import Select                  from "react-select";
import websiteThemes           from "./website-themes.json";
import contactFormData         from "./contact-form-data.json";

class EditWebsitesComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sideMenu          : true,
            paymentGatewayData: {},
            paymentGateway    : "",
            contactForm       : {
                label: "",
                value: ""
            },
            theme             : {
                label: "",
                value: ""
            }
        };

        this.paymentGatewayOptions = [];
    }


    WebsiteForm = () => {
        const data          = this.props.singleWebsite;
        const websiteLocale = data.locale ?? [];
        const localeName    = websiteLocale[0]?.name;
        const companyName   = data?.company?.name;
        const projectName   = data?.project?.name;
        const marketName    = data?.market?.name;

        this.props.setValue("domain", data?.domain);
        this.props.setValue("descriptor", data?.descriptor);
        this.props.setValue("supportEmail", data?.supportEmail);
        this.props.setValue("phoneNumber", data?.phoneNumber);
        this.props.setValue("gtmId", data?.gtmId);
        this.props.setValue("bannerActive", data?.cookieBannerActive);
        this.props.setValue("trengoKey", data?.trengoKey);

        let themes = [];
        let contactForms = [];

        for (let theme in websiteThemes) {
            themes.push(
                {
                    label: websiteThemes[theme],
                    value: theme
                }
            );
        }
        themes.sort((a, b) => a.label.localeCompare(b.label));

        for (let contactForm in contactFormData) {
            contactForms.push(
                {
                    label: contactFormData[contactForm],
                    value: contactForm
                }
            );
        }
        contactForms.sort((a, b) => a.label.localeCompare(b.label));

        return (
          <>
            <Card>
              <Card.Body>
                {this.props.failMessage && (
                  <div className="alert alert-danger" role="alert">
                    {this.props.failMessage}
                  </div>
                )}

                <div className="card-header">
                  <Card.Title>Edit Website</Card.Title>
                </div>

                <Form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                  <Form.Group>
                    <Form.Label>Domain</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Domain"
                      {...this.props.register("domain")}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Descriptor</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Descriptor"
                      {...this.props.register("descriptor")}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Support Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Support Email"
                      {...this.props.register("supportEmail")}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Phone Number"
                      {...this.props.register("phoneNumber")}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Gtm Id</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Gtm Id"
                      {...this.props.register("gtmId")}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Trengo Key</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Trengo Key"
                      {...this.props.register("trengoKey")}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Active Cookie Banner</Form.Label>
                    <FormCheck
                      type="switch"
                      id={`bannerActive`}
                      {...this.props.register(`bannerActive`)}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Contact Form</Form.Label>
                    <Select
                      value={this.state.contactForm}
                      options={contactForms}
                      onChange={this.onContactFormValueChange}
                      backspaceRemovesValue={true}
                      escapeClearsValue={true}
                      menuPlacement={"auto"}
                      placeholder={"Choose Website Theme"}
                    />
                  </Form.Group>

                  <Form.Row className="form-row">
                    <Form.Group className="form-group col-md-6">
                      <Form.Label>Payment Gateway</Form.Label>
                      <Select
                        value={this.state.paymentGatewayData}
                        options={this.paymentGatewayOptions}
                        onChange={this.onPaymentGatewayValueChange}
                        backspaceRemovesValue={true}
                        escapeClearsValue={true}
                        menuPlacement={"auto"}
                        placeholder={"Choose a Payment Gateway"}
                      />
                      {this.props.paymentGatewayListError !== null && (
                        <span className="text-danger">
                          Something went wrong and the services cannot be
                          loaded. Please contact the development team to ask for
                          more information.
                        </span>
                      )}
                    </Form.Group>

                    <Form.Group className="form-group col-md-6">
                      <Form.Label>Theme</Form.Label>
                      <Select
                        value={this.state.theme}
                        options={themes}
                        onChange={this.onThemeValueChange}
                        backspaceRemovesValue={true}
                        escapeClearsValue={true}
                        menuPlacement={"auto"}
                        placeholder={"Choose Website Theme"}
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row className="form-row">
                    <Form.Group className="form-group col-md-6">
                      <Form.Label>Market Name</Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue={marketName ?? undefined}
                        disabled="disabled"
                      />
                    </Form.Group>

                    <Form.Group className="form-group col-md-6">
                      <Form.Label>Project Name</Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue={projectName ?? undefined}
                        disabled="disabled"
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row className="form-row">
                    <Form.Group className="form-group col-md-6">
                      <Form.Label>Company Name</Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue={companyName ?? undefined}
                        disabled="disabled"
                      />
                    </Form.Group>

                    <Form.Group className="form-group col-md-6">
                      <Form.Label>Locale</Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue={localeName ?? undefined}
                        disabled="disabled"
                      />
                    </Form.Group>
                  </Form.Row>

                  <div className="form-group">
                    <Button variant="primary" type="submit">
                      Update Website
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </>
        );
    };

    async componentDidMount() {
        await this.props.getSingleWebsite(this.props.match.params.website_uuid);
        await this.props.getPaymentGatewayList();

        this.paymentGatewayOptions = this.props.paymentGatewayList.map(
            paymentGateway => {
                return {
                    value: paymentGateway["@id"],
                    label: paymentGateway.name
                };
            }
        );

        const themeValue = {
            label: websiteThemes?.[this.props.singleWebsite?.theme] ?? "",
            value: this.props.singleWebsite?.theme
        };

        const contactFormValue = {
            label: this.props.singleWebsite?.contactForm,
            value: contactFormData?.[this.props.singleWebsite?.contactForm] ?? ""
        };

        this.setState(
            {
                paymentGatewayData: {
                    value: this?.props?.singleWebsite?.paymentGateway?.["@id"] ?? "",
                    label: this?.props?.singleWebsite?.paymentGateway?.name ?? ""
                },
                theme             : themeValue,
                contactForm  : contactFormValue
            }
        );
    }

    onPaymentGatewayValueChange = paymentGateway => this.setState({paymentGatewayData: paymentGateway});

    onThemeValueChange = selectedOptions => {
        let values = selectedOptions.value;

        this.setState(
            {
                theme: {
                    label: websiteThemes?.[values],
                    value: values
                }
            }
        );
    };

    onContactFormValueChange = selectedOptions => {
        let values = selectedOptions.value;

        this.setState(
            {
                contactForm: {
                    label: contactFormData?.[values],
                    value: values
                }
            }
        );
    }

    _onSideMenu = (active) => {
        this.setState({sideMenu: active});
    };

    onSubmit = async (data, e) => {
        e.preventDefault();
        data.paymentGateway = this.state.paymentGatewayData;
        data.theme          = this.state.theme.value;
        data.contactForm    = this.state.contactForm.label;

        await this.props.updateWebsite(this.props.match.params.website_uuid, data);

        if (this.props.successMessage) {
            this.props.history.push(`/list-websites`);
        }
    };

    render() {
        let loader = null;

        if (this.props.loadingUpdateWebsite) {
            loader = <Loader message="Loading..." />;
        }

        return <>
            <div className="page-wrapper">
                {/* Navigation */}
                <Navigation onClick={this._onSideMenu} />
                {/* End Navigation */}

                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? "" : "hide-sidemenu"}`}>
                    {/* Loader */}
                    {loader}
                    {/* End Loader */}

                    {/* Breadcrumb */}
                    <div className="main-content-header">
                        <Breadcrumb>
                            <Link to={routerConstants.dashboard} className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Link to={routerConstants.listWebsites} className="breadcrumb-item">
                                Website List
                            </Link>
                            <Breadcrumb.Item active>
                                Website Edit
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <Row>
                        <Col lg={12}>
                            {
                                this.WebsiteForm()
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </>;
    }
}

const EditWebsitePage = (props) => {
    const {register, handleSubmit, setValue} = useForm();

    return <EditWebsitesComponent
        {...props}
        register={register}
        handleSubmit={handleSubmit}
        setValue={setValue}
    />;
};

const mapStateToProps = state => {
    return {
        singleWebsite          : state.websites.set_single_website,
        loadingWebsite         : state.websites.set_loading_websites,
        successMessage         : state.websites.website_form_success,
        failMessage            : state.websites.website_form_fail,
        paymentGatewayList     : state.paymentGateway.paymentGatewayList,
        paymentGatewayListError: state.paymentGateway.paymentGatewayListError
    };
};

const mapActionsToProps = {
    getSingleWebsite,
    getPaymentGatewayList,
    updateWebsite
};

const EditWebsitesConnected = connect(
    mapStateToProps,
    mapActionsToProps
)(EditWebsitePage);

export default withRouter(EditWebsitesConnected);
