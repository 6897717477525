const routes = {
    login                         : "/",
    forgotPassword                : "/forgot-password/",
    resetPassword                 : "/reset-password/:token/:user_uuid",
    dashboard                     : "/dashboard",
    profile                       : "/profile/",
    profileSettings               : "/profile-settings/",
    listClients                   : "/list-clients/",
    listSingleClient              : "/list-clients/:client_uuid",
    listPendingInProgressPurchases: "/list-purchases/purchases",
    listSinglePurchases           : "/list-purchases/purchase/:purchase_uuid",
    rebillBreakdown               : "/statistics/rebill-breakdown",
    salesStats                    : "/statistics/sales",
    editWebsites                  : "/list-websites/update/:website_uuid",
    listWebsites                  : "/list-websites",
    createWebsites                : "/create-websites",
    listProductSubscription       : "/product-subscription",
    editProductSubscription       : "/product-subscription/update/:product_uuid",
    createProductSubscription     : "/create-product-subscription",
    listProject                   : "/list-project",
    editProject                   : "/list-project/update/:project_uuid",
    createProject                 : "/create-project",
    createServices                : "/create-services",
    listServices                  : "/list-services",
    editServices                  : "/list-services/update/:services_uuid",
    createCompany                 : "/create-company",
    listCompany                   : "/list-company",
    editCompany                   : "/list-company/update/:company_uuid",
    singleClientGetResetPassword  : "/reset-password/:client_uuid",
    listUsers                     : "/list-users",
    createUser                    : "/create-user",
    updateUser                    : "/update-user/:user_uuid",
    statsOrderActive              : "/statistics/stats-order-active",
    listPaymentGateway            : "/payment-gateway",
    editPaymentGateway            : "/payment-gateway/:paymentGateway_uuid",
    listEmails                    : "/list-email",
    editEmailTemplate             : "/email-template/:emailTemplate_uuid",
    createNewEmailTemplate        : "/create-email-template",
    purchasesStats                : "/statistics/purchases",
    listWebsitesConfig            : "/list-websites-config",
    editWebsiteConfig             : "/list-websites-config/update/:websiteConfig_uuid",
    purchasesDeliveryTimeStats    : "/statistics/purchases-delivery-time"
};

export default routes;
