import http from "../../services/http";
import { 
    setLocalesList,
    setLoadingLocales,
    setErrorMessageLocales
}           from "./locale.actions";

export const getLocalesList = () => {
    return async dispatch => {
        dispatch(setLoadingLocales(true));
        dispatch(setErrorMessageLocales(null))

        try {
            const response =  await http.get(`locales`)
            const data = response.data;

            dispatch(setLocalesList(data["hydra:member"]))
        } catch(e) {
            dispatch(setErrorMessageLocales(e.message));
        } finally {
            dispatch(setLoadingLocales(false))
        }
    }
}
