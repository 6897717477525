import http from "../../services/http";
import { 
    setCompanyList,
    setLoadingCompany,
    setCompanyError,
    setSingleCompany,
    setSingleCompanyError,
    setErrorUpdateCompany,
    setPostCompanyError,
    setPostCompanySucceed,
    setPostCompanyLoading,
    setUpdateLoadingCompany,
    setUpdateSuccedCompany
}           from "./company.actions";

export const getCompanyList = () => {
    return async dispatch => {
        dispatch(setLoadingCompany(true))
        dispatch(setCompanyError(null))

        try {
            const response = await http.get(`companies`)
            const data = response.data

            dispatch(setCompanyList(data["hydra:member"]))
        } catch(e) {
            dispatch(setCompanyError(e.message))
        } finally {
            dispatch(setLoadingCompany(false))
        }
    }
}

export const getSingleCompany = (companyId) => {
    return async dispatch => {
        dispatch(setSingleCompanyError(null))

        try {
            const response = await http.get(`companies/${companyId}`)

            dispatch(setSingleCompany(response.data))
        } catch(e) {
            dispatch(setSingleCompanyError(e.message))
        }
    }
}

export const updateSingleCompany = (companyId, data) => {
    return async dispatch => {
        dispatch(setUpdateLoadingCompany(true))
        dispatch(setUpdateSuccedCompany(false))
        try {
            await http.patch(`companies/${companyId}`,
                {
                    address  : data.address,
                    city     : data.city,
                    name     : data.name,
                    number   : data.number,
                    vatNumber: data.vatNumber
                }
            )
            dispatch(setUpdateSuccedCompany(true))
            dispatch(setUpdateLoadingCompany(false))
        } catch(e) {

            dispatch(setErrorUpdateCompany(e.message))
        } finally {
            setTimeout(() => {
                dispatch(setUpdateSuccedCompany(false))
                dispatch(setErrorUpdateCompany(null))
            }, 3000)
        }
    }
}

export const postCompany = (data) => {
    return async dispatch => {
        dispatch(setPostCompanyError(null))
        dispatch(setPostCompanyLoading(true))
        dispatch(setPostCompanySucceed(false))
        try {
            await http.post(`companies`,
                {
                    address  : data.address,
                    city     : data.city,
                    country  : data.country,
                    name     : data.name,
                    number   : data.number,
                    vatNumber: data.vatNumber
                }
            )
            dispatch(setPostCompanyLoading(false))
            dispatch(setPostCompanySucceed(true))
        } catch(e) {
            dispatch(setPostCompanyError(e.message))
        } finally {
            setTimeout(() => {
                dispatch(setPostCompanySucceed(false))
                dispatch(setPostCompanyError(null))
            }, 3000)
        }
    }
}
