import React           from "react";
import {Button, Modal} from "react-bootstrap";

class RefundErrorsModal extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        return (
            <div className="modal-wrap">
                <Modal
                    show={this.props.show}
                    onHide={this.handleClose}
                    backdrop="static"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>
                            <strong className="text-danger">Warning!</strong> Payments In Refund Queue
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        This client has been added to refund queue because {this.props.totalRefundErrors} of the payments cannot be processed right now.
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            variant="success"
                            onClick={this.props.onClose}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default RefundErrorsModal;
