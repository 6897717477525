import {
    SET_PROJECT_LIST,
    SET_PROJECT_LOADING,
    SET_PROJECT_ERROR,
    SET_SINGLE_PROJECT,
    SET_UPDATE_ERROR,
    SET_UPDATE_SUCCESS,
    SET_UPDATE_LOADING,
    SET_CREATE_ERROR,
    SET_CREATE_SUCCESS,
    SET_CREATE_LOADING,
} from "./project.types";

const INITIAL_STATE = {
    setProjectList   : [],
    setProjectLoading: false,
    setProjectError  : null,
    setSingleProject : [],
    setUpdateError   : null,
    setUpdateSuccess : false,
    setUpdateLoading : false,
    setCreateSuccess : false,
    setCreateError   : null,
    setCreateLoading : false
};

const projectReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_PROJECT_LIST:
            return {
                ...state,
                setProjectList: action.payload
            };
        case SET_PROJECT_LOADING:
            return {
                ...state,
                setProjectLoading: action.payload
            };
        case SET_PROJECT_ERROR:
            return {
                ...state,
                setProjectError: action.payload
            };
        case SET_SINGLE_PROJECT:
            return {
                ...state,
                setSingleProject: action.payload
            };
        case SET_UPDATE_ERROR:
            return {
                ...state,
                setUpdateError: action.payload
            };
        case SET_UPDATE_SUCCESS:
            return {
                ...state,
                setUpdateSuccess: action.payload
            };
        case SET_CREATE_ERROR:
            return {
                ...state,
                setCreateError: action.payload
            };
        case SET_CREATE_SUCCESS:
            return {
                ...state,
                setCreateSuccess: action.payload
            };
        case SET_CREATE_LOADING:
            return {
                ...state,
                setCreateLoading: action.payload
            };
        case SET_UPDATE_LOADING:
            return {
                ...state,
                setUpdateLoading: action.payload
            };

        default:
            return state;
    }
};

export default projectReducer;
