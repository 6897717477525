export default {
    "AG": "AG - Agrigento",
    "AL": "AL - Alessandria",
    "AN": "AN - Ancona",
    "AO": "AO - Aosta",
    "AR": "AR - Arezzo",
    "AP": "AP - Ascoli Piceno",
    "AT": "AT - Asti",
    "AV": "AV - Avellino",
    "BA": "BA - Bari",
    "BT": "BT - Barletta-Andria-Trani",
    "BL": "BL - Belluno",
    "BN": "BN - Benevento",
    "BG": "BG - Bergamo",
    "BI": "BI - Biella",
    "BO": "BO - Bologna",
    "BZ": "BZ - Bolzano",
    "BS": "BS - Brescia",
    "BR": "BR - Brindisi",
    "CA": "CA - Cagliari",
    "CL": "CL - Caltanissetta",
    "CB": "CB - Campobasso",
    "CI": "CI - Carbonia-Iglesias",
    "CE": "CE - Caserta",
    "CT": "CT - Catania",
    "CZ": "CZ - Catanzaro",
    "CH": "CH - Chieti",
    "CO": "CO - Como",
    "CS": "CS - Cosenza",
    "CR": "CR - Cremona",
    "KR": "KR - Crotone",
    "CN": "CN - Cuneo",
    "EN": "EN - Enna",
    "ES": "ES - Estero",
    "FM": "FM - Fermo",
    "FE": "FE - Ferrara",
    "FI": "FI - Firenze",
    "FG": "FG - Foggia",
    "FC": "FC - Forlì-Cesena",
    "FR": "FR - Frosinone",
    "GE": "GE - Genoa",
    "GO": "GO - Gorizia",
    "GR": "GR - Grosseto",
    "IM": "IM - Imperia",
    "IS": "IS - Isernia",
    "SP": "SP - La Spezia",
    "AQ": "AQ - L'Aquila",
    "LT": "LT - Latina",
    "LE": "LE - Lecce",
    "LC": "LC - Lecco",
    "LI": "LI - Livorno",
    "LO": "LO - Lodi",
    "LU": "LU - Lucca",
    "MC": "MC - Macerata",
    "MN": "MN - Mantova",
    "MS": "MS - Massa and Carrara",
    "MT": "MT - Matera",
    "ME": "ME - Messina",
    "MI": "MI - Milano",
    "MO": "MO - Modena",
    "MB": "MB - Monza and Brianza",
    "NA": "NA - Napoli",
    "NO": "NO - Novara",
    "NU": "NU - Nuoro",
    "OR": "OR - Oristano",
    "PD": "PD - Padova",
    "PA": "PA - Palermo",
    "PR": "PR - Parma",
    "PV": "PV - Pavia",
    "PG": "PG - Perugia",
    "PU": "PU - Pesaro and Urbino",
    "PE": "PE - Pescara",
    "PC": "PC - Piacenza",
    "PI": "PI - Pisa",
    "PT": "PT - Pistoia",
    "PN": "PN - Pordenone",
    "PZ": "PZ - Potenza",
    "PO": "PO - Prato",
    "RG": "RG - Ragusa",
    "RA": "RA - Ravenna",
    "RC": "RC - Reggio Calabria",
    "RE": "RE - Reggio Emilia",
    "RI": "RI - Rieti",
    "RN": "RN - Rimini",
    "RM": "RM - Roma",
    "RO": "RO - Rovigo",
    "SA": "SA - Salerno",
    "SS": "SS - Sassari",
    "SV": "SV - Savona",
    "SI": "SI - Siena",
    "SO": "SO - Sondrio",
    "SR": "SR - Syracusa",
    "TA": "TA - Taranto",
    "TE": "TE - Teramo",
    "TR": "TR - Terni",
    "TP": "TP - Trapani",
    "TN": "TN - Trento",
    "TV": "TV - Treviso",
    "TS": "TS - Trieste",
    "UD": "UD - Udine",
    "VA": "VA - Varese",
    "VE": "VE - Venezia",
    "VB": "VB - Verbano-Cusio-Ossola",
    "VC": "VC - Vercelli",
    "VR": "VR - Verona",
    "VV": "VV - Vibo Valentia",
    "VI": "VI - Vicenza",
    "VT": "VT - Viterbo"
};
