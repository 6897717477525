import React               from 'react';
import Navigation          from "../../../components/Navigation/Navigation";
import {
    Breadcrumb,
    Button,
    Col,
    Row,
    Table
}                          from "react-bootstrap";
import {Link}              from "react-router-dom";
import routerConstants     from "../../../constants/router-constants";
import {getWebsitesConfig} from "../../../redux/WebsitesConfig/websites-config.async-actions";
import {connect}           from "react-redux";
import DateUtils           from "../../../services/DateUtils";
import UUIDs               from "../../../services/UUIDs";
import Loader              from '../../../components/Common/Loader';

class ListWebsitesConfig extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sideMenu: true
        };
    }

    async componentDidMount() {
        await this?.props?.getWebsitesConfig();
    }

    goToRoute(route) {
        this.props.history.push(route);
    }

    _onSideMenu = (active) => {
        this.setState({sideMenu: active});
    };

    mapWebsitesConfig = () => {

        return this.props.websitesConfig.map(websitesConfig => {
            return <>
                <tr key={websitesConfig["@id"]}>
                    <td>
                        {websitesConfig.slugConfig}
                    </td>
                    <td>
                        {websitesConfig.dataConfig["text"]}
                    </td>
                    <td>
                        {websitesConfig.enabledConfig ? "Enabled" : "Disabled"}
                    </td>
                    <td>
                        {DateUtils.formatISO8601ToFormat(websitesConfig.createdAt)}
                        &nbsp;<small>({DateUtils.formatISO8601ToHumanReadable(websitesConfig.createdAt)})</small>
                    </td>
                    <td>
                        {DateUtils.formatISO8601ToFormat(websitesConfig.updatedAt)}
                        &nbsp;<small>({DateUtils.formatISO8601ToHumanReadable(websitesConfig.updatedAt)})</small>
                    </td>
                    <td>
                        <Button
                            variant="outline-primary"
                            as={"button"}
                            onClick={e => this.goToRoute(`/list-websites-config/update/${UUIDs.getEntityUUID(
                                websitesConfig["@id"])}`, e)}
                        >
                            Edit
                        </Button>
                    </td>
                </tr>
            </>;
        });
    }

    render() {
        let loader = null;

        if (this.props.websitesConfigLoading) {
            loader = <Loader message="Loading..."/>;
        }

        return <>
            <div className="page-wrapper">
                {/* Navigation */}
                <Navigation onClick={this._onSideMenu}/>
                {/* End Navigation */}
                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? "" : "hide-sidemenu"}`}>
                    {/* Loader */}
                    {loader}
                    {/* End Loader */}

                    <div className="main-content-header">
                        <Breadcrumb>
                            <Link to={routerConstants.dashboard} className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Breadcrumb.Item active>
                                Alert Banner List
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>

                    {true === this.props.successEdit &&
                        <div className="alert alert-success" role="alert">
                            Success
                        </div>
                    }

                    <Row>
                        <Col lg={12}>
                            <div className="card mb-4">
                                <div className="card-body">

                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <h5 className="card-title float-lg-left">Alert Banner</h5>
                                    </div>

                                    <Table className="m-0" responsive>
                                        <thead>
                                            <tr key="table_headers">
                                                <th width={"15%"}>Slug Config</th>
                                                <th width={"25%"}>Text Config</th>
                                                <th width={"15%"}>Enabled Config</th>
                                                <th width={"15%"}>Created At</th>
                                                <th width={"15%"}>Updated At</th>
                                                <th width={"15%"}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.mapWebsitesConfig()}
                                        </tbody>
                                    </Table>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        websitesConfig	      : state.websitesConfig.websitesConfig,
        websitesConfigLoading : state.websitesConfig.loadingWebsitesConfig,
        websitesConfigError   : state.websitesConfig.websitesConfigError,
        successEdit           : state.websitesConfig.editWebsiteConfigSuccess,
    };
}

const mapActionsToProps = {
    getWebsitesConfig
}

const connectedListWebsitesConfig = connect(
    mapStateToProps,
    mapActionsToProps
)(ListWebsitesConfig);

export default connectedListWebsitesConfig;
