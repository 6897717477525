import {
    SET_WEBSITES_CONFIG,
    SET_LOADING_WEBSITES_CONFIG,
    SET_WEBSITES_CONFIG_ERROR,
    SET_SINGLE_WEBSITE_CONFIG_ERROR,
    SET_LOADING_SINGLE_WEBSITE_CONFIG,
    SET_SINGLE_WEBSITE_CONFIG,
    SET_EDIT_WEBSITE_CONFIG_ERROR,
    SET_EDIT_WEBSITE_CONFIG_SUCCESS,
    SET_EDIT_WEBSITE_CONFIG_LOADING
} from "./websites-config.types";

export const setWebsitesConfig = data => {
    return {
        type   : SET_WEBSITES_CONFIG,
        payload: data
    };
}

export const setLoadingWebsitesConfig = loading => {
    return {
        type   : SET_LOADING_WEBSITES_CONFIG,
        payload: loading
    };
}

export const setWebsitesConfigError = error => {
    return {
        type   : SET_WEBSITES_CONFIG_ERROR,
        payload: error
    };
}

export const setSingleWebsiteConfig = data => {
    return {
        type   : SET_SINGLE_WEBSITE_CONFIG,
        payload: data
    };
}

export const setLoadingSingleWebsiteConfig = loading => {
    return {
        type   : SET_LOADING_SINGLE_WEBSITE_CONFIG,
        payload: loading
    };
}

export const setSingleWebsiteConfigError = error => {
    return {
        type   : SET_SINGLE_WEBSITE_CONFIG_ERROR,
        payload: error
    };
}

export const setEditWebsiteConfigError = error => {
    return {
        type   : SET_EDIT_WEBSITE_CONFIG_ERROR,
        payload: error
    };
}

export const setEditWebsiteConfigSuccess = success => {
    return {
        type   : SET_EDIT_WEBSITE_CONFIG_SUCCESS,
        payload: success
    };
}

export const setEditWebsiteConfigLoading = loading => {
    return {
        type   : SET_EDIT_WEBSITE_CONFIG_LOADING,
        payload: loading
    };
}
