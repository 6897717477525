import {
    SET_ORDER_STATISTICS,
    SET_ORDER_STATISTICS_LOADER
} from "./stats-order-active.types";

const INITIAL_STATE = {
    orderStatistics       : [],
    orderStatisticsLoader : false
}

const statsOrderActive = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_ORDER_STATISTICS:
            return {
                ...state,
                orderStatistics: action.payload
            };
        case SET_ORDER_STATISTICS_LOADER:
            return {
                ...state,
                orderStatisticsLoader: action.payload
            };
        default:
            return state;
    }
}

export default statsOrderActive;
