import React                       from "react";
import Navigation                  from "../../../components/Navigation/Navigation";
import {Link}                      from "react-router-dom";
import routerConstants             from "../../../constants/router-constants";
import {
    Breadcrumb, 
    Row, 
    Col, 
    Form, 
    Button,
    Card
}                                  from "react-bootstrap";
import {connect}                   from "react-redux";
import {useForm}                   from "react-hook-form";
import {createProductSubscription} from "../../../redux/Products/products.async-actions";
import Loader                      from "../../../components/Common/Loader";
import {getPaymentPlanSettingList} from "../../../redux/PaymentPlanSettings/payment-plan-setting.async-actions";
import Select                      from "react-select";

class CreateProductSubscriptionComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sideMenu               : true,
            paymentPlanSetting     : "",
            paymentPlanSettingData : {},
            paymentPlanError       : null
        };

        this.paymentPlanSettingsList = [];
    }

    _onSideMenu = (active) => {
        this.setState({sideMenu: active});
    };

    ProductSubscriptionForm = () => {
        return <>
            <Card>
                <Card.Body>
                    <div className="card-header">
                        <Card.Title>Create Product Subscription</Card.Title>
                    </div>
                    <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="name">
                                    Name
                                </label>
                                <input
                                    id="name"
                                    type="text"
                                    className="form-control"
                                    placeholder="Name"
                                    name="type"
                                    {
                                        ...this
                                            .props
                                            .register(
                                                "name",
                                                {
                                                    required: {
                                                        value  : true,
                                                        message: <span style={{color: "red"}}>This field is required</span>
                                                    }
                                                }
                                            )
                                    }
                                />
                                <Form.Text className="text-muted">
                                    A name that you will recognize across the system.
                                </Form.Text>
                                {
                                    null !== this?.props?.errors?.type?.message &&
                                    <span className="text-danger">
                                        {this?.props?.errors?.type?.message}
                                    </span>
                                }
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="paymentPlan">
                                    Payment Plan
                                </label>
                                <Select
                                    id="paymentPlan"
                                    value={this.state.paymentPlanSetting}
                                    onChange={this.onPaymentPlanSettingValueChange}
                                    options={this.paymentPlanSettingsList}
                                    backspaceRemovesValue={true}
                                    escapeClearsValue={true}
                                    menuPlacement={"auto"}
                                    isMulti
                                />
                                <Form.Text className="text-muted">
                                    Choose the payment plan you need for this product subscription.
                                </Form.Text>
                                {
                                    this.props.paymentPlanSettingsError !== null &&
                                    <span className="text-danger">
                                        Something went wrong and cannot load the Payment Plan Settings. Please
                                        contact the development team to ask for more information.
                                    </span>
                                }
                                {
                                    null !== this.state.paymentPlanError &&
                                    <span className="text-danger">
                                        {this.state.paymentPlanError}
                                    </span>
                                }
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="trialPeriodPrice">
                                    Trial Period Price
                                </label>
                                <input
                                    id="trialPeriodPrice"
                                    type="number"
                                    min={0}
                                    step=".01"
                                    className="form-control"
                                    placeholder="Trial Period Price"
                                    name="type"
                                    {
                                        ...this
                                            .props
                                            .register(
                                                "trialPeriodPrice",
                                                {
                                                    required: {
                                                        value  : true,
                                                        message: <span style={{color: "red"}}>This field is required</span>
                                                    },
                                                    min     : {
                                                        value  : 0,
                                                        message:
                                                            <span style={{color: "red"}}>Value should be greater than 0</span>
                                                    }
                                                }
                                            )
                                    }
                                />
                                <Form.Text className="text-muted">
                                    Enter the amount the customer will be charged for the trial period
                                </Form.Text>
                                {
                                    null !== this?.props?.errors?.trialPeriodPrice?.message &&
                                    <span className="text-danger">
                                        {this?.props?.errors?.trialPeriodPrice?.message}
                                    </span>
                                }  
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="trialPeriodDays">
                                    Trial Period Days
                                </label>
                                <input
                                    id="trialPeriodDays"
                                    type="number"
                                    min={0}
                                    className="form-control"
                                    placeholder="Trial Period Days"
                                    name="type"
                                    {
                                        ...this
                                            .props
                                            .register(
                                                "trialPeriodDays",
                                                {
                                                    required: {
                                                        value  : true,
                                                        message: <span style={{color: "red"}}>This field is required</span>
                                                    },
                                                    min     : {
                                                        value  : 0,
                                                        message:
                                                            <span style={{color: "red"}}>Value should be greater than 0</span>
                                                    }
                                                }
                                            )
                                    }
                                />
                                <Form.Text className="text-muted">
                                    Enter the amount of trial days
                                </Form.Text>
                                {
                                    null !== this?.props?.errors?.trialPeriodDays?.message &&
                                    <span className="text-danger">
                                        {this?.props?.errors?.trialPeriodDays?.message}
                                    </span>
                                }
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="trialMaxPurchasesAllowed">
                                    Trial Max Purchases Allowed
                                </label>
                                <input
                                    id="trialMaxPurchasesAllowed"
                                    type="number"
                                    min={0}
                                    className="form-control"
                                    placeholder="Trial Max Purchases Allowed"
                                    name="type"
                                    {
                                        ...this
                                            .props
                                            .register(
                                                "trialMaxPurchasesAllowed",
                                                {
                                                    required: {
                                                        value  : true,
                                                        message: <span style={{color: "red"}}>This field is required</span>
                                                    },
                                                    min     : {
                                                        value  : 0,
                                                        message:
                                                            <span style={{color: "red"}}>Value should be greater than 0</span>
                                                    }
                                                }
                                            )
                                    }
                                />
                                <Form.Text className="text-muted">
                                    Enter the amount of Trial Max Purchases Allowed
                                </Form.Text>
                                {
                                    null !== this?.props?.errors?.trialMaxPurchasesAllowed?.message &&
                                    <span className="text-danger">
                                        {this?.props?.errors?.trialMaxPurchasesAllowed?.message}
                                    </span>
                                }
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="subscriptionMaxPurchasesAllowed">
                                    Subscription Max Purchases Allowed
                                </label>
                                <input
                                    id="subscriptionMaxPurchasesAllowed"
                                    type="number"
                                    min={0}
                                    className="form-control"
                                    placeholder="Subscription Max Purchases Allowed"
                                    name="type"
                                    {
                                        ...this
                                            .props
                                            .register(
                                                "subscriptionMaxPurchasesAllowed",
                                                {
                                                    required: {
                                                        value  : true,
                                                        message: <span style={{color: "red"}}>This field is required</span>
                                                    },
                                                    min     : {
                                                        value  : 0,
                                                        message:
                                                            <span style={{color: "red"}}>Value should be greater than 0</span>
                                                    }
                                                }
                                            )
                                    }
                                />
                                <Form.Text className="text-muted">
                                    Enter the amount of subscriptionMaxPurchasesAllowed
                                </Form.Text>
                                {
                                    null !== this?.props?.errors?.subscriptionMaxPurchasesAllowed?.message &&
                                    <span className="text-danger">
                                        {this?.props?.errors?.subscriptionMaxPurchasesAllowed?.message}
                                    </span>
                                }
                            </div>
                        </div>

                        <div className="form-group">
                            <Button
                                variant="primary"
                                type="submit"
                            >
                                Create Product Subscription
                            </Button>
                        </div>
                    </form>
                </Card.Body>
            </Card>
        </>                  
    };

    onPaymentPlanSettingValueChange = paymentPlanSetting => this.setState({paymentPlanSetting});

    async componentDidMount() {
        await this.props.getPaymentPlanSettingList();

        if (null === this.props.paymentPlanSettingsError) {
            this.paymentPlanSettingsList = this.props.paymentPlanSettingsList.map(
                planSettings => {
                    return {
                        value: planSettings["@id"],
                        label: planSettings.name
                    };
                }
            );

            this.setState(
                {
                    paymentPlanSettingData: this.paymentPlanSettingsList
                }
            );
        }
    }

    onSubmit = async (data) => {
        let paymentPlanArryIds = [];

        this.state.paymentPlanSetting.map(
            paymentPlanSetting => { 
                return paymentPlanArryIds.push(paymentPlanSetting.value);
            }
        );
        

        data.trialPeriodPrice                = Number(data.trialPeriodPrice);
        data.trialPeriodDays                 = Number(data.trialPeriodDays);
        data.trialMaxPurchasesAllowed        = Number(data.trialMaxPurchasesAllowed);
        data.subscriptionMaxPurchasesAllowed = Number(data.subscriptionMaxPurchasesAllowed);
        data.paymentPlan                     = paymentPlanArryIds;

        await this.props.createProductSubscription(data);

        if (this.props.createProductSuccess)  {
            this.props.history.push(`/product-subscription`);
        }
    };

    render() {
        const {
            createLoading,
            createProductError,
            paymentPlanSettingsLoading
        }          = this.props;
        let loader = null;

        if (createLoading || paymentPlanSettingsLoading) {
            loader = <Loader message="Loading..." />;
        }

        return <>
            <div className="page-wrapper">
                {/* Navigation */}
                <Navigation onClick={this._onSideMenu} />
                {/* End Navigation */}
                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? "" : "hide-sidemenu"}`}>
                    {loader}

                    {null !== createProductError &&
                        <div className="alert alert-danger" role="alert">
                            Something went wrong. Please try again or contact the developers for more information.
                        </div>
                    }

                    <div className="main-content-header">
                        <Breadcrumb>
                            <Link to={routerConstants.dashboard} className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Link to={routerConstants.listProductSubscription} className="breadcrumb-item">
                                Product Subscription List
                            </Link>
                            <Breadcrumb.Item active>
                                Create Product Subscription
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <Row>
                        <Col lg={12}>
                            {this.ProductSubscriptionForm()}
                        </Col>
                    </Row>
                </div>
            </div>
        </>;
    }
}

const CreateProductSubscriptionPage = props => {
    const {register, handleSubmit, formState: {errors}} = useForm();

    return <CreateProductSubscriptionComponent
        {...props}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
    />;
};

const mapStateToProps = state => {
    return {
        createProductSuccess      : state.products.setCreateProductSuccess,
        createProductError        : state.products.setCreateProductError,
        createLoading             : state.products.setLoadingProductsCreate,
        paymentPlanSettingsList   : state.paymentPlanSettings.paymentPlanSettingList,
        paymentPlanSettingsLoading: state.paymentPlanSettings.loadingPaymentPlanSettingList,
        paymentPlanSettingsError  : state.paymentPlanSettings.paymentPlanSettingListError
    };
};

const mapActionsToProps = {
    createProductSubscription,
    getPaymentPlanSettingList
};

const CreateProductSubscriptionConnected = connect(
    mapStateToProps,
    mapActionsToProps
)(CreateProductSubscriptionPage);

export default CreateProductSubscriptionConnected;
