import {
    SET_LOADING_PRODUCTS_LIST,
    SET_PRODUCTS_LIST,
    SET_PRODUCTS_LIST_ERROR,
    SET_PRODUCTS_UPDATE_ERROR,
    SET_PRODUCTS_UPDATE_SUCCESS,
    SET_SINGLE_PRODUCT,
    SET_SINGLE_PRODUCT_ERROR,
    SET_SINGLE_PRODUCT_LOADING,
    SET_CREATE_PRODUCT_ERROR,
    SET_CREATE_PRODUCT_SUCCESS,
    SET_LOADING_PRODUCTS_CREATE,
    SET_LOADING_PRODUCTS_UPDATE
} from "./products.types";

const INITIAL_STATE = {
    productsList             : [],
    loadingProductsList      : false,
    productListError         : null,
    setProductsUpdatedError  : null,
    setProductsUpdatedSuccess: false,
    setSingleProduct         : [],
    setSingleProductError    : null,
    setSingleProductLoading  : false,
    setCreateProductError    : null,
    setCreateProductSuccess  : false,
    setLoadingProductsCreate : false,
    setLoadingProductsUpdate : false
};

const productsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_PRODUCTS_LIST:
            return {
                ...state,
                productsList: action.payload
            };
        case SET_LOADING_PRODUCTS_LIST:
            return {
                ...state,
                loadingProductsList: action.payload
            };
        case SET_PRODUCTS_LIST_ERROR:
            return {
                ...state,
                productListError: action.payload
            };
        case SET_PRODUCTS_UPDATE_ERROR:
            return {
                ...state,
                setProductsUpdatedError: action.payload
            };
        case SET_PRODUCTS_UPDATE_SUCCESS:
            return {
                ...state,
                setProductsUpdatedSuccess: action.payload
            };
        case SET_SINGLE_PRODUCT:
            return {
                ...state,
                setSingleProduct: action.payload
            };
        case SET_SINGLE_PRODUCT_ERROR:
            return {
                ...state,
                setSingleProductError: action.payload
            };
        case SET_SINGLE_PRODUCT_LOADING:
            return {
                ...state,
                setSingleProductLoading: action.payload
            };
        case SET_CREATE_PRODUCT_ERROR:
            return {
                ...state,
                setCreateProductError: action.payload
            };
        case SET_CREATE_PRODUCT_SUCCESS:
            return {
                ...state,
                setCreateProductSuccess: action.payload
            };
        case SET_LOADING_PRODUCTS_CREATE:
            return {
                ...state,
                setLoadingProductsCreate: action.payload
            };
        case SET_LOADING_PRODUCTS_UPDATE:
            return {
                ...state,
                setLoadingProductsUpdate: action.payload
            };

        default:
            return state;
    }
};

export default productsReducer;
