export const SET_COMPANY_LIST           = "SET_COMPANY_LIST";
export const SET_LOADING_COMPANY        = "SET_LOADING_COMPANY";
export const SET_COMPANY_ERROR          = "SET_COMPANY_ERROR";
export const SET_SINGLE_COMPANY         = "SET_SINGLE_COMPANY";
export const SET_SINGLE_COMPANY_ERROR   = "SET_SINGLE_COMPANY_ERROR";
export const SET_ERROR_UPDATE_COMPANY   = "SET_ERROR_UPDATE_COMPANY";
export const SET_POST_COMPANY_LOADING   = "SET_POST_COMPANY_LOADING";
export const SET_POST_COMPANY_SUCCEED   = "SET_POST_COMPANY_SUCCEED";
export const SET_POST_COMPANY_ERROR     = "SET_POST_COMPANY_ERROR";
export const SET_UPDATE_LOADING_COMPANY = "SET_UPDATE_LOADING_COMPANY";
export const SET_UPDATE_SUCCED_COMPANY  = "SET_UPDATE_SUCCED_COMPANY";
