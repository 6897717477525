import {applyMiddleware, createStore} from "redux";
import {composeWithDevTools}          from "redux-devtools-extension";
import rootReducer                    from "./rootReducer";
import ReduxThunk                     from "redux-thunk";
import {AlphaCookieMiddleware}      from "./Middlewares/AlphaCookieMiddleware";

const middleware = [
    ReduxThunk,
    AlphaCookieMiddleware
];
const devTools   = process.env.NODE_ENV === "production" ?
    applyMiddleware(...middleware) :
    composeWithDevTools(applyMiddleware(...middleware));

const store = createStore(rootReducer, devTools);

export default store;
