export const SET_EMAIL_TEMPLATES_LIST          		= "SET_EMAIL_TEMPLATES_LIST";
export const SET_ERROR_EMAIL_TEMPLATES         		= "SET_ERROR_EMAIL_TEMPLATES";
export const SET_LOADING_EMAIL_TEMPLATES       		= "SET_LOADING_EMAIL_TEMPLATES";
export const SET_SINGLE_EMAIL_TEMPLATE         		= "SET_SINGLE_EMAIL_TEMPLATE";
export const SET_SINGLE_EMAIL_TEMPLATE_ERROR   		= "SET_SINGLE_EMAIL_TEMPLATE_ERROR";
export const SET_SINGLE_EMAIL_TEMPLATE_LOADING	 	= "SET_SINGLE_EMAIL_TEMPLATE_LOADING";
export const SET_UPDATE_EMAIL_TEMPLATE_SUCCESS 		= "SET_UPDATE_EMAIL_TEMPLATE_SUCCESS";
export const SET_UPDATE_EMAIL_TEMPLATE_ERROR   		= "SET_UPDATE_EMAIL_TEMPLATE_ERROR";
export const SET_UPDATE_EMAIL_TEMPLATE_LOADING 		= "SET_UPDATE_EMAIL_TEMPLATE_LOADING";
export const SET_CREATE_EMAIL_TEMPLATE_LOADING 		= "SET_CREATE_EMAIL_TEMPLATE_LOADING";
export const SET_CREATE_EMAIL_TEMPLATE_SUCCESS		= "SET_CREATE_EMAIL_TEMPLATE_SUCCESS";
export const SET_CREATE_EMAIL_TEMPLATE_ERROR   		= "SET_CREATE_EMAIL_TEMPLATE_ERROR";
export const SET_DELETE_EMAIL_TEMPLATE_SUCCESS 		= "SET_DELETE_EMAIL_TEMPLATE_SUCCESS";
export const SET_DELETE_EMAIL_TEMPLATE_ERROR   		= "SET_DELETE_EMAIL_TEMPLATE_ERROR";
export const SET_DELETE_EMAIL_TEMPLATE_LOADING 		= "SET_DELETE_EMAIL_TEMPLATE_LOADING"
export const SET_SENDGRID_URL                  		= "SET_SENGRID_URL";
export const SET_SENDGRID_URL_LOADING          		= "SET_SENDGRID_URL_LOADING";
export const SET_SENDGRID_URL_ERROR            		= "SET_SENDGRID_URL_ERROR";
export const SET_SENDGRID_URL_SUCCESS          		= "SET_SENDGRID_URL_SUCCESS";
export const SET_EMAIL_TEMPLATE_CURRENT_PAGE   		= "SET_EMAIL_TEMPLATE_CURRENT_PAGE";
export const SET_EMAIL_TEMPLATE_FIRST_PAGE     		= "SET_EMAIL_TEMPLATE_FIRST_PAGE";
export const SET_EMAIL_TEMPLATE_LAST_PAGE      		= "SET_EMAIL_TEMPLATE_LAST_PAGE";
export const SET_EMAIL_TEMPLATE_PREV_PAGE      		= "SET_EMAIL_TEMPLATE_PREV_PAGE";
export const SET_EMAIL_TEMPLATE_NEXT_PAGE      		= "SET_EMAIL_TEMPLATE_NEXT_PAGE";
export const SET_TOTAL_EMAIL_TEMPLATES         		= "SET_TOTAL_EMAIL_TEMPLATES";
export const SET_MEILI_EMAIL_TEMPLATES 		  		= "SET_MEILI_EMAIL_TEMPLATES";
export const SET_MEILI_TOTAL_EMAIL_TEMPLATES   		= "SET_MEILI_TOTAL_EMAIL_TEMPLATES";
export const SET_MEILI_TOTAL_PAGES             		= "SET_MEILI_TOTAL_PAGES";
export const SET_MEILI_EMAIL_TEMPLATES_ERROR	  	    = "SET_MEILI_EMAIL_TEMPLATES_ERROR";
export const SET_MEILI_EMAIL_TEMPLATES_CURRENT_PAGE	= "SET_MEILI_EMAIL_TEMPLATES_CURRENT_PAGE";
export const SET_MEILI_EMAIL_TEMPLATES_FIRST_PAGE    = "SET_MEILI_EMAIL_TEMPLATES_FIRST_PAGE";
export const SET_MEILI_EMAIL_TEMPLATES_LAST_PAGE     = "SET_MEILI_EMAIL_TEMPLATES_LAST_PAGE";
export const SET_MEILI_EMAIL_TEMPLATES_PREV_PAGE     = "SET_MEILI_EMAIL_TEMPLATES_PREV_PAGE";
export const SET_MEILI_EMAIL_TEMPLATES_NEXT_PAGE     = "SET_MEILI_EMAIL_TEMPLATES_NEXT_PAGE";
export const SET_MEIlI_RESET_PAGINATION              = "SET_MEIlI_RESET_PAGINATION";
