import {
    SET_ORDER_STATISTICS,
    SET_ORDER_STATISTICS_LOADER
} from "./stats-order-active.types";

export const setOrderStatistics = data => {
    return {
        type   : SET_ORDER_STATISTICS,
        payload: data
    }
}

export const setOrderStatisticsLoader = loading => {
    return {
        type   : SET_ORDER_STATISTICS_LOADER,
        payload: loading
    }
}
