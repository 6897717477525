import React             from "react";
import Navigation        from "../../../components/Navigation/Navigation";
import {
    Breadcrumb,
    Row,
    Col,
    Button,
    Table
}                        from "react-bootstrap";
import {Link}            from "react-router-dom";
import routerConstants   from "../../../constants/router-constants";
import Footer            from "../../Footer/Footer";
import {getProductsList} from "../../../redux/Products/products.async-actions";
import {connect}         from "react-redux";
import UUIDs             from "../../../services/UUIDs";
import DateUtils         from "../../../services/DateUtils";
import ListSorter        from "../../../Utils/ListSorter";

class ListProductSubscriptionPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sideMenu: true
        };
    }

    componentDidMount() {
        this.props.getProductsList();
    }

    goToRoute(route) {
        this.props.history.push(route);
    }

    _onSideMenu = (active) => {
        this.setState({sideMenu: active});
    };

    productSubscriptionTable = () => ListSorter.orderListByProperty(
            this.props.productSubscriptionList, 'name'
        ).map(
            product => {
                const productId = product["@id"];

                return (<tr key={productId}>
                    <td>
                        {product.name}
                    </td>
                    <td>
                        {product.trialPeriodDays}
                    </td>
                    <td>
                        {product.trialPeriodPrice}
                    </td>
                    <td>
                        {product.currency}
                    </td>
                    <td>
                        {DateUtils.formatISO8601ToFormat(product.createdAt)}
                        &nbsp;<small>({DateUtils.formatISO8601ToHumanReadable(product.createdAt)})</small>
                    </td>
                    <td>
                        {DateUtils.formatISO8601ToFormat(product.updatedAt)}
                        &nbsp;<small>({DateUtils.formatISO8601ToHumanReadable(product.updatedAt)})</small>
                    </td>
                    <td>
                        <Button
                            variant="outline-primary"
                            as={"button"}
                            onClick={
                                () => this.goToRoute(
                                    `/product-subscription/update/${UUIDs.getEntityUUID(productId)}`
                                )
                            }
                        >
                            Edit
                        </Button>
                    </td>
                </tr>
            );
        }
    );

    render() {
        return <>
            <div className="page-wrapper">
                {/* Navigation */}
                <Navigation onClick={this._onSideMenu} />
                {/* End Navigation */}
                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? "" : "hide-sidemenu"}`}>
                    <div className="main-content-header">
                        <Breadcrumb>
                            <Link to={routerConstants.dashboard} className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Breadcrumb.Item active>
                                Product Subscription List
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    {true === this.props.createProductSuccess &&
                        <div className="alert alert-success" role="alert">
                            The Product Subscription has been created successfully!
                        </div>
                    }
                    <Row>
                        <Col lg={12}>
                            <div className="card mb-4">
                                <div className="card-body">

                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <h5 className="card-title float-lg-left">Product Subscription</h5>
                                        <Button
                                            onClick={e => this.goToRoute(routerConstants.createProductSubscription, 2)}
                                            variant="outline-primary"
                                            className="mb-2"
                                        >
                                            Create Product Subscription
                                        </Button>
                                    </div>

                                    <Table className="m-0" responsive>
                                        <thead>
                                            <tr key="table_headers">
                                                <th width={"265px"}>Name</th>
                                                <th width={"170px"}>Trial Period Days</th>
                                                <th width={"150px"}>Trial Period Price</th>
                                                <th width={"150px"}>Currency</th>
                                                <th width={"290px"}>Created At</th>
                                                <th width={"290px"}>Updated At</th>
                                                <th width={"100px"}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.productSubscriptionTable()}
                                        </tbody>
                                    </Table>

                                </div>
                            </div>
                        </Col>
                    </Row>

                    {/* Footer */}
                    <div className="flex-grow-1" />
                    <Footer />
                    {/* End Footer */}
                </div>
            </div>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        productSubscriptionList : state.products.productsList,
        createProductSuccess : state.products.setCreateProductSuccess
    };
};

const mapActionsToProps = {
    getProductsList
};

const ListProdcutSubscription = connect(
    mapStateToProps,
    mapActionsToProps
)(ListProductSubscriptionPage);

export default ListProdcutSubscription;