import {
    SET_CLIENT_COMMENTS_DATA,
    SET_CLIENT_COMMENTS_ERROR,
    SET_CLIENT_COMMENTS_LOADING,
    SET_CLIENT_NEW_EMAIL_ADDRESS,
    SET_CLIENTS_CURRENT_PAGE,
    SET_CLIENTS_FIRST_PAGE,
    SET_CLIENTS_LAST_PAGE,
    SET_CLIENTS_LIST,
    SET_CLIENTS_LIST_ERROR,
    SET_CLIENTS_NEXT_PAGE,
    SET_CLIENTS_PAYMENTS,
    SET_CLIENTS_PREV_PAGE,
    SET_LOADING_CLIENTS,
    SET_LOADING_SINGLE_CLIENT,
    SET_MEILI_CLIENTS,
    SET_MEILI_CLIENTS_CURRENT_PAGE,
    SET_MEILI_CLIENTS_ERROR,
    SET_MEILI_CLIENTS_FIRST_PAGE,
    SET_MEILI_CLIENTS_LAST_PAGE,
    SET_MEILI_CLIENTS_NEXT_PAGE,
    SET_MEILI_CLIENTS_PREV_PAGE,
    SET_MEIlI_RESET_CLIENTS_PAGINATION,
    SET_MEILI_TOTAL_CLIENTS,
    SET_MEILI_TOTAL_CLIENTS_PAGES,
    SET_RANDOM_PASSWORD,
    SET_SINGLE_CLIENT,
    SET_SINGLE_CLIENT_ERROR,
    SET_TOTAL_CLIENTS,
    SET_SEND_WELCOME_EMAIL_SUCCESS,
    SET_SEND_WELCOME_EMAIL_ERROR,
    SET_SEND_WELCOME_EMAIL_LOADING,
    SET_REFUND_ERROR
} from "./clients.types";

export const setLoadingClientsList = loading => {
    return {
        type   : SET_LOADING_CLIENTS,
        payload: loading
    };
};

export const setTotalClients = total => {
    return {
        type   : SET_TOTAL_CLIENTS,
        payload: total
    };
};

export const setClientsListError = error => {
    return {
        type   : SET_CLIENTS_LIST_ERROR,
        payload: error
    };
};

export const setClientsList = clients => {
    return {
        type   : SET_CLIENTS_LIST,
        payload: clients
    };
};

export const setLoadingSingleClient = loading => {
    return {
        type   : SET_LOADING_SINGLE_CLIENT,
        payload: loading
    };
};

export const setSingleClient = client => {
    return {
        type   : SET_SINGLE_CLIENT,
        payload: client
    };
};

export const setSingleClientError = client_error => {
    return {
        type   : SET_SINGLE_CLIENT_ERROR,
        payload: client_error
    };
};

export const setClientsCurrentPage = currentPage => {
    return {
        type   : SET_CLIENTS_CURRENT_PAGE,
        payload: currentPage
    };
};

export const setClientsFirstPage = firstPage => {
    return {
        type   : SET_CLIENTS_FIRST_PAGE,
        payload: firstPage
    };
};

export const setClientsLastPage = lastPage => {
    return {
        type   : SET_CLIENTS_LAST_PAGE,
        payload: lastPage
    };
};

export const setClientsNextPage = lastPage => {
    return {
        type   : SET_CLIENTS_NEXT_PAGE,
        payload: lastPage
    };
};

export const setClientsPrevPage = prevPage => {
    return {
        type   : SET_CLIENTS_PREV_PAGE,
        payload: prevPage
    };
};

export const setClientsPayments = payments => {
    return {
        type   : SET_CLIENTS_PAYMENTS,
        payload: payments
    };
};

export const setRandomPassword = password => {
    return {
        type   : SET_RANDOM_PASSWORD,
        payload: password
    };
};

export const setNewCustomerEmailAddress = email => {
    return {
        type   : SET_CLIENT_NEW_EMAIL_ADDRESS,
        payload: email
    };
};

export const setClientCommentsData = comments => {
    return {
        type   : SET_CLIENT_COMMENTS_DATA,
        payload: comments
    };
};

export const setClientCommentsLoading = loading => {
    return {
        type   : SET_CLIENT_COMMENTS_LOADING,
        payload: loading
    };
};

export const setClientCommentsError = error => {
    return {
        type   : SET_CLIENT_COMMENTS_ERROR,
        payload: error
    };
};

export const setMeiliClients = clients => {
    return {
        type   : SET_MEILI_CLIENTS,
        payload: clients
    };
};

export const setMeiliTotalClients = total => {
    return {
        type   : SET_MEILI_TOTAL_CLIENTS,
        payload: total
    };
};

export const setMeiliClientsError = error => {
    return {
        type   : SET_MEILI_CLIENTS_ERROR,
        payload: error
    };
};

export const setMeiliClientsCurrentPage = currentPage => {
    return {
        type   : SET_MEILI_CLIENTS_CURRENT_PAGE,
        payload: currentPage
    };
};

export const setMeiliTotalClientPages = totalPages => {
    return {
        type   : SET_MEILI_TOTAL_CLIENTS_PAGES,
        payload: totalPages
    };
};

export const setMeiliClientsFirstPage = firstPage => {
    return {
        type   : SET_MEILI_CLIENTS_FIRST_PAGE,
        payload: firstPage
    };
};

export const setMeiliClientsLastPage = lastPage => {
    return {
        type   : SET_MEILI_CLIENTS_LAST_PAGE,
        payload: lastPage
    };
};

export const setMeiliClientsPreviousPage = previousPage => {
    return {
        type   : SET_MEILI_CLIENTS_PREV_PAGE,
        payload: previousPage
    };
};

export const setMeiliClientsNextPage = nextPage => {
    return {
        type   : SET_MEILI_CLIENTS_NEXT_PAGE,
        payload: nextPage
    };
};

export const resetMeiliClientsPagination = () => {
    return {
        type: SET_MEIlI_RESET_CLIENTS_PAGINATION
    };
};

export const setSendWelcomeEmailSuccess = success => {
    return {
        type   : SET_SEND_WELCOME_EMAIL_SUCCESS,
        payload: success
    };
}

export const setSendWelcomeEmailError = error => {
    return {
        type   : SET_SEND_WELCOME_EMAIL_ERROR,
        payload: error
    };
}

export const setSendWelcomeEmailLoading = loading => {
    return {
        type   : SET_SEND_WELCOME_EMAIL_LOADING,
        payload: loading
    };
}

export const setRefundError = error => {
    return {
        type   : SET_REFUND_ERROR,
        payload: error
    };
}
